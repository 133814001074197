import { useState } from "react"

const isSupervisor = (msg) => {
  if (!("finishedTask" in msg)) {
    return true
  } else {
    return false
  }
}

const hasTask = (msg) => {
  if ("finishedTask" in msg && msg.finishedTask !== "N/A") {
    return true
  } else {
    return false
  }
}

const SingleChat = ({ messaging, trainee }) => {
  const [newMessage, setNewMessage] = useState("")

  return (
    <div style={{ width: "100%" }}>
      <br></br>
      <span>
        <h3>
          &nbsp;&nbsp;{trainee.lastName}, {trainee.firstName} (
          {trainee.phoneNumber})
        </h3>
      </span>
      <div className="messages">
        {messaging &&
          messaging.map((msg, i) => (
            <div style={{ display: "flex" }} key={i}>
              <span
                style={{
                  backgroundColor: isSupervisor(msg) ? "#BEE3F8" : "#B9F5D0",
                  marginLeft: isSupervisor(msg) ? "auto" : 33,
                  marginRight: isSupervisor(msg) ? 33 : "auto",
                  marginTop: isSupervisor(msg) ? 3 : 10,
                  borderRadius: "20px",
                  padding: "5px 15px",
                  whiteSpace: "pre-wrap",
                  maxWidth: "50%",
                }}
              >
                {msg.messageContent}
              </span>
              <span>{hasTask(msg) ? "(" + msg.finishedTask + ")" : ""}</span>
              <sub
                style={{
                  padding: "5px 15px",
                }}
              >
                {new Date(msg.timeStamp).toLocaleString("en-US", {
                  hour12: false,
                })}
              </sub>
            </div>
          ))}
      </div>
    </div>
  )
}

export default SingleChat
