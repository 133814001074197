import React, { useEffect, useState } from "react"

import DefaultLayout from "../../layouts/defaultLayout"
import LoadingDashboard from "../../components/loadingDashboard"
import EventDetailBody from "../../components/eventDetailBody"

import axios from "axios"

import { Link, useParams } from "react-router-dom"

const EventDetail = () => {
  const { eventID } = useParams()

  const [event, setEvent] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState("")

  useEffect(() => {
    if (eventID && eventID.length > 0) {
      axios
        .get(`/get-event/${eventID}`)
        .then((res) => {
          setEvent(res.data)
          setLoading(false)
        })
        .catch((err) => {
          setError("Error has occured")
          if (err.response.status === 404) {
            setError("An event with this id does not exist")
          } else if (err.response.status === 403) {
            setError("You are not authorized to view this event")
          } else {
            setError(
              "An unexpected error has occured, please return to the previous page and try again"
            )
          }
          setLoading(false)
        })
    }
  }, [eventID])

  if (loading) {
    return <LoadingDashboard />
  }

  if (error && error.length > 0) {
    return (
      <DefaultLayout disableTeams>
        <h5>{error}</h5>
      </DefaultLayout>
    )
  }

  return (
    <DefaultLayout disableTeams>
      <h1>
        {event.title}{" "}
        <Link
          className="default-button"
          to={`/event/${event.id}/edit`}
          style={{ fontSize: "1rem" }}
        >
          Edit Event
        </Link>
      </h1>
      <div style={{ margin: "1rem 0" }}>
        <EventDetailBody event={event} />
        <Link to="/events" style={{ textDecoration: "none" }}>
          <span className="default-button">Go back</span>
        </Link>
      </div>
    </DefaultLayout>
  )
}

export default EventDetail
