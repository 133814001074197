import React, { useState, useEffect } from "react"
import "bootstrap"
import TraineeLayout from "../../layouts/traineeLayout"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { ScaleLoader } from "react-spinners"
// Redux
import { useDispatch } from "react-redux"
import axios from "axios"

// Helper Components
const UploadDocuments = ({
  setUploading,
  myDownloadLinks,
  setMyDownloadLinks,
}) => {
  const [uploadStatus, setUploadStatus] = useState("default")
  const [newDescription, setNewDescription] = useState("")
  const [newFile, setNewFile] = useState("")
  const [fileName, setFileName] = useState("")
  const [newExpoDate, setNewExpoDate] = useState("")
  const [uploadError, setUploadError] = useState("")

  const errorStyle = {
    backgroundColor: "rgb(251, 198, 16)",
    marginRight: 20,
    color: "black",
    fontSize: "16px",
    fontStyle: "bold",
    borderRadius: 3,
    textAlign: "center",
  }

  const handleDocumentSubmission = (e) => {
    e.preventDefault()
    setUploadStatus("loading")
    const formData = new FormData()
    formData.append("file", newFile[0])
    formData.append("title", fileName)
    formData.append("description", newDescription)
    if (newExpoDate) {
      formData.append("expiration_date", newExpoDate)
    }

    axios
      .post(`/upload-document`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        const updatedDocuments = [res.data, ...myDownloadLinks]
        setMyDownloadLinks(updatedDocuments)
        setUploading(false)
      })
      .catch((err) => {
        setUploadStatus("default")
        setFileName("")
        setNewDescription("")
        setNewExpoDate("")
        setUploadError(err.response.data)
      })
  }

  return (
    <>
      <div className="col-6" style={{ margin: "auto" }}>
        <div className="card h-100">
          <div className="card-body">
            <h5 className="card-title" style={{ color: "darkblue" }}>
              <strong>Upload Documents</strong>
            </h5>
            {uploadError && (
              <>
                <div style={errorStyle}>{uploadError}</div>
                <br />
              </>
            )}
            {uploadStatus === "default" && (
              <form onSubmit={handleDocumentSubmission}>
                <table className="table">
                  <tbody>
                    <tr>
                      <td>
                        <label htmlFor="fileName"> File Name:</label>
                      </td>
                      <td>
                        <input
                          id="fileName"
                          type="text"
                          value={fileName}
                          onChange={(v) => setFileName(v.target.value)}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="description">Description:</label>
                      </td>
                      <td>
                        <input
                          id="description"
                          type="text"
                          value={newDescription}
                          onChange={(v) => setNewDescription(v.target.value)}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="file">File:</label>
                      </td>
                      <td>
                        <input
                          type="file"
                          onChange={(e) => setNewFile(e.target.files)}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="expiration">
                          Expiration Date (optional):
                        </label>
                      </td>
                      <td>
                        <input
                          type="date"
                          id="expiration"
                          value={newExpoDate}
                          onChange={(e) => setNewExpoDate(e.target.value)}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <button
                  type="button"
                  className="btn btn-outline-danger btn-md m-1"
                  onClick={(e) => {
                    e.preventDefault()
                    setUploading(false)
                  }}
                >
                  Cancel
                </button>
                <button
                  id="documentSubmission"
                  type="submit"
                  className="btn btn-outline-primary btn-md"
                >
                  Upload File
                </button>
              </form>
            )}
            {uploadStatus === "loading" && (
              <div style={{ textAlign: "center", padding: 50 }}>
                <h5>Submitting document...</h5>
                <br />
                <ScaleLoader color="gray" loading={true} size={50} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

const DocumentItem = ({ document, myDownloadLinks, setMyDownloadLinks }) => {
  const [link, setLink] = useState("")

  const tableHeaders = [
    "File Name",
    "Title",
    "Description",
    "Uploaded At",
    "Expiration Date",
    "Remove",
  ]
  const rowStyle = {
    backgroundColor: "white",
    border: "0px solid rgba(0,0,0,0)",
    borderBottom: "1px solid rgba(100,100,100,0.2)",
  }

  const deleteDocument = (e) => {
    e.preventDefault()
    const confirmation = window.confirm(
      `This will delete ${document.local_name}. Are you sure?`
    )
    if (confirmation) {
      axios
        .delete(`/delete-document/${document.id}`)
        .then((res) => {
          const updatedDocumentList = myDownloadLinks.filter(
            (doc) => doc.id !== document.id
          )
          setMyDownloadLinks(updatedDocumentList)
        })
        .catch((err) => console.log(err.response.data))
    }
  }
  const retreiveDocument = (e) => {
    e.preventDefault()
    axios
      .get(`get-download-link/${document.id}`)
      .then((res) => {
        window.open(res.data, "_blank")
      })
      .catch((err) => console.log(err))
  }

  return (
    <tr style={rowStyle} key={document.id}>
      <td>
        <a className="tableLink" href={link} onClick={retreiveDocument}>
          {document.local_name}
        </a>
      </td>
      <td>{document.title}</td>
      <td>{document.description}</td>
      <td>{document.uploaded_at}</td>
      <td>
        {document.expiration_date && (
          <strong style={{ color: "orange" }}>
            {document.expiration_date}
          </strong>
        )}
        {!document.expiration_date && <strong>N/A</strong>}
      </td>
      <td>
        <button
          className="btn btn-sm btn-danger"
          type="submit"
          onClick={deleteDocument}
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </td>
    </tr>
  )
}

const CurrentDocuments = ({
  setUploading,
  myDownloadLinks,
  setMyDownloadLinks,
}) => {
  const tableHeaders = [
    "File Name",
    "Title",
    "Description",
    "Uploaded At",
    "Expiration Date",
    "Remove",
  ]
  const tableStyle = {
    fontSize: 14,
    color: "rgba(100,100,100,0.9)",
  }
  const blueishColor = "rgb(232, 243, 250)"
  const headerStyle = {
    backgroundColor: blueishColor,
    border: "1px solid rgba(0,0,0,0)",
    borderLeft: "1px solid " + blueishColor,
    borderBottom: "2px solid rgba(100,100,100,0.9)",
  }
  return (
    <div className="col-10" style={{ margin: "auto" }}>
      <div className="card h-100">
        <div className="card-body">
          <h5 className="card-title" style={{ color: "darkblue" }}>
            <strong>My Documents</strong>
          </h5>
          <p className="card-text" style={{ float: "left" }}>
            Click on the file name to download. Click on trash icon to delete.
          </p>
          <button
            type="button"
            onClick={() => setUploading(true)}
            className="btn btn-primary"
            style={{ float: "right", marginBottom: 10 }}
          >
            {" "}
            Upload Document{" "}
          </button>
          <table style={tableStyle} className="table table-sm ">
            <thead style={headerStyle}>
              <tr>
                {tableHeaders.map((header) => (
                  <th key={`th:${header}`} scope="col">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {myDownloadLinks.map((traineeDocument) => (
                <DocumentItem
                  key={`documentItemKey:${traineeDocument.id}`}
                  document={traineeDocument}
                  myDownloadLinks={myDownloadLinks}
                  setMyDownloadLinks={setMyDownloadLinks}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

const DocumentsHeader = ({ message, myStatus }) => (
  <>
    <div
      className="h3 text-center"
      style={{ marginTop: "2rem", color: "darkblue" }}
    >
      <strong>My Documents</strong>
    </div>
    <p className="text-lg-center">
      Manage your documents here. Any files you upload are viewable and managed
      by your supervisor.
    </p>
    {message && <p className="text-danger">{message}</p>}
    {myStatus && (
      <div
        className="alert alert-danger"
        role="alert"
        style={{ margin: "1rem auto", maxWidth: 1000 }}
      >
        {myStatus}
      </div>
    )}
  </>
)

// Main Component
const TraineeDocuments = () => {
  const dispatch = useDispatch()
  const message = false
  const myStatus = ""
  const [uploading, setUploading] = useState(false)

  const [myDownloadLinks, setMyDownloadLinks] = useState([])
  useEffect(() => {
    axios
      .get("/get-trainee-documents")
      .then((res) => setMyDownloadLinks(res.data))
      .catch((err) => {
        console.log("There was an error getting trainee documents")
      })
  }, [])

  return (
    <TraineeLayout
      myDownloadLinks={myDownloadLinks}
      setMyDownloadLinks={setMyDownloadLinks}
    >
      <div
        className="container-fluid"
        style={{ color: "rgba(100,100,100,0.8)" }}
      >
        <DocumentsHeader message={message} myStatus={myStatus} />
        <br />
        <div className="row g-4">
          {!uploading && (
            <CurrentDocuments
              setUploading={setUploading}
              myDownloadLinks={myDownloadLinks}
              setMyDownloadLinks={setMyDownloadLinks}
            />
          )}
          {uploading && (
            <UploadDocuments
              setUploading={setUploading}
              myDownloadLinks={myDownloadLinks}
              setMyDownloadLinks={setMyDownloadLinks}
            />
          )}
        </div>
      </div>
    </TraineeLayout>
  )
}

export default TraineeDocuments
