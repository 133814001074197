import React from "react"
import PropTypes from "prop-types"

import { Link } from "react-router-dom"

// Redux
import { useDispatch } from "react-redux"
import { login } from "../redux/userSlice"

const AuthLayout = ({ children }) => {
  // Redux for debugging
  const dispatch = useDispatch()

  return (
    <div>
      <main style={{ width: "100%" }}>{children}</main>
    </div>
  )
}

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AuthLayout
