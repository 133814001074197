import React, { useState, useEffect } from "react"

import DefaultLayout from "../../layouts/defaultLayout"
import LoadingDashboard from "../../components/loadingDashboard"

import { useNavigate } from "react-router"

import { useSelector, useDispatch } from "react-redux"
import { setErrors } from "../../redux/userSlice"

import axios from "axios"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimesCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons"

import Modal from "react-responsive-modal"
import { modalStylesDanger } from "../../styles/constanStyles"

const AddAttendance = () => {
  const [trainees, setTrainees] = useState([])
  const [traineeIds, setTraineeIds] = useState([])

  const [loadingData, setLoadingData] = useState(false)
  const [loadingForm, setLoadingForm] = useState(false)
  const [loadingOverwrite, setLoadingOverwrite] = useState(false)
  const [formError, setFormError] = useState("")

  const [date, setDate] = useState("")
  // List of trainee ids selected for present and absent
  const [preTrainees, setPreTrainees] = useState([])
  const [absTrainees, setAbsTrainees] = useState([])

  // Modal States
  const [overwriteModalOpen, setOverwriteModalOpen] = useState(false)

  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()

  const navigate = useNavigate()

  useEffect(() => {
    if (user.status === "idle" || user.status === "success") {
      if (user.currentFocusedTeam && user.currentFocusedTeam.length > 0) {
        setLoadingData(true)
        setTrainees([])

        axios
          .get(`/get-shop/${user.currentFocusedTeam}`)
          .then((res) => {
            setLoadingData(false)
            setTrainees(res.data.trainees)

            // set list of trainee ids
            res.data.trainees.map((t) => {
              setTraineeIds((prev) => [...prev, t.id])
            })
          })
          .catch((err) => {
            console.log(err)
            dispatch(
              setErrors([
                "An unexpected error has occured please reload and try again",
              ])
            )
          })
      }
    }
  }, [user.currentFocusedTeam, user.status])

  const handleAbsClick = (id) => {
    // Currently clicked as absent
    if (absTrainees.includes(id)) {
      setAbsTrainees((prev) => prev.filter((t) => t !== id))
    } else {
      setAbsTrainees((prev) => [...prev, id])
      setPreTrainees((prev) => prev.filter((t) => t !== id))
    }
  }

  const handlePreClick = (id) => {
    // Currently clicked as present
    if (preTrainees.includes(id)) {
      setPreTrainees((prev) => prev.filter((t) => t !== id))
    } else {
      setPreTrainees((prev) => [...prev, id])
      setAbsTrainees((prev) => prev.filter((t) => t !== id))
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (!date || date === "") {
      setFormError("Please select a date")
      return
    }

    setLoadingForm(true)
    setFormError("")

    const body = {
      date: date,
      pre_trainees: preTrainees,
      abs_trainees: absTrainees,
    }

    axios
      .post(`/add-attendance/${user.currentFocusedTeam}`, body)
      .then((res) => {
        navigate("/attendance")
      })
      .catch((err) => {
        if (err.response.data && !err.response.data.includes("html")) {
          // If shop attendance already exists, prompt user to overwrite
          if (
            err.response.data ===
            `Shop attendance already exists for date ${date}`
          ) {
            setOverwriteModalOpen(true)
          } else {
            setFormError(err.response.data)
          }
        } else {
          setFormError("An unexpected error has happened, please try again")
        }

        setLoadingForm(false)
      })
  }

  const handleOverwrite = () => {
    setLoadingOverwrite(true)
    setFormError("")

    const body = {
      date: date,
      pre_trainees: preTrainees,
      abs_trainees: absTrainees,
      na_trainees: traineeIds.filter(
        (t) => !preTrainees.includes(t.id) && !absTrainees.includes(t.id)
      ),
    }

    axios
      .patch(`/edit-attendance/${user.currentFocusedTeam}`, body)
      .then((res) => {
        navigate("/attendance")
      })
      .catch((err) => {
        if (err.response.data) {
          setFormError(err.response.data)
        } else {
          setFormError("An unexpected error has happened, please try again")
        }

        setLoadingOverwrite(false)
      })
  }

  if (loadingData) {
    return <LoadingDashboard />
  }

  return (
    <DefaultLayout disableTeams>
      <div className="container-fluid">
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="table-responsive">
            <div className="table-wrapper">
              <div className="table-title">
                <div className="row">
                  <div className="col-sm-12">
                    <h2>Add New Attendance Record</h2>
                    <br />
                    <br />
                    <strong>Date:</strong>
                    <input
                      type="date"
                      className="form-control"
                      style={{ width: "200px" }}
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                      required
                    />
                    <div style={{ textAlign: "right" }}>
                      <button
                        disabled={loadingForm}
                        className="default-button default-button-red"
                        onClick={() => navigate("/attendance")}
                        type="reset"
                      >
                        Cancel
                      </button>
                      <button
                        disabled={loadingForm}
                        className="default-button"
                        type="submit"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {trainees.map((trainee) => {
                    return (
                      <tr>
                        <td className="col-sm-8">
                          <h5>
                            <div key={trainee.id}>{trainee.name}</div>
                          </h5>
                        </td>
                        <td>
                          <a className="delete" data-toggle="modal">
                            <i
                              className="material-icons"
                              data-toggle="tooltip"
                              title="Delete"
                            >
                              <FontAwesomeIcon
                                icon={faTimesCircle}
                                style={
                                  absTrainees.includes(trainee.id)
                                    ? {
                                        color: "var(--red)",
                                        fontSize: "2rem",
                                        cursor: "pointer",
                                      }
                                    : {
                                        color: "gray",
                                        fontSize: "2rem",
                                        cursor: "pointer",
                                      }
                                }
                                onClick={() => handleAbsClick(trainee.id)}
                              />
                            </i>
                          </a>
                          <a className="add">
                            <i
                              className="material-icons"
                              data-toggle="tooltip"
                              title="Add"
                            >
                              <FontAwesomeIcon
                                icon={faCheckCircle}
                                style={
                                  preTrainees.includes(trainee.id)
                                    ? {
                                        color: "var(--green)",
                                        fontSize: "2rem",
                                        cursor: "pointer",
                                      }
                                    : {
                                        color: "gray",
                                        fontSize: "2rem",
                                        cursor: "pointer",
                                      }
                                }
                                onClick={() => handlePreClick(trainee.id)}
                              />
                            </i>
                          </a>
                        </td>
                      </tr>
                    )
                  })}
                  {formError && (
                    <div
                      className="h6 text-center text-danger"
                      style={{ flexBasis: "100%" }}
                    >
                      {formError}
                    </div>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </form>
      </div>
      {/* Overwrite modal */}
      <Modal
        styles={modalStylesDanger}
        open={overwriteModalOpen}
        center
        focusTrapped={false}
        closeOnOverlayClick={!loadingOverwrite}
        closeIcon={!loadingOverwrite}
        onClose={() => setOverwriteModalOpen(false)}
      >
        <h5 className="modal-title">Overwrite Attendance Record?</h5>
        <hr />
        <p className="modal-subtitle">
          An attendance record already exists for date <b>{date}</b>. This
          action will overwrite the existing attendance record and create a new
          one. This cannot be undone. Do you still wish to proceed?
        </p>
        <br />
        {formError && (
          <div
            className="h6 text-center text-danger"
            style={{ flexBasis: "100%" }}
          >
            {formError}
          </div>
        )}
        <button
          className="default-button float-right"
          style={{ marginLeft: "0.5rem" }}
          onClick={handleOverwrite}
          disabled={loadingOverwrite}
        >
          Proceed
        </button>
        <button
          className="default-button default-button-gray float-right"
          type="reset"
          onClick={(e) => setOverwriteModalOpen(false)}
          disabled={loadingOverwrite}
        >
          Cancel
        </button>
      </Modal>
    </DefaultLayout>
  )
}

export default AddAttendance
