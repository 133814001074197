import React, { useEffect } from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import "./styles/global.css"
import "bootstrap/dist/css/bootstrap.min.css"

import axios from "axios"

// Redux
import {
  setAuthenticated,
  getProfile,
  setFirstLoadStatus,
  setTeam,
  getUtas,
} from "./redux/userSlice"
import { store } from "./redux/store"
import { useSelector } from "react-redux"

// Pages
import ProtectedRoute from "./util/ProtectedRoute"
import AdminRoute from "./util/AdminRoute"
import UnauthenticatedRoute from "./util/UnauthenticatedRoute"
import TraineeRoute from "./util/TraineeRoute"

import AdminHome from "./pages/admin/admin"

import AddAttendance from "./pages/supervisor/addAttendance"
import AddEvent from "./pages/supervisor/addEvent"
import Attendance from "./pages/supervisor/attendance"
import Communications from "./pages/supervisor/communications"
import CommunicationsLog from "./pages/supervisor/communicationsLog"
import AddCompliance from "./pages/supervisor/addCompliance"
import Compliance from "./pages/supervisor/compliance"
import DocumentsPage from "./pages/supervisor/documents"
import EditEvent from "./pages/supervisor/editEvent"
import EventDetail from "./pages/supervisor/eventDetail"
import Events from "./pages/supervisor/events"
import EventNotify from "./pages/supervisor/eventNotify"
import Dashboard from "./pages/supervisor/dashboard"
import Index from "./pages/index"
import BaseOverview from "./pages/supervisor/baseOverview"
import Log from "./pages/supervisor/log"
import ReceivedMessagesLog from "./pages/supervisor/receivedMessagesLog"
import Login from "./pages/login"
import Profile from "./pages/supervisor/traineeProfile"
import Register from "./pages/register"
import ResetPassword from "./pages/resetPassword"
import ShopAccess from "./pages/non-admin/shop-acess"
import SupervisorProfile from "./pages/supervisor/supervisorProfile"
import SupervisorChangePassword from "./pages/supervisor/supervisorChangePassword"

import TraineeHome from "./pages/trainee/traineeHome"
import TraineeUserProfile from "./pages/trainee/traineeUserProfile"
import TraineeAvailability from "./pages/trainee/traineeAvailability"
import TraineeDocuments from "./pages/trainee/traineeDocuments"
import TraineeViewSchedule from "./pages/trainee/traineeViewSchedule"
import TraineeViewTasks from "./pages/trainee/traineeViewTasks"
import Upload from "./pages/supervisor/upload"

import PageNotFound from "./pages/404"
import NonAdminRoute from "./util/NonAdminRoute"

const token = localStorage.Token
const team = localStorage.Team

// Persist auth state if token exists in local storage
if (token) {
  store.dispatch(setFirstLoadStatus("loading"))
  store.dispatch(setAuthenticated())
  axios.defaults.headers.common["Authorization"] = "Token " + token
  setTimeout(function () {
    store
      .dispatch(getProfile())
      .then(({ payload }) => {
        // Set currentFocusedTeam if it exists in local storage
        if (team && team.length > 0) {
          if (payload.data.teams.some((e) => e.code === team)) {
            store.dispatch(setTeam(team))
            store.dispatch(getUtas())
            setTimeout(function () {
              store.dispatch(setFirstLoadStatus("success"))
            }, 5000)
          } else {
            localStorage.removeItem("Team")
            store.dispatch(setFirstLoadStatus("failed"))
          }
        }
      })
      .catch(() => {})
  }, 1)
} else {
  store.dispatch(setFirstLoadStatus("idle"))
}

function App() {
  const currentFocusedTeam = useSelector(
    (state) => state.user.currentFocusedTeam
  )

  // Set currentFocusedTeam to local storage on page unload
  useEffect(() => {
    const onbeforeunloadFn = () => {
      if (currentFocusedTeam && currentFocusedTeam.length > 0) {
        localStorage.setItem("Team", currentFocusedTeam)
      }
    }

    window.addEventListener("beforeunload", onbeforeunloadFn)

    return () => {
      window.removeEventListener("beforeunload", onbeforeunloadFn)
    }
  }, [currentFocusedTeam])

  return (
    <Router>
      <div>
        <Routes>
          <Route exact path="/" element={<Index />} />
          <Route
            exact
            path="/login"
            element={
              <UnauthenticatedRoute>
                <Login />
              </UnauthenticatedRoute>
            }
          />
          <Route
            exact
            path="/register"
            element={
              <UnauthenticatedRoute>
                <Register />
              </UnauthenticatedRoute>
            }
          />
          <Route
            exact
            path="/reset-password"
            element={
              <UnauthenticatedRoute>
                <ResetPassword />
              </UnauthenticatedRoute>
            }
          />

          {/* Admin only routes */}
          <Route
            exact
            path="/admin"
            element={
              <AdminRoute>
                <AdminHome />
              </AdminRoute>
            }
          />

          {/* Non-Admin only routes */}
          <Route
            exact
            path="/shop-access"
            element={
              <NonAdminRoute>
                <ShopAccess />
              </NonAdminRoute>
            }
          />

          {/* Supervisor only routes */}
          <Route
            exact
            path="/attendance"
            element={
              <ProtectedRoute>
                <Attendance />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/attendance/new"
            element={
              <ProtectedRoute>
                <AddAttendance />{" "}
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/compliance"
            element={
              <ProtectedRoute>
                <Compliance />{" "}
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/compliance/new"
            element={
              <ProtectedRoute>
                <AddCompliance />{" "}
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/communications"
            element={
              <ProtectedRoute>
                <Communications />{" "}
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/communications/log"
            element={
              <ProtectedRoute>
                <CommunicationsLog />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/compliance"
            element={
              <ProtectedRoute>
                <Compliance />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/documents"
            element={
              <ProtectedRoute>
                <DocumentsPage />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/events"
            element={
              <ProtectedRoute>
                <Events />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/events/new"
            element={
              <ProtectedRoute>
                <AddEvent />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/event/:eventID"
            element={
              <ProtectedRoute>
                <EventDetail />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/event/:eventID/edit"
            element={
              <ProtectedRoute>
                <EditEvent />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/event/:eventID/notify"
            element={
              <ProtectedRoute>
                <EventNotify />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/events/new"
            element={
              <ProtectedRoute>
                <AddEvent />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/home"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/tasks"
            element={
              <ProtectedRoute>
                <Log />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/tasks/log"
            element={
              <ProtectedRoute>
                <ReceivedMessagesLog />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/profile"
            element={
              <ProtectedRoute>
                <SupervisorProfile />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/trainee/profile/:userID"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/change-password"
            element={
              <ProtectedRoute>
                <SupervisorChangePassword />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/upload"
            element={
              <ProtectedRoute>
                <Upload />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/base-overview"
            element={
              <ProtectedRoute>
                <BaseOverview />
              </ProtectedRoute>
            }
          />

          {/* Trainee only routes */}
          <Route
            exact
            path="/trainee-home"
            element={
              <TraineeRoute>
                <TraineeHome />
              </TraineeRoute>
            }
          />
          <Route
            exact
            path="/trainee-user-profile"
            element={
              <TraineeRoute>
                <TraineeUserProfile />
              </TraineeRoute>
            }
          />
          <Route
            exact
            path="/trainee-availability"
            element={
              <TraineeRoute>
                <TraineeAvailability />
              </TraineeRoute>
            }
          />
          <Route
            exact
            path="/trainee-documents"
            element={
              <TraineeRoute>
                <TraineeDocuments />
              </TraineeRoute>
            }
          />
          <Route
            exact
            path="/trainee-schedule"
            element={
              <TraineeRoute>
                <TraineeViewSchedule />
              </TraineeRoute>
            }
          />
          <Route
            exact
            path="/trainee-tasks"
            element={
              <TraineeRoute>
                <TraineeViewTasks />
              </TraineeRoute>
            }
          />

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
    </Router>
  )
}

export default App
