import React, { useEffect, useState } from "react"

import DefaultLayout from "../../layouts/defaultLayout"
import LoadingDashboard from "../../components/loadingDashboard"
import EventDetailBody from "../../components/eventDetailBody"

import axios from "axios"

import { Link } from "react-router-dom"
import { useNavigate, useParams } from "react-router"

const EventNotify = () => {
  const { eventID } = useParams()
  const navigate = useNavigate()

  const [event, setEvent] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState("")

  // List of trainees with numbers
  const [notifList, setNotifList] = useState([])
  // List of trainees selected to notify
  const [traineeIDList, setTraineeIDList] = useState([])

  // Notification content
  const [notifContent, setNotifContent] = useState("")

  const [notifLoading, setNotifLoading] = useState(false)
  const [notifError, setNotifError] = useState("")

  useEffect(() => {
    if (eventID && eventID.length > 0) {
      axios
        .get(`/get-event/${eventID}`)
        .then((res) => {
          setEvent(res.data)
          setLoading(false)
          res.data.trainees.map((t) => {
            if (t.phoneNumber && t.phoneNumber.length > 0) {
              setNotifList((prev) => [...prev, t])
              setTraineeIDList((prev) => [...prev, t.id])
            }
          })
          // Set default template notification message
          if (res.data.start_date === res.data.end_date) {
            if (res.data.all_day) {
              setNotifContent(`EVENT REMINDER: You are signed up for the following event: ${res.data.title} 
DATE-TIME: ${res.data.start_date}, All Day
DESCRIPTION: ${res.data.description}`)
            } else {
              setNotifContent(`EVENT REMINDER: You are signed up for the following event: ${
                res.data.title
              }
DATE-TIME: ${res.data.start_date}, ${res.data.start_time.substring(
                0,
                5
              )} to ${res.data.end_time.substring(0, 5)}
DESCRIPTION: ${res.data.description}`)
            }
          } else {
            if (res.data.all_day) {
              setNotifContent(`EVENT REMINDER: You are signed up for the following event: ${res.data.title}
DATE-TIME: ${res.data.start_date} to ${res.data.end_date}, All Day
DESCRIPTION: ${res.data.description}`)
            } else {
              setNotifContent(`EVENT REMINDER: You are signed up for the following event: ${
                res.data.title
              }
DATE-TIME: ${res.data.start_date} @ ${event.start_time.substring(0, 5)} to ${
                res.data.end_date
              } @ ${event.end_time.substring(0, 5)}
DESCRIPTION: ${res.data.description}`)
            }
          }
        })
        .catch((err) => {
          setError("An error has occured, please reefresh and try again")
          if (err.response.status === 404) {
            setError("An event with this id does not exist")
          } else if (err.response.status === 403) {
            setError("You are not authorized to view this event")
          } else {
            setError(
              "An unexpected error has occured, please return to the previous page and try again"
            )
          }
          setLoading(false)
        })
    }
  }, [eventID])

  const handleSend = () => {
    setNotifLoading(true)

    if (notifContent.length <= 0) {
      setNotifLoading(false)
      setNotifError("Please enter more a message")
      return
    }

    if (notifContent.length > 450) {
      setNotifLoading(false)
      setNotifError("Please enter less than 450 characters")
      return
    }

    const body = {
      type: "event",
      message: notifContent,
      trainees: traineeIDList,
    }
    axios
      .post("/notify-trainees", body)
      .then(() => {
        navigate("/events")
      })
      .catch((err) => {
        setNotifLoading(false)
        console.log(err)
        setNotifError(
          "An error occured while sending the notification, please try again"
        )
      })
  }

  if (loading) {
    return <LoadingDashboard />
  }

  if (error && error.length > 0) {
    return (
      <DefaultLayout disableTeams>
        <h5>{error}</h5>
      </DefaultLayout>
    )
  }

  return (
    <DefaultLayout disableTeams>
      <h1>
        {event.title}{" "}
        <Link
          className="default-button"
          to={`/event/${event.id}/edit`}
          style={{ fontSize: "1rem" }}
        >
          Edit Event
        </Link>
      </h1>
      <div className="row" style={{ padding: "1rem 0" }}>
        <div className="col-sm-6">
          <EventDetailBody event={event} />
          <Link to="/events" style={{ textDecoration: "none" }}>
            <span className="default-button">Go back</span>
          </Link>
        </div>
        <div className="col-sm-6">
          <div className="card">
            <div className="card-body">
              <h5>Remind attendees</h5>
              {!event.trainees ||
                (event.trainees.length === 0 ? (
                  <p>There are no trainees signed up for this event</p>
                ) : notifList.length > 0 ? (
                  <div>
                    <p>Message Content</p>
                    <textarea
                      rows={10}
                      style={{ width: "100%", padding: "0.5rem" }}
                      value={notifContent}
                      onChange={(e) => setNotifContent(e.target.value)}
                      maxLength={450}
                    />
                    <p
                      style={{
                        float: "right",
                        marginTop: "-5px",
                        fontSize: "0.70rem",
                        color: notifContent.length >= 450 ? "red" : "gray",
                        marginBottom: 0,
                      }}
                    >
                      {notifContent.length}/450
                    </p>
                    <p>
                      The above message is only a template. We recommend you
                      edit and add additional details, including your signature.
                    </p>
                    <p>
                      NOTE: The following attendees (with valid phone numbers)
                      will be notified:
                    </p>
                    <ul>
                      {notifList.map((t) => {
                        return (
                          <li key={t.id}>
                            {t.name} ({t.phoneNumber})
                          </li>
                        )
                      })}
                    </ul>
                    {notifError && notifError.length > 0 && (
                      <div className="h6 text-center text-danger">
                        {notifError}
                      </div>
                    )}
                    <div>
                      <button
                        diasbled={notifLoading}
                        className="default-button"
                        onClick={handleSend}
                      >
                        Send
                      </button>
                      <Link
                        diasbled={notifLoading}
                        to="/events"
                        className="default-button default-button-gray"
                      >
                        Cancel
                      </Link>
                    </div>
                  </div>
                ) : (
                  <p>
                    No attendees have phone numbers. Please add/edit phone
                    numbers{" "}
                    <Link
                      to="/communications"
                      style={{ textDecoration: "none" }}
                    >
                      <b>here</b>
                    </Link>
                    .
                  </p>
                ))}
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  )
}

export default EventNotify
