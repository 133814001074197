import React, { useState, useEffect } from "react"

import DefaultLayout from "../../layouts/defaultLayout"
import LoadingDashboard from "../../components/loadingDashboard"

import axios from "axios"

// Redux
import { useSelector } from "react-redux"

const CommunicationsLog = () => {
  const [loading, setLoading] = useState(false)
  const [log, setLog] = useState([])

  const user = useSelector((state) => state.user)

  //Search function state
  const [q, setQ] = useState("")
  const searchParam = ["recipient"]
  //

  const compLogEntry = (a, b) => {
    if (new Date(a.timeStamp) === new Date(b.timeStamp)) {
      return a.recipient > b.recipient ? 1 : a.recipient < b.recipient ? -1 : 0
    }

    return new Date(a.timeStamp) > new Date(b.timeStamp) ? 1 : -1
  }

  useEffect(() => {
    if (user.status === "idle" || user.status === "success") {
      if (user.currentFocusedTeam && user.currentFocusedTeam.length > 0) {
        setLoading(true)
        axios
          .get(`/get-notificationsLog/${user.currentFocusedTeam}`)
          .then((res) => {
            setLog(res.data.sort(compLogEntry))
            setLoading(false)
          })
          .catch((err) => {
            console.log(err)
            setLoading(false)
          })
      }
    }
  }, [user.currentFocusedTeam, user.status])

  if (loading) {
    return <LoadingDashboard />
  }

  //Search function
  function search(items) {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        if (item[newItem]) {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          )
        } else {
          return false
        }
      })
    })
  }
  //

  return (
    <DefaultLayout currentPage="Notifications Log">
      <div className="container-fluid">
        <p>
          This displays all notifications sent by supervisors in the current
          shop, along with their recipients and message content.
        </p>
        <div className="search-wrapper">
          <label htmlFor="search-form">
            <input
              style={{ marginBottom: "16px" }}
              type="search"
              name="search-form"
              id="search-form"
              className="search-input textInput form-control"
              placeholder="Search for..."
              value={q}
              onChange={(e) => setQ(e.target.value)}
            />
            <span className="sr-only">Search names here</span>
          </label>
        </div>
        <table
          className="table table-hover table-bordered"
          style={{ backgroundColor: "#eaecf4" }}
        >
          <thead className="thead-light">
            <tr>
              {/* <th scope="col">Sender</th> */}
              <th scope="col">Recipient</th>
              <th scope="col">Shop</th>
              <th scope="col">Message Topic</th>
              <th scope="col">Message Content</th>
              <th scope="col">Time Stamp</th>
            </tr>
          </thead>
          <tbody>
            {search(log.slice(0).reverse()).map((item, i) => {
              return (
                <tr key={i}>
                  <td>{item.recipient}</td>
                  <td>{item.shop}</td>
                  <td>{item.messageTitle}</td>
                  <td>{item.messageContent}</td>
                  <td>
                    {new Date(item.timeStamp).toLocaleString("en-US", {
                      hour12: false,
                    })}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </DefaultLayout>
  )
}

export default CommunicationsLog
