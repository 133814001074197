import React, { useState, useEffect } from "react"

import DefaultLayout from "../../layouts/defaultLayout"
import LoadingDashboard from "../../components/loadingDashboard"
import Accordion from "../../components/accordion"

import { useSelector, useDispatch } from "react-redux"
import { setErrors } from "../../redux/userSlice"

import DocumentsView from "../../components/documentsView"

import SmallLoader from "../../components/smallLoader"
import Modal from "react-responsive-modal"
import { modalStylesWarning } from "../../styles/constanStyles"

import axios from "axios"

// Main Component
const DocumentsPage = () => {
  const [loading, setLoading] = useState(true)

  const [documentsList, setDocumentsList] = useState([])

  // Comparator func for sorting document accordions
  const compDocumentsList = (a, b) => {
    if (a.name === b.name) {
      return a.id > b.id ? 1 : a.id < b.id ? -1 : 0
    }

    return a.name > b.name ? 1 : -1
  }

  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()

  // Form Value States
  const [formTrainee, setFormTrainee] = useState("")
  const [formTitle, setFormTitle] = useState("")
  const [formDescription, setFormDescription] = useState("")
  const [formFiles, setFormFiles] = useState([])
  const [formExpirationDate, setFormExpirationDate] = useState("")
  const [formError, setFormError] = useState("")
  const [formLoading, setFormLoading] = useState(false)

  //Search function state
  const [q, setQ] = useState("")
  const searchParam = ["name"]
  //

  useEffect(() => {
    if (user.status === "idle" || user.status === "success") {
      if (user.currentFocusedTeam && user.currentFocusedTeam.length > 0) {
        setLoading(true)
        setDocumentsList([])
        axios
          .get(`/get-documents/${user.currentFocusedTeam}`)
          .then((res) => {
            setDocumentsList(res.data.sort(compDocumentsList))
            setLoading(false)
          })
          .catch((err) => {
            console.log(err)
            setLoading(false)
            dispatch(
              setErrors([
                "An unexpected error has occured please reload and try again",
              ])
            )
          })
      }
    }
  }, [user.currentFocusedTeam, user.status])

  const getDocuments = () => {
    setLoading(true)
    setDocumentsList([])
    axios
      .get(`/get-documents/${user.currentFocusedTeam}`)
      .then((res) => {
        setDocumentsList(res.data.sort(compDocumentsList))
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        dispatch(
          setErrors([
            "An unexpected error has occured please reload and try again",
          ])
        )
      })
  }

  const clearFormInput = () => {
    setFormFiles([])
    setFormTrainee("")
    setFormTitle("")
    setFormDescription("")
    setFormExpirationDate("")
    setFormError("")
    document.getElementById("doc-input").value = ""
  }

  const handleUploadDocument = (e) => {
    e.preventDefault()
    setFormLoading(true)

    if (
      formTitle.length === 0 ||
      formDescription.length === 0 ||
      formTrainee === "" ||
      !parseInt(formTrainee) ||
      !formFiles ||
      !formFiles[0]
    ) {
      setFormError("Please fill in all fields")
      setFormLoading(false)
      return
    }

    setFormError("")

    const formData = new FormData()
    formData.append("file", formFiles[0])
    formData.append("id", formTrainee)
    formData.append("title", formTitle)
    formData.append("description", formDescription)
    if (formExpirationDate && formExpirationDate.length > 0) {
      formData.append("expiration_date", formExpirationDate)
    }

    axios
      .post("/upload-document", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        clearFormInput()
        getDocuments()
        setFormLoading(false)
      })
      .catch((err) => {
        console.log(err.response)
        if (err.response.data.includes("already exists")) {
          setFormError(err.response.data)
        } else {
          setFormError(
            "An unexpected error has occured, please refresh and try again"
          )
        }
        setFormLoading(false)
      })
  }

  if (loading) {
    return <LoadingDashboard />
  }

  function search(items) {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        if (item[newItem]) {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          )
        } else {
          return false
        }
      })
    })
  }

  return (
    <DefaultLayout currentPage="Documents">
      <div className="container-fluid">
        <div className="row row-cols-1 row-cols-md-3 g-4">
          <div className="col-4">
            <div className="card" style={{ padding: "1rem" }}>
              <h5 className="header-text" style={{ fontSize: "1.25rem" }}>
                Upload Documents For Trainee
              </h5>
              <br />
              <p>
                As as supervisor, you can view and upload documents for your
                trainees.
              </p>
              <form onSubmit={handleUploadDocument}>
                <select
                  value={formTrainee}
                  onChange={(e) => setFormTrainee(e.target.value)}
                  required
                  className="custom-select"
                >
                  <option value="">Select Trainee...</option>
                  {documentsList &&
                    documentsList.length > 0 &&
                    documentsList.map((d) => {
                      return (
                        <option value={d.id} key={d.id}>
                          {d.name.toUpperCase()}
                        </option>
                      )
                    })}
                </select>
                <br />
                <label>Title*</label>
                <input
                  type="text"
                  className="textInput form-control"
                  value={formTitle}
                  onChange={(e) => setFormTitle(e.target.value)}
                  required
                />
                <br />
                <label>Description*</label>
                <input
                  type="text"
                  className="textInput form-control"
                  value={formDescription}
                  onChange={(e) => setFormDescription(e.target.value)}
                  required
                />
                <br />
                <label>Expiration Date (Optional)</label>
                <input
                  type="date"
                  className="textInput form-control"
                  value={formExpirationDate}
                  onChange={(e) => setFormExpirationDate(e.target.value)}
                />
                <br />
                <label>Document*</label>
                <input
                  type="file"
                  className="form-control"
                  onChange={(e) => setFormFiles(e.target.files)}
                  required
                  id="doc-input"
                />
                <br />
                {formError && (
                  <div className="h6 text-center text-danger">{formError}</div>
                )}
                <button
                  className="default-button"
                  style={{ marginLeft: 0 }}
                  disabled={formLoading}
                >
                  Upload Document
                </button>
                <button
                  className="default-button default-button-red"
                  type="reset"
                  onClick={clearFormInput}
                >
                  Clear
                </button>
              </form>
            </div>
          </div>
          <div className="col-8">
            <div className="search-wrapper" style={{ paddingBottom: "0.5rem" }}>
              <i className="fas fa-search"></i>
              <label
                htmlFor="search-form"
                id="input-label"
                style={{ display: "none" }}
              >
                Trainee Search
              </label>
              <input
                type="search"
                name="search-form"
                id="search-form"
                className="search-input textInput form-control"
                placeholder="Search Trainees"
                value={q}
                onChange={(e) => setQ(e.target.value)}
              />
              <span className="sr-only">Search trainees here</span>
            </div>
            {documentsList &&
              documentsList.length > 0 &&
              search(documentsList).map((trainee) => {
                const AccordionTitle = () => {
                  return (
                    <h2 style={{ fontSize: "1.1rem", margin: 0 }}>
                      <b
                        style={
                          trainee.expired.length > 0
                            ? { color: "#e74a3b" }
                            : trainee.almost_expired.length > 0
                              ? { color: "#f6c23e" }
                              : {}
                        }
                      >
                        {trainee.name}
                      </b>{" "}
                      <span style={{ color: "var(--gray-300)" }}>
                        ({trainee.documents.length})
                      </span>
                    </h2>
                  )
                }
                if (trainee.documents && trainee.documents.length > 0) {
                  return (
                    <Accordion
                      key={trainee.id}
                      title={<AccordionTitle />}
                      containerStyles={{ maxWidth: "none" }}
                      bodyStyles={{ maxWidth: "none" }}
                    >
                      <DocumentsView
                        documents={trainee.documents}
                        getDocuments={getDocuments}
                        expired={trainee.expired}
                        almost_expired={trainee.almost_expired}
                      />
                    </Accordion>
                  )
                } else {
                  return (
                    <Accordion
                      key={trainee.id}
                      title={<AccordionTitle />}
                      containerStyles={{ maxWidth: "none" }}
                      bodyStyles={{ maxWidth: "none" }}
                    >
                      <p>This trainee doesn't have any documents</p>
                    </Accordion>
                  )
                }
              })}
          </div>
        </div>
      </div>
      {/* Loading Modal */}
      {/* Loading Modal */}
      <Modal
        styles={modalStylesWarning}
        open={formLoading}
        center
        focusTrapped={false}
        showCloseIcon={false}
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <h5 className="modal-title">Uploading document</h5>
        <hr />
        <p className="modal-subtitle">
          We are currently uploading the following file: <b>{formTitle}</b>{" "}
          {formFiles[0] && <>{formFiles[0].name}</>}. Please do not refresh or
          leave this page while or the process might end abruptly.
        </p>
        <SmallLoader />
      </Modal>
    </DefaultLayout>
  )
}

export default DocumentsPage
