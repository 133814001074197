import React from "react"
import { Navigate } from "react-router-dom"
import { useSelector } from "react-redux"

import LoadingSplashScreen from "../components/loadingSplashScreen"

// Route only for authenticated users who are supervisors
const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, isSupervisor, firstLoadStatus } = useSelector(
    (state) => state.user
  )

  // First time load splash screen
  if (firstLoadStatus === "loading") {
    return <LoadingSplashScreen />
  }

  if (isAuthenticated) {
    if (isSupervisor) {
      return <>{children}</>
    } else {
      return <Navigate to="/trainee-home" />
    }
  }

  return <Navigate to="/login" />
}

export default ProtectedRoute
