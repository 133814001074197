import React, { useState, useEffect } from "react"

import DefaultLayout from "../../layouts/defaultLayout"
import LoadingDashboard from "../../components/loadingDashboard"

import axios from "axios"

// Redux
import { useSelector } from "react-redux"

const ReceivedMessagesLog = () => {
  const [loading, setLoading] = useState(false)
  const [log, setLog] = useState([])

  const user = useSelector((state) => state.user)

  //Search function state
  const [q, setQ] = useState("")
  const searchParam = [
    "sender",
    "trainee",
    "shop",
    "messageTitle",
    "messageContent",
    "finishedTask",
  ]
  //

  useEffect(() => {
    if (user.status === "idle" || user.status === "success") {
      if (user.currentFocusedTeam && user.currentFocusedTeam.length > 0) {
        setLoading(true)
        axios
          .get(`/get-received-messages/${user.currentFocusedTeam}`)
          .then((res) => {
            setLog(res.data)
            setLoading(false)
          })
          .catch((err) => {
            console.log(err)
            setLoading(false)
          })
      }
    }
  }, [user.currentFocusedTeam, user.status])

  if (loading) {
    return <LoadingDashboard />
  }

  //Search function
  function search(items) {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        if (!item[newItem]) {
          return false
        }
        if (newItem === "trainee" && item[newItem]) {
          return (
            item[newItem].name
              .toString()
              .toLowerCase()
              .indexOf(q.toLowerCase()) > -1
          )
        }
        if (item[newItem]) {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          )
        }
        return false
      })
    })
  }
  //

  return (
    <DefaultLayout currentPage="Received Messages Log">
      <div className="container-fluid">
        <p>
          This page shows all message responses from trainees in the current
          shop, categorized by message topic and content.
        </p>
        <div className="search-wrapper">
          <label htmlFor="search-form">
            <input
              style={{ marginBottom: "16px" }}
              type="search"
              name="search-form"
              id="search-form"
              className="search-input textInput form-control"
              placeholder="Search for..."
              value={q}
              onChange={(e) => setQ(e.target.value)}
            />
            <span className="sr-only">Search names here</span>
          </label>
        </div>
        <table
          className="table table-hover table-bordered"
          style={{ backgroundColor: "#eaecf4" }}
        >
          <thead className="thead-light">
            <tr>
              <th scope="col">Sender Phone Number</th>
              <th scope="col">Trainee</th>
              <th scope="col">Shop</th>
              <th scope="col">Message Topic</th>
              <th scope="col">Message Content</th>
              <th scope="col">Task Completed</th>
              <th scope="col">Timestamp</th>
            </tr>
          </thead>
          <tbody>
            {search(log.slice(0).reverse()).map((item, i) => {
              return (
                <tr key={i}>
                  <td>{item.sender}</td>
                  <td>{item.trainee.name}</td>
                  <td>{item.shop}</td>
                  <td>{item.messageTitle}</td>
                  <td>{item.messageContent}</td>
                  <td>{item.finishedTask}</td>
                  <td>
                    {new Date(item.timeStamp).toLocaleString("en-US", {
                      hour12: false,
                    })}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </DefaultLayout>
  )
}

export default ReceivedMessagesLog
