import React, { useEffect, useState } from "react"

import DefaultLayout from "../../layouts/defaultLayout"
import SmallLoader from "../../components/smallLoader"
import ProgressBar from "../../components/progressBar"
import LoadingDashboard from "../../components/loadingDashboard"

import Modal from "react-responsive-modal"
import { modalStylesWarning } from "../../styles/constanStyles"
// Removed confetti due to user complaints that it lags the site
// import Confetti from "react-confetti"
import axios from "axios"

// REDUX
import { useSelector } from "react-redux"

// ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons"

// Dropdown Selector
// Used in a generic manner
const DropdownSelector = ({ items, setSelectedItem, category }) => {
  const handleItemChange = (id) => {
    if (id === "") {
      setSelectedItem()
    } else {
      setSelectedItem(
        items.filter((item) => parseInt(item.id) === parseInt(id))[0]
      )
    }
  }
  return (
    <div style={{}} className="d-flex">
      <span style={{ marginLeft: "0.5rem" }}>
        <select
          onChange={(e) => handleItemChange(e.target.value)}
          className="custom-select"
        >
          <option value="" key="All Items">
            Select a {category}
          </option>
          {items.map((item) => {
            return (
              <option value={item.id} key={item.id}>
                {item.title || item.name}
              </option>
            )
          })}
        </select>
      </span>
    </div>
  )
}

// Templates Component
const SpreadsheetTemplates = () => {
  const [templates, setTemplates] = useState([])
  const [selectedTemplate, setSelectedTemplate] = useState()

  const downloadCsv = (e) => {
    e.preventDefault()

    axios
      .get(`/spreadsheet-templates/${selectedTemplate.id}/download/`)
      .then((res) => {
        var hiddenElement = document.createElement("a")
        hiddenElement.href =
          "data:text/csv;charset=utf-8," + encodeURI(res.data)
        hiddenElement.target = "_blank"
        hiddenElement.download = selectedTemplate.title + " template.csv"
        hiddenElement.click()
      })
      .catch((err) => {
        console.error(err.message)
      })
  }

  useEffect(() => {
    axios
      .get(`/spreadsheet-templates/`)
      .then((res) => {
        setTemplates(res.data)
      })
      .catch((err) => {
        console.error(err.message)
      })
  }, [])

  return (
    <div className="col-sm-8">
      <div
        className="card"
        style={{
          padding: "1rem",
        }}
      >
        <h4 style={{ borderBottom: "1px solid black" }}>
          Spreadsheet Templates
        </h4>
        <div
          style={{
            overflow: "auto",
            maxHeight: "15rem",
            height: "14rem",
          }}
        >
          <div style={{}} className="d-flex">
            <DropdownSelector
              items={templates}
              selectedItem={selectedTemplate}
              setSelectedItem={setSelectedTemplate}
              category="template"
            />
            <span style={{ marginBottom: "0.5rem", marginLeft: "1rem" }}>
              <button
                disabled={!selectedTemplate}
                onClick={downloadCsv}
                className={
                  selectedTemplate
                    ? "btn btn-outline-primary"
                    : "btn btn-outline-secondary"
                }
              >
                Download
              </button>
            </span>
          </div>
          {selectedTemplate && (
            <div>Description: {selectedTemplate.description}</div>
          )}

          {selectedTemplate && (
            <table
              className="table table-hover table-responsive text-center"
              style={{ backgroundColor: "#eaecf4" }}
            >
              <thead className="thead-light">
                <tr>
                  <th scope="col">Column</th>
                  <th scope="col">Column Header</th>
                  <th scope="col">Required</th>
                  <th scope="col">Description</th>
                </tr>
              </thead>
              <tbody>
                {selectedTemplate.fields.map((field) => (
                  <tr>
                    <td>{field.display_name}</td>
                    <td>{field.column_header}</td>
                    <td>
                      {field.required ? (
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          style={{ color: "var(--green)" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          style={{ color: "var(--red)" }}
                        />
                      )}
                    </td>
                    <td>{field.description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  )
}

// Main Component
const Upload = () => {
  const [files, setFiles] = useState([])

  const [sheetType, setSheetType] = useState("")

  const [uploadStatus, setUploadStatus] = useState("idle")
  const [lastSheetTitle, setLastSheetTitle] = useState("")
  const [lastSheetType, setLastSheetType] = useState("")

  const [percentComplete, setPercentComplete] = useState(0)

  const [uploadedSheets, setUploadedSheets] = useState([])
  const [lastUploadedDate, setLastUploadedDate] = useState("")
  const [sheets, setSheets] = useState([])

  const user = useSelector((state) => state.user)

  const [loadingList, setLoadingList] = useState(false)
  const [loadingLog, setLoadingLog] = useState(false)

  //search states
  const [q, setQ] = useState("")
  const searchParam = ["type", "localName"]

  // can this profile upload spreadsheets for the current UTA
  const can_upload =
    user.is_uploader && user.uta && user.uta.active === user.uta.started

  //fetch call for sheets
  useEffect(() => {
    axios
      .get(`/spreadsheet-options/`)
      .then((res) => {
        setSheets(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [user.uploadedSheets, user.uploadStatus])

  useEffect(() => {
    if (user.currentFocusedTeam) {
      axios
        .get(`/get-associated-spreadsheets/${user.currentFocusedTeam}`)
        .then((res) => {
          setUploadedSheets(res.data)
          if (res.data && res.data.length > 0) {
            setLastUploadedDate(new Date(res.data[0].created).toDateString())
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [user])

  const handleSubmit = (e) => {
    e.preventDefault()
    setLastSheetTitle(files[0].name)
    setLastSheetType(sheetType)
    setUploadStatus("loading")
    setPercentComplete(0)

    const formData = new FormData()
    formData.append("sheet", files[0])
    formData.append("type", sheetType)

    axios
      .post("/upload-sheet", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        // getProgress(res.data.id)
        // clearInput()
        setUploadStatus("success")
      })
      .catch((err) => {
        setUploadStatus("failed")
        // clearInput()
        console.log(err.response)
      })
  }

  const getProgress = (uploadId) => {
    axios
      .get(`/get-worker-status/${uploadId}`)
      .then((res) => {
        if (res.data.status === "loading") {
          setPercentComplete(res.data.percentComplete)
          setTimeout(() => {
            getProgress(uploadId)
          }, 1000)
        } else if (res.data.status === "complete") {
          setPercentComplete(100)
          setTimeout(() => {
            setUploadStatus("success")
          }, 750)
        } else {
          setUploadStatus("failed")
        }
      })
      .catch((err) => {
        setUploadStatus("failed")
        clearInput()
      })
  }

  const clearInput = () => {
    document.getElementById("sheet-input").value = ""
    setSheetType("")
  }

  function search(items) {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        if (!item[newItem]) {
          return false
        }
        if (newItem === "trainee" && item[newItem]) {
          return (
            item[newItem].name
              .toString()
              .toLowerCase()
              .indexOf(q.toLowerCase()) > -1
          )
        }
        if (item[newItem]) {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          )
        }
        return false
      })
    })
  }
  //

  return (
    <DefaultLayout currentPage="Upload Spreadsheet" allowNoTeamSelection>
      <div>
        <div style={{ margin: ".25rem 0" }}>
          <div className="row">
            <div className="col-sm-4">
              <div
                className="card"
                style={{
                  padding: "1rem",
                  height: "100%",
                  fontSize: "1.1rem",
                }}
              >
                <form onSubmit={handleSubmit}>
                  {uploadStatus === "failed" && (
                    <li
                      className="list-group-item list-group-item-danger text-center"
                      style={{ borderRadius: "5px", border: "none" }}
                    >
                      There was an error uploading the following sheet:{" "}
                      <b>{lastSheetTitle}</b> of type <b>{lastSheetType}</b>
                    </li>
                  )}
                  {uploadStatus === "success" && (
                    <li
                      className="list-group-item list-group-item-success text-center"
                      style={{ borderRadius: "5px", border: "none" }}
                    >
                      Successfully uploaded the following sheet:{" "}
                      <b>{lastSheetTitle}</b> of type <b>{lastSheetType}</b>
                    </li>
                  )}
                  <div>
                    <div className="row">
                      <div className="d-flex justify-content-between align-content-center">
                        <h4 style={{ marginTop: "0.5rem" }}>
                          Spreadsheet Uploader
                        </h4>
                        {/* <button className="default-button">
                          Start New UTA
                        </button> */}
                      </div>
                      <div
                        className="form-group col-md-12"
                        style={{ marginTop: "3rem" }}
                      >
                        <label style={{ display: "none" }}>Select File :</label>
                        <input
                          type="file"
                          className="form-control"
                          name="upload_file"
                          onChange={(e) => setFiles(e.target.files)}
                          id="sheet-input"
                          required
                          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        />
                        <br />
                        <div className="input-group mb-3">
                          <div className="d-flex" style={{ width: "100%" }}>
                            <select
                              className="custom-select"
                              required
                              value={sheetType}
                              onChange={(e) => {
                                setSheetType(e.target.value)
                              }}
                            >
                              <option value={null}>Select Sheet Type</option>
                              {sheets.map((sheet, si) => {
                                return (
                                  <option
                                    id={sheet.pk}
                                    value={sheet.action_type}
                                    key={si}
                                  >
                                    {sheet.name}
                                  </option>
                                )
                              })}
                            </select>
                          </div>
                        </div>
                        <div>
                          <button
                            type="submit"
                            className={
                              can_upload
                                ? "default-button"
                                : "btn btn-secondary"
                            }
                            disabled={!can_upload}
                          >
                            Upload File
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            {uploadedSheets.length > 0 && false && (
              <div className="col-sm-6">
                <div
                  className="card"
                  style={{
                    padding: "1rem",
                    height: "100%",
                    fontSize: "1.1rem",
                  }}
                >
                  <h4 style={{ borderBottom: "1px solid black" }}>
                    Usable Training Data List{" "}
                    {lastUploadedDate && (
                      <span
                        style={{ fontSize: "1rem", color: "var(--gray-300)" }}
                      >
                        (Last sheet uploaded {lastUploadedDate})
                      </span>
                    )}
                  </h4>
                  <div
                    style={{
                      overflow: "auto",
                      maxHeight: "250px",
                      fontSize: "1.15rem",
                    }}
                  >
                    <ul
                      style={{
                        margin: "1em 0",
                        display: "grid",
                        gridTemplateColumns:
                          "repeat(auto-fill, minmax(150px, 1fr))",
                        gridGap: "1em",
                        listStyle: "none",
                      }}
                    >
                      {sheets.map((sheet_option, si) => {
                        return (
                          <li key={si}>
                            {sheet_option.name}
                            {uploadedSheets.filter(
                              (s) => s.spreadsheet_option_id === sheet_option.pk
                            )[0] && (
                              <FontAwesomeIcon
                                icon={faCheckCircle}
                                style={{ color: "var(--green)" }}
                              />
                            )}
                            {!sheets && (
                              <FontAwesomeIcon
                                icon={faTimesCircle}
                                style={{ color: "var(--red)" }}
                              />
                            )}
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            )}
            {<SpreadsheetTemplates />}
          </div>
        </div>

        {/* Notifications list of trainees */}
        <div className="card" style={{ padding: "1rem" }}>
          <h4>
            List of Uploaded Sheets
            <div className="search-wrapper" style={{ marginTop: "1rem" }}>
              <label htmlFor="search-form">
                <input
                  style={{ marginBottom: "16px" }}
                  type="search"
                  name="search-form"
                  id="search-form"
                  className="search-input textInput form-control"
                  placeholder="Search for..."
                  value={q}
                  onChange={(e) => setQ(e.target.value)}
                />
                <span className="sr-only">Search here</span>
              </label>
            </div>
          </h4>

          <table
            className="table table-hover table-responsive text-center"
            style={{ backgroundColor: "#eaecf4" }}
          >
            <thead className="thead-light">
              <tr>
                <th scope="col">Sheet Type</th>
                <th scope="col">Sheet Name</th>
                <th scope="col">Uploader Name</th>
                <th scope="col">Time Stamp</th>
                <th scope="col">Upload Status</th>
              </tr>
            </thead>
            <tbody>
              {uploadedSheets &&
                uploadedSheets.length > 0 &&
                search(uploadedSheets).map((sheet) => {
                  return (
                    <tr key={sheet.id}>
                      <td>
                        {sheet.spreadsheet_option
                          ? sheet.spreadsheet_option.name
                          : "N/A"}
                      </td>
                      <td>{sheet.localName}</td>
                      <td>
                        {sheet.created_by
                          ? `${sheet.created_by.last_name}, ${sheet.created_by.first_name}`
                          : ""}
                      </td>
                      <td>
                        {new Date(sheet.created).toLocaleString("en-US", {
                          hour12: false,
                        })}
                      </td>
                      <td>{sheet.status ? sheet.status.status : "unknown"}</td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>
      </div>
      {/* Loading Modal */}
      <Modal
        styles={modalStylesWarning}
        open={uploadStatus === "loading"}
        center
        focusTrapped={false}
        showCloseIcon={false}
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <h5 className="modal-title">Uploading sheet</h5>
        <hr />
        <p className="modal-subtitle">
          We are currently processing the following sheet:{" "}
          <b>{lastSheetTitle}</b> of type <b>{lastSheetType}</b>. Please do not
          refresh or leave this page while or the process might end abruptly.
          This may take a few minutes.
        </p>
        <SmallLoader />
        <ProgressBar color="var(--blue)" percent={percentComplete} />
      </Modal>
      {/* {percentComplete === 100 && <Confetti recycle={false} />} */}
    </DefaultLayout>
  )
}

export default Upload
