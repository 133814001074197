import React from "react"
import { LineChart, Line, CartesianGrid, XAxis, YAxis } from "recharts"

import SubGroupLabel from "./SubGroupLabel"

const SubGroupCard = (props) => {
  return (
    <div>
      <p className="text font-weight-bold mb-1 ">TEAM METRICS</p>
      {props.data
        .sort((a, b) => a.percComplete - b.percComplete)
        .map((team) => {
          return <SubGroupLabel data={team} key={team.name} />
        })}
    </div>
  )
}

export default SubGroupCard
