import React, { useState, useEffect } from "react"

import DefaultLayout from "../../layouts/defaultLayout"
import LoadingDashboard from "../../components/loadingDashboard"

import { Link } from "react-router-dom"
import axios from "axios"

import { useDispatch, useSelector } from "react-redux"
import { setErrors } from "../../redux/userSlice"

import Modal from "react-responsive-modal"
import { modalStyles, modalStylesDanger } from "../../styles/constanStyles"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheckCircle,
  faTimesCircle,
  faPlus,
  faTrash,
  faEdit,
} from "@fortawesome/free-solid-svg-icons"

// Edit compliance modal
const EditComplianceModal = ({
  modalOpen,
  compliance,
  trainees,
  dateToEdit,
  setModalOpen,
  currentFocusedTeam,
  getCompliance,
}) => {
  const [formError, setFormError] = useState("")
  const [loadingForm, setLoadingForm] = useState(false)

  const [nonCompTrainees, setNonCompTrainees] = useState([])
  const [compTrainees, setCompTrainees] = useState([])

  const [traineeIds, setTraineeIds] = useState([])

  // Set initial states using given compliance data
  useEffect(() => {
    if (dateToEdit) {
      setNonCompTrainees([])
      setCompTrainees([])
      compliance.map((a) => {
        if (a.date === dateToEdit) {
          a.non_comp_trainees.map((t) => {
            setNonCompTrainees((prev) => [...prev, t.id])
          })
          a.comp_trainees.map((t) => {
            setCompTrainees((prev) => [...prev, t.id])
          })
        }
      })
    }
  }, [dateToEdit])

  useEffect(() => {
    trainees.map((t) => {
      setTraineeIds((prev) => [...prev, t.id])
    })
  }, [trainees])

  const handleSubmit = (e) => {
    e.preventDefault()

    setLoadingForm(true)
    setFormError("")

    const body = {
      date: dateToEdit,
      comp_trainees: compTrainees,
      non_comp_trainees: nonCompTrainees,
      na_trainees: traineeIds.filter(
        (t) => !compTrainees.includes(t) && !nonCompTrainees.includes(t)
      ),
    }

    axios
      .patch(`/edit-compliance/${currentFocusedTeam}`, body)
      .then((res) => {
        setModalOpen(false)
        getCompliance()
      })
      .catch((err) => {
        if (err.response.data) {
          setFormError(err.response.data)
        } else {
          setFormError("An unexpected error has happened, please try again")
        }

        setLoadingForm(false)
      })
  }

  const handleNonCompClick = (id) => {
    // Currently clicked as absent
    if (nonCompTrainees.includes(id)) {
      setNonCompTrainees((prev) => prev.filter((t) => t !== id))
    } else {
      setNonCompTrainees((prev) => [...prev, id])
      setCompTrainees((prev) => prev.filter((t) => t !== id))
    }
  }

  const handleCompClick = (id) => {
    // Currently clicked as present
    if (compTrainees.includes(id)) {
      setCompTrainees((prev) => prev.filter((t) => t !== id))
    } else {
      setCompTrainees((prev) => [...prev, id])
      setNonCompTrainees((prev) => prev.filter((t) => t !== id))
    }
  }

  return (
    <Modal
      styles={modalStyles}
      open={modalOpen}
      center
      focusTrapped={false}
      onClose={() => setModalOpen(false)}
    >
      <div className="container-fluid">
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="table-responsive">
            <div className="table-wrapper">
              <div className="table-title">
                <div className="row">
                  <div className="col-sm-12">
                    <h2>Edit Compliance Record</h2>
                    <br />
                    <br />
                    <strong>Date: {dateToEdit}</strong>

                    <div style={{ textAlign: "right" }}>
                      <button
                        disabled={loadingForm}
                        className="default-button default-button-red"
                        onClick={() => setModalOpen(false)}
                        type="reset"
                      >
                        Cancel
                      </button>
                      <button
                        disabled={loadingForm}
                        className="default-button"
                        type="submit"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {trainees.map((trainee) => {
                    return (
                      <tr key={trainee.id}>
                        <td className="col-sm-8">
                          <h5>
                            <div key={trainee.id}>{trainee.name}</div>
                          </h5>
                        </td>
                        <td>
                          <a className="delete" data-toggle="modal">
                            <i
                              className="material-icons"
                              data-toggle="tooltip"
                              title="Delete"
                            >
                              <FontAwesomeIcon
                                icon={faTimesCircle}
                                style={
                                  nonCompTrainees.includes(trainee.id)
                                    ? {
                                        color: "var(--red)",
                                        fontSize: "2rem",
                                        cursor: "pointer",
                                      }
                                    : {
                                        color: "gray",
                                        fontSize: "2rem",
                                        cursor: "pointer",
                                      }
                                }
                                onClick={() => handleNonCompClick(trainee.id)}
                              />
                            </i>
                          </a>
                          <a className="add">
                            <i
                              className="material-icons"
                              data-toggle="tooltip"
                              title="Add"
                            >
                              <FontAwesomeIcon
                                icon={faCheckCircle}
                                style={
                                  compTrainees.includes(trainee.id)
                                    ? {
                                        color: "var(--green)",
                                        fontSize: "2rem",
                                        cursor: "pointer",
                                      }
                                    : {
                                        color: "gray",
                                        fontSize: "2rem",
                                        cursor: "pointer",
                                      }
                                }
                                onClick={() => handleCompClick(trainee.id)}
                              />
                            </i>
                          </a>
                        </td>
                      </tr>
                    )
                  })}
                  {formError && (
                    <div
                      className="h6 text-center text-danger"
                      style={{ flexBasis: "100%" }}
                    >
                      {formError}
                    </div>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  )
}

const Compliance = () => {
  const [compliance, setCompliance] = useState([])
  const [trainees, setTrainees] = useState([])
  const [loadingCompliance, setLoadingCompliance] = useState(false)
  const [loadingTrainees, setLoadingTrainees] = useState(false)
  const [q, setQ] = useState("")
  const searchParam = ["name"]

  // States for deletion of date
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [deleteDate, setDeleteDate] = useState("")
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [deleteError, setDeleteError] = useState("")

  const [dateToEdit, setDateToEdit] = useState("")
  const [editModalOpen, setEditModalOpen] = useState(false)

  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)

  useEffect(() => {
    if (user.status === "idle" || user.status === "success") {
      if (user.currentFocusedTeam && user.currentFocusedTeam.length > 0) {
        setLoadingCompliance(true)
        setLoadingTrainees(true)
        setCompliance([])
        setTrainees([])

        axios
          .get(`/get-shop-compliance/${user.currentFocusedTeam}`)
          .then((res) => {
            setCompliance(res.data.reverse())
            setLoadingCompliance(false)
          })
          .catch((err) => {
            console.log(err)
            setLoadingCompliance(false)
            dispatch(
              setErrors([
                "An unexpected error has occured please reload and try again",
              ])
            )
          })

        axios
          .get(`/get-shop/${user.currentFocusedTeam}`)
          .then((res) => {
            setLoadingTrainees(false)
            setTrainees(res.data.trainees)
          })
          .catch((err) => {
            console.log(err)
            setLoadingCompliance(false)
            dispatch(
              setErrors([
                "An unexpected error has occured please reload and try again",
              ])
            )
          })
      }
    }
  }, [user.currentFocusedTeam, user.status])

  const getCompliance = () => {
    setLoadingCompliance(true)
    axios
      .get(`/get-shop-compliance/${user.currentFocusedTeam}`)
      .then((res) => {
        setCompliance(res.data.reverse())
        setLoadingCompliance(false)
      })
      .catch((err) => {
        console.log(err)
        setLoadingCompliance(false)
        dispatch(
          setErrors([
            "An unexpected error has occured please reload and try again",
          ])
        )
      })
  }

  const handleDeleteDate = () => {
    setDeleteError("")
    setDeleteLoading(true)

    const body = {
      date: deleteDate,
    }
    axios
      .delete(`/delete-compliance/${user.currentFocusedTeam}`, { data: body })
      .then(() => {
        setDeleteModalOpen(false)
        setDeleteLoading(false)
        setDeleteError("")
        // Filter out deleted date
        setCompliance((prev) =>
          prev.filter((entry) => entry.date !== deleteDate)
        )
      })
      .catch((err) => {
        setDeleteError("An unexpected error has occured, please try again")
        setDeleteLoading(false)
        console.log(err.response)
      })
  }

  if (loadingCompliance || loadingTrainees) {
    return <LoadingDashboard />
  }

  function search(items) {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        if (item[newItem]) {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          )
        } else {
          return false
        }
      })
    })
  }

  return (
    <DefaultLayout currentPage="Compliance">
      <div>
        <h1 className="title d-flex">
          <Link
            className="btn btn-md font-weight-bold"
            style={{ color: "var(--blue)", borderColor: "var(--blue)" }}
            to="/compliance/new"
          >
            <FontAwesomeIcon icon={faPlus} /> Add Date
          </Link>
          <div className="search-wrapper">
            <label htmlFor="search-form">
              <input
                style={{ marginLeft: "0.5rem" }}
                type="search"
                name="search-form"
                id="search-form"
                className="search-input textInput form-control"
                placeholder="Search for..."
                value={q}
                onChange={(e) => setQ(e.target.value)}
              />
              <span className="sr-only">Search names here</span>
            </label>
          </div>
        </h1>
        <div className="wrapper">
          <div className="container-fluid">
            <table
              className="table table-hover table-responsive text-center"
              style={{ backgroundColor: "#eaecf4" }}
            >
              <thead className="thead-light">
                <tr>
                  <th scope="col">Name</th>
                  {compliance &&
                    compliance.map((a) => {
                      if (a.date) {
                        return (
                          <th key={a.date}>
                            {a.date}
                            <FontAwesomeIcon
                              icon={faTrash}
                              style={{
                                color: "var(--red)",
                                marginLeft: "0.5rem",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setDeleteModalOpen(true)
                                setDeleteDate(a.date)
                                setDeleteError("")
                              }}
                            />
                            <FontAwesomeIcon
                              icon={faEdit}
                              style={{
                                color: "var(--yellow)",
                                marginLeft: "0.5rem",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setEditModalOpen(true)
                                setDateToEdit(a.date)
                              }}
                            />
                          </th>
                        )
                      }
                      return <></>
                    })}
                </tr>
              </thead>
              <tbody>
                {search(trainees).map((trainee) => (
                  <tr key={trainee.id}>
                    <td>{trainee.name}</td>
                    {compliance.map((date) => {
                      if (date.date) {
                        const mapKey = `${trainee.id}${date.date}`
                        if (
                          date.non_comp_trainees.some(
                            (t) => t.id === trainee.id
                          )
                        ) {
                          return (
                            <td key={mapKey}>
                              <FontAwesomeIcon
                                icon={faTimesCircle}
                                style={{ color: "var(--red)" }}
                              />
                            </td>
                          )
                        } else if (
                          date.comp_trainees.some((t) => t.id === trainee.id)
                        ) {
                          return (
                            <td key={mapKey}>
                              <FontAwesomeIcon
                                icon={faCheckCircle}
                                style={{ color: "var(--green)" }}
                              />
                            </td>
                          )
                        } else {
                          return <td key={mapKey}>N/A</td>
                        }
                      }
                      return <></>
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* Delete Modal */}
      <Modal
        styles={modalStylesDanger}
        open={deleteModalOpen}
        center
        focusTrapped={false}
        onClose={() => setDeleteModalOpen(false)}
      >
        <h5 className="modal-title">Delete Compliance Record</h5>
        <hr />
        <p className="modal-subtitle">
          Are you sure you want to delete the compliance record for{" "}
          <b>{deleteDate}</b>? This action cannot be undone.
        </p>
        <br />
        {deleteError && (
          <div
            className="h6 text-center text-danger"
            style={{ flexBasis: "100%" }}
          >
            {deleteError}
          </div>
        )}
        <button
          className="default-button default-button-red float-right"
          style={{ marginLeft: "0.5rem" }}
          onClick={handleDeleteDate}
          disabled={deleteLoading}
        >
          Delete
        </button>
        <button
          className="default-button float-right"
          type="reset"
          onClick={(e) => setDeleteModalOpen(false)}
          disabled={deleteLoading}
        >
          Cancel
        </button>
      </Modal>
      <EditComplianceModal
        trainees={trainees}
        compliance={compliance}
        dateToEdit={dateToEdit}
        modalOpen={editModalOpen}
        setModalOpen={setEditModalOpen}
        currentFocusedTeam={user.currentFocusedTeam}
        getCompliance={getCompliance}
      />
    </DefaultLayout>
  )
}

export default Compliance
