import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import DefaultLayout from "../../layouts/defaultLayout"
import LoadingDashboard from "../../components/loadingDashboard"
import SmallLoader from "../../components/smallLoader"
import ProgressBar from "../../components/progressBar"

import Modal from "react-responsive-modal"
import { modalStyles, modalStylesDanger } from "../../styles/constanStyles"
import Accordion from "../../components/accordion"

import axios from "axios"

// Redux
import { useSelector, useDispatch } from "react-redux"
import {
  setErrors,
  setTeam,
  getProfile,
  setTeamOrEmpty,
} from "../../redux/userSlice"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faExclamationTriangle,
  faCheckCircle,
  faUserSlash,
  faUserFriends,
  faUsers,
  faTasks,
  faTrash,
} from "@fortawesome/free-solid-svg-icons"

// Helper components
const DashboardCard = ({ color, textColor, children, trainees }) => {
  return (
    <div className="col-xl-3 col-md-6 mb-4">
      <div
        className="card shadow h-100"
        style={{ borderLeft: `4px solid ${color}` }}
      >
        <div className="card-body text">
          <div
            style={{
              color: `${textColor}`,
            }}
          >
            {children}
          </div>
          <br />
          <div style={{ fontSize: "1rem", color: "gray" }}>
            {trainees &&
              trainees.length > 0 &&
              trainees.map((t) => {
                return (
                  <div>
                    <TraineeCardSlim
                      name={t.formattedName}
                      compliance={t.percentComplete}
                      color="var(--black)"
                      traineeID={t.id}
                      key={t.id}
                    />
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </div>
  )
}

const DashboardCardSlim = ({ color, textColor, children, trainees }) => {
  return (
    <div className="">
      <div
        className="card shadow container"
        style={{
          borderLeft: `4px solid ${color}`,
          height: "60px",
          width: "275px",
          paddingTop: "1.5px",
        }}
      >
        <div className="text">
          <div
            style={{
              color: `${textColor}`,
              paddingLeft: "5px",
            }}
          >
            {children}
          </div>
          <br />
          <div style={{ fontSize: "1rem", color: "gray" }}>
            {trainees &&
              trainees.length > 0 &&
              trainees.map((t, ti) => {
                return <p key={ti}>{t.toUpperCase()}</p>
              })}
          </div>
        </div>
      </div>
    </div>
  )
}

const TraineeCardSlim = (props) => {
  return (
    <Link
      to={{
        pathname: `/trainee/profile/${props.traineeID}`,
        state: { name: props.name },
      }}
      style={{
        textDecoration: "none",
        color: "inherit",
        color: `${props.color}`,
      }}
    >
      <p>
        {props.name.toUpperCase()}{" "}
        {props.group && `(${props.group.name.toUpperCase()})`}
      </p>
    </Link>
  )
}

const TraineeCard = (props) => {
  return (
    <div className="col-xl-2 col-md-6 mb-4">
      <Link
        to={{
          pathname: `/trainee/profile/${props.traineeID}`,
          state: { name: props.name },
        }}
        style={{
          textDecoration: "none",
          color: "inherit",
          color: `${props.color}`,
        }}
      >
        <div className="card shadow h-100">
          <div className="card-body">
            <p className="text font-weight-bold mb-1">
              {props.name.toUpperCase()}{" "}
              {props.group && `(${props.group.name.toUpperCase()})`}
            </p>

            <ProgressBar percent={props.compliance} color={props.color} />
          </div>
        </div>
      </Link>
    </div>
  )
}

const TeamOverview = ({
  currentTeam,
  currentTeamTrainees,
  handleTraineeComparatorChange,
  isTraineeCompareByName,
  ...rest
}) => {
  const [q, setQ] = useState("")
  const searchParam = ["name"]

  function search(items) {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        if (item[newItem]) {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          )
        } else {
          return false
        }
      })
    })
  }

  return (
    <div className="wrapper">
      <div
        className="search-wrapper d-flex justify-content-between"
        style={{
          marginTop: "1rem",
          marginLeft: "1rem",
          alignItems: "center",
          marginRight: "1rem",
        }}
      >
        <label htmlFor="search-form">
          <input
            type="search"
            name="search-form"
            id="search-form"
            className="search-input textInput form-control"
            placeholder="Search for..."
            value={q}
            onChange={(e) => setQ(e.target.value)}
          />
          <span className="sr-only">Search</span>
        </label>
        <div>
          <button
            className={
              !isTraineeCompareByName
                ? "default-button default-button-active"
                : "default-button"
            }
            style={{ margin: 0, marginLeft: "1rem" }}
            onClick={() => handleTraineeComparatorChange(false)}
          >
            Sort by Percent Complete
          </button>
          <button
            className={
              isTraineeCompareByName
                ? "default-button default-button-active"
                : "default-button"
            }
            style={{ margin: 0, marginLeft: "1rem" }}
            onClick={() => handleTraineeComparatorChange(true)}
          >
            Sort by Name
          </button>
        </div>
      </div>
      {isTraineeCompareByName ? (
        <div className="container-fluid fade-in">
          <br />
          <div className="row">
            {search(currentTeamTrainees).map((trainee) => {
              if (trainee.percentComplete < 85) {
                return (
                  <TraineeCard
                    name={trainee.formattedName}
                    group={trainee.group}
                    compliance={trainee.percentComplete}
                    color="var(--red)"
                    traineeID={trainee.id}
                    key={trainee.id}
                  />
                )
              } else if (trainee.percentComplete === 100) {
                return (
                  <TraineeCard
                    name={trainee.formattedName}
                    group={trainee.group}
                    compliance={trainee.percentComplete}
                    color="var(--green)"
                    traineeID={trainee.id}
                    key={trainee.id}
                  />
                )
              } else {
                return (
                  <TraineeCard
                    name={trainee.formattedName}
                    group={trainee.group}
                    compliance={trainee.percentComplete}
                    color="var(--yellow)"
                    traineeID={trainee.id}
                    key={trainee.id}
                  />
                )
              }
            })}
          </div>
        </div>
      ) : (
        <div className="container-fluid fade-in">
          <br />
          <h6 className="header-text" style={{ color: "var(--red)" }}>
            <FontAwesomeIcon icon={faExclamationTriangle} /> Noncompliant
            Trainees
          </h6>
          <br />

          <div className="row">
            {search(currentTeamTrainees).map((trainee) => {
              if (trainee.percentComplete < 85) {
                return (
                  <TraineeCard
                    name={trainee.formattedName}
                    group={trainee.group}
                    compliance={trainee.percentComplete}
                    color="var(--red)"
                    traineeID={trainee.id}
                    key={trainee.id}
                  />
                )
              }
            })}
          </div>

          <br />
          <h6 className="header-text" style={{ color: "var(--green)" }}>
            <FontAwesomeIcon icon={faCheckCircle} /> Compliant Trainees
          </h6>
          <br />

          <div className="row">
            {currentTeam.trainees &&
              currentTeam.trainees.length > 0 &&
              search(currentTeamTrainees).map((trainee) => {
                if (trainee.percentComplete === 100) {
                  return (
                    <TraineeCard
                      name={trainee.formattedName}
                      compliance={trainee.percentComplete}
                      color="var(--green)"
                      traineeID={trainee.id}
                      key={trainee.id}
                    />
                  )
                } else if (trainee.percentComplete >= 85) {
                  return (
                    <TraineeCard
                      name={trainee.formattedName}
                      compliance={trainee.percentComplete}
                      color="var(--yellow)"
                      traineeID={trainee.id}
                      key={trainee.id}
                    />
                  )
                }
                return <></>
              })}
          </div>
        </div>
      )}
      <br />
    </div>
  )
}

const TaskOverview = ({ teamTasks, taskLoading }) => {
  //Search function state
  const [q, setQ] = useState("")
  const searchParam = ["task"]

  const statuses = ["od", "d", "cd", "f"]
  const statusNames = [" Overdue", " Due", " Coming Due", " Satisfied"]

  const [checkedState, setCheckedState] = useState(
    new Array(statuses.length).fill(true)
  )

  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    )

    setCheckedState(updatedCheckedState)
  }

  if (taskLoading) {
    return <SmallLoader />
  }

  //Search function
  function filterStatus(items) {
    if (
      !checkedState.reduce((sum, currentStatus, index) => {
        return sum + currentStatus
      }, 0)
    ) {
      return items
    }

    return items.filter((item) => {
      if (
        statuses
          .filter((status, index) => checkedState[index])
          .includes(item.status)
      ) {
        return true
      }
      return false
    })
  }
  //

  function search(items) {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        if (item[newItem]) {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          )
        } else {
          return false
        }
      })
    })
  }

  return (
    <div className="container-fluid fade-in">
      <div className="search-wrapper d-flex" style={{ marginTop: "1rem" }}>
        <label htmlFor="search-form">
          <input
            type="search"
            name="search-form"
            id="search-form"
            className="search-input textInput form-control"
            placeholder="Search for..."
            value={q}
            onChange={(e) => setQ(e.target.value)}
          />
          <span className="sr-only">Search teams here</span>
        </label>

        <div className="d-flex justify-content-between">
          <ul
            style={{
              listStyle: "none",
              display: "flex",
              gridGap: "14px",
            }}
          >
            {statuses.map((status, index) => {
              return (
                <li key={index}>
                  <div>
                    <div>
                      <input
                        type="checkbox"
                        status={status}
                        value={status}
                        checked={checkedState[index]}
                        onChange={() => handleOnChange(index)}
                        style={{ cursor: "pointer" }}
                      />
                      <label
                        style={{
                          marginLeft: "0.2rem",
                        }}
                      >
                        {statusNames[index]}
                      </label>
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>

      <br />
      {teamTasks && teamTasks.length > 0 ? (
        search(teamTasks).map((task) => {
          const TaskTitle = () => {
            return (
              <div>
                <h2 style={{ fontSize: "1.1rem", margin: 0 }}>
                  <b>{task.task}</b>{" "}
                  <span style={{ color: "var(--gray-300)" }}>
                    ({task.trainees.length})
                  </span>
                </h2>
              </div>
            )
          }

          return (
            <Accordion key={task.id} title={<TaskTitle />}>
              <div className="d-flex justify-content-between">
                <ul
                  style={{
                    listStyle: "none",
                    display: "flex",
                    gridGap: "14px",
                  }}
                >
                  {statuses.map((status, index) => {
                    return (
                      <li key={index}>
                        <div>
                          <div>
                            <input
                              type="checkbox"
                              status={status}
                              value={status}
                              checked={checkedState[index]}
                              onChange={() => handleOnChange(index)}
                              style={{ cursor: "pointer" }}
                            />
                            <label
                              style={{
                                marginLeft: "0.2rem",
                              }}
                            >
                              {statusNames[index]}
                            </label>
                          </div>
                        </div>
                      </li>
                    )
                  })}
                </ul>
                <p>Selected Trainees: {filterStatus(task.trainees).length}</p>
              </div>
              <table className="table table-hover">
                <thead className="thead-light">
                  <tr>
                    {checkedState[0] && <th>Overdue</th>}
                    {checkedState[1] && <th>Due</th>}
                    {checkedState[2] && <th>Coming Due</th>}
                    {checkedState[3] && <th>Satisfied</th>}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {/* Overdue tasks */}
                    {checkedState[0] && (
                      <td>
                        {task.trainees.map((trainee) => {
                          if (trainee.status === "od") {
                            return (
                              <li key={trainee.id}>
                                <Link
                                  to={`/trainee/profile/${trainee.id}`}
                                  style={{
                                    color: "red",
                                    textDecoration: "none",
                                  }}
                                >
                                  {trainee.name.toUpperCase()}
                                </Link>
                              </li>
                            )
                          }
                          return null
                        })}
                      </td>
                    )}
                    {/* Due tasks */}
                    {checkedState[1] && (
                      <td>
                        {task.trainees.map((trainee) => {
                          if (
                            trainee.status === "d" ||
                            trainee.status === "nk"
                          ) {
                            return (
                              <li key={trainee.id}>
                                <Link
                                  to={`/trainee/profile/${trainee.id}`}
                                  style={{
                                    color: "orange",
                                    textDecoration: "none",
                                  }}
                                >
                                  {trainee.name.toUpperCase()}
                                </Link>
                              </li>
                            )
                          }
                          return null
                        })}
                      </td>
                    )}
                    {/* Coming due */}
                    {checkedState[2] && (
                      <td>
                        {task.trainees.map((trainee) => {
                          if (trainee.status === "cd") {
                            return (
                              <li key={trainee.id}>
                                <Link
                                  to={`/trainee/profile/${trainee.id}`}
                                  style={{
                                    color: "blue",
                                    textDecoration: "none",
                                  }}
                                >
                                  {trainee.name.toUpperCase()}
                                </Link>
                              </li>
                            )
                          }
                          return null
                        })}
                      </td>
                    )}
                    {/* Satisfied */}
                    {checkedState[3] && (
                      <td>
                        {task.trainees.map((trainee) => {
                          if (trainee.status == "f") {
                            return (
                              <li key={trainee.id}>
                                <Link
                                  to={`/trainee/profile/${trainee.id}`}
                                  style={{
                                    color: "var(--dark-green)",
                                    textDecoration: "none",
                                  }}
                                >
                                  {trainee.name.toUpperCase()}
                                </Link>
                              </li>
                            )
                          }
                          return null
                        })}
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
            </Accordion>
          )
        })
      ) : (
        <div>There are currently no tasks in this team</div>
      )}
      <br />
    </div>
  )
}

const GroupAccordionEntry = ({ group, getGroups }) => {
  const [modalError, setModalError] = useState("")
  const [modalLoading, setModalLoading] = useState(false)

  const [deleteModal, setDeleteModal] = useState(false)

  const AccordionTitle = () => {
    return (
      <h2 style={{ fontSize: "1.1rem", margin: 0 }}>
        <b>{group.title}</b>
      </h2>
    )
  }

  const handleDelete = () => {
    setModalLoading(true)
    setModalError("")

    const body = {
      group_id: group.id,
    }
    axios
      .delete(`/delete-group`, { data: body })
      .then(() => {
        setDeleteModal(false)
        setModalLoading(false)
        getGroups()
      })
      .catch((err) => {
        setModalLoading(false)
        setModalError("An unexpected error has occured, please try again")
      })
  }

  return (
    <>
      {/* Delete Modal */}
      <Modal
        styles={modalStyles}
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        center
        focusTrapped={false}
      >
        <h5 className="modal-title">Delete Group</h5>
        <hr />
        <p className="modal-subtitle">
          Are you sure you want to delete this group: <b>{group.title}</b>?
        </p>
        {modalError.length > 0 && (
          <p className="h6 text-center text-danger">{modalError}</p>
        )}
        <div className="modal-button-container">
          <button
            className="default-button"
            onClick={() => setDeleteModal(false)}
          >
            Cancel
          </button>
          <button
            className="default-button default-button-red"
            onClick={() => handleDelete()}
            disabled={modalLoading}
          >
            Delete
          </button>
        </div>
      </Modal>
      {/* Edit Modal
      <Modal
        styles={modalStyles}
        open={editModal}
        onClose={() => setEditModal(false)}
        center
        focusTrapped={false}
      >
        <h5 className="modal-title">Edit Group Title</h5>
        <hr />
        <p className="modal-subtitle">Input new title:</p>
        <input
          type="text"
          required
          value={newTitle}
          onChange={(e) => setNewTitle(e.target.value)}
          className="modal-input"
        />
        <hr />
        {modalError.length > 0 && (
          <p className="h6 text-center text-danger">{modalError}</p>
        )}
        <div className="modal-button-container">
          <button
            className="default-button default-button-gray"
            onClick={() => setEditModal(false)}
          >
            Cancel
          </button>
          <button
            className="default-button"
            onClick={(e) => handleEdit(e)}
            disabled={modalLoading}
          >
            Submit
          </button>
        </div>
      </Modal> */}
      <Accordion
        title={<AccordionTitle />}
        key={group.id}
        bodyStyles={{ maxWidth: "400px" }}
      >
        <div style={{ fontSize: "0.9rem", margin: "0 0 0.5rem 0" }}>
          {/* <button
            className="default-button"
            onClick={() => {
              setNewTitle(group.title)
              setModalError("")
              setModalLoading(false)
              setEditModal(true)
            }}
          >
            <FontAwesomeIcon icon={faEdit} /> Edit Group Title
          </button> */}
          <button
            className="default-button default-button-red"
            onClick={() => {
              setModalError("")
              setModalLoading(false)
              setDeleteModal(true)
            }}
          >
            <FontAwesomeIcon icon={faTrash} /> Delete Group
          </button>
        </div>
        {group.trainees && group.trainees.length > 0 ? (
          <ul>
            {group.trainees.map((t) => {
              return (
                <li key={t.id}>
                  <Link
                    to={`/trainee/profile/${t.id}`}
                    style={{ textDecoration: "none" }}
                  >
                    {t.name.toUpperCase()}
                  </Link>
                </li>
              )
            })}
          </ul>
        ) : (
          <p>There are no trainees in this group</p>
        )}
      </Accordion>
    </>
  )
}

const GroupOverview = ({ props }) => {
  const { currentTeam, currentTeamGroups, groupLoading, getGroups } = props

  // Modal States
  const [loading, setLoading] = useState(false)
  const [modalError, setModalError] = useState("")

  const [newGroupOpen, setNewGroupOpen] = useState(false)
  const [newGroupName, setNewGroupName] = useState("")

  const [addTraineesOpen, setAddTraineesOpen] = useState(false)
  const [addTraineesGroup, setAddTraineesGroup] = useState("")
  const [addTraineesList, setAddTraineesList] = useState([])
  // Have a list of trainee ids in current selected group so
  // that they won't show up in selection to add
  const [addTraineesCurrentGroup, setAddTraineesCurrentGroup] = useState([])

  const [newTaskOpen, setNewTaskOpen] = useState(false)
  const [newTaskGroup, setNewTaskGroup] = useState("")
  const [newTaskName, setNewTaskName] = useState("")

  const handleNewGroup = (e) => {
    e.preventDefault()

    if (!newGroupName || newGroupName.length === 0) {
      setModalError("Please enter a group name")
      return
    }

    setLoading(true)
    setModalError("")

    const body = {
      groupName: newGroupName,
      shop: currentTeam.code,
    }

    axios
      .post("/create-group", body)
      .then(() => {
        getGroups()
        setModalError("")
        setLoading(false)
        setNewGroupOpen(false)
      })
      .catch((err) => {
        if (err.response.data && err.response.data.length > 0) {
          setModalError(err.response.data)
        } else {
          setModalError("An unexpected error has occured, please try again")
        }
        setLoading(false)
      })
  }

  const handleAddTrainees = (e) => {
    e.preventDefault()

    if (!addTraineesList || addTraineesList.length === 0) {
      setModalError("Please select at least one trainee")
      return
    }

    if (!addTraineesGroup || addTraineesGroup.length === 0) {
      setModalError("Please choose a group")
      return
    }

    setLoading(true)
    setModalError("")

    const body = {
      group_id: addTraineesGroup,
      assignedTrainees: addTraineesList,
    }

    axios
      .patch("/assign-group-trainees", body)
      .then(() => {
        getGroups()
        setModalError("")
        setLoading(false)
        setAddTraineesOpen(false)
        setAddTraineesList([])
      })
      .catch((err) => {
        if (err.response.data && err.response.data.length > 0) {
          setModalError(err.response.data)
        } else {
          setModalError("An unexpected error has occured, please try again")
        }
        setLoading(false)
      })
  }

  const handleNewTask = (e) => {
    e.preventDefault()

    if (!newTaskName || newTaskName.length === 0) {
      setModalError("Please enter a task title")
      return
    }

    if (!newTaskGroup || newTaskGroup.length === 0) {
      setModalError("Please choose a group")
      return
    }

    setLoading(true)
    setModalError("")

    const body = {
      group_id: newTaskGroup,
      task: {
        title: newTaskName,
      },
    }

    axios
      .post("/create-group-task", body)
      .then(() => {
        setModalError("")
        setLoading(false)
        setNewTaskOpen(false)
      })
      .catch((err) => {
        if (err.response.data && err.response.data.length > 0) {
          setModalError(err.response.data)
        } else {
          setModalError("An unexpected error has occured, please try again")
        }
        setLoading(false)
      })
  }

  // Comparator for sorting groups into alpha order
  const compGroups = (a, b) => {
    if (a.title === b.title) {
      return a.trainees.length > b.trainees.length
        ? 1
        : a.trainees.length < b.trainees.length
          ? -1
          : 0
    }

    return a.title > b.title ? 1 : -1
  }

  const handleAddTraineesGroupSelect = (v) => {
    setAddTraineesGroup(v)
    setAddTraineesCurrentGroup([])

    // Find current selected group
    // Add ids to addTraineesCurrentGroup
    // These ids are then used to filter out the trainee selections
    // This is to prevent users from trying to add a
    // trainee to the group they are already in

    currentTeamGroups.sort(compGroups).map((group) => {
      if (group.id == v) {
        group.trainees.map((t) => {
          setAddTraineesCurrentGroup((prev) => [...prev, t.id])
          // Remove from current selection if selected
          if (addTraineesList.includes(t.id)) {
            setAddTraineesList((prev) => prev.filter((id) => t.id !== id))
          }
        })
      }
    })
  }

  if (groupLoading) {
    return <SmallLoader />
  }

  return (
    <>
      {/* New Group Modal */}
      <Modal
        styles={modalStyles}
        open={newGroupOpen}
        onClose={() => setNewGroupOpen(false)}
        center
        focusTrapped={false}
      >
        <h5 className="modal-title">
          Add a New Group to <b>{currentTeam.title}</b>
        </h5>
        <hr />
        <form onSubmit={handleNewGroup}>
          <p className="modal-subtitle">Input Group Name*</p>
          <input
            type="text"
            required
            className="textinput textInput form-control"
            value={newGroupName}
            onChange={(e) => setNewGroupName(e.target.value)}
          />
          <br />
          {modalError && (
            <div className="h6 text-center text-danger">{modalError}</div>
          )}
          <button
            className="default-button float-right"
            style={{ marginLeft: "0.5rem" }}
            disabled={loading}
          >
            Add Group
          </button>
          <button
            className="default-button default-button-gray float-right"
            onClick={() => setNewGroupOpen(false)}
            disabled={loading}
            type="reset"
          >
            Cancel
          </button>
        </form>
      </Modal>

      {/* Add Trainees Modal */}
      <Modal
        styles={modalStyles}
        open={addTraineesOpen}
        onClose={() => setAddTraineesOpen(false)}
        center
        focusTrapped={false}
      >
        <h5 className="modal-title">Add Trainees to a Group</h5>
        <hr />
        <form onSubmit={handleAddTrainees}>
          <p className="modal-subtitle">
            Select Group from <b>{currentTeam.title}</b>
          </p>
          <select
            value={addTraineesGroup}
            onChange={(e) => handleAddTraineesGroupSelect(e.target.value)}
            className="custom-select"
            required
          >
            <option value="">Select group</option>
            {currentTeamGroups.sort(compGroups).map((g) => {
              return (
                <option key={g.id} value={g.id}>
                  {g.title}
                </option>
              )
            })}
          </select>
          <br />
          <p className="modal-subtitle">Assign Trainees*</p>
          {currentTeam.trainees.map((trainee) => {
            const trainee_id = trainee.id
            if (!addTraineesCurrentGroup.includes(trainee.id)) {
              return (
                <div key={trainee_id}>
                  <label style={{ cursor: "pointer" }}>
                    <input
                      type="checkbox"
                      checked={addTraineesList.includes(trainee_id)}
                      style={{ cursor: "pointer" }}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setAddTraineesList((list) => [...list, trainee_id])
                        } else {
                          setAddTraineesList((list) =>
                            list.filter((item) => item !== trainee_id)
                          )
                        }
                      }}
                    />
                    <b> {trainee.name.toUpperCase()}</b>
                  </label>
                </div>
              )
            }
          })}

          <br />
          {modalError && (
            <div className="h6 text-center text-danger">{modalError}</div>
          )}
          <button
            className="default-button float-right"
            style={{ marginLeft: "0.5rem" }}
            disabled={loading}
          >
            Assign Trainees
          </button>
          <button
            className="default-button default-button-gray float-right"
            onClick={() => setAddTraineesOpen(false)}
            disabled={loading}
            type="reset"
          >
            Cancel
          </button>
        </form>
      </Modal>

      {/* New Task Modal */}
      <Modal
        styles={modalStyles}
        open={newTaskOpen}
        onClose={() => setNewTaskOpen(false)}
        center
        focusTrapped={false}
      >
        <h5 className="modal-title">Add a Task</h5>
        <hr />
        <form onSubmit={handleNewTask}>
          <p className="modal-subtitle">
            Select Group from <b>{currentTeam.title}</b>
          </p>
          <select
            value={newTaskGroup}
            onChange={(e) => setNewTaskGroup(e.target.value)}
            className="custom-select"
            required
          >
            <option value="">Select group</option>
            {currentTeamGroups.sort(compGroups).map((g) => {
              return (
                <option key={g.id} value={g.id}>
                  {g.title}
                </option>
              )
            })}
          </select>
          <br />
          <p className="modal-subtitle">Input Task Title*</p>
          <input
            type="text"
            required
            className="textinput textInput form-control"
            value={newTaskName}
            onChange={(e) => setNewTaskName(e.target.value)}
          />
          <br />
          {modalError && (
            <div className="h6 text-center text-danger">{modalError}</div>
          )}
          <button
            className="default-button float-right"
            style={{ marginLeft: "0.5rem" }}
            disabled={loading}
          >
            Add Task
          </button>
          <button
            className="default-button default-button-gray float-right"
            onClick={() => setNewTaskOpen(false)}
            disabled={loading}
            type="reset"
          >
            Cancel
          </button>
        </form>
      </Modal>

      {/* Main Component */}
      <div className="container-fluid fade-in">
        {/* Buttons */}
        <div style={{ fontSize: "0.9rem", margin: "2rem 0" }}>
          <button
            className="default-button"
            onClick={() => {
              setNewGroupName("")
              setModalError("")
              setLoading(false)
              setNewGroupOpen(true)
            }}
          >
            <FontAwesomeIcon icon={faUsers} /> Add Group
          </button>
          <button
            className="default-button"
            onClick={() => {
              setAddTraineesGroup("")
              setAddTraineesList([])
              setModalError("")
              setLoading(false)
              setAddTraineesOpen(true)
              setAddTraineesCurrentGroup([])
            }}
          >
            <FontAwesomeIcon icon={faUsers} /> Add Trainee to Group
          </button>
          <button
            className="default-button"
            onClick={() => {
              setNewTaskName("")
              setNewTaskGroup("")
              setModalError("")
              setLoading(false)
              setNewTaskOpen(true)
            }}
          >
            <FontAwesomeIcon icon={faTasks} /> Add Group Task
          </button>
        </div>
        {currentTeamGroups &&
          currentTeamGroups.length > 0 &&
          currentTeamGroups.sort(compGroups).map((group) => {
            return (
              <GroupAccordionEntry
                group={group}
                key={group.id}
                getGroups={getGroups}
              />
            )
          })}
        <br />
      </div>
    </>
  )
}

// Trainee comparator functions
const compTraineeByName = (a, b) => {
  if (a.formattedName === b.formattedName) {
    return a.id > b.id ? 1 : a.id < b.id ? -1 : 0
  }

  return a.formattedName > b.formattedName ? 1 : -1
}

const compTraineeByPercentComplete = (a, b) => {
  if (
    a.formattedName === b.formattedName &&
    a.percentComplete === b.percentComplete
  ) {
    return a.id > b.id ? 1 : a.id < b.id ? -1 : 0
  }
  if (a.percentComplete === b.percentComplete) {
    return a.formattedName > b.formattedName
      ? 1
      : a.formattedName < b.formattedName
        ? -1
        : 0
  }
  return a.percentComplete > b.percentComplete ? 1 : -1
}

const OverviewCard = (props) => {
  const { currentTeam, teamTasks, taskLoading } = props

  const { teams } = useSelector((state) => state.user)
  const dispatch = useDispatch()

  // Sorted trainees
  const [currentTeamTrainees, setCurrentTeamTrainees] = useState([])
  const [isTraineeCompareByName, setIsTraineeCompareByName] = useState(true)

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [mergeModalOpen, setMergeModalOpen] = useState(false)
  const [inviteModalOpen, setInviteModalOpen] = useState(false)

  const [mergeToTeam, setMergeToTeam] = useState("")
  const [inviteEmail, setInviteEmail] = useState("")

  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  // Overview Card Tab State
  const [overviewView, setOverviewView] = useState("team")

  // Sort trainees by name on load
  useEffect(() => {
    if (Array.isArray(currentTeam.trainees)) {
      setCurrentTeamTrainees([...currentTeam.trainees].sort(compTraineeByName))
    }
  }, [currentTeam])

  // Handler function for changing sorting methods
  const handleTraineeComparatorChange = (isName) => {
    if (isName) {
      setIsTraineeCompareByName(true)
      if (Array.isArray(currentTeam.trainees)) {
        setCurrentTeamTrainees(
          [...currentTeam.trainees].sort(compTraineeByName)
        )
      }
    } else {
      setIsTraineeCompareByName(false)
      if (Array.isArray(currentTeam.trainees)) {
        setCurrentTeamTrainees(
          [...currentTeam.trainees].sort(compTraineeByPercentComplete)
        )
      }
    }
  }

  const handleDeleteTeam = (e) => {
    e.preventDefault()
    setLoading(true)
    setError("")
    axios
      .delete(`/delete-shop/${currentTeam.code}`)
      .then(() => {
        setDeleteModalOpen(false)
        setLoading(false)
        setError("")
        dispatch(setTeamOrEmpty(currentTeam.code))
        dispatch(getProfile())
      })
      .catch((err) => {
        console.log(err)
        setError("An error has occured, please try again")
        setLoading(false)
      })
  }

  const handleMerge = (e) => {
    e.preventDefault()
    if (mergeToTeam === "") {
      setError("Please select a team")
    } else {
      setError("")
      setLoading(true)
      const body = {
        from: currentTeam.code,
        to: mergeToTeam,
      }
      axios
        .post("/merge-shops", body)
        .then(() => {
          setLoading(false)
          setMergeModalOpen(false)
          dispatch(setTeam(mergeToTeam))
          dispatch(getProfile())
        })
        .catch((err) => {
          console.log(err.response)
          setLoading(false)
        })
    }
  }

  const handleInvite = (e) => {
    e.preventDefault()
    if (inviteEmail.length === 0) setError("Please input an email adress")
    else {
      setLoading(true)
      setError("")
      const body = {
        email: inviteEmail,
        code: currentTeam.code,
      }
      axios
        .post("/invite-trainee", body)
        .then(() => {
          setLoading(false)
          setInviteModalOpen(false)
        })
        .catch((err) => {
          if (err.response.status === 400) {
            setError(err.response.data)
          } else {
            setError("An unexpected error has occured, please try again")
          }
          setLoading(false)
        })
    }
  }

  return (
    <>
      {/* Delete Modal */}
      <Modal
        styles={modalStylesDanger}
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        center
        focusTrapped={false}
      >
        <h5 className="modal-title">
          Are you sure you want to remove <b>{currentTeam.title}</b>
        </h5>
        <hr />
        <p className="modal-subtitle">
          Removing this team will also delete all of their associated data. This
          action cannot be undone. Additionally, this feature will{" "}
          <b>delete all trainees within the current team.</b>
        </p>
        {error && <div className="h6 text-center text-danger">{error}</div>}
        <button
          className="default-button float-right"
          style={{ marginLeft: "0.5rem" }}
          onClick={handleDeleteTeam}
          disabled={loading}
        >
          Remove Team
        </button>
        <button
          className="default-button float-right"
          onClick={() => setDeleteModalOpen(false)}
          disabled={loading}
        >
          Cancel
        </button>
      </Modal>
      {/* Merge Modal */}
      <Modal
        styles={modalStyles}
        open={mergeModalOpen}
        onClose={() => setMergeModalOpen(false)}
        center
        focusTrapped={false}
      >
        <h5 className="modal-title">Merge Teams</h5>
        <hr />
        <p className="modal-subtitle">
          Merge trainees from <b>{currentTeam.title}</b> into
        </p>
        {error && <div className="h6 text-center text-danger">{error}</div>}
        <form onSubmit={handleMerge}>
          <select
            value={mergeToTeam}
            required
            onChange={(e) => setMergeToTeam(e.target.value)}
            className="custom-select"
          >
            <option value="">Choose...</option>
            {teams.map((t) => {
              if (t.code !== currentTeam.code) {
                return (
                  <option value={t.code} key={t.code}>
                    {t.title}
                  </option>
                )
              }
            })}
          </select>
          <br />
          {error && <div className="h6 text-center text-danger">{error}</div>}
          <button
            className="default-button float-right"
            style={{ marginLeft: "0.5rem" }}
            disabled={loading}
          >
            Merge Team
          </button>
          <button
            className="default-button default-button-gray float-right"
            onClick={() => setMergeModalOpen(false)}
            disabled={loading}
            type="reset"
          >
            Cancel
          </button>
        </form>
      </Modal>
      {/* Invite Modal */}
      <Modal
        styles={modalStyles}
        open={inviteModalOpen}
        onClose={() => setInviteModalOpen(false)}
        center
        focusTrapped={false}
      >
        <h5 className="modal-title">Invite a New Trainee to your Team</h5>
        <hr />
        <p className="modal-subtitle">Input Your Trainee's Email*</p>
        <form onSubmit={handleInvite}>
          <input
            type="email"
            required
            className="textinput textInput form-control"
            value={inviteEmail}
            onChange={(e) => setInviteEmail(e.target.value)}
          />
          <br />
          {error && <div className="h6 text-center text-danger">{error}</div>}
          <button
            className="default-button float-right"
            style={{ marginLeft: "0.5rem" }}
            disabled={loading}
          >
            Invite Trainee
          </button>
          <button
            className="default-button default-button-gray float-right"
            onClick={() => setInviteModalOpen(false)}
            disabled={loading}
            type="reset"
          >
            Cancel
          </button>
        </form>
      </Modal>

      <div className="card shadow">
        <div
          className="card-header"
          style={{
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div className="nav">
            <h4
              className={
                overviewView === "team"
                  ? "tab-link header-text tab-link-active"
                  : "tab-link header-text"
              }
              onClick={() => setOverviewView("team")}
            >
              My Team
            </h4>
            <h4
              className={
                overviewView === "task"
                  ? "tab-link header-text tab-link-active"
                  : "tab-link header-text"
              }
              onClick={() => setOverviewView("task")}
            >
              Task Overview
            </h4>
            {/* <h4
              className={
                overviewView === "group"
                  ? "tab-link header-text tab-link-active"
                  : "tab-link header-text"
              }
              onClick={() => setOverviewView("group")}
            >
              Group Overview
            </h4> */}
          </div>
          {/* <button
            className="default-button"
            onClick={() => {
              setInviteModalOpen(true)
              setError("")
              setLoading(false)
              setInviteEmail("")
            }}
          >
            <FontAwesomeIcon icon={faUserPlus} /> Invite Trainee
          </button> */}
          <div>
            <button
              className="default-button"
              onClick={() => {
                setMergeModalOpen(true)
                setError("")
                setLoading(false)
                setMergeToTeam("")
              }}
            >
              <FontAwesomeIcon icon={faUserFriends} /> Merge Team
            </button>
            <button
              className="default-button default-button-red"
              onClick={() => {
                setDeleteModalOpen(true)
                setError("")
                setLoading(false)
              }}
            >
              <FontAwesomeIcon icon={faUserSlash} /> Remove Team
            </button>
          </div>
        </div>
        {overviewView === "team" && (
          <TeamOverview
            numOverdue={props.numOverdue}
            numNoncompliant={props.numNoncompliant}
            numCompliant={props.numCompliant}
            tasksCompleted={props.tasksCompleted}
            user={props.user}
            currentTeam={currentTeam}
            currentTeamTrainees={currentTeamTrainees}
            handleTraineeComparatorChange={handleTraineeComparatorChange}
            isTraineeCompareByName={isTraineeCompareByName}
          />
        )}
        {overviewView === "task" && (
          <TaskOverview teamTasks={teamTasks} taskLoading={taskLoading} />
        )}
        {overviewView === "group" && <GroupOverview props={props} />}

        <div />
      </div>
    </>
  )
}

// Main component
const Dashboard = () => {
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()

  const [currentTeam, setCurrentTeam] = useState({})
  const [loading, setLoading] = useState(false)

  const [currentTeamGroups, setCurrentTeamGroups] = useState([])
  const [groupLoading, setGroupLoading] = useState(false)

  const [teamTasks, setTeamTasks] = useState([])
  const [taskLoading, setTaskLoading] = useState(false)

  // Top dashboard info states
  const [numOverdue, setNumOverdue] = useState(0)
  const [numNoncompliant, setNumNoncompliant] = useState(0)
  const [numCompliant, setNumCompliant] = useState(0)
  const [tasksCompleted, setTasksCompleted] = useState(0)

  // Top cards trainee lists
  const [redList, setRedList] = useState([])
  const [yellowList, setYellowList] = useState([])
  const [greenList, setGreenList] = useState([])

  useEffect(() => {
    if (user.status === "idle" || user.status === "success") {
      if (user.currentFocusedTeam && user.currentFocusedTeam.length > 0) {
        setLoading(true)
        setNumOverdue(0)
        setNumNoncompliant(0)
        setNumCompliant(0)

        axios
          .get(`/get-shop/${user.currentFocusedTeam}`)
          .then((res) => {
            setCurrentTeam(res.data)
            // Limit trainee lists to 6
            setRedList(res.data.red_list.slice(0, 6))
            setYellowList(res.data.yellow_list.slice(0, 6))
            setGreenList(res.data.green_list.slice(0, 6))

            setLoading(false)

            getGroups()
            getTasks()

            // Set top info states
            setTasksCompleted(res.data.tasksCompleted)
            res.data.trainees.map((t) => {
              if (t.percentComplete < 85) {
                setNumOverdue((prev) => prev + 1)
              } else if (t.percentComplete === 100) {
                setNumCompliant((prev) => prev + 1)
              } else {
                setNumNoncompliant((prev) => prev + 1)
              }
            })
          })
          .catch((err) => {
            console.log(err)
            dispatch(
              setErrors([
                "An unexpected error has occured, please reload the page",
              ])
            )
          })
      }
    }
  }, [user.currentFocusedTeam, user.status])

  const getGroups = () => {
    setGroupLoading(true)
    axios
      .get(`/get-shop-groups/${user.currentFocusedTeam}`)
      .then((res) => {
        setCurrentTeamGroups(res.data)
        setGroupLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setGroupLoading(false)
        dispatch(
          setErrors(["An unexpected error has occured, please reload the page"])
        )
      })
  }

  // Comparator method to sort tasks by most trainees
  const compTasks = (a, b) => {
    if (a.trainees.length === b.trainees.length) {
      return a.task > b.task ? 1 : a.task < b.task ? -1 : 0
    }

    return a.trainees.length < b.trainees.length ? 1 : -1
  }

  const getTasks = () => {
    setTaskLoading(true)
    axios
      .get(`/get-task-overview/${user.currentFocusedTeam}`)
      .then((res) => {
        setTeamTasks(res.data.sort(compTasks))
        setTaskLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setTaskLoading(false)
        dispatch(
          setErrors(["An unexpected error has occured, please reload the page"])
        )
      })
  }

  if (loading) {
    return <LoadingDashboard />
  }

  return (
    <DefaultLayout currentPage="Team Overview">
      <div>
        {user.analytics_on ? (
          <>
            <div className="col-xl-12 col-lg-7">
              <div className="row">
                <DashboardCard color="var(--blue)" textColor="black">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                        Tasks Completed
                      </div>
                      <ProgressBar
                        percent={Math.round(tasksCompleted)}
                        color="var(--blue)"
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-uppercase mb-1 text-red">
                        Individuals with Overdue Tasks
                      </div>
                      <div
                        className="h5 mb-0 text-gray-800"
                        style={{ fontWeight: "bold" }}
                      >
                        {numOverdue}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-yellow text-uppercase mb-1">
                        Noncompliant Individuals
                      </div>
                      <div
                        className="h5 mb-0 text-gray-800"
                        style={{ fontWeight: "bold" }}
                      >
                        {numNoncompliant}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-green text-uppercase mb-1">
                        Compliant Individuals
                      </div>
                      <div
                        className="h5 mb-0 text-gray-800"
                        style={{ fontWeight: "bold" }}
                      >
                        {numCompliant}
                      </div>
                    </div>
                  </div>
                </DashboardCard>

                <DashboardCard
                  color="var(--red)"
                  textColor="var(--red)"
                  trainees={redList}
                >
                  TRAINEES MOST LIKELY TO FALL BEHIND
                </DashboardCard>

                <DashboardCard
                  color="var(--yellow)"
                  textColor="var(--yellow)"
                  trainees={yellowList}
                >
                  TRAINEES LEAST LIKELY TO COMPLETE TASKS
                </DashboardCard>

                <DashboardCard
                  color="var(--green)"
                  textColor="var(--green)"
                  trainees={greenList}
                >
                  TRAINEES MOST LIKELY TO COMPLETE TASKS
                </DashboardCard>
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className="d-flex justify-content-between"
              style={{
                marginTop: "5px",
                marginBottom: "20px",
              }}
            >
              <DashboardCardSlim color="var(--blue)" textColor="black">
                <div className="">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                      Tasks Completed
                    </div>
                    <div
                      className="h5 mb-0 text-gray-800"
                      style={{ fontWeight: "bold" }}
                    >
                      <ProgressBar
                        percent={Math.round(tasksCompleted)}
                        color="var(--blue)"
                      />
                    </div>
                  </div>
                </div>
              </DashboardCardSlim>
              <DashboardCardSlim color="var(--blue)" textColor="black">
                <div className="">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-danger text-uppercase">
                      Trainees with Overdue Tasks
                    </div>
                    <div
                      className="h5 mb-0 text-gray-800"
                      style={{ fontWeight: "bold" }}
                    >
                      {numOverdue}
                    </div>
                  </div>
                </div>
              </DashboardCardSlim>
              <DashboardCardSlim color="var(--blue)" textColor="black">
                <div className="">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                      Noncompliant Individuals
                    </div>
                    <div
                      className="h5 mb-0 text-gray-800"
                      style={{ fontWeight: "bold" }}
                    >
                      {numNoncompliant}
                    </div>
                  </div>
                </div>
              </DashboardCardSlim>
              <DashboardCardSlim color="var(--blue)" textColor="black">
                <div className="">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                      <div>Compliant Individuals</div>
                    </div>
                    <div
                      className="h5 mb-0 text-gray-800"
                      style={{ fontWeight: "bold" }}
                    >
                      {numCompliant}
                    </div>
                  </div>
                </div>
              </DashboardCardSlim>
            </div>
          </>
        )}
        <div className="col-xl-12 col-lg-7">
          <OverviewCard
            currentTeam={currentTeam}
            currentTeamGroups={currentTeamGroups}
            groupLoading={groupLoading}
            teamTasks={teamTasks}
            taskLoading={taskLoading}
            getGroups={getGroups}
            numOverdue={numOverdue}
            numNoncompliant={numNoncompliant}
            numCompliant={numCompliant}
            tasksCompleted={tasksCompleted}
            user={user}
          />
        </div>
      </div>
    </DefaultLayout>
  )
}

export default Dashboard
