import React from "react"
import ProgressBar from "./progressBar"

const SubGroupLabel = (props) => {
  return (
    <div
      className="card shadow h-200"
      style={{ margin: "1rem", height: "100%" }}
    >
      <div className="card-body">
        <p className="text font-weight-bold mb-1">
          {props.data.name.toUpperCase()}{" "}
        </p>
        <ProgressBar
          percent={props.data.percComplete}
          color={
            props.data.percComplete === 100
              ? "var(--green)"
              : props.data.percComplete > 87
                ? "var(--yellow)"
                : "var(--red)"
          }
        />
      </div>
    </div>
  )
}

export default SubGroupLabel
