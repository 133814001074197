import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import DefaultLayout from "../../layouts/defaultLayout"

import axios from "axios"
import { useDispatch } from "react-redux"
import { setTeam } from "../../redux/userSlice"

import { ReactMultiEmail } from "react-multi-email"
import "react-multi-email/dist/style.css"
import { modalStyles, modalStylesDanger } from "../../styles/constanStyles"
import Modal from "react-responsive-modal"
import StatusEnum from "../../util/StatusEnum"
import { ScaleLoader } from "react-spinners"
import Accordion from "../../components/accordion"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle, faTrash } from "@fortawesome/free-solid-svg-icons"
import ReactTooltip from "react-tooltip"
import UTAManager from "./tabs/utaManager"

const SupervisorListTab = () => {
  const [supervisorList, setSupervisorList] = useState([])
  const [adminList, setAdminList] = useState([])
  const [supervisorListLoading, setSupervisorListLoading] = useState(true)
  const [shopToRemove, setShopToRemove] = useState([])
  const [supervisorToRemove, setSupervisorToRemove] = useState({})
  const [removeShopModalOpen, setRemoveShopModalOpen] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    getSupervisorList()
  }, [])

  const getSupervisorList = () => {
    axios
      .get("/get-institution-supervisors")
      .then((res) => {
        if (res.data.admin) {
          setAdminList(res.data.admin)
        }
        if (res.data.nonadmin) {
          setSupervisorList(res.data.nonadmin)
        }
        setSupervisorListLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setSupervisorListLoading(false)
      })
  }

  const handleRemoveShopAccess = () => {
    setSupervisorListLoading(true)
    setRemoveShopModalOpen(false)

    const body = {
      shop_list: [shopToRemove[1]],
      supervisor_id: supervisorToRemove.id,
    }

    axios
      .delete("/remove-shop-access", { data: body })
      .then((res) => {
        getSupervisorList()
      })
      .catch((err) => {
        console.log(err.response)
        getSupervisorList()
      })
  }

  const AccordionTitle = ({ supervisor }) => {
    return (
      <h2 style={{ fontSize: "1.1rem", margin: 0 }}>
        <b>{supervisor.name}</b>
      </h2>
    )
  }

  return (
    <div className="col-xl-12 col-lg-7 mt-2">
      <div className="container-fluid fade-in" style={{ padding: "1rem" }}>
        {supervisorListLoading ? (
          <div style={{ textAlign: "center" }}>
            <ScaleLoader />
          </div>
        ) : (
          <div>
            <h3>List of Institution Admins</h3>
            {adminList.map((supervisor) => (
              <Accordion
                key={supervisor.id}
                title={<AccordionTitle supervisor={supervisor} />}
                containerStyles={{ maxWidth: "none" }}
                bodyStyles={{ maxWidth: "none" }}
              >
                <h6>
                  <b>{supervisor.name}</b> has access to all shops.
                </h6>
              </Accordion>
            ))}
            <h3>List of Supervisors</h3>
            {supervisorList.map((supervisor) => (
              <Accordion
                key={supervisor.id}
                title={<AccordionTitle supervisor={supervisor} />}
                containerStyles={{ maxWidth: "none" }}
                bodyStyles={{ maxWidth: "none" }}
              >
                <h6>
                  <b>{supervisor.name}</b> has access to{" "}
                  {supervisor.shops.length} shop(s):
                </h6>
                <ul style={{ marginLeft: "1rem" }}>
                  {(supervisor.shops && supervisor.shops.length) > 0 ? (
                    supervisor.shops.map((shop, si) => {
                      return (
                        <li key={si}>
                          <div style={{ display: "flex", gap: "0.5rem" }}>
                            <Link
                              onClick={() => {
                                localStorage.setItem("Team", shop[1])
                                dispatch(setTeam(shop[1]))
                              }}
                              style={{ textDecoration: "none" }}
                              to="/home"
                            >
                              <p style={{ marginBottom: "0" }}>{shop[0]}</p>
                            </Link>
                            <FontAwesomeIcon
                              icon={faTrash}
                              style={{
                                cursor: "pointer",
                                color: "var(--red)",
                              }}
                              onClick={() => {
                                setSupervisorToRemove(supervisor)
                                setShopToRemove(shop)
                                setRemoveShopModalOpen(true)
                              }}
                            />
                          </div>
                        </li>
                      )
                    })
                  ) : (
                    <p>None</p>
                  )}
                </ul>
              </Accordion>
            ))}
          </div>
        )}
      </div>
      <Modal
        styles={modalStylesDanger}
        open={removeShopModalOpen}
        onClose={() => {
          setRemoveShopModalOpen(false)
        }}
        center
        focusTrapped={false}
      >
        <p className="modal-title" style={{ color: "var(--red)" }}>
          Remove Shop Access
        </p>
        <hr />
        <p className="modal-subtitle">
          Are you sure you want to remove {supervisorToRemove.name}'s access to{" "}
          {shopToRemove[0]}?
        </p>
        <div className="modal-button-container">
          <button
            className="default-button default-button-gray"
            onClick={() => setRemoveShopModalOpen(false)}
          >
            No
          </button>
          <button
            className="default-button default-button-red"
            onClick={() => handleRemoveShopAccess()}
          >
            Yes
          </button>
        </div>
      </Modal>
    </div>
  )
}

const ShopAccessRequestTab = () => {
  const [shopRequsts, setShopRequests] = useState([])
  const [loadingRequests, setLoadingRequests] = useState(true)

  useEffect(() => {
    getShopRequests()
  }, [])

  const getShopRequests = () => {
    setLoadingRequests(true)
    setShopRequests([])

    axios
      .get("/get-institution-shop-access-requests")
      .then((res) => {
        setShopRequests(res.data)
        setLoadingRequests(false)
      })
      .catch((err) => {
        console.log(err.response)
      })
  }

  const handleReviewShopRequest = (isApproved, membershipId) => {
    setLoadingRequests(true)

    const body = {
      membership_id: membershipId,
      approve: isApproved,
    }

    axios
      .post("/review-shop-access", body)
      .then((res) => {
        getShopRequests()
      })
      .catch((err) => {
        console.log(err.response)
        getShopRequests()
      })
  }

  return (
    <div className="col-xl-12 col-lg-7 mt-2">
      <div className="container-fluid fade-in">
        {loadingRequests ? (
          <div style={{ textAlign: "center" }}>
            <ScaleLoader />
          </div>
        ) : (
          <table
            className="table table-hover table-responsive text-center"
            style={{ backgroundColor: "#eaecf4" }}
          >
            <thead className="thead-light">
              <tr>
                <th scope="col">Supervisor Name</th>
                <th scope="col">Email</th>
                <th scope="col">Shop</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {shopRequsts.map((req) => {
                if (req.status === "requested") {
                  return (
                    <tr>
                      <td>{req.profile}</td>
                      <td>{req.profile_email}</td>
                      <td>{req.title}</td>
                      <td>
                        <div>
                          <button
                            className="default-button"
                            onClick={() => {
                              handleReviewShopRequest("true", req.membership_id)
                            }}
                          >
                            Approve
                          </button>
                          <button
                            className="default-button default-button-red"
                            onClick={() => {
                              handleReviewShopRequest(
                                "false",
                                req.membership_id
                              )
                            }}
                          >
                            Deny
                          </button>
                        </div>
                      </td>
                    </tr>
                  )
                } else {
                  return <></>
                }
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  )
}

const InvitationTab = () => {
  const [emails, setEmails] = useState([])
  const [inviteModalOpen, setInviteModalOpen] = useState(false)
  const [inviteStatus, setInviteStatus] = useState(StatusEnum.error)
  const [invitationList, setInvitationList] = useState([])
  const [loadingInvitations, setLoadingInvitations] = useState(true)
  const [isAdmin, setIsAdmin] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    getAccountInvitations()
  }, [])

  const getAccountInvitations = () => {
    setInvitationList(true)

    axios
      .get("/get-account-invitations")
      .then((res) => {
        setInvitationList(res.data)
        setLoadingInvitations(false)
      })
      .catch((err) => {
        console.log(err)
        setLoadingInvitations(false)
      })
  }

  const handleInvitationSend = (e) => {
    e.preventDefault()
    setInviteStatus(StatusEnum.loading)
    setInviteModalOpen(true)

    const body = {
      to_email_list: emails,
      isAdmin: isAdmin ? "true" : "false",
    }

    axios
      .post("/send-profile-invitation", body)
      .then((res) => {
        setInviteStatus(StatusEnum.success)
        setEmails([])
        getAccountInvitations()
      })
      .catch((err) => {
        console.log(err.response)
        setInviteStatus(StatusEnum.error)
        if (err.response.data) {
          setErrorMessage(err.response.data)
        }
      })
  }
  return (
    <>
      <div
        className="col-xl-12 col-lg-7"
        style={{
          marginBottom: "1rem",
          marginTop: "1rem",
          marginLeft: "1rem",
          minHeight: "15rem",
        }}
      >
        <div className="container-fluid fade-in">
          <form onSubmit={(e) => handleInvitationSend(e)}>
            <h3>Invite People to EZTrain</h3>
            <ReactMultiEmail
              placeholder="Input email(s)"
              emails={emails}
              onChange={(_emails) => {
                setEmails(_emails)
              }}
              autoFocus={true}
              style={{ width: "50%" }}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={index}>
                    <div data-tag-item>{email}</div>
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                    </span>
                  </div>
                )
              }}
            />
            <label
              style={{
                cursor: "pointer",
                marginTop: "0.25rem",
                marginRight: "0.25rem",
              }}
            >
              <input
                type="checkbox"
                value={isAdmin}
                style={{ cursor: "pointer" }}
                onChange={(e) => setIsAdmin(e.target.checked)}
              />{" "}
              Invite with admin privileges
            </label>
            <FontAwesomeIcon
              icon={faInfoCircle}
              style={{ color: "gray", cursor: "pointer" }}
              data-tip="Admin users have access to all shops and can invite other supervisors to the platform."
            />
            <ReactTooltip place="top" type="dark" effect="float" />
            <br />
            <button
              type="submit"
              className="default-button"
              style={{ marginLeft: 0 }}
            >
              Send Invitations
            </button>
          </form>
          <div>
            <h3>Past Invitations: </h3>
            {loadingInvitations ? (
              <div style={{ textAlign: "center" }}>
                <ScaleLoader />
              </div>
            ) : (
              <table
                className="table table-hover table-responsive text-center"
                style={{ backgroundColor: "#eaecf4" }}
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Email</th>
                    <th scope="col">Date</th>
                    <th scope="col">Invitation Status</th>
                  </tr>
                </thead>
                <tbody>
                  {invitationList &&
                    invitationList.length > 0 &&
                    invitationList.map((invitation) => {
                      return (
                        <tr key={invitation.email}>
                          <td>{invitation.email}</td>
                          <td>{new Date(invitation.created).toDateString()}</td>
                          <td>{invitation.status}</td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      <Modal
        styles={modalStyles}
        open={inviteModalOpen}
        onClose={() => {
          if (inviteStatus !== StatusEnum.loading) {
            setInviteModalOpen(false)
          }
        }}
        showCloseIcon={!inviteStatus === StatusEnum.loading}
        center
        focusTrapped={false}
      >
        {inviteStatus === StatusEnum.loading && (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <ScaleLoader color="black" loading={true} size={75} />
          </div>
        )}

        {inviteStatus === StatusEnum.error && (
          <>
            {errorMessage && errorMessage.length > 0 ? (
              <p className="modal-title" style={{ color: "var(--red)" }}>
                {errorMessage}
              </p>
            ) : (
              <p className="modal-title" style={{ color: "var(--red)" }}>
                There was an error sending the invitation(s)
              </p>
            )}

            <div className="modal-button-container">
              <button
                className="default-button default-button-red"
                onClick={() => setInviteModalOpen(false)}
              >
                Ok
              </button>
            </div>
          </>
        )}
        {inviteStatus === StatusEnum.success && (
          <>
            <p className="modal-title">Invitation(s) successfully sent</p>
            <div className="modal-button-container">
              <button
                className="default-button"
                onClick={() => setInviteModalOpen(false)}
              >
                Ok
              </button>
            </div>
          </>
        )}
      </Modal>
    </>
  )
}

const AdminHome = () => {
  const [tabView, setTabView] = useState("list")

  return (
    <DefaultLayout currentPage="Admin Settings" allowNoTeamSelection>
      <div className="card shadow">
        <div
          className="card-header"
          style={{
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
            paddingBottom: 0,
          }}
        >
          <div className="nav">
            <h4
              className={
                tabView === "list"
                  ? "tab-link header-text tab-link-active"
                  : "tab-link header-text"
              }
              onClick={() => setTabView("list")}
            >
              Supervisor List
            </h4>
            <h4
              className={
                tabView === "access-requests"
                  ? "tab-link header-text tab-link-active"
                  : "tab-link header-text"
              }
              onClick={() => setTabView("access-requests")}
            >
              Shop Access Requests
            </h4>
            <h4
              className={
                tabView === "invite"
                  ? "tab-link header-text tab-link-active"
                  : "tab-link header-text"
              }
              onClick={() => setTabView("invite")}
            >
              Send Invitations
            </h4>
            <h4
              className={
                tabView === "uta-manager"
                  ? "tab-link header-text tab-link-active"
                  : "tab-link header-text"
              }
              onClick={() => setTabView("uta-manager")}
            >
              Manage UTA's
            </h4>
          </div>
        </div>
        {tabView === "list" && <SupervisorListTab />}
        {tabView === "access-requests" && <ShopAccessRequestTab />}
        {tabView === "invite" && <InvitationTab />}
        {tabView === "uta-manager" && <UTAManager />}
      </div>
    </DefaultLayout>
  )
}

export default AdminHome
