import React from "react"
import { createRoot } from "react-dom/client"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import "./styles/global.css"
import axios from "axios"

// Redux
import { store } from "./redux/store"
import { Provider } from "react-redux"

// Set base url for http requests
axios.defaults.baseURL = process.env.REACT_APP_API_URL

const container = document.getElementById("root")
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
)

reportWebVitals()
