import React, { useState, useEffect } from "react"

import Modal from "react-responsive-modal"
import { modalStyles } from "../styles/constanStyles"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBell } from "@fortawesome/free-solid-svg-icons"

import { useSelector } from "react-redux"
import axios from "axios"

const EventReminderModal = ({ events }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [modalError, setModalError] = useState("")

  const [message, setMessage] = useState("")

  const user = useSelector((state) => state.user)

  //   Create pre generated default reminder message on load
  useEffect(() => {
    if (user.currentFocusedTeam && user.currentFocusedTeam.length > 0) {
      setMessage("")
      user.teams.forEach((team) => {
        if (team.code === user.currentFocusedTeam) {
          setMessage(`UPCOMING EVENTS FOR "${team.title.toUpperCase()}"`)
        }
      })

      let eventCount = 0

      for (var i = 0; i < events.length; i++) {
        const event = events[i]

        // Check for upcoming event
        const eventDate = new Date(`${event.end_date}T${event.end_time}`)
        if (eventDate > Date.now()) {
          let newMessage = `${message}${event.title} (${event.trainees.length} / ${event.cap} spots filled)\n`
          if (event.cap === 0) {
            newMessage = `${message}${event.title} (${event.trainees.length} participants)\n`
          }
          eventCount += 1
          if (eventCount > 5) {
            break
          }
          // Conditionals for same day and multi day events

          if (!event.all_day) {
            if (event.start_date === event.end_date) {
              newMessage += `${event.start_time.substring(
                0,
                5
              )} -${" "}${event.end_time.substring(0, 5)} on ${
                event.start_date
              }`
            } else {
              newMessage += `${event.start_time.substring(0, 5)} on ${
                event.start_date
              } to${" "}${event.end_time.substring(0, 5)} on ${event.end_date} `
            }
          } else {
            if (event.start_date === event.end_date) {
              newMessage += `${event.start_date}, All Day`
            } else {
              newMessage += `${event.start_date} to${" "}${
                event.end_date
              }, All Day`
            }
          }
          setMessage((prev) => `${prev}\n\n${newMessage}`)
        }
      }
    }
  }, [])

  const handleSend = () => {
    setModalLoading(true)
    setModalError("")

    const body = {
      code: user.currentFocusedTeam,
      type: "custom",
      message: message,
    }

    axios
      .post("/notify-trainees", body)
      .then(() => {
        setModalLoading(false)
        setModalOpen(false)
        setMessage("")
      })
      .catch((err) => {
        console.log(err)
        setModalError("An unexpected error occured, please try again")
        setModalLoading(false)
      })
  }

  return (
    <div>
      <button
        className={
          user.uta && user.uta.active ? "default-button" : "disabled-button"
        }
        disabled={!user.uta || !user.uta.active}
        style={{ fontSize: "1rem" }}
        onClick={() => {
          setModalError("")
          setModalOpen(true)
        }}
      >
        <FontAwesomeIcon icon={faBell} /> Send Signup Reminder
      </button>
      <Modal
        styles={modalStyles}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        center
        focusTrapped={false}
      >
        <h5 className="modal-title">Event Signup Reminder</h5>

        <hr />

        <textarea
          rows={4}
          style={{ width: "100%", padding: "0.5rem" }}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          maxLength={450}
        />
        <p
          style={{
            float: "right",
            marginTop: "-5px",
            fontSize: "0.70rem",
            color: "gray",
            marginBottom: 0,
          }}
        >
          {message.length}/450
        </p>
        <p className="modal-subtitle">
          WARNING: This will notify all trainees in the current shop with your
          custom message.
        </p>
        {modalError.length > 0 && (
          <p className="h6 text-center text-danger">{modalError}</p>
        )}
        <div className="modal-button-container">
          <button
            className="default-button default-button-gray"
            onClick={() => setModalOpen(false)}
          >
            Cancel
          </button>
          <button
            className="default-button"
            onClick={() => handleSend()}
            disabled={modalLoading}
          >
            Send
          </button>
        </div>
      </Modal>
    </div>
  )
}

export default EventReminderModal
