import React from "react"
import "../styles/mylearning.css"

import { Pie } from "react-chartjs-2"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"

ChartJS.register(ArcElement, Tooltip, Legend)

const MyLearningChart = (props) => {
  const colors = ["#ff0000", "#ffde2e", "#016969"]

  let chartData = {
    labels: props.data.map((v) => v["name"]),
    datasets: [
      {
        data: props.data.map((v) => v["value"]),
        backgroundColor: props.data.map((v, i) => colors[i]),
        borderWidth: 1,
      },
    ],
  }

  return (
    <div
      style={{
        userSelect: "none",
        width: "100%",
        height: "100%",
      }}
    >
      <div className="header-text mt-1" style={{ textAlign: "center" }}>
        My Learning Completion
      </div>
      <br />

      <Pie data={chartData} />
    </div>
  )
}

export default MyLearningChart
