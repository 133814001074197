import React from "react"
import { useState, useEffect } from "react"
import StatusEnum from "../util/StatusEnum"
import axios from "axios"
import { ScaleLoader } from "react-spinners"
import { Link, useSearchParams } from "react-router-dom"

import AuthLayout from "../layouts/authLayout"

const ResetPassword = () => {
  const [searchParams] = useSearchParams()

  const [password, setPassword] = useState("")
  const [repeatPassword, setRepeatPassword] = useState("")

  const [status, setStatus] = useState(StatusEnum.idle)
  const [error, setError] = useState("")

  const [email, setEmail] = useState("")
  const [token, setToken] = useState("")
  const [uidb, setUidb] = useState("")

  useEffect(() => {
    setEmail(searchParams.get("email"))
    setToken(searchParams.get("token"))
    setUidb(searchParams.get("uidb64"))
  }, [searchParams])

  const handleNewPassowrd = (e) => {
    e.preventDefault()
    setError("")
    setStatus(StatusEnum.loading)

    if (password !== repeatPassword) {
      setStatus(StatusEnum.error)
      setError("The passwords do not match")
      return
    }

    const body = {
      email: email,
      token: token,
      uidb64: uidb,
      password: password,
    }

    axios
      .patch("/reset-password", body)
      .then(() => {
        setStatus(StatusEnum.success)
      })
      .catch(() => {
        setStatus(StatusEnum.error)
        setError(
          "An error has occurred, please try again or contact the EzTrain team directly"
        )
      })
  }

  return (
    <AuthLayout>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-8 col-lg-12 col-md-9">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">
                <div style={{ minHeight: "250px", padding: "2rem" }}>
                  {status === StatusEnum.success ? (
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <h5 className="text-gray-900">
                        You have successfully reset your password. Please click{" "}
                        <Link to="/login">here</Link> to login
                      </h5>
                    </div>
                  ) : (
                    <div>
                      <div className="text-center">
                        <h5 className="text-gray-900 mb-4">
                          Please enter your new password
                        </h5>
                      </div>
                      <hr />
                      {status === StatusEnum.loading ? (
                        <div className="text-center">
                          <ScaleLoader color="black" loading={true} size={75} />
                        </div>
                      ) : (
                        <form onSubmit={handleNewPassowrd}>
                          <input
                            type="password"
                            className="textinput textInput form-control"
                            required
                            placeholder="Password"
                            autoComplete="new-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <br />
                          <input
                            type="password"
                            className="textinput textInput form-control"
                            required
                            placeholder="Repeat Password"
                            autoComplete="new-password"
                            value={repeatPassword}
                            onChange={(e) => setRepeatPassword(e.target.value)}
                          />
                          <hr />
                          {status === StatusEnum.error && error.length > 0 && (
                            <>
                              <div className="h6 text-center text-danger">
                                {error}
                              </div>
                            </>
                          )}
                          <button
                            className="default-button"
                            type="submit"
                            disabled={status === StatusEnum.loading}
                          >
                            Submit
                          </button>
                        </form>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  )
}

export default ResetPassword
