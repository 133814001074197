import React from "react"
import PropTypes from "prop-types"
import "../styles/layout.css"

// Components
import TraineeHeader from "../components/traineeHeader"

import ScaleLoader from "react-spinners/ScaleLoader"

// Redux
import { useSelector } from "react-redux"

const TraineeLayout = ({ children }) => {
  const user = useSelector((state) => state.user)

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <TraineeHeader />

      {/* Loading spinner if status is loading*/}

      {user.status === "loading" && (
        <main>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              marginTop: "2rem",
            }}
          >
            <ScaleLoader color="black" loading={true} size={75} />
          </div>
        </main>
      )}

      <main>
        {/* Main Content */}

        {(user.status === "idle" || user.status === "success") &&
          user.currentFocusedTeam &&
          user.currentFocusedTeam.length > 0 && <>{children}</>}

        {/* Error message */}
        {user.status === "failed" && (
          <h4 style={{ textAlign: "center" }}>
            An unexpected error has occured, please reload the page
          </h4>
        )}
      </main>
    </div>
  )
}

TraineeLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TraineeLayout
