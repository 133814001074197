import React, { useState } from "react"
import { useNavigate } from "react-router"

import DefaultLayout from "../../layouts/defaultLayout"
import LoadingDashboard from "../../components/loadingDashboard"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons"

import { useSelector } from "react-redux"

import axios from "axios"

const SupervisorChangePassword = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")

  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [newPassword2, setNewPassword2] = useState("")

  const navigate = useNavigate()
  const user = useSelector((state) => state.user)

  const clearInput = () => {
    setOldPassword("")
    setNewPassword("")
    setNewPassword2("")
  }

  const handlePasswordSubmission = (e) => {
    e.preventDefault()
    if (newPassword !== newPassword2) {
      setError("New passwords must match")
      clearInput()
      return
    }

    setLoading(true)

    const body = {
      email: user.email,
      oldPassword: oldPassword,
      newPassword: newPassword,
    }
    axios
      .patch(`/change-password`, body)
      .then(() => {
        setError("")
        navigate("/profile")
      })
      .catch((err) => {
        setError(err.response.data)
        setLoading(false)
      })

    clearInput()
  }

  if (loading) {
    return <LoadingDashboard />
  }

  return (
    <DefaultLayout>
      <div className="container-fluid">
        <h3>Change Password</h3>

        <form onSubmit={(e) => handlePasswordSubmission(e)}>
          <br />
          <div>Password Criteria:</div>
          <ul>
            <li>
              Your password can’t be too similar to your other personal
              information.
            </li>
            <li>Your password must contain at least 8 characters.</li>
            <li>Your password can’t be a commonly used password.</li>
            <li>Your password can’t be entirely numeric.</li>
          </ul>
          <table className="table">
            <tbody>
              <tr>
                <td>Old password:</td>
                <td>
                  <input
                    className="textInput form-control"
                    type="password"
                    value={oldPassword}
                    onChange={(v) => setOldPassword(v.target.value)}
                    required
                  />
                </td>
              </tr>
              <tr>
                <td>New password:</td>
                <td>
                  <input
                    className="textInput form-control"
                    type="password"
                    value={newPassword}
                    onChange={(v) => setNewPassword(v.target.value)}
                    required
                  />
                </td>
              </tr>
              <tr>
                <td>Confirm new password:</td>
                <td>
                  <input
                    className="textInput form-control"
                    type="password"
                    value={newPassword2}
                    onChange={(v) => setNewPassword2(v.target.value)}
                    required
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          {error && error.length > 0 && (
            <div className="h6 text-center text-danger">
              <b>{error}</b>
            </div>
          )}
          <button
            type="reset"
            className="default-button default-button-red"
            onClick={() => {
              navigate(-1)
            }}
            style={{ marginRight: 20 }}
          >
            <FontAwesomeIcon icon={faTimes} className="fas fa-times" /> Cancel
          </button>
          <button type="submit" className="default-button" disabled={loading}>
            <FontAwesomeIcon icon={faCheck} className="fas fa-check" /> Save
            changes
          </button>
        </form>
      </div>
    </DefaultLayout>
  )
}

export default SupervisorChangePassword
