import React, { useEffect, useState } from "react"
import axios from "axios"
import { Modal } from "react-responsive-modal"
import { modalStyles } from "../../../styles/constanStyles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { useSelector } from "react-redux"

function formatDate(date) {
  if (date) {
    const newDate = new Date(date)
    return newDate.toDateString()
  }
  return "TBD"
}
const UTAButton = (props) => {
  const classes = `btn ${props.children === "End UTA" && "btn-danger"} ${
    props.children === "Start UTA" && "btn-primary"
  }`

  const handleButtonClick = (e) => {
    e.preventDefault()
    axios
      .get(`/tasks/utas/${props.uta.id}/${props.uta.active ? "end" : "start"}/`)
      .then((res) => {
        window.location.reload()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <button
      disabled={!props.user.is_uploader}
      onClick={handleButtonClick}
      className={classes}
    >
      {props.children}
    </button>
  )
}
const UTAActions = ({ uta, user }) => {
  console.log(user)
  return (
    <>
      {!uta.started && user.is_uploader && (
        <UTAButton uta={uta} user={user}>
          Start UTA
        </UTAButton>
      )}
      {uta.started && user.is_uploader && uta.active && (
        <UTAButton uta={uta} user={user}>
          End UTA
        </UTAButton>
      )}
    </>
  )
}

const UTAStatus = ({ uta }) => {
  return (
    <>
      {uta.started && !uta.active && (
        <>
          Complete{" "}
          <FontAwesomeIcon
            icon={faCheckCircle}
            style={{ color: "var(--green)" }}
          />
        </>
      )}
      {uta.started && uta.active && "In Progress"}
      {!uta.started && "Scheduled"}
    </>
  )
}

const UTARow = ({ uta, user }) => {
  return (
    <tr key={`uta_${uta.id}`}>
      <td>{uta.title}</td>
      <td>
        <UTAStatus uta={uta} />
      </td>
      <td>
        <UTAActions uta={uta} user={user} />
      </td>
      <td>{formatDate(uta.start_date)}</td>
      <td>{formatDate(uta.end_date)}</td>
    </tr>
  )
}
const UTAManager = (props) => {
  const user = useSelector((state) => state.user)
  // Input states
  const [title, setTitle] = useState("")
  const [userEmail, setUserEmail] = useState("")
  const [active, setActive] = useState("")
  const [started, setStarted] = useState("")
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [utas, setUtas] = useState([])

  // State for modal prompting user to add new UTA
  const [UTAModalOpen, setUTAModalOpen] = useState(false)
  const [modalError, setModalError] = useState("")

  const handleUTAModalOpen = () => {
    // setModalError("")
    // setModalLoading(false)
    setUTAModalOpen(true)
  }

  const addUTA = () => {
    setUserEmail(user.email)
    const body = {
      title: title,
      // active: active,
      // started: started,
      // start_date: startDate,
      // end_date: endDate,
    }

    axios
      .post("/add-uta", body)
      .then((res) => {
        setUTAModalOpen(false)
        window.location.reload()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  // Fetch UTA options
  useEffect(() => {
    axios
      .get("/tasks/utas/")
      .then((res) => {
        setUtas(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  return (
    <div className="col-xl-12 col-lg-7 mt-2">
      <div className="container-fluid fade-in">
        <button onClick={handleUTAModalOpen} className={"btn btn-secondary"}>
          + UTA
        </button>
        <table
          className="table mt-3"
          style={{
            backgroundColor: "#eaecf4",
          }}
        >
          <thead className="thead-light">
            <tr>
              <th>Title</th>
              <th>Status</th>
              <th>Actions</th>
              <th>Start Date</th>
              <th>End Date</th>
            </tr>
          </thead>
          <tbody>
            {utas.map((uta) => (
              <UTARow uta={uta} key={uta.id} user={user} />
            ))}
          </tbody>
        </table>

        {/* Add UTA Modal */}
        <Modal
          styles={modalStyles}
          center
          open={UTAModalOpen}
          onClose={() => {
            setUTAModalOpen(false)
          }}
        >
          <h5 className="modal-title">Create a New UTA</h5>
          <hr />
          <p className="modal-subtitle"></p>
          {modalError && (
            <div className="h6 text-center text-danger">{modalError}</div>
          )}
          <form className="search-form" onSubmit={addUTA}>
            <p>Title*</p>
            <div className="input-group mb-3">
              <input
                type="text"
                className="textinput textInput form-control"
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </div>
          </form>
          <div className="modal-button-container">
            <button
              className="default-button default-button-red"
              onClick={() => setUTAModalOpen(false)}
            >
              Cancel
            </button>
            <button className="default-button" onClick={addUTA}>
              Create UTA
            </button>
          </div>
        </Modal>
      </div>
    </div>
  )
}

export default UTAManager
