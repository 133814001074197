import React, { useState, useEffect } from "react"

import Modal from "react-responsive-modal"
import { modalStyles, modalStylesDanger } from "../styles/constanStyles"

import EventReminderModal from "./eventReminderModal"

// Calendar
import CalendarComponent from "./calendar"

import { Link } from "react-router-dom"

import axios from "axios"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faList,
  faEdit,
  faInfoCircle,
  faTrash,
  faBell,
} from "@fortawesome/free-solid-svg-icons"

const SupervisorCalendar = ({
  setListView,
  events,
  getEvents,
  unformattedEvents,
}) => {
  const [event, setEvent] = useState(null)
  const [futureEvent, setFutureEvent] = useState(false)

  // Delete modal
  const [modalOpen, setModalOpen] = useState(false)
  const [modalError, setModalError] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (event && event.end_date) {
      const eventDate = new Date(`${event.end_date}T${event.end_time}`)
      if (eventDate > Date.now()) {
        setFutureEvent(true)
      } else {
        setFutureEvent(false)
      }
    }
  }, [event])

  const handleDelete = () => {
    setLoading(true)
    setModalError(false)
    axios
      .delete("/delete-event", { data: { id: event.id } })
      .then((res) => {
        getEvents()
        setModalOpen(false)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err.response)
        setModalError("An error has occured, please try again")
        setLoading(false)
      })
  }

  const handleModalOpen = () => {
    setModalError("")
    setModalOpen(true)
    setLoading(false)
  }

  const handleEventSelect = (e) => {
    setEvent(e)
    setModalOpen(false)
  }

  return (
    <div className="container-fluid fade-in">
      <div style={{ margin: "1.5rem 0", display: "flex" }}>
        <button
          className="default-button"
          onClick={() => setListView(true)}
          style={{ fontSize: "1rem" }}
        >
          <FontAwesomeIcon icon={faList} /> List View
        </button>
        <EventReminderModal events={unformattedEvents} />
      </div>
      <div className="row">
        <div className="col-sm-8">
          <CalendarComponent
            events={events}
            handleEventSelect={handleEventSelect}
          />
        </div>
        <div className="col-sm-4">
          {event ? (
            <div style={{ marginTop: "2rem" }}>
              <h3>
                <b>{event.title}</b>
              </h3>
              <b>Date/Time: </b>
              {event.all_day ? (
                event.start_date === event.end_date ? (
                  <span>{event.start_date}, All Day</span>
                ) : (
                  <span>
                    {event.start_date} to {event.end_date}, All Day
                  </span>
                )
              ) : event.start_date === event.end_date ? (
                <span>
                  {event.start_date}, {event.start_time.substring(0, 5)} to{" "}
                  {event.end_time.substring(0, 5)}
                </span>
              ) : (
                <span>
                  {event.start_date} @ {event.start_time.substring(0, 5)} to{" "}
                  {event.end_date} @ {event.end_time.substring(0, 5)}
                </span>
              )}

              <br />
              <b>Description: </b>
              {event.description}
              <br />
              {!event.cap || event.cap === 0 ? (
                <p style={{ color: "var(--blue)" }}>
                  {event.trainees.length} participant(s)
                </p>
              ) : (
                <p
                  style={{
                    color:
                      event.trainees.length === event.cap
                        ? "var(--red)"
                        : "green",
                  }}
                >
                  {event.trainees.length} / {event.cap} capacity
                </p>
              )}
              <div>
                {futureEvent && (
                  <>
                    <Link to={`/event/${event.id}/edit`}>
                      <span
                        style={{
                          color: "var(--gray-300)",
                          margin: "0 0.2rem",
                        }}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </span>
                    </Link>
                    <Link to={`/event/${event.id}/notify`}>
                      <span
                        style={{
                          color: "var(--gray-300)",
                          margin: "0 0.4rem",
                        }}
                      >
                        <FontAwesomeIcon icon={faBell} />
                      </span>
                    </Link>
                  </>
                )}

                <span
                  onClick={handleModalOpen}
                  style={{
                    color: "var(--gray-300)",
                    margin: "0 0.4rem",
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </span>
                <Link to={`/event/${event.id}`}>
                  <span
                    style={{ color: "var(--gray-300)", margin: "0 0.4rem" }}
                  >
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </span>
                </Link>
              </div>
            </div>
          ) : (
            <div style={{ marginTop: "2rem" }}>
              <h4>No event currently selected</h4>
            </div>
          )}
        </div>
      </div>

      {/* Delete Modal */}
      {event && event.title && (
        <Modal
          styles={modalStylesDanger}
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          center
          focusTrapped={false}
        >
          <h5 className="modal-title">Delete event</h5>
          {modalError.length > 0 && (
            <p className="h6 text-center text-danger">{modalError}</p>
          )}
          <hr />
          <p className="modal-subtitle">
            Are you sure you want to delete this event: <b>{event.title}</b>?
          </p>
          <div className="modal-button-container">
            <button
              className="default-button"
              onClick={() => setModalOpen(false)}
            >
              Cancel
            </button>
            <button
              className="default-button default-button-red"
              onClick={() => handleDelete()}
              disabled={loading}
            >
              Delete
            </button>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default SupervisorCalendar
