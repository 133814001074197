import React, { useState, useEffect } from "react"

import DefaultLayout from "../../layouts/defaultLayout"
import LoadingDashboard from "../../components/loadingDashboard"

import { useNavigate } from "react-router"

import { useSelector, useDispatch } from "react-redux"
import { setErrors } from "../../redux/userSlice"

import axios from "axios"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimesCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons"

import Modal from "react-responsive-modal"
import { modalStylesDanger } from "../../styles/constanStyles"

const AddCompliance = () => {
  const [trainees, setTrainees] = useState([])
  const [traineeIds, setTraineeIds] = useState([])

  const [loadingData, setLoadingData] = useState(false)
  const [loadingForm, setLoadingForm] = useState(false)
  const [loadingOverwrite, setLoadingOverwrite] = useState(false)
  const [formError, setFormError] = useState("")

  const [date, setDate] = useState("")
  // List of trainee ids selected for compliant and noncompliant
  const [compTrainees, setCompTrainees] = useState([])
  const [noncompTrainees, setNoncompTrainees] = useState([])

  // Modal States
  const [overwriteModalOpen, setOverwriteModalOpen] = useState(false)

  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()

  const navigate = useNavigate()

  useEffect(() => {
    if (user.status === "idle" || user.status === "success") {
      if (user.currentFocusedTeam && user.currentFocusedTeam.length > 0) {
        setLoadingData(true)
        setTrainees([])

        axios
          .get(`/get-shop/${user.currentFocusedTeam}`)
          .then((res) => {
            setLoadingData(false)
            setTrainees(res.data.trainees)

            // set list of trainee ids
            res.data.trainees.map((t) => {
              setTraineeIds((prev) => [...prev, t.id])
            })
          })
          .catch((err) => {
            console.log(err)
            dispatch(
              setErrors([
                "An unexpected error has occured please reload and try again",
              ])
            )
          })
      }
    }
  }, [user.currentFocusedTeam, user.status])

  const handleNoncompClick = (id) => {
    // Currently clicked as noncompliant trainees
    if (noncompTrainees.includes(id)) {
      setNoncompTrainees((prev) => prev.filter((t) => t !== id))
    } else {
      setNoncompTrainees((prev) => [...prev, id])
      setCompTrainees((prev) => prev.filter((t) => t !== id))
    }
  }

  const handleCompClick = (id) => {
    // Currently clicked as compliant
    if (compTrainees.includes(id)) {
      setCompTrainees((prev) => prev.filter((t) => t !== id))
    } else {
      setCompTrainees((prev) => [...prev, id])
      setNoncompTrainees((prev) => prev.filter((t) => t !== id))
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (!date || date === "") {
      setFormError("Please select a date")
      return
    }

    setLoadingForm(true)
    setFormError("")

    const body = {
      date: date,
      comp_trainees: compTrainees,
      non_comp_trainees: noncompTrainees,
    }

    axios
      .post(`/add-compliance/${user.currentFocusedTeam}`, body)
      .then((res) => {
        navigate("/compliance")
      })
      .catch((err) => {
        if (err.response.data) {
          // If shop compliance already exists, prompt user to overwrite
          if (
            err.response.data ===
            `Shop attendance already exists for date ${date}`
          ) {
            setOverwriteModalOpen(true)
          } else {
            setFormError(err.response.data)
          }
        } else {
          setFormError("An unexpected error has happened, please try again")
        }

        setLoadingForm(false)
      })
  }

  const handleOverwrite = () => {
    setLoadingOverwrite(true)
    setFormError("")

    const body = {
      date: date,
      comp_trainees: compTrainees,
      non_comp_trainees: noncompTrainees,
      na_trainees: traineeIds.filter(
        (t) => !compTrainees.includes(t.id) && !noncompTrainees.includes(t.id)
      ),
    }

    axios
      .patch(`/edit-compliance/${user.currentFocusedTeam}`, body)
      .then((res) => {
        navigate("/compliance")
      })
      .catch((err) => {
        if (err.response.data && !err.response.data.includes("html")) {
          setFormError(err.response.data)
        } else {
          setFormError("An unexpected error has happened, please try again")
        }

        setLoadingOverwrite(false)
      })
  }

  if (loadingData) {
    return <LoadingDashboard />
  }

  return (
    <DefaultLayout disableTeams>
      <div className="container-fluid">
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="table-responsive">
            <div className="table-wrapper">
              <div className="table-title">
                <div className="row">
                  <div className="col-sm-12">
                    <h2>Add New Compliance Record</h2>
                    <br />
                    <br />
                    <strong>Date:</strong>
                    <input
                      type="date"
                      className="form-control"
                      style={{ width: "200px" }}
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                      required
                    />
                    <div style={{ textAlign: "right" }}>
                      <button
                        disabled={loadingForm}
                        className="default-button default-button-red"
                        onClick={() => navigate("/compliance")}
                        type="reset"
                      >
                        Cancel
                      </button>
                      <button
                        disabled={loadingForm}
                        className="default-button"
                        type="submit"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {trainees.map((trainee) => {
                    return (
                      <tr>
                        <td className="col-sm-8">
                          <h5>
                            <div key={trainee.id}>{trainee.name}</div>
                          </h5>
                        </td>
                        <td>
                          <FontAwesomeIcon
                            icon={faTimesCircle}
                            style={
                              noncompTrainees.includes(trainee.id)
                                ? {
                                    color: "var(--red)",
                                    fontSize: "2rem",
                                    cursor: "pointer",
                                  }
                                : {
                                    color: "gray",
                                    fontSize: "2rem",
                                    cursor: "pointer",
                                  }
                            }
                            onClick={() => handleNoncompClick(trainee.id)}
                          />
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            style={
                              compTrainees.includes(trainee.id)
                                ? {
                                    color: "var(--green)",
                                    fontSize: "2rem",
                                    cursor: "pointer",
                                  }
                                : {
                                    color: "gray",
                                    fontSize: "2rem",
                                    cursor: "pointer",
                                  }
                            }
                            onClick={() => handleCompClick(trainee.id)}
                          />
                        </td>
                      </tr>
                    )
                  })}
                  {formError && (
                    <div
                      className="h6 text-center text-danger"
                      style={{ flexBasis: "100%" }}
                    >
                      {formError}
                    </div>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </form>
      </div>
      {/* Overwrite modal */}
      <Modal
        styles={modalStylesDanger}
        open={overwriteModalOpen}
        center
        focusTrapped={false}
        closeOnOverlayClick={!loadingOverwrite}
        closeIcon={!loadingOverwrite}
        onClose={() => setOverwriteModalOpen(false)}
      >
        <h5 className="modal-title">Overwrite Compliance Record?</h5>
        <hr />
        <p className="modal-subtitle">
          A compliance record already exists for date <b>{date}</b>. This action
          will overwrite the existing compliance record and create a new one.
          This cannot be undone. Do you still wish to proceed?
        </p>
        <br />
        {formError && (
          <div
            className="h6 text-center text-danger"
            style={{ flexBasis: "100%" }}
          >
            {formError}
          </div>
        )}
        <button
          className="default-button float-right"
          style={{ marginLeft: "0.5rem" }}
          onClick={handleOverwrite}
          disabled={loadingOverwrite}
        >
          Proceed
        </button>
        <button
          className="default-button default-button-gray float-right"
          type="reset"
          onClick={(e) => setOverwriteModalOpen(false)}
          disabled={loadingOverwrite}
        >
          Cancel
        </button>
      </Modal>
    </DefaultLayout>
  )
}

export default AddCompliance
