import React, { useEffect, useState } from "react"
import "../styles/layout.css"

import { Link } from "react-router-dom"

import axios from "axios"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { getProfile } from "../redux/userSlice"

const Header = (props) => {
  const dispatch = useDispatch()

  const user = useSelector((state) => state.user)

  const [utaOptions, setUtaOptions] = useState([])

  const handleUtaChange = (uta_id) => {
    const body = {
      uta: uta_id,
    }
    axios
      .patch("/update-supervisor-preferences", body)
      .then(() => {
        dispatch(getProfile())
      })
      .catch((err) => {
        console.log(err.response)
      })
  }

  // Fetch UTA options
  useEffect(() => {
    if (Array.isArray(user.utas)) {
      setUtaOptions(user.utas)
    }
  }, [user])

  return (
    <header
      className="header shadow d-flex"
      style={{
        marginLeft: "200px",
        paddingTop: "1rem",
        paddingBottom: "1rem",
      }}
    >
      <h1
        className="me-auto align-self-center title"
        style={{ marginLeft: "16px", fontSize: "35px" }}
      >
        {props.currentPage}
        <div>{props.progressBar}</div>
      </h1>
      {!(user.uta && user.uta.title) && (
        <div style={{ height: "100%" }} className="d-flex">
          <span style={{ marginTop: "0.5rem" }}>
            <strong> Current </strong>
          </span>

          <span style={{ marginLeft: "0.5rem" }}>
            <select
              value={"No UTA Selected"}
              onChange={(e) => handleUtaChange(e.target.value)}
              className="custom-select"
              style={{
                background: "var(--yellow)",
              }}
            >
              <option value="">{"No UTA Selected"}</option>
              {utaOptions.map((uta) => {
                return (
                  <option value={uta.id} key={uta.id}>
                    {uta.title}
                  </option>
                )
              })}
            </select>
          </span>
        </div>
      )}
      {user.uta && user.uta.title && (
        <div style={{ height: "100%" }} className="d-flex">
          <span style={{ marginTop: "0.5rem" }}>
            <strong> Current </strong>
          </span>

          <span style={{ marginLeft: "0.5rem" }}>
            <select
              value={user.uta.title}
              onChange={(e) => handleUtaChange(e.target.value)}
              className="custom-select"
              style={{
                background: user.uta.active
                  ? "var(--green)"
                  : user.uta.started
                    ? "var(--red)"
                    : "var(--yellow)",
              }}
            >
              <option value="">{user.uta.title}</option>
              {utaOptions
                .filter((uta) => uta.title !== user.uta.title)
                .map((uta) => {
                  return (
                    <option value={uta.id} key={uta.id}>
                      {uta.title}
                    </option>
                  )
                })}
            </select>
          </span>
          <span style={{ marginTop: "0.5rem" }}>
            <strong style={{ marginLeft: "0.5rem" }}>
              {user.uta.active
                ? " is active"
                : user.uta.started
                  ? "has ended"
                  : "not started"}
            </strong>
          </span>
        </div>
      )}

      {/* <button
        onClick={() => {
          dispatch(logout())
        }}
        className="default-button default-button-gray"
      >
        Logout
      </button> */}
      <div className="vertical-divider" />
      <Link
        to="/profile"
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          textDecoration: "none",
        }}
      >
        <div>
          <p style={{ margin: 0 }}>
            {user.first_name} {user.last_name}
          </p>
        </div>
      </Link>
    </header>
  )
}

export default Header
