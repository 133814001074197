import React, { useState, useEffect } from "react"

const SearchTraineeListItem = (props) => {
  const { trainee, setTrainees, addedTrainees } = props

  const [added, setAdded] = useState(false)

  useEffect(() => {
    if (addedTrainees.includes(trainee)) {
      setAdded(true)
    } else {
      setAdded(false)
    }
  }, [addedTrainees])

  const handleAdd = () => {
    // setAdded(true)
    setTrainees((trainees) => [...trainees, trainee])
  }

  const handleRemove = () => {
    // setAdded(false)
    setTrainees((trainees) => trainees.filter((item) => item.id !== trainee.id))
  }

  return (
    <li className="list-group-item">
      {trainee.name}
      {!added ? (
        <button
          className="add-btn btn btn-outline-primary btn-sm float-right"
          onClick={handleAdd}
        >
          Add
        </button>
      ) : (
        <button
          className="add-btn btn btn-outline-danger btn-sm float-right"
          onClick={handleRemove}
          style={{ outline: 0 }}
        >
          Remove
        </button>
      )}
    </li>
  )
}

export default SearchTraineeListItem
