import React from "react"

import Logo from "../images/logo.png"
import { ScaleLoader } from "react-spinners"

const LoadingSplashScreen = () => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexFlow: "row wrap",
      }}
    >
      <div style={{ textAlign: "center" }} className="fade-in-long">
        <img
          src={Logo}
          alt="EzTrain Logo"
          style={{ width: "50%", flexBasis: "100%", marginBottom: "25px" }}
        />
        <br />
        <ScaleLoader />
      </div>
    </div>
  )
}

export default LoadingSplashScreen
