import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import DefaultLayout from "../../layouts/defaultLayout"
import LoadingDashboard from "../../components/loadingDashboard"

import { useSelector, useDispatch } from "react-redux"
import { getProfile, logout } from "../../redux/userSlice"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser, faInfoCircle } from "@fortawesome/free-solid-svg-icons"

import PhoneInput from "react-phone-number-input"

import axios from "axios"

import ReactTooltip from "react-tooltip"

const SupervisorProfile = () => {
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()

  const [editMode, setEditMode] = useState(false)

  // States for view mode
  const [docDuration, setDocDuration] = useState(0)
  const [docDurationUnits, setDocDurationUnits] = useState("Day(s)")
  const [eventDrop, setEventDrop] = useState(0)
  const [eventDropUnits, setEventDropUnits] = useState("Day(s)")

  // State for edit mode
  const [editSmsOpt, setEditSmsOpt] = useState(false)
  const [editEmailOpt, setEditEmailOpt] = useState(false)
  const [editPhoneNumber, setEditPhoneNumber] = useState("")
  const [editDocDuration, setEditDocDuration] = useState(0)
  const [editDocDurationUnits, setEditDocDurationUnits] = useState("Days")
  const [editEventDrop, setEditEventDrop] = useState(0)
  const [editEventDropUnits, setEditEventDropUnits] = useState("Days")
  const [editAnalytics, setEditAnalytics] = useState()
  const [editUta, setEditUta] = useState()

  const [pageLoading, setPageLoading] = useState(false)
  const [utaOptions, setUtaOptions] = useState([])

  // Fetch UTA options
  useEffect(() => {
    setPageLoading(true)

    axios
      .get("/tasks/utas/")
      .then((res) => {
        setUtaOptions(res.data)
        setPageLoading(false)
      })
      .catch((err) => {
        setPageLoading(false)
        console.log(err)
      })
  }, [])

  useEffect(() => {
    setEditPhoneNumber(user.phoneNumber)
    setEditEmailOpt(user.email_opt)
    setEditSmsOpt(user.sms_opt)
    setEditAnalytics(user.analytics_on)
    if (user.uta) {
      setEditUta(user.uta.id)
    }

    let docDurationFloat = parseFloat(user.doc_duration.split(" ")[0])
    if (docDurationFloat % 7 === 0) {
      setDocDuration(docDurationFloat / 7)
      setDocDurationUnits("Week(s)")
      setEditDocDuration(docDurationFloat / 7)
      setEditDocDurationUnits("Weeks")
    } else {
      setDocDuration(docDurationFloat)
      setDocDurationUnits("Day(s)")
      setEditDocDuration(docDurationFloat)
      setEditDocDurationUnits("Days")
    }

    if (!(!user.event_drop_limit || user.event_drop_limit === "00:00:00")) {
      let eventDropFloat = parseFloat(user.event_drop_limit.split(" ")[0])
      if (eventDropFloat % 7 === 0) {
        setEventDrop(eventDropFloat / 7)
        setEventDropUnits("Week(s)")
        setEditEventDrop(eventDropFloat / 7)
        setEditEventDropUnits("Weeks")
      } else {
        setEventDrop(eventDropFloat)
        setEventDropUnits("Day(s)")
        setEditEventDrop(eventDropFloat)
        setEditEventDropUnits("Days")
      }
    }
  }, [user])

  const handleSave = () => {
    const body = {
      phoneNumber: editPhoneNumber,
      sms_opt: editSmsOpt,
      email_opt: editEmailOpt,
      doc_duration: editDocDuration + " " + editDocDurationUnits.toLowerCase(),
      event_drop_limit:
        !editEventDrop || editEventDrop === 0
          ? "0 days"
          : editEventDrop + " " + editEventDropUnits.toLowerCase(),
      analytics_on: editAnalytics,
      uta: editUta,
    }

    axios
      .patch("/update-supervisor-preferences", body)
      .then(() => {
        setEditMode(false)
        dispatch(getProfile())
      })
      .catch((err) => {
        console.log(err.response)
      })
  }

  if (user.status === "loading" || pageLoading) {
    return <LoadingDashboard />
  }

  return (
    <DefaultLayout
      allowNoTeamSelection={true}
      currentPage={
        <h1
          style={{
            color: "var(--blue)",
            fontSize: "32px",
            marginLeft: "16px",
            paddingTop: "12px",
          }}
        >
          <FontAwesomeIcon icon={faUser} /> My Profile
        </h1>
      }
    >
      <div className="container-fluid">
        <div>
          <button className="default-button" onClick={() => setEditMode(true)}>
            Edit Settings
          </button>
          <Link className="default-button" to="/change-password">
            Change Password
          </Link>
          <button
            onClick={() => {
              dispatch(logout())
            }}
            className="default-button default-button-gray"
            style={{ float: "right" }}
          >
            Logout
          </button>
        </div>
        <hr />
        <div className="card shadow">
          <div className="card-body">
            <h5>Account Info</h5>
            <p style={{ margin: 0 }}>
              <b>Name: </b>
              {user.first_name} {user.last_name}
            </p>
            <p style={{ margin: 0 }}>
              <b>Username / Email: </b>
              {user.email}
            </p>
          </div>
        </div>
        <br />
        <div className="card shadow">
          <div className="card-body">
            {editMode ? (
              <div>
                <div>
                  <button className="default-button" onClick={handleSave}>
                    Save
                  </button>
                  <button
                    className="default-button default-button-gray"
                    onClick={() => setEditMode(false)}
                  >
                    Cancel
                  </button>
                </div>
                <p style={{ margin: 0, fontSize: "1.1rem" }}>
                  My Phone Number
                  <PhoneInput
                    style={{
                      maxWidth: "15rem",
                    }}
                    value={editPhoneNumber}
                    onChange={setEditPhoneNumber}
                    defaultCountry="US"
                    placeholder="Enter phone number"
                  />
                </p>
                <br />
                <div className="custom-control custom-checkbox small">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    style={{ marginRight: "5px" }}
                    checked={editSmsOpt}
                    onChange={(e) => setEditSmsOpt(e.target.checked)}
                    id="smsCheck"
                  />
                  <label
                    className="custom-control-label"
                    style={{ fontSize: "1.1rem" }}
                    for="smsCheck"
                  >
                    Opt In to Receive SMS Texts
                  </label>
                </div>

                <div className="custom-control custom-checkbox small">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    style={{ marginRight: "5px" }}
                    checked={editEmailOpt}
                    onChange={(e) => setEditEmailOpt(e.target.checked)}
                    id="emailCheck"
                  />
                  <label
                    className="custom-control-label"
                    style={{ fontSize: "1.1rem" }}
                    for="emailCheck"
                  >
                    Opt In to Receive Emails
                  </label>
                </div>
                <div>
                  <div className="custom-control custom-checkbox small">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      style={{ marginRight: "5px" }}
                      checked={editAnalytics}
                      onChange={(e) => setEditAnalytics(e.target.checked)}
                      id="analyticsCheck"
                    />
                    <label
                      className="custom-control-label"
                      style={{ fontSize: "1.1rem" }}
                      for="analyticsCheck"
                    >
                      Show Predictive Analytics on Dashboard
                    </label>
                  </div>
                </div>
                <br />
                <div>
                  <p style={{ margin: 0 }}>Document Reminder Duration</p>
                  <div className="row" style={{ maxWidth: "500px" }}>
                    <div className="col-sm-6">
                      <input
                        type="number"
                        className="textInput form-control"
                        min={1}
                        required
                        value={editDocDuration}
                        onChange={(e) => setEditDocDuration(e.target.value)}
                      />
                    </div>
                    <div className="col-sm-6">
                      <select
                        className="textInput form-control"
                        value={editDocDurationUnits}
                        onChange={(e) =>
                          setEditDocDurationUnits(e.target.value)
                        }
                      >
                        <option value="Days">Days</option>
                        <option value="Weeks">Weeks</option>
                      </select>
                    </div>
                  </div>
                </div>
                <br />
                <div>
                  <p style={{ margin: 0 }}>
                    Event Drop Limit (optional - leave blank or 0)
                  </p>
                  <div className="row" style={{ maxWidth: "500px" }}>
                    <div className="col-sm-6">
                      <input
                        type="number"
                        className="textInput form-control"
                        min={1}
                        required
                        value={editEventDrop}
                        onChange={(e) => setEditEventDrop(e.target.value)}
                      />
                    </div>
                    <div className="col-sm-6">
                      <select
                        className="textInput form-control"
                        value={editEventDropUnits}
                        onChange={(e) => setEditEventDropUnits(e.target.value)}
                      >
                        <option value="Days">Days</option>
                        <option value="Weeks">Weeks</option>
                      </select>
                    </div>
                  </div>
                  <br />
                </div>
                <div>
                  <label>Select UTA</label>
                  <select
                    value={editUta}
                    onChange={(e) => setEditUta(e.target.value)}
                    className="custom-select"
                  >
                    <option value="">Select...</option>
                    {utaOptions.map((uta) => {
                      return (
                        <option value={uta.id} key={uta.id}>
                          {uta.title}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </div>
            ) : (
              <div>
                <h5>Settings</h5>
                <p style={{ margin: 0 }}>
                  <b>My Phone Number: </b>
                  {user.phoneNumber && user.phoneNumber.length > 0
                    ? user.phoneNumber
                    : "None"}
                </p>
                <p style={{ margin: 0 }}>
                  <b>SMS Opt-In : </b>
                  {user.sms_opt ? "True " : "False "}
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    style={{ color: "gray", cursor: "pointer" }}
                    data-tip="Receive a text message from EZTrain whenever a trainee add or drops an event."
                  />
                  <ReactTooltip />
                </p>
                <p style={{ margin: 0 }}>
                  <b>Email Opt-In: </b>
                  {user.email_opt ? "True " : "False "}
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    style={{ color: "gray", cursor: "pointer" }}
                    data-tip="Receive an email from EZTrain whenever a trainee add or drops an event."
                  />
                  <ReactTooltip />
                </p>
                <p style={{ margin: 0 }}>
                  <b>Show Predictive Analytics on Dashboard </b>
                  {user.analytics_on ? "True " : "False "}
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    style={{ color: "gray", cursor: "pointer" }}
                    data-tip="Turns Predictive Analytics Off on Dashboard"
                  />
                  <ReactTooltip />
                </p>
                <p style={{ margin: 0 }}>
                  <b>Document Reminder Duration: </b>
                  {docDuration} {docDurationUnits}{" "}
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    style={{ color: "gray", cursor: "pointer" }}
                    data-tip={`Default = 7 days. Determines what documents get marked as "about to expire".<br/> For example, if you set duration = "1 week", all documents <br/>within 7 days of expiring will be marked yellow.
                    `}
                    data-multiline={true}
                  />
                  <ReactTooltip multiline={true} />
                </p>
                <p style={{ margin: 0 }}>
                  <b>Event Drop Limit: </b>
                  {eventDrop === 0 ? (
                    "None "
                  ) : (
                    <span>
                      {eventDrop} {eventDropUnits}{" "}
                    </span>
                  )}
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    style={{ color: "gray", cursor: "pointer" }}
                    data-tip={`Turned off by default. Determines how long before an event<br/>trainees are allowed to drop. For example, if you set duration = "3 days", <br/>trainees will not be able to drop the event if today is <br/>within 3 days of the start time. (you will still be able to drop for them).
                    `}
                    data-multiline={true}
                  />
                  <ReactTooltip multiline={true} />
                </p>
                <p style={{ margin: 0 }}>
                  <b>Current UTA: </b>
                  {user.uta ? user.uta.title : "None"}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </DefaultLayout>
  )
}

export default SupervisorProfile
