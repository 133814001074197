import React, { useState, useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

import DefaultLayout from "../../layouts/defaultLayout"
import LoadingDashboard from "../../components/loadingDashboard"
import SearchTraineeListItem from "../../components/searchTraineeListItem"

import axios from "axios"

import { useSelector } from "react-redux"

const EditEvent = () => {
  const { eventID } = useParams()
  const navigate = useNavigate()

  const user = useSelector((state) => state.user)

  const [loadingPage, setLoadingPage] = useState(false)
  const [loadingForm, setLoadingForm] = useState(false)
  const [loadingTrainees, setLoadingTrainees] = useState(true)
  const [error, setError] = useState("")

  const [currentDate, setCurrentDate] = useState("")

  const [originalTrainees, setOriginalTrainees] = useState([])
  const [originalTraineeIds, setOriginalTraineeIds] = useState([])

  // Input states
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [location, setLocation] = useState("")
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [startTime, setStartTime] = useState("")
  const [endTime, setEndTime] = useState("")
  const [allDay, setAllDay] = useState(false)
  const [capacity, setCapacity] = useState(0)
  const [color, setColor] = useState("#000000")

  const [addedTrainees, setAddedTrainees] = useState([])
  const [addedTraineesIds, setAddedTraineeIds] = useState([])
  const [availableTrainees, setAvailableTrainees] = useState([])
  const [conflictTrainees, setConflictTrainees] = useState([])

  useEffect(() => {
    setLoadingPage(true)
    if (eventID && eventID.length > 0) {
      axios
        .get(`/get-event/${eventID}`)
        .then((res) => {
          setTitle(res.data.title)
          setDescription(res.data.description)
          setStartDate(res.data.start_date)
          setEndDate(res.data.end_date)
          setStartTime(res.data.start_time)
          setEndTime(res.data.end_time)
          setCapacity(res.data.cap)
          setColor(res.data.color)
          setAllDay(res.data.all_day)
          setLocation(res.data.location)
          setAddedTrainees(res.data.trainees)
          setOriginalTrainees(res.data.trainees)
          res.data.trainees.map((t) => {
            setAddedTraineeIds((prev) => [...prev, t.id])
          })
          setLoadingPage(false)
        })
        .catch((err) => {
          setError("Error has occured")
          if (err.response.status === 404) {
            setError("An event with this id does not exist")
          } else if (err.response.status === 403) {
            setError("You are not authorized to view this event")
          } else {
            setError(
              "An unexpected error has occured, please return to the previous page and try again"
            )
          }
          setLoadingPage(false)
        })
    }
  }, [eventID])

  // Query for trainees
  useEffect(() => {
    if (
      startDate &&
      startDate.length > 0 &&
      endDate &&
      endDate.length > 0 &&
      startTime &&
      startTime.length > 0 &&
      endTime &&
      endTime.length > 0
    ) {
      setAvailableTrainees([])
      setConflictTrainees([])
      axios
        .get(
          `/search-trainees/${user.currentFocusedTeam}?start_date=${startDate}&end_date=${endDate}&start_time=${startTime}&end_time=${endTime}`
        )
        .then((res) => {
          setAvailableTrainees(res.data.available_trainees)

          // Filter out trainees already added
          if (addedTraineesIds && addedTraineesIds.length > 0) {
            setConflictTrainees(
              conflictTrainees.filter((t) => !addedTraineesIds.includes(t.id))
            )
          } else {
            setConflictTrainees(conflictTrainees)
          }
          setLoadingTrainees(false)
        })
        .catch((err) => {
          setLoadingTrainees(false)
          console.log(err)
          setError(
            "An unexpected error has occured, please return to the previous page and try again"
          )
        })
    }
  }, [startDate, endDate, startTime, endTime, addedTraineesIds])

  // Get current date

  useEffect(() => {
    const d = new Date()
    let month = "" + (d.getMonth() + 1)
    let day = "" + d.getDate()
    let year = d.getFullYear()

    if (month.length < 2) month = "0" + month
    if (day.length < 2) day = "0" + day

    setCurrentDate([year, month, day].join("-"))
  })

  const handleSubmit = (e) => {
    e.preventDefault()

    setLoadingForm(true)
    let traineesList = []
    // Get trainee ids
    if (addedTrainees && addedTrainees.length > 0) {
      addedTrainees.map((item) => {
        traineesList.push(item.id)
      })
    }

    const body = {
      title,
      id: eventID,
      description,
      start_date: startDate,
      end_date: endDate,
      all_day: allDay,
      start_time: startTime,
      end_time: endTime,
      cap: capacity && capacity !== 0 ? capacity : 0,
      color,
      location,
    }

    axios
      .patch("/edit-event", body)
      .then(() => {
        const signupBody = traineesList.filter(
          (t) => !addedTraineesIds.includes(t)
        )

        axios
          .post(`/create-trainee-signups/${eventID}`, signupBody)
          .then(() => {
            const removeBody = addedTraineesIds.filter(
              (t) => !traineesList.includes(t)
            )

            axios
              .delete(`/remove-trainee-signups/${eventID}`, {
                data: removeBody,
              })
              .then(() => {
                navigate("/events")
              })
              .catch((err) => {
                console.log(err.response)
                setError("An error has occured please try again")
                setLoadingForm(false)
              })
          })
          .catch((err) => {
            console.log(err.response)
            setError("An error has occured please try again")
            setLoadingForm(false)
          })
      })
      .catch((err) => {
        console.log(err.response)
        setError("An error has occured please try again")
        setLoadingForm(false)
      })
  }

  if (loadingPage || loadingTrainees) {
    return <LoadingDashboard />
  }

  if (error && error.length > 0) {
    return (
      <DefaultLayout disableTeams>
        <h5>{error}</h5>
      </DefaultLayout>
    )
  }

  return (
    <DefaultLayout disableTeams>
      <h1>Edit Event</h1>
      <div className="row">
        <div className="col-sm-6">
          <form style={{ maxWidth: "600px" }} onSubmit={handleSubmit}>
            <p>Title*</p>
            <div className="input-group mb-3">
              <input
                type="text"
                className="textinput textInput form-control"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </div>

            <p>Description*</p>
            <div className="input-group mb-3">
              <textarea
                rows={10}
                type="text"
                className="form-control form-control-user textarea"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
              />
            </div>

            <p>Location (Optional)</p>
            <div className="input-group mb-3">
              <input
                type="text"
                className="textinput textInput form-control"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              />
            </div>

            <div className="form-row row">
              <div className="form-group col-md-6">
                <p>Start Date*</p>
                <input
                  type="date"
                  className="textinput textInput form-control"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value)
                  }}
                  min={currentDate}
                  required
                />
              </div>
              {!allDay && (
                <div className="form-group col-md-6">
                  <p>Start Time*</p>
                  <input
                    type="time"
                    className="textinput textInput form-control"
                    value={startTime}
                    onChange={(e) => {
                      setStartTime(e.target.value)
                    }}
                    required
                  />
                </div>
              )}
            </div>
            <br />

            <div className="form-row row">
              <div className="form-group col-md-6">
                <p>End Date*</p>
                <input
                  type="date"
                  className="textinput textInput form-control"
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value)
                  }}
                  min={currentDate}
                  required={!allDay}
                />
              </div>

              {!allDay && (
                <div className="form-group col-md-6">
                  <p>End Time*</p>
                  <input
                    type="time"
                    className="textinput textInput form-control"
                    value={endTime}
                    onChange={(e) => {
                      setEndTime(e.target.value)
                    }}
                    required={!allDay}
                  />
                </div>
              )}
            </div>
            <br />

            <div className="form-row row">
              <div className="forg-group">
                <label>
                  <input
                    type="checkbox"
                    value={allDay}
                    onChange={(e) => {
                      setAllDay(e.target.checked)
                    }}
                  />{" "}
                  All Day
                </label>
              </div>
            </div>
            <br />

            <div className="form-row row">
              <div className="form-group col-md-6">
                <p>Capacity</p>
                <input
                  type="number"
                  className="textinput textInput form-control"
                  value={capacity}
                  onChange={(e) => {
                    setCapacity(e.target.value)
                  }}
                  min={0}
                />
              </div>

              <div className="form-group col-md-6">
                <p>Color</p>
                <input
                  type="color"
                  className="textinput textInput form-control"
                  value={color}
                  onChange={(e) => {
                    setColor(e.target.value)
                  }}
                  required
                  list={[]}
                />
              </div>
            </div>
            <br />
            <Link className="default-button default-button-red" to="/events">
              Cancel
            </Link>
            <button
              className="default-button"
              disabled={loadingForm || loadingPage}
              type="submit"
            >
              Submit
            </button>
          </form>
        </div>
        <div className="col-sm-6">
          <div className="container-fluid">
            <h5>Search Result</h5>
            <ul className="list-group available-list">
              <li className="list-group-item list-group-item-info">
                <strong> Trainees already added </strong>
              </li>
              {originalTrainees &&
                originalTrainees.length > 0 &&
                originalTrainees.map((trainee) => {
                  return (
                    <SearchTraineeListItem
                      trainee={trainee}
                      setTrainees={setAddedTrainees}
                      addedTrainees={addedTrainees}
                      key={trainee.id}
                    />
                  )
                })}
            </ul>
            <br />
            <ul className="list-group conflict-list">
              <li className="list-group-item list-group-item-danger">
                <strong> Trainees with time conflict </strong>
                {conflictTrainees && conflictTrainees.length > 0 && (
                  <button
                    className="btn btn-outline-primary btn-sm float-right add-all"
                    onClick={() =>
                      setAddedTrainees([
                        ...originalTrainees,
                        ...conflictTrainees,
                      ])
                    }
                  >
                    Add All
                  </button>
                )}
              </li>
              {conflictTrainees &&
                conflictTrainees.length > 0 &&
                conflictTrainees.map((trainee) => {
                  return (
                    <SearchTraineeListItem
                      trainee={trainee}
                      setTrainees={setAddedTrainees}
                      addedTrainees={addedTrainees}
                      key={trainee.id}
                    />
                  )
                })}
            </ul>
            <br />
            <ul className="list-group available-list">
              <li className="list-group-item list-group-item-success">
                <strong> Trainees without time conflict </strong>
                {availableTrainees && availableTrainees.length > 0 && (
                  <button
                    className="btn btn-outline-primary btn-sm float-right add-all"
                    onClick={() =>
                      setAddedTrainees([
                        ...originalTrainees,
                        ...availableTrainees,
                      ])
                    }
                  >
                    Add All
                  </button>
                )}
              </li>
              {availableTrainees &&
                availableTrainees.length > 0 &&
                availableTrainees.map((trainee) => {
                  return (
                    <SearchTraineeListItem
                      trainee={trainee}
                      setTrainees={setAddedTrainees}
                      addedTrainees={addedTrainees}
                      key={trainee.id}
                    />
                  )
                })}
            </ul>
          </div>
        </div>
      </div>
    </DefaultLayout>
  )
}

export default EditEvent
