import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons"
import axios from "axios"

import TraineeLayout from "../../layouts/traineeLayout"

import CalendarComponent from "../../components/calendar"
import SmallLoader from "../../components/smallLoader"

// Parsing params
import * as qs from "qs"

// Helper Functions
const formatDate = (date) => {
  const year = date.slice(0, 4)
  const month = date.slice(5, 7)
  const day = date.slice(8, 10)
  return `${month}/${day}/${year}`
}

const formatTime = (time) => {
  let hour = parseInt(time.slice(0, 2))
  let suffix = " a.m."
  if (hour > 12) {
    suffix = " p.m."
    hour = hour - 12
  }
  const minutes = time.slice(2, 5)
  return hour.toString() + minutes + suffix
}

// Helper Components
const NotCompliantMessage = () => (
  <div
    className="alert alert-danger"
    role="alert"
    style={{ margin: "1rem auto", maxWidth: "1000px" }}
  >
    <strong>You are not compliant for this month.</strong> Contact your
    supervisor to find out how to meet compliance requirements for the month.
  </div>
)

const EventMessage = ({
  title,
  date,
  start_date,
  start_time,
  end_date,
  end_time,
  ...rest
}) => (
  <div
    className="alert alert-primary"
    role="alert"
    style={{ margin: "1rem auto", maxWidth: "1000px" }}
  >
    <strong>You have an upcoming event: </strong> {title} on{" "}
    {formatDate(start_date)} @{formatTime(start_time)} - {formatTime(end_time)}
  </div>
)

const EventDisplay = ({ event, events, setEvents, registered, myId }) => {
  const weekday = {
    0: "MON",
    1: "TUE",
    2: "WED",
    3: "THU",
    4: "FRI",
    5: "SAT",
    6: "SUN",
  }
  const d = new Date(event.start_date)
  const dayOfWeek = weekday[d.getDay()]
  const formattedDay =
    dayOfWeek.slice(0, 1) + dayOfWeek.slice(1, 3).toLowerCase()

  const dropEvent = (e, id) => {
    e.preventDefault()
    axios
      .delete(`/drop-event/${id}`)
      .then((res) => {
        const newEvents = events.map((currentEvent) => {
          if (currentEvent.id === id) {
            currentEvent.trainees = currentEvent.trainees.filter(
              (tr) => tr.id !== myId
            )
            return currentEvent
          } else {
            return currentEvent
          }
        })
        setEvents(newEvents)
      })
      .catch((err) => console.log(err.response.data))
  }

  const signupForEvent = (e, id) => {
    e.preventDefault()
    axios
      .post(`/signup-for-event/${id}`)
      .then((res) => {
        const newEvents = events.map((currentEvent) => {
          if (currentEvent.id === id) {
            currentEvent.trainees.push({ id: myId })
            return currentEvent
          } else {
            return currentEvent
          }
        })
        setEvents(newEvents)
      })
      .catch((err) => console.log("There was an error signing up"))
  }

  return (
    <div style={{ display: "flex", maxWidth: "750px", margin: "auto" }}>
      <ul
        className="list-group overdue-list"
        style={{
          borderColor: "red",
          marginTop: "1rem",
          width: "60rem",
          minWidth: "20rem",
        }}
      >
        <li className="list-group-item list-group-item-secondary text-center">
          <FontAwesomeIcon icon={faCalendarAlt} />{" "}
          <strong>
            {event.start_date} ({dayOfWeek}){" "}
          </strong>
        </li>
        <li
          className="list-group-item text-center"
          id={`container-${event.id}`}
        >
          <strong style={{ color: "#555" }} id={`time-${event.id}`}>
            {formattedDay}, {formatDate(event.start_date)} @
            {formatTime(event.start_time)} - {formatTime(event.end_time)}
          </strong>
          <div style={{ color: event.color }} id={`title-${event.id}`}>
            {event.title}{" "}
          </div>
          <div>"{event.description === "None" ? "" : event.description}"</div>
          <div className="text-warning">
            Capacity = {event.trainees.length} / {event.cap}
          </div>
          {registered ? (
            <button
              type="button"
              className="btn btn-outline-danger btn-sm my-1 preview-btn"
              id={`${event.id}`}
              onClick={(e) => dropEvent(e, event.id)}
            >
              Withdraw
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-outline-primary btn-sm my-1 preview-btn"
              id={`${event.id}`}
              onClick={(e) => signupForEvent(e, event.id)}
            >
              Sign up
            </button>
          )}
        </li>
      </ul>
    </div>
  )
}

// Calendar View
const CalendarView = ({ calendarEvents, setEvents, myId, events }) => {
  const [currentEvent, setCurrentEvent] = useState(null)
  const [loading, setLoading] = useState(false)
  const [signedUpEvents, setSignedUpEvents] = useState([])

  useEffect(() => {
    setSignedUpEvents([])
    calendarEvents.map((thisEvent) => {
      for (const t of thisEvent.trainees) {
        if (t.id === myId) {
          setSignedUpEvents((prev) => [...prev, thisEvent.id])
        }
      }
    })
  }, [calendarEvents, myId])

  const handleEventSelect = (e) => {
    setCurrentEvent(e)
  }

  const dropEvent = (e, id) => {
    e.preventDefault()
    setLoading(true)

    axios
      .delete(`/drop-event/${id}`)
      .then((res) => {
        const newEvents = events.map((thisEvent) => {
          if (thisEvent.id === id) {
            thisEvent.trainees = thisEvent.trainees.filter(
              (tr) => tr.id !== myId
            )
            return thisEvent
          } else {
            return thisEvent
          }
        })
        setEvents(newEvents)
        setSignedUpEvents((prev) => prev.filter((id) => id !== currentEvent.id))
        setLoading(false)
      })
      .catch((err) => {
        console.log(err.response.data)
        setLoading(false)
      })
  }

  const signupForEvent = (e, id) => {
    e.preventDefault()
    setLoading(true)

    axios
      .post(`/signup-for-event/${id}`)
      .then((res) => {
        const newEvents = events.map((e) => {
          if (e.id === id) {
            e.trainees.push({ id: myId })
            return e
          } else {
            return e
          }
        })
        setEvents(newEvents)
        setSignedUpEvents((prev) => [...prev, currentEvent.id])
        setLoading(false)
      })
      .catch((err) => {
        console.log("There was an error signing up")
        setLoading(false)
      })
  }

  if (loading) {
    return <SmallLoader />
  }

  return (
    <div className="row fade-in">
      <div className="col-sm-8">
        <CalendarComponent
          events={calendarEvents}
          handleEventSelect={handleEventSelect}
        />
      </div>
      <div className="col-sm-4">
        {currentEvent ? (
          <div style={{ marginTop: "2rem" }}>
            <h3>
              <b>{currentEvent.title}</b>
            </h3>
            <b>Date/Time: </b>
            {currentEvent.all_day ? (
              currentEvent.start_date === currentEvent.end_date ? (
                <span>{currentEvent.start_date}, All Day</span>
              ) : (
                <span>
                  {currentEvent.start_date} to {currentEvent.end_date}, All Day
                </span>
              )
            ) : currentEvent.start_date === currentEvent.end_date ? (
              <span>
                {currentEvent.start_date},{" "}
                {currentEvent.start_time.substring(0, 5)} to{" "}
                {currentEvent.end_time.substring(0, 5)}
              </span>
            ) : (
              <span>
                {currentEvent.start_date} @{" "}
                {currentEvent.start_time.substring(0, 5)} to{" "}
                {currentEvent.end_date} @{" "}
                {currentEvent.end_time.substring(0, 5)}
              </span>
            )}

            <br />
            <b>Description: </b>
            {currentEvent.description}
            <br />
            {!currentEvent.cap || currentEvent.cap === 0 ? (
              <p style={{ color: "var(--blue)" }}>
                {currentEvent.trainees.length} participant(s)
              </p>
            ) : (
              <p
                style={{
                  color:
                    currentEvent.trainees.length === currentEvent.cap
                      ? "var(--red)"
                      : "green",
                }}
              >
                {currentEvent.trainees.length} / {currentEvent.cap} capacity
              </p>
            )}

            {signedUpEvents.includes(currentEvent.id) ? (
              <button
                type="button"
                className="btn btn-outline-danger btn-sm my-1"
                style={{ transition: "none" }}
                onClick={(e) => dropEvent(e, currentEvent.id)}
              >
                Withdraw
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-outline-primary btn-sm my-1"
                style={{ transition: "none" }}
                onClick={(e) => signupForEvent(e, currentEvent.id)}
              >
                Sign up
              </button>
            )}
          </div>
        ) : (
          <div style={{ marginTop: "2rem" }}>
            <h4>No event currently selected</h4>
          </div>
        )}
      </div>
    </div>
  )
}

// Main Component
const TraineeViewSchedule = (props) => {
  const MS_IN_DAY = 86400000 // Number of microsections in a day
  const complienceThreshold = 87
  const view = qs.parse(props.location.search, { ignoreQueryPrefix: true }).view
  const user = useSelector((state) => state.user)
  const [myId, setMyId] = useState(0)
  const [events, setEvents] = useState([])
  const [isCompliant, setIsCompliant] = useState(false)

  // Calendar view states
  const [calendarEvents, setCalendarEvents] = useState([])
  const [calendarView, setCalendarView] = useState(false)

  useEffect(() => {
    axios
      .get(`/get-trainee`)
      .then((res) => {
        const traineeId = res.data.id
        setMyId(traineeId)
        const shop = res.data.shop
        setIsCompliant(complienceThreshold < res.data.percentComplete)
        return { shop, traineeId }
      })
      .then(({ shop, traineeId }) => {
        axios
          .get(`/get-events/${shop}`)
          .then((res) => {
            {
              /* we don't need events that have passed */
            }
            const now = new Date()
            const relevantEvents = res.data.filter((event) => {
              const end_date = new Date(`${event.end_date}:${event.end_time}`)
              return now - end_date < MS_IN_DAY //end_date is in the future or within a day
            })

            // Format data to readable version for calendar component
            setCalendarEvents([])
            res.data.map((e) => {
              const end_date = new Date(`${e.end_date}T${e.end_time}`)
              if (now - end_date < MS_IN_DAY) {
                const newEvent = {
                  title: e.title,
                  start: new Date(`${e.start_date}T${e.start_time}`),
                  end: new Date(`${e.end_date}T${e.end_time}`),
                  id: e.id,
                  description: e.description,
                  location: e.location,
                  start_time: e.start_time,
                  end_time: e.end_time,
                  color: e.color,
                  start_date: e.start_date,
                  end_date: e.end_date,
                  trainees: e.trainees,
                  cap: e.cap,
                  all_day: e.all_day,
                }
                setCalendarEvents((prev) => [...prev, newEvent])
              }
            })
            setEvents(relevantEvents)
          })
          .catch((err) => {
            console.log(err.response.status, err.response.data)
          })
      })
  }, [])

  useEffect(() => {
    if (view === "calendar") {
      setCalendarView(true)
    }
  }, [props])

  const signedUp = (event) => {
    for (const trainee of event.trainees) {
      if (trainee.id === myId) {
        return true
      }
    }
    return false
  }

  const myEvents = events.filter((event) => signedUp(event))
  const today = new Date()
  const myUpcomingEvents = myEvents.filter((event) => {
    // Events coming up in the next 5 days
    const start_date = new Date(event.start_date)
    const end_date = new Date(event.end_date)
    return start_date - today < MS_IN_DAY * 5 && today - end_date < MS_IN_DAY
  })
  const otherEvents = events.filter((event) => !signedUp(event))

  return (
    <TraineeLayout>
      <div className="container-fluid" style={{ marginBottom: "3rem" }}>
        <div>
          <div
            className="h3 text-center"
            style={{ marginTop: "2rem", color: "darkblue" }}
          >
            <strong>
              <a
                data-toggle="tooltip"
                title="You are currently signed up for these events"
              >
                My Events
              </a>
            </strong>
          </div>
          <div className="text-center">
            {calendarView ? (
              <button
                className="default-button"
                onClick={() => setCalendarView(false)}
              >
                List View
              </button>
            ) : (
              <button
                className="default-button"
                onClick={() => setCalendarView(true)}
              >
                Calendar View
              </button>
            )}
          </div>

          {!isCompliant && <NotCompliantMessage />}
          {myUpcomingEvents &&
            myUpcomingEvents.length > 0 &&
            myUpcomingEvents.map((event) => (
              <EventMessage key={event.id} {...event} />
            ))}
          {calendarView ? (
            <div style={{ margin: "1rem" }}>
              <CalendarView
                calendarEvents={calendarEvents}
                setEvents={setEvents}
                myId={myId}
                events={events}
              />
            </div>
          ) : (
            <div>
              <div className="fade-in">
                {myEvents &&
                  myEvents.length > 0 &&
                  myEvents.map((event) => (
                    <EventDisplay
                      key={`event${event.id}`}
                      event={event}
                      registered={true}
                      events={events}
                      setEvents={setEvents}
                      myId={myId}
                    />
                  ))}
                {myEvents && myEvents.length === 0 && (
                  <div className="text-center">
                    <strong className="text-dark">
                      You have no events scheduled.
                    </strong>
                  </div>
                )}
              </div>
              <div>
                <div
                  className="h3 text-center"
                  style={{ marginTop: "2rem", color: "darkblue" }}
                >
                  <strong>
                    <a
                      data-toggle="tooltip"
                      title="These are other available events you may sign up for"
                    >
                      Other Events
                    </a>
                  </strong>
                </div>
                {otherEvents &&
                  otherEvents.map((event) => (
                    <EventDisplay
                      key={`event${event.id}`}
                      event={event}
                      registered={false}
                      events={events}
                      setEvents={setEvents}
                      myId={myId}
                    />
                  ))}
                {otherEvents && otherEvents.length === 0 && (
                  <div className="text-center">
                    <strong className="text-dark">
                      There are no more events scheduled.
                    </strong>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </TraineeLayout>
  )
}

export default TraineeViewSchedule
