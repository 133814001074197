import StatusEnum from "../../../util/StatusEnum"
import SmallLoader from "../../../components/smallLoader"
import ProgressBar from "../../../components/progressBar"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowDown,
  faArrowUp,
  faChevronRight,
  faCog,
} from "@fortawesome/free-solid-svg-icons"
import SubGroupCard from "../../../components/SubGroupCard"
import UTAChart from "../../../components/utaChart"
import MyLearningChart from "../../../components/myLearningChart"
import IMRChart from "../../../components/imrChart"
import React, { useEffect, useMemo, useState } from "react"
import axios from "axios"
import { useSelector } from "react-redux"

function filterDict(key_name, value, dict) {
  const temp = Object.groupBy(dict, (d) => d[key_name])
  return temp[value]
}

const DropdownSelect = ({ options, currentVal, setVal, title }) => {
  const [menuDropdownOpen, setMenuDropdownOpen] = useState(false)

  const [q, setQ] = useState("")

  function search(items) {
    return items.filter((item) => {
      return item.toLowerCase().indexOf(q.toLowerCase()) > -1
    })
  }

  return (
    <div>
      <div
        className="dropdown"
        onClick={() => setMenuDropdownOpen((prev) => !prev)}
      >
        {currentVal ? <h6>{currentVal} </h6> : <h6>{title}</h6>}

        <FontAwesomeIcon
          icon={faChevronRight}
          className="m-2 "
          style={!menuDropdownOpen ? {} : { transform: "rotate(90deg)" }}
        />
      </div>

      <div className="wrapper">
        <div
          className="dropdown-list"
          style={menuDropdownOpen ? { maxHeight: "20rem" } : { maxHeight: "0" }}
        >
          <div className="sidebar-heading" style={{ color: "var(--gray-300)" }}>
            TEAMS:
            <div className="search-wrapper">
              <label htmlFor="search-form">
                <input
                  type="search"
                  name="search-form"
                  id="search-form"
                  className="search-input textInput form-control"
                  placeholder="Search for..."
                  value={q}
                  onChange={(e) => setQ(e.target.value)}
                />
                <span className="sr-only">Search</span>
              </label>
            </div>
          </div>
          {search(options).map((option) => (
            <div className="teams-link" key={option}>
              <div
                onClick={() => {
                  setVal(option)
                  setMenuDropdownOpen(false)
                }}
              >
                {option}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const ReadinessAnalysisTab = ({}) => {
  const user = useSelector((state) => state.user)
  const [orgLevel, setOrgLevel] = useState("Group Overview")

  const [selectedGroup, setSelectedGroup] = useState("")
  const [selectedSquadron, setSelectedSquadron] = useState("")
  const [selectedShop, setSelectedShop] = useState("")

  const [readinessAnalysisData, setReadinessAnalysisData] = useState([])
  const [readinessAnalysisDataStatus, setReadinessAnalysisDataStatus] =
    useState(StatusEnum.loading)

  // const [groupOptions, setGroupOptions] = useState({})
  // const [squadOptions, setSquadOptions] = useState({})
  // const [shopOptions, setShopOptions] = useState({})

  useEffect(() => {
    if (user.isInstitutionAdmin) {
      axios
        .get(`/get-institution-metrics`)
        .then((res) => {
          setReadinessAnalysisData(res.data)
          setReadinessAnalysisDataStatus(StatusEnum.success)
        })
        .catch((err) => {
          setReadinessAnalysisDataStatus(StatusEnum.error)
          console.log(err)
        })
    }
  }, [])

  const groupOptions = useMemo(() => {
    if (readinessAnalysisData.length > 0) {
      const groups = Object.groupBy(
        readinessAnalysisData,
        ({ group_id }) => group_id
      )
      return Object.keys(groups)
    }
    return []
  }, [readinessAnalysisData])

  let squadOptions = useMemo(() => {
    if (readinessAnalysisData.length > 0) {
      let filtered = readinessAnalysisData
      if (selectedGroup) {
        filtered = filterDict("group_id", selectedGroup, filtered)
      }

      const squads = Object.groupBy(filtered, ({ squadron_id }) => squadron_id)
      return Object.keys(squads)
    }
    return []
  }, [readinessAnalysisData, selectedGroup])

  const shopOptions = useMemo(() => {
    if (readinessAnalysisData.length > 0) {
      let filtered = readinessAnalysisData

      if (selectedGroup) {
        filtered = filterDict("group_id", selectedGroup, filtered)
      }
      if (selectedSquadron) {
        filtered = filterDict("squadron_id", selectedSquadron, filtered)
      }

      return filtered
    }
    return []
  }, [selectedGroup, selectedSquadron, readinessAnalysisData])

  const subGroupMetrics = useMemo(() => {
    if (shopOptions.length > 0) {
      const subs = []
      let dict = {}
      if (selectedSquadron) {
        dict = Object.groupBy(shopOptions, ({ shop_id }) => shop_id)
      } else if (selectedGroup) {
        dict = Object.groupBy(shopOptions, ({ squadron_id }) => squadron_id)
      } else {
        dict = Object.groupBy(shopOptions, ({ group_id }) => group_id)
      }

      Object.keys(dict).forEach((key) => {
        subs.push({
          name: key,
          percComplete: (
            (dict[key].reduce((arr, v) => {
              return arr + v.num_completed
            }, 0) /
              dict[key].reduce((arr, v) => {
                return arr + v.total_tasks
              }, 0)) *
            100
          ).toFixed(),
          percComplete1: (
            (dict[key].reduce((arr, v) => {
              return arr + v.month_ago_1.num_completed
            }, 0) /
              dict[key].reduce((arr, v) => {
                return arr + v.month_ago_1.total_tasks
              }, 0)) *
            100
          ).toFixed(),
          percComplete2: (
            (dict[key].reduce((arr, v) => {
              return arr + v.month_ago_2.num_completed
            }, 0) /
              dict[key].reduce((arr, v) => {
                return arr + v.month_ago_2.total_tasks
              }, 0)) *
            100
          ).toFixed(),
          percComplete3: (
            (dict[key].reduce((arr, v) => {
              return arr + v.month_ago_3.num_completed
            }, 0) /
              dict[key].reduce((arr, v) => {
                return arr + v.month_ago_3.total_tasks
              }, 0)) *
            100
          ).toFixed(),
          percComplete4: (
            (dict[key].reduce((arr, v) => {
              return arr + v.month_ago_4.num_completed
            }, 0) /
              dict[key].reduce((arr, v) => {
                return arr + v.month_ago_4.total_tasks
              }, 0)) *
            100
          ).toFixed(),
        })
      })
      return subs
    }
    return []
  }, [selectedGroup, selectedSquadron, readinessAnalysisData])

  const percentComplete = useMemo(() => {
    if (subGroupMetrics.length > 0) {
      return (
        (shopOptions.reduce((arr, v) => {
          return arr + v.num_completed
        }, 0) /
          shopOptions.reduce((arr, v) => {
            return arr + v.total_tasks
          }, 0)) *
        100
      ).toFixed()
    }
    return 0
  }, [shopOptions, subGroupMetrics])

  const historicalPercent1 = useMemo(() => {
    if (subGroupMetrics.length > 0) {
      return (
        (shopOptions.reduce((arr, v) => {
          return arr + v.month_ago_1.num_completed
        }, 0) /
          shopOptions.reduce((arr, v) => {
            return arr + v.month_ago_1.total_tasks
          }, 0)) *
        100
      ).toFixed()
    }
    return 0
  }, [shopOptions, subGroupMetrics])

  const imrData = useMemo(() => {
    const imr = [
      { name: "Imm", overdue: 0, due: 0, finished: 0 },

      { name: "Lab", overdue: 0, due: 0, finished: 0 },

      { name: "PHA", overdue: 0, due: 0, finished: 0 },

      { name: "Eqp", overdue: 0, due: 0, finished: 0 },
    ]
    if (shopOptions.length > 0) {
      shopOptions.forEach((v) => {
        imr[0] = {
          name: "Imm",
          overdue: imr[0]["overdue"] + v["Imm"]["overdue"],
          due: imr[0]["due"] + v["Imm"]["due"],
          finished: imr[0]["finished"] + v["Imm"]["finished"],
        }
        imr[1] = {
          name: "Lab",
          overdue: imr[1]["overdue"] + v["Lab"]["overdue"],
          due: imr[1]["due"] + v["Lab"]["due"],
          finished: imr[1]["finished"] + v["Lab"]["finished"],
        }
        imr[2] = {
          name: "PHA",
          overdue: imr[2]["overdue"] + v["PHA"]["overdue"],
          due: imr[2]["due"] + v["PHA"]["due"],
          finished: imr[2]["finished"] + v["PHA"]["finished"],
        }
        imr[3] = {
          name: "Eqp",
          overdue: imr[3]["overdue"],
          due: imr[3]["due"],
          finished: imr[3]["finished"],
        }
      })
    }
    return imr
  }, [shopOptions, subGroupMetrics])

  const tfatData = useMemo(() => {
    let tfat = [
      { name: "overdue", value: 0 },
      { name: "due", value: 0 },
      { name: "finished", value: 0 },
    ]

    if (shopOptions.length > 0) {
      shopOptions.forEach((v) => {
        tfat = [
          { name: "Overdue", value: tfat[0]["value"] + v["TFAT"]["overdue"] },
          { name: "Due", value: tfat[1]["value"] + v["TFAT"]["due"] },
          { name: "Finished", value: tfat[2]["value"] + v["TFAT"]["finished"] },
        ]
      })
    }
    return tfat
  }, [shopOptions, subGroupMetrics])

  if (readinessAnalysisDataStatus != StatusEnum.error) {
    if (readinessAnalysisDataStatus == StatusEnum.loading) {
      return <SmallLoader />
    }
    return (
      <div className="">
        <div className="container-fluid fade-in">
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <h4>Group:</h4>
            <DropdownSelect
              options={groupOptions}
              currentVal={selectedGroup}
              setVal={setSelectedGroup}
              title={"Select a Group"}
            />
            <button
              className="default-button"
              style={{ margin: 0, marginLeft: "1rem" }}
              onClick={() => setSelectedGroup("")}
            >
              Clear
            </button>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <h4>Squadron:</h4>
            <DropdownSelect
              options={squadOptions}
              currentVal={selectedSquadron}
              setVal={setSelectedSquadron}
              title={"Select a Squadron"}
            />
            <button
              className="default-button"
              style={{ margin: 0, marginLeft: "1rem" }}
              onClick={() => setSelectedSquadron("")}
            >
              Clear
            </button>
          </div>
          {/*</div>*/}

          {shopOptions.length > 0 ? (
            <div
              style={{
                display: "flex",
                // width: "50%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "1rem",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  marginBottom: "1rem",
                  gap: "1rem",
                }}
              >
                {/* Org */}
                <div className="card shadow" style={{ flexBasis: "20%" }}>
                  <div className="card-body">
                    <p className="text font-weight-bold mb-1">
                      {selectedSquadron ? selectedSquadron : selectedGroup}
                    </p>
                    <ProgressBar
                      percent={percentComplete}
                      color={
                        percentComplete === 100
                          ? "var(--green)"
                          : percentComplete > 87
                            ? "var(--yellow)"
                            : "var(--red)"
                      }
                    />
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        maxWidth: "100%",
                      }}
                    >
                      <div>
                        {historicalPercent1 - percentComplete > 0 && (
                          <FontAwesomeIcon
                            icon={faArrowUp}
                            style={{ color: "#6fdb9c" }}
                          />
                        )}
                        {historicalPercent1 - percentComplete < 0 && (
                          <FontAwesomeIcon
                            icon={faArrowDown}
                            style={{ color: "#ff0000" }}
                          />
                        )}
                      </div>
                      <div
                        className="mt-1"
                        style={{ paddingLeft: 10, width: "100%" }}
                      >
                        <p>
                          {historicalPercent1 - percentComplete}% change in
                          readiness month over month
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* END Org */}

                {/* My learning */}

                <div className="card shadow h-200" style={{ flexBasis: "20%" }}>
                  <div className="card-body">
                    <MyLearningChart data={tfatData} />
                  </div>
                </div>

                {/* END My learning */}

                {/* IMR */}
                <div className="card shadow h-200" style={{ flexBasis: "40%" }}>
                  <div className="card-body">
                    <IMRChart data={imrData} />
                  </div>
                </div>
                {/* END IMR */}
              </div>

              <div
                style={{
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  marginBottom: "1rem",
                }}
              >
                {/* SUB */}
                <div
                  className="card shadow h-200 "
                  style={{ flexBasis: "30%" }}
                >
                  <div className="card-body card_body_scroll">
                    <SubGroupCard data={subGroupMetrics} />
                  </div>
                </div>
                {/* END SUB */}

                {/* UTA */}
                <div className="card shadow h-200" style={{ flexBasis: "50%" }}>
                  <div className="card-body">
                    <UTAChart data={subGroupMetrics} />
                  </div>
                </div>
                {/* END UTA */}
              </div>

              {/* END Historical readiness */}
              {/*</div>*/}
              {/*<div style={{ display: "flex" }}>*/}
              {/* My learning */}
              {/*<div*/}
              {/*  style={{*/}
              {/*    display: "flex",*/}
              {/*    width: "50%",*/}
              {/*    padding: "1rem",*/}
              {/*    justifyContent: "center",*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <div className="card shadow h-200" style={{ width: "100%" }}>*/}
              {/*    <div className="card-body" style={{ padding: "1rem 4rem" }}>*/}
              {/*      <MyLearningChart*/}
              {/*        data={readinessAnalysisData.my_learning_completion}*/}
              {/*      />*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              {/* END My learning */}
            </div>
          ) : (
            <div>"No Data Found</div>
          )}
        </div>
      </div>
    )
  }

  if (readinessAnalysisDataStatus === StatusEnum.error) {
    return (
      <div style={{ width: "100%", textAlign: "center", padding: "1rem" }}>
        <h5>An unexpected error has occurred, please try again</h5>
      </div>
    )
  }
}

export default ReadinessAnalysisTab
