import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"

import TraineeLayout from "../../layouts/traineeLayout"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFileAlt,
  faIdCard,
  faTasks,
  faUserClock,
  faCalendarAlt,
  faBorderNone,
  faUser,
} from "@fortawesome/free-solid-svg-icons"

import axios from "axios"

//Helper Components
const EventMessage = ({ title, date, start_time, end_time, ...rest }) => (
  <div
    className="alert alert-primary"
    role="alert"
    style={{ margin: "1rem auto", maxWidth: "1000px" }}
  >
    <strong>You have an upcoming event: </strong> {title} on {date}:{" "}
    {start_time} - {end_time}
  </div>
)

const TraineeDashboardCard = ({
  title,
  message,
  target,
  shortName,
  faIcon,
}) => (
  <div
    className="card"
    style={{
      width: "30rem",
      margin: "1rem 1rem",
      borderColor: "royalblue",
      borderRadius: "0.75rem",
      borderWidth: "0.1rem",
    }}
  >
    <div className="card-body">
      <h5 className="h5 card-title" style={{ color: "royalblue" }}>
        <i className="fas fa-user-clock" />
        <FontAwesomeIcon icon={faIcon} /> <strong>{title}</strong>
      </h5>
      <p className="card-text text-dark">{message}</p>
      <Link
        to={"/" + target}
        className="btn btn-outline-primary btn-sm text-dark"
      >
        {`Go to ${shortName}`}
      </Link>
    </div>
  </div>
)

const NotCompliantMessage = () => (
  <div
    className="alert alert-danger"
    role="alert"
    style={{ margin: "1rem auto", maxWidth: "1000px" }}
  >
    <strong>You are not compliant for this month.</strong> Contact your
    supervisor to find out how to meet compliance requirements for the month.
  </div>
)

const WelcomeMessage = ({ user }) => (
  <div
    className="h3 text-center"
    style={{ marginTop: "2rem", color: "darkblue" }}
  >
    {user && <strong>Welcome, {user.first_name}</strong>}
    {user.teams && user.teams.length > 0 && (
      <p>Your team: {user.teams[0].title}</p>
    )}
  </div>
)

//Main Component
const TraineeHome = () => {
  const user = useSelector((state) => state.user)
  const [events, setEvents] = useState([])
  const [isCompliant, setIsCompliant] = useState(false)
  var traineeId
  const complienceThreshold = 87
  useEffect(() => {
    axios
      .get(`/get-trainee`)
      .then((res) => {
        traineeId = res.data.id
        setIsCompliant(complienceThreshold < res.data.percentComplete)
      })
      .then(
        axios
          .get(`/get-events/${user.currentFocusedTeam}`)
          .then((res) => {
            return res.data.map((e) => ({
              signedUp: traineeId.toString() in e.trainees,
              title: e.title,
              start: `${e.date}T${e.start_time}`,
              end: `${e.date}T${e.end_time}`,
              id: e.id,
              description: e.description,
              date: e.date,
              start_time: e.start_time,
              end_time: e.end_time,
              color: e.color,
            }))
          })
          .then((data) => {
            {
              /* Filter events here */
            }
            setEvents(data)
          })
          .catch((err) => {
            console.log(`Error getting events ${err}`)
          })
      )
  }, [])
  return (
    <TraineeLayout>
      {/* TODO: Fix Title of Page */}
      <div className="container-fluid">
        <WelcomeMessage user={user} />
        {!isCompliant && <NotCompliantMessage />}
        {events &&
          events.length > 0 &&
          events.map((event) =>
            event.signedUp ? <EventMessage key={event.id} {...event} /> : null
          )}
      </div>
      <div className="row justify-content-center text-center text-dark">
        <TraineeDashboardCard
          title="Events"
          message="View, signup, or cancel upcoming events."
          target="trainee-schedule"
          shortName="events"
          faIcon={faIdCard}
        />
        <TraineeDashboardCard
          title="Events Calendar"
          message="View all upcoming events in a calendar view."
          target="trainee-schedule?view=calendar"
          shortName="calendar"
          faIcon={faCalendarAlt}
        />
        <TraineeDashboardCard
          title="Availability"
          message="View, add, or edit your available time slots."
          target="trainee-availability"
          shortName="availability"
          faIcon={faUserClock}
        />
        <TraineeDashboardCard
          title="Tasks"
          message="View and complete your tasks."
          target="trainee-tasks"
          shortName="tasks"
          faIcon={faTasks}
        />
        <TraineeDashboardCard
          title="My Documents"
          message="View, upload, or delete your documents."
          target="trainee-documents"
          shortName="My Documents"
          faIcon={faFileAlt}
        />
        <TraineeDashboardCard
          title="Profile"
          message="View or edit your phone number and information."
          target="trainee-user-profile"
          shortName="Profile"
          faIcon={faUser}
        />
      </div>
      <br />
    </TraineeLayout>
  )
}

export default TraineeHome
