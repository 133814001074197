import React from "react"

import "../styles/layout.css"
import Logo from "../images/logo.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faClock,
  faCog,
  faFileAlt,
  faIdCard,
  faTasks,
} from "@fortawesome/free-solid-svg-icons"

import { Link, NavLink } from "react-router-dom"

// Redux
import { useDispatch } from "react-redux"
import { logout } from "../redux/userSlice"

const HeaderListItem = ({ target, faIcon, description }) => (
  <li className="nav-item">
    <NavLink className="nav-link" to={"/" + target}>
      <span style={{ fontSize: "1rem" }}>
        <FontAwesomeIcon icon={faIcon} className="header-icon" /> {description}
      </span>
    </NavLink>
  </li>
)

const TraineeHeader = () => {
  const dispatch = useDispatch()
  return (
    <nav
      className="navbar navbar-expand-lg navbar-dark"
      style={{ backgroundColor: "royalblue" }}
    >
      <Link
        to="/trainee-home"
        style={{ outline: "none", paddingLeft: "1rem", paddingRight: "1rem" }}
      >
        <img
          src={Logo}
          width={120}
          height={40}
          className="d-inline-block align-top"
          alt=""
          loading="lazy"
        />
      </Link>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav">
          <HeaderListItem
            target="trainee-schedule"
            faIcon={faIdCard}
            description="Events"
          />
          <HeaderListItem
            target="trainee-availability"
            faIcon={faClock}
            description="Availability"
          />
          <HeaderListItem
            target="trainee-tasks"
            faIcon={faTasks}
            description="Task"
          />
          <HeaderListItem
            target="trainee-documents"
            faIcon={faFileAlt}
            description="Documents"
          />
          <HeaderListItem
            target="trainee-user-profile"
            faIcon={faCog}
            description="Settings"
          />
          <li className="nav-item">
            <span
              className="nav-link"
              style={{ fontSize: "1rem" }}
              onClick={() => dispatch(logout())}
            >
              Logout
            </span>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default TraineeHeader
