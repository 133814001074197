import React, { useEffect, useState, useLayoutEffect, useRef } from "react"
import { Link, useNavigate } from "react-router-dom"
import useIsomorphicLayoutEffect from "use-isomorphic-layout-effect"

import DefaultLayout from "../../layouts/defaultLayout"
import LoadingDashboard from "../../components/loadingDashboard"
import ProgressBar from "../../components/progressBar"

import SmallLoader from "../../components/smallLoader"
import StatusEnum from "../../util/StatusEnum"

import ReadinessAnalysisTab from "./baseOverview/readinessAnalysis"

import axios from "axios"
import { Modal } from "react-responsive-modal"
import { modalStyles } from "../../styles/constanStyles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPlaneDeparture,
  faCopy,
  faCheckCircle,
  faTimesCircle,
  faSort,
  faBell,
  faCommentDots,
  faExclamationTriangle,
  faPhone,
  faTimes,
  faCheck,
  faArrowUp,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons"

import { OrgChart } from "d3-org-chart"
import { renderToString } from "react-dom/server"
import { select } from "d3"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { setTeam, setErrors } from "../../redux/userSlice"
import PhoneInput from "react-phone-number-input"

// Helper components
const CopyContent = (props) => {
  return (
    <>
      <button
        className="btn btn-primary"
        onClick={() => navigator.clipboard.writeText(props.text)}
      >
        <strong>{props.text}</strong> <FontAwesomeIcon icon={faCopy} />
      </button>
    </>
  )
}

const ShopSummary = ({ data, changeTeam }) => {
  const [large, setLarge] = useState(true)

  return (
    <div
      className="col-xl-3 col-md-6 mb-4"
      // onClick={() => setLarge((prev) => !prev)}
    >
      <div className="card shadow h-200">
        <div className="card-body">
          <p className="text_lg font-weight-bold mb-1">
            {data.title.toUpperCase()}{" "}
          </p>
          <Link
            className="default-button"
            style={{ fontSize: "0.5rem", float: "right", marginTop: 0 }}
            to="/home"
            onClick={() => {
              localStorage.setItem("Team", data.code)
              changeTeam(data.code)
            }}
          >
            <FontAwesomeIcon icon={faPlaneDeparture} />
          </Link>
          <ProgressBar
            percent={data.percent_complete}
            color={
              data.percent_complete === 100
                ? "var(--green)"
                : data.percent_complete > 87
                  ? "var(--yellow)"
                  : "var(--red)"
            }
          />
          {large && (
            <div className="mt-1">
              Trainee Count: {data.trainees.length} <br />
              {/* Total Tasks: {data.total_tasks} <br />
              Completed Tasks: {data.satisfied_tasks} <br /> */}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const SimpleShop = ({ data, selectedShops, setSelectedShops }) => {
  const [hovering, setHovering] = useState(false)
  const [traineeHover, setTraineeHover] = useState(false)
  const [showTrainees, setShowTrainees] = useState(false)

  const handleClick = (e) => {
    e.preventDefault()
    if (!selectedShops.some((s) => s.supervisorCode === data.supervisorCode)) {
      setSelectedShops((prev) => [
        ...prev.filter((s) => s.supervisorCode !== data.supervisorCode),
        { supervisorCode: data.supervisorCode, title: data.title },
      ])
    } else {
      setSelectedShops(
        selectedShops.filter((s) => s.supervisorCode !== data.supervisorCode)
      )
    }
  }

  return (
    <div className="col-xl-3 col-md-6 mb-4">
      <div
        className="card shadow h-200"
        style={{
          border: selectedShops.some(
            (s) => s.supervisorCode === data.supervisorCode
          )
            ? "1px solid red"
            : hovering
              ? "1px solid orange"
              : null,
          cursor: "pointer",
        }}
      >
        <div
          className="card-body"
          style={{ textAlign: "center" }}
          onMouseOver={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
          onClick={handleClick}
        >
          <p className="header-text" style={{ fontSize: 12 }}>
            {data.title.toUpperCase()} <br />
          </p>
        </div>
        <div
          style={{
            textAlign: "center",
            color: "white",
            backgroundColor: traineeHover
              ? "rgb(100, 166, 234)"
              : "cornflowerblue",
            borderTop: "2px solid gray",
            borderRadius: "0 0 5px 5px",
          }}
          onClick={() => setShowTrainees((prev) => !prev)}
          onMouseOver={() => setTraineeHover(true)}
          onMouseLeave={() => setTraineeHover(false)}
        >
          Trainees: {data.trainees.length}
          {showTrainees && (
            <div>
              <ul>
                {data.trainees.map((trainee) => (
                  <li>{trainee.formatted_name}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const ShopsDropdown = ({ shops, selectedShop, setSelectedShop }) => {
  const handleShopChange = (code) => {
    if (code === "") {
      setSelectedShop({})
    } else {
      setSelectedShop(shops.filter((s) => s.code === code)[0])
    }
  }
  return (
    <div style={{}} className="d-flex">
      <span style={{ marginTop: "0.5rem" }}>
        <strong> Shop:</strong>
      </span>

      <span style={{ marginLeft: "0.5rem" }}>
        <select
          onChange={(e) => handleShopChange(e.target.value)}
          className="custom-select"
        >
          <option value="" key="All Shops">
            All Shops
          </option>
          {shops.map((shop) => {
            return (
              <option value={shop.code} key={shop.code}>
                {shop.title}
              </option>
            )
          })}
        </select>
      </span>
    </div>
  )
}

const TraineeRow = ({ trainee }) => {
  const [showTraineeDetails, setShowTraineeDetails] = useState(false)
  const style = {}

  const dispatch = useDispatch()

  return (
    <tr
      key={trainee.id}
      style={style}
      onClick={() => setShowTraineeDetails((prev) => !prev)}
    >
      <td>
        <Link
          to={`/trainee/profile/${trainee.id}`}
          style={{ textDecoration: "none" }}
          // Change current shop to trainee's shop when clicked
          onClick={() => dispatch(setTeam(trainee.shop.code))}
        >
          {trainee.formatted_name}
        </Link>
      </td>
      <td>{trainee.shop.title}</td>
      <td>{trainee.title}</td>
      <td>{trainee.phoneNumber}</td>
      <td>
        {trainee.isVerified && (
          <FontAwesomeIcon
            icon={faCheckCircle}
            style={{ color: "var(--green)" }}
          />
        )}
        {trainee.phoneNumber && !trainee.isVerified && (
          <FontAwesomeIcon
            icon={faTimesCircle}
            style={{ color: "var(--red)" }}
          />
        )}
      </td>
    </tr>
  )
}

const TraineeManager = (props) => {
  const [trainees, setTrainees] = useState([])
  const [traineeCount, setTraineeCount] = useState(0)
  const [next, setNext] = useState()
  const [previous, setPrevious] = useState()
  const [search, setSearch] = useState("")
  const [shops, setShops] = useState([])
  const [selectedShop, setSelectedShop] = useState({})
  const [ordering, setOrdering] = useState("lastName")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    axios
      .get(`/shop/shops/`)
      .then((res) => {
        setShops(res.data)
      })
      .catch((err) => {
        console.error(err.message)
      })
  }, [])

  useEffect(() => {
    setLoading(true)
    axios
      .get(
        `/trainees/?limit=30&offset=0&ordering=${ordering}&search=${search}&shop=${
          selectedShop.code ? selectedShop.code : ""
        }`
      )
      .then((res) => {
        setTrainees(res.data.results)
        setTraineeCount(res.data.count)
        if (res.data.next && res.data.next.startsWith("http:")) {
          setNext(res.data.next.replace("http:", "https:"))
        } else {
          setNext(res.data.next)
        }
        if (res.data.previous && res.data.previous.startsWith("http:")) {
          setPrevious(res.data.previous.replace("http:", "https:"))
        } else {
          setPrevious(res.data.previous)
        }
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }, [ordering, selectedShop])

  const handleSearchSubmit = (e) => {
    e.preventDefault()
    setLoading(true)

    axios
      .get(
        `/trainees/?limit=30&offset=0&ordering=${ordering}&search=${search}&shop=${
          selectedShop.code ? selectedShop.code : ""
        }`
      )
      .then((res) => {
        setTrainees(res.data.results)
        setTraineeCount(res.data.count)
        if (res.data.next.startsWith("http:")) {
          setNext(res.data.next.replace("http:", "https:"))
        } else {
          setNext(res.data.next)
        }
        if (res.data.previous.startsWith("http:")) {
          setPrevious(res.data.previous.replace("http:", "https:"))
        } else {
          setPrevious(res.data.previous)
        }
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  const handleShopHeaderClick = (e) => {
    e.preventDefault()
    if (ordering === "shop") {
      setOrdering("-shop")
    } else {
      setOrdering("shop")
    }
  }

  const handleNameHeaderClick = (e) => {
    e.preventDefault()
    if (ordering === "lastName") {
      setOrdering("-lastName")
    } else {
      setOrdering("lastName")
    }
  }

  const handleClickedNext = (e) => {
    e.preventDefault()
    setLoading(true)
    axios
      .get(next)
      .then((res) => {
        setTrainees(res.data.results)
        setTraineeCount(res.data.count)
        if (res.data.next && res.data.next.startsWith("http:")) {
          setNext(res.data.next.replace("http:", "https:"))
        } else {
          setNext(res.data.next)
        }
        if (res.data.previous && res.data.previous.startsWith("http:")) {
          setPrevious(res.data.previous.replace("http:", "https:"))
        } else {
          setPrevious(res.data.previous)
        }
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0
  }

  const handleClickedPrevious = (e) => {
    e.preventDefault()
    setLoading(true)
    axios
      .get(previous)
      .then((res) => {
        setTrainees(res.data.results)
        setTraineeCount(res.data.count)
        if (res.data.next && res.data.next.startsWith("http:")) {
          setNext(res.data.next.replace("http:", "https:"))
        } else {
          setNext(res.data.next)
        }
        if (res.data.previous && res.data.previous.startsWith("http:")) {
          setPrevious(res.data.previous.replace("http:", "https:"))
        } else {
          setPrevious(res.data.previous)
        }
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
    setLoading(false)
    document.body.scrollTop = 0 // For Safari?
    document.documentElement.scrollTop = 0 // For other browsers
  }

  return (
    <div className="col-xl-12 col-lg-7 mt-2">
      <div className="container-fluid fade-in">
        <div className="row">
          <div className="search-wrapper" style={{ marginBottom: "1rem" }}>
            <form onSubmit={(e) => handleSearchSubmit(e)}>
              <label htmlFor="search-form">
                <input
                  type="search"
                  name="search-form"
                  id="search-form"
                  className="search-input textInput form-control"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </label>
              <button className="default-button" type="submit">
                Search
              </button>
            </form>
            <div>Trainees: {traineeCount}</div>
            <ShopsDropdown
              shops={shops}
              selectedShop={selectedShop}
              setSelectedShop={setSelectedShop}
            />
            {loading ? (
              <SmallLoader />
            ) : (
              <table
                className="table mt-3"
                style={{
                  backgroundColor: "#eaecf4",
                }}
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col" onClick={handleNameHeaderClick}>
                      Name <FontAwesomeIcon icon={faSort} />
                    </th>
                    <th scope="col" onClick={handleShopHeaderClick}>
                      Shop <FontAwesomeIcon icon={faSort} />
                    </th>
                    <th scope="col">Title</th>
                    <th scope="col">Phone Number</th>
                    <th scope="col">Verified</th>
                  </tr>
                </thead>

                <tbody>
                  {trainees.map((trainee) => {
                    return <TraineeRow trainee={trainee} />
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-auto mb-3 floatRight">
            <button
              className="btn btn-secondary"
              disabled={previous === null}
              onClick={handleClickedPrevious}
            >
              Previous
            </button>
          </div>
          <div className="col-lg-auto mb-3">
            <button
              className="btn btn-secondary"
              disabled={next === null}
              onClick={handleClickedNext}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const ShopsNotificationsTraineeRow = ({
  trainee,
  selectedTrainees,
  setSelectedTrainees,
  handlePhoneModalOpen,
  setPhoneModalTrainee,
  phoneEditState,
  getTrainees,
}) => {
  const [newPhoneNumber, setNewPhoneNumber] = useState(trainee.phoneNumber)

  useEffect(() => {
    // Patch phone number changes if changes detected from original and submit button pushed
    if (phoneEditState === "submit") {
      // Changes detected
      if (newPhoneNumber !== trainee.phoneNumber) {
        if (newPhoneNumber) {
          const body = {
            id: trainee.id,
            phoneNumber: newPhoneNumber,
            isVerified: false,
          }
          axios
            .patch("/edit-trainee", body)
            .then(() => {
              getTrainees()
            })
            .catch((err) => {
              console.log(err)
            })
        }

        // Change to blank phone number if empty
        else {
          const body = {
            id: trainee.id,
            phoneNumber: "",
            isVerified: false,
          }

          axios
            .patch("/edit-trainee", body)
            .then((res) => {
              getTrainees()
            })
            .catch((err) => {
              console.log(err)
            })
        }
      }
    }

    // Reset input values if cancel is pressed
    if (phoneEditState === "cancel") {
      setNewPhoneNumber(trainee.phoneNumber)
    }
  }, [phoneEditState])

  const handleClick = (e) => {
    // If trainee doesn't have phone number, prompt user to input wiht modal
    if (!trainee.phoneNumber) {
      setPhoneModalTrainee(trainee)
      handlePhoneModalOpen()
    } else {
      if (e.target.checked) {
        setSelectedTrainees((prev) => [...prev, trainee.id])
      } else {
        setSelectedTrainees((prev) => prev.filter((t) => t !== trainee.id))
      }
    }
  }

  const handleNoPhoneNumberClick = () => {
    setPhoneModalTrainee(trainee)
    handlePhoneModalOpen()
  }

  return (
    <tr key={trainee.id}>
      <td>
        {trainee.phoneNumber ? (
          <input
            type="checkbox"
            style={{ cursor: "pointer" }}
            checked={selectedTrainees.includes(trainee.id)}
            onChange={handleClick}
          />
        ) : (
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            style={{ cursor: "pointer", color: "var(--red)" }}
            onClick={() => handleNoPhoneNumberClick()}
          />
        )}
      </td>
      <td>{trainee.formatted_name}</td>
      <td>{trainee.title}</td>
      <td>
        {phoneEditState === "edit" ? (
          <div>
            <PhoneInput
              style={{}}
              value={newPhoneNumber}
              onChange={setNewPhoneNumber}
              defaultCountry="US"
            />
          </div>
        ) : trainee.phoneNumber ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {trainee.phoneNumber}{" "}
          </div>
        ) : (
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            style={{ cursor: "pointer", color: "var(--red)" }}
            onClick={() => handleNoPhoneNumberClick()}
          />
        )}
      </td>
      <td>
        {trainee.isVerified && (
          <FontAwesomeIcon
            icon={faCheckCircle}
            style={{ color: "var(--green)" }}
          />
        )}
        {trainee.phoneNumber && !trainee.isVerified && (
          <FontAwesomeIcon
            icon={faTimesCircle}
            style={{ color: "var(--red)" }}
          />
        )}
      </td>
    </tr>
  )
}

const ShopsNotificationsButtonsCard = ({ selectedTrainees, user }) => {
  const [loadingNotify, setLoadingNotify] = useState(false)

  // Modal States
  const [verificationOpen, setVerificationOpen] = useState(false)
  const [actionOpen, setActionOpen] = useState(false)
  const [customOpen, setCustomOpen] = useState(false)

  const [modalError, setModalError] = useState("")

  const [customMessage, setCustomMessage] = useState("")

  // Send verfication text
  const sendVerifcation = (e) => {
    e.preventDefault()
    setLoadingNotify(true)
    const body = {
      trainees: selectedTrainees,
      type: "verification",
    }
    axios
      .post("/notify-trainees", body)
      .then((res) => {
        setVerificationOpen(false)
        setLoadingNotify(false)
      })
      .catch((err) => {
        console.log(err)
        setLoadingNotify(false)
        setModalError(
          "An unexpected error has occurred, please try again later"
        )
      })
  }

  // Send action reminder text
  const sendActionReminder = (e) => {
    e.preventDefault()
    setLoadingNotify(true)
    const body = {
      trainees: selectedTrainees,
      type: "action",
    }
    axios
      .post("/notify-trainees", body)
      .then((res) => {
        setActionOpen(false)
        setLoadingNotify(false)
      })
      .catch((err) => {
        setModalError(
          "An unexpected error has occurred, please try again later"
        )
        console.log(err)
        setLoadingNotify(false)
      })
  }

  // Send custom text
  const sendCustomMessage = (e) => {
    e.preventDefault()

    if (!customMessage || customMessage.length === 0) {
      setModalError("Please enter a message")
      return
    }
    if (customMessage.length > 450) {
      setModalError(
        "Please enter a message containing less than 450 characters"
      )
      return
    }

    setModalError("")
    setLoadingNotify(true)
    const body = {
      trainees: selectedTrainees,
      type: "custom",
      message: customMessage,
    }
    axios
      .post("/notify-trainees", body)
      .then((res) => {
        handleCustomClose()
        setLoadingNotify(false)
      })
      .catch((err) => {
        setModalError(
          "An unexpected error has occurred, please try again later"
        )
        console.log(err)
        setLoadingNotify(false)
      })
  }

  const handleCustomClose = () => {
    setCustomOpen(false)
    setCustomMessage("")
  }

  return (
    <div className="col-sm-6">
      <div className="card" style={{ padding: "1rem", height: "100%" }}>
        <h5>Notify Trainees via SMS</h5>
        <div
          className="d-flex flex-row align-items-center justify-content-between"
          style={{ fontSize: "1.1rem" }}
        >
          <button
            className={
              user.uta && user.uta.active
                ? "default-button"
                : "btn btn-secondary"
            }
            disabled={!user.uta || !user.uta.active}
            onClick={() => setVerificationOpen(true)}
          >
            <FontAwesomeIcon icon={faCheckCircle} /> Send Verification Text
          </button>
          <button
            className={
              user.uta && user.uta.active
                ? "default-button"
                : "btn btn-secondary"
            }
            disabled={!user.uta || !user.uta.active}
            onClick={() => setActionOpen(true)}
          >
            <FontAwesomeIcon icon={faBell} /> Send Action Reminder
          </button>
          <button
            className={
              user.uta && user.uta.active
                ? "default-button"
                : "btn btn-secondary"
            }
            disabled={!user.uta || !user.uta.active}
            onClick={() => {
              setModalError("")
              setCustomOpen(true)
            }}
          >
            <FontAwesomeIcon icon={faCommentDots} /> Send Custom Message
          </button>
        </div>
        <p style={{ fontSize: "1.1rem" }}>
          This will send a SMS text message to all selected trainees. They will
          receive an automated message from +1 (567) 459-5369. For a detailed
          view of a specific trainee, navigate to said trainee's card view on
          the Dashboard. We recommend you send a verification text first in
          order to confirm that they can receive texts from us.
        </p>
      </div>
      {/* Modals */}
      {/* Verification Modal */}
      <Modal
        styles={modalStyles}
        open={verificationOpen}
        onClose={() => setVerificationOpen(false)}
        center
      >
        <h5 className="modal-title">Verification Text</h5>
        <hr />
        <p className="modal-subtitle">
          WARNING: This will send verification texts to{" "}
          <b>{selectedTrainees.length} selected trainees</b>. They will be asked
          to respond to the text in order to verify their phone number. Do you
          wish to proceed?
        </p>
        {modalError && (
          <div className="h6 text-center text-danger">{modalError}</div>
        )}
        <div className="modal-button-container">
          <button
            className="default-button default-button-red"
            onClick={() => setVerificationOpen(false)}
            disabled={loadingNotify}
          >
            Cancel
          </button>
          <button
            className="default-button"
            disabled={loadingNotify}
            onClick={sendVerifcation}
          >
            Send
          </button>
        </div>
      </Modal>

      {/* Action Modal */}
      <Modal
        styles={modalStyles}
        open={actionOpen}
        onClose={() => setActionOpen(false)}
        center
      >
        <h5 className="modal-title">Action Reminder Text</h5>
        <hr />
        <p className="modal-subtitle">
          WARNING: This will notify{" "}
          <b>{selectedTrainees.length} selected trainees</b>
          of their incomplete tasks. They may then reply and automatically
          update the system database. Recorded in the Finished Task Log. Do you
          wish to proceed?
        </p>
        {modalError && (
          <div className="h6 text-center text-danger">{modalError}</div>
        )}
        <div className="modal-button-container">
          <button
            className="default-button default-button-red"
            onClick={() => setActionOpen(false)}
            disabled={loadingNotify}
          >
            Cancel
          </button>
          <button
            className="default-button"
            disabled={loadingNotify}
            onClick={sendActionReminder}
          >
            Send
          </button>
        </div>
      </Modal>

      {/* Custom Message Modal */}
      <Modal
        styles={modalStyles}
        open={customOpen}
        onClose={() => handleCustomClose()}
        center
      >
        <h5 className="modal-title">Custom Message</h5>
        <hr />
        <textarea
          rows={4}
          style={{ width: "100%", padding: "0.5rem" }}
          value={customMessage}
          onChange={(e) => setCustomMessage(e.target.value)}
          maxLength={450}
        />
        <p
          style={{
            float: "right",
            marginTop: "-5px",
            fontSize: "0.70rem",
            color: "gray",
            marginBottom: 0,
          }}
        >
          {customMessage.length}/450
        </p>
        <p className="modal-subtitle" style={{ marginTop: "10px" }}>
          WARNING: This will notify{" "}
          <b>{selectedTrainees.length} selected trainees</b> with your custom
          message. Do you wish to proceed?
        </p>
        {modalError && (
          <div className="h6 text-center text-danger">{modalError}</div>
        )}
        <div className="modal-button-container">
          <button
            className="default-button default-button-red"
            onClick={() => handleCustomClose()}
            disabled={loadingNotify}
          >
            Cancel
          </button>
          <button
            className="default-button"
            disabled={loadingNotify}
            onClick={sendCustomMessage}
          >
            Send
          </button>
        </div>
      </Modal>
    </div>
  )
}

const ShopsNotificationsSelectedTraineesCard = ({
  selectedTrainees,
  trainees,
}) => {
  return (
    <div className="col-sm-6">
      <div className="card" style={{ padding: "1rem", height: "100%" }}>
        <h5>Selected Trainees</h5>
        <div style={{ fontSize: "1.1rem" }}>
          <p>
            <b>{selectedTrainees.length} selected trainee(s)</b>
          </p>
          <div
            style={{
              overflow: "auto",
              maxHeight: "300px",
              fontSize: "1rem",
            }}
          >
            {selectedTrainees.length > 0 && (
              <table
                className="table mt-3"
                style={{
                  backgroundColor: "#eaecf4",
                  width: "100%",
                }}
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Shop</th>
                  </tr>
                </thead>
                <tbody>
                  {trainees.map((trainee) => {
                    if (selectedTrainees.includes(trainee.id)) {
                      return (
                        <tr key={trainee.id}>
                          <td>{trainee.formatted_name}</td>
                          <td>{trainee.shop.title}</td>
                        </tr>
                      )
                    }
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const ShopsNotifications = ({ user }) => {
  const dispatch = useDispatch()

  const [trainees, setTrainees] = useState([])
  const [teams, setTeams] = useState([])
  const [teamView, setTeamView] = useState("")

  const [selectedTrainees, setSelectedTrainees] = useState([])

  const [loadingTrainees, setLoadingTrainees] = useState(true)

  // State for modal prompting user to change phone number
  const [phoneModalOpen, setPhoneModalOpen] = useState(false)
  const [phoneModalTrainee, setPhoneModalTrainee] = useState({})

  // State for list of all ids in event of select all click
  const [allTraineeIds, setAllTraineeIds] = useState([])

  // New phone numbers
  const [newPhoneNumber, setNewPhoneNumber] = useState("")
  const [modalLoading, setModalLoading] = useState(false)
  const [modalError, setModalError] = useState("")

  // Search function state
  const [q, setQ] = useState("")
  const searchParam = ["formatted_name", "title"]

  // Editing phone numbers
  const [phoneEditState, setPhoneEditState] = useState("idle")

  // Get trainees
  useEffect(() => {
    axios
      .get(`/trainees/?ordering=lastName`)
      .then((res) => {
        setTrainees(res.data)
        setLoadingTrainees(false)
        // Loop through all trainees to create list of all ids with phone numbers
        res.data.forEach((t) => {
          if (t.phoneNumber) {
            setAllTraineeIds((prev) => [...prev, t.id])
          }
        })
      })
      .catch((err) => {
        console.log(err)
        dispatch(
          setErrors("An unexpected error occurred, please refresh the page")
        )
      })
  }, [])

  useEffect(() => {
    if (user.teams) {
      setTeams(user.teams)
    }
    if (user.currentFocusedTeam) {
      setTeamView(user.currentFocusedTeam)
    }
  }, [user])

  const selectAllTrainees = () => {
    setSelectedTrainees(allTraineeIds)
  }

  const clearAllTrainees = () => {
    setSelectedTrainees([])
  }

  const handlePhoneModalOpen = () => {
    setModalError("")
    setModalLoading(false)
    setPhoneModalOpen(true)
  }

  const selectAllInCurrentShop = () => {
    trainees.forEach((t) => {
      if (
        t.shop.code === teamView &&
        t.phoneNumber &&
        !selectedTrainees.includes(t.id)
      ) {
        setSelectedTrainees((prev) => [...prev, t.id])
      }
    })
  }

  const clearAllInCurrentShop = () => {
    trainees.forEach((t) => {
      if (t.shop.code === teamView) {
        setSelectedTrainees((prev) =>
          prev.filter((selectedTrainee) => selectedTrainee !== t.id)
        )
      }
    })
  }

  const getTrainees = () => {
    setTrainees([])
    setLoadingTrainees(true)
    axios
      .get(`/trainees/?ordering=lastName`)
      .then((res) => {
        setTrainees(res.data)
        setLoadingTrainees(false)
        // Loop through all trainees to create list of all ids with phone numbers
        res.data.forEach((t) => {
          if (t.phoneNumber) {
            setAllTraineeIds((prev) => [...prev, t.id])
          }
        })
      })
      .catch((err) => {
        console.log(err)
        dispatch(
          setErrors("An unexpected error occurred, please refresh the page")
        )
      })
  }

  const handleNewPhoneNumberSubmit = (e) => {
    e.preventDefault()
    setModalLoading(true)

    const body = {
      id: phoneModalTrainee.id,
      phoneNumber: newPhoneNumber,
      isVerified: false,
    }

    axios
      .patch("/edit-trainee", body)
      .then(() => {
        setModalLoading(false)
        setPhoneModalOpen(false)
        getTrainees()
      })
      .catch((err) => {
        console.log(err)
        setModalLoading(false)
        setModalError("An unexpected error has happened, please try again")
      })
  }

  // Search function
  function search(items) {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        if (item[newItem]) {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          )
        } else {
          return false
        }
      })
    })
  }

  if (loadingTrainees) {
    return <SmallLoader />
  }

  return (
    <div className="col-xl-12 col-lg-7 mt-2">
      <div className="container-fluid fade-in">
        <div className="row">
          <ShopsNotificationsButtonsCard
            selectedTrainees={selectedTrainees}
            user={user}
          />
          <ShopsNotificationsSelectedTraineesCard
            selectedTrainees={selectedTrainees}
            trainees={trainees}
          />
        </div>
        <div className="row">
          <div style={{ margin: "0.5rem 0" }}>
            <button className="default-button" onClick={selectAllTrainees}>
              Select all trainees
            </button>
            <button
              className="default-button default-button-gray"
              onClick={clearAllTrainees}
            >
              Clear all trainees
            </button>
          </div>
          <span>
            <select
              className="custom-select"
              onChange={(e) => {
                setTeamView(e.target.value)
                setPhoneEditState("cancel")
              }}
              value={teamView}
              style={{ marginLeft: "0.5rem" }}
            >
              <option value="">Select a shop</option>
              {teams.map((team) => {
                return (
                  <option key={team.code} value={team.code}>
                    {team.title}
                  </option>
                )
              })}
            </select>
          </span>
          <div>
            {teamView === "" ? (
              <div>
                <h4 style={{ marginTop: "1rem" }}>Please select a shop</h4>
              </div>
            ) : (
              <>
                <div style={{ margin: "0.5rem 0" }}>
                  <button
                    className="default-button"
                    onClick={selectAllInCurrentShop}
                  >
                    Select all in current shop
                  </button>
                  <button
                    className="default-button default-button-gray"
                    onClick={clearAllInCurrentShop}
                  >
                    Clear all in current shop
                  </button>
                </div>
                <div className="search-wrapper">
                  <label htmlFor="search-form">
                    <input
                      type="search"
                      name="search-form"
                      id="search-form"
                      className="search-input textInput form-control"
                      placeholder="Search for..."
                      value={q}
                      onChange={(e) => setQ(e.target.value)}
                    />
                    <span className="sr-only">Search trainees here</span>
                  </label>
                </div>
                <br />
                {phoneEditState !== "edit" ? (
                  <button
                    className="default-button"
                    onClick={() => setPhoneEditState("edit")}
                  >
                    <FontAwesomeIcon icon={faPhone} /> Edit Phone Numbers
                  </button>
                ) : (
                  <>
                    <button
                      className="default-button default-button-red"
                      onClick={() => setPhoneEditState("cancel")}
                      style={{ marginRight: "0.5rem" }}
                    >
                      <FontAwesomeIcon icon={faTimes} /> Cancel
                    </button>
                    <button
                      className="default-button"
                      onClick={() => setPhoneEditState("submit")}
                    >
                      <FontAwesomeIcon icon={faCheck} /> Save
                    </button>
                  </>
                )}
                <table
                  className="table mt-3"
                  style={{
                    backgroundColor: "#eaecf4",
                  }}
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Name</th>
                      <th scope="col">Title</th>
                      <th scope="col">Phone Number</th>
                      <th scope="col">Verified</th>
                    </tr>
                  </thead>

                  <tbody>
                    {search(trainees).map((trainee) => {
                      if (trainee.shop.code === teamView) {
                        return (
                          <ShopsNotificationsTraineeRow
                            trainee={trainee}
                            selectedTrainees={selectedTrainees}
                            setSelectedTrainees={setSelectedTrainees}
                            handlePhoneModalOpen={handlePhoneModalOpen}
                            setPhoneModalTrainee={setPhoneModalTrainee}
                            key={trainee.id}
                            getTrainees={getTrainees}
                            phoneEditState={phoneEditState}
                          />
                        )
                      }
                      return null
                    })}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>
      </div>
      {/* Modal prompting user to enter phone number */}
      <Modal
        styles={modalStyles}
        open={phoneModalOpen}
        onClose={() => {
          setPhoneModalOpen(false)
        }}
        center
        focusTrapped={false}
      >
        <h5 className="modal-title">No Phone Number</h5>
        <hr />
        <p className="modal-subtitle">
          <Link
            style={{ textDecoration: "none" }}
            to={`/trainee/profile/${phoneModalTrainee.id}`}
          >
            {phoneModalTrainee.formatted_name}{" "}
          </Link>
          currently has no phone number. Please add a phone number in order to
          send a notification.
        </p>
        <form onSubmit={handleNewPhoneNumberSubmit}>
          <PhoneInput
            value={newPhoneNumber}
            onChange={setNewPhoneNumber}
            defaultCountry="US"
          />
          <hr />
          {modalError.length > 0 && (
            <p className="h6 text-center text-danger">{modalError}</p>
          )}
          <div className="modal-button-container">
            <button
              className="default-button default-button-gray"
              onClick={() => setPhoneModalOpen(false)}
              disabled={modalLoading}
              type="reset"
            >
              Cancel
            </button>
            <button
              className="default-button"
              type="submit"
              disabled={modalLoading}
            >
              Confirm
            </button>
          </div>
        </form>
      </Modal>
    </div>
  )
}

const OrgChartTab = ({
  orgChartData,
  setMessagingModalOpen,
  setMessagingShop,
  setActionReminderModalOpen,
  orgChartDataStatus,
}) => {
  const d3Container = useRef(null)
  const [chart, _] = useState(new OrgChart())
  const history = useNavigate()
  const dispatch = useDispatch()

  useIsomorphicLayoutEffect(() => {
    if (
      d3Container.current &&
      chart.firstDraw() &&
      orgChartDataStatus != StatusEnum.error
    ) {
      chart
        .container(d3Container.current)
        .data(orgChartData)
        .nodeWidth((d) => 200)
        .nodeHeight((d) => 160)
        .onNodeClick((d, i, arr) => {
          // props.onNodeClick(d)
        })
        .nodeContent((d, i, arr) => {
          switch (d.data.nodeType) {
            case "institution":
              return renderToString(
                <div
                  className="org-chart-card shadow"
                  style={{ width: 200, height: 160, cursor: "default" }}
                >
                  <div
                    className="card-body"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexFlow: "row wrap",
                      alignItems: "center",
                    }}
                  >
                    <h6
                      style={{
                        textAlign: "center",
                        minWidth: "100%",
                        color: "var(--blue)",
                        cursor: "text",
                      }}
                    >
                      <b>{d.data.name}</b>
                    </h6>
                    <p
                      style={{
                        color: "var(--gray-300)",
                        cursor: "text",
                        margin: 0,
                      }}
                    >
                      Group
                    </p>
                  </div>
                  <div style={{ padding: "0 1rem 1rem 1rem", marginTop: 0 }}>
                    <ProgressBar
                      percent={d.data.percentComplete}
                      color={
                        d.data.percentComplete === 100
                          ? "var(--green)"
                          : d.data.percentComplete > 87
                            ? "var(--yellow)"
                            : "var(--red)"
                      }
                    />
                  </div>
                </div>
              )
            case "squadron":
              return renderToString(
                <div
                  className="org-chart-card shadow"
                  style={{ width: 200, height: 160, cursor: "default" }}
                >
                  <div
                    className="card-body"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexFlow: "row wrap",
                      alignItems: "center",
                    }}
                  >
                    <h6
                      style={{
                        textAlign: "center",
                        minWidth: "100%",
                        color: "var(--blue)",
                        cursor: "text",
                      }}
                    >
                      <b>{d.data.name}</b>
                    </h6>
                    <p
                      style={{
                        color: "var(--gray-300)",
                        cursor: "text",
                        margin: 0,
                      }}
                    >
                      Squadron ({d.data._directSubordinates} Shops)
                    </p>
                  </div>
                  <div style={{ padding: "0 1rem 1rem 1rem", marginTop: 0 }}>
                    <ProgressBar
                      percent={d.data.percentComplete}
                      color={
                        d.data.percentComplete === 100
                          ? "var(--green)"
                          : d.data.percentComplete > 87
                            ? "var(--yellow)"
                            : "var(--red)"
                      }
                    />
                  </div>
                </div>
              )
            case "shop":
              return renderToString(
                <div
                  className="org-chart-card shadow"
                  style={{ width: 200, height: 160, cursor: "default" }}
                >
                  <div
                    className="card-body"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexFlow: "row wrap",
                      alignItems: "center",
                    }}
                  >
                    <h6
                      style={{
                        textAlign: "center",
                        minWidth: "100%",
                        cursor: "pointer",
                      }}
                      className="hover-link-color shop-node-navigate"
                    >
                      <b>{d.data.name}</b>
                    </h6>
                    <p
                      style={{
                        color: "var(--gray-300)",
                        cursor: "text",
                        textAlign: "center",
                        minWidth: "100%",
                        margin: 0,
                      }}
                    >
                      Shop ({d.data._directSubordinates} Trainees)
                    </p>
                    {/* <div>
                      <div
                        className="shop-node-navigate"
                        style={{ margin: "0.5rem" }}
                      >
                        <FontAwesomeIcon
                          icon={faExternalLinkAlt}
                          style={{ fontSize: "1rem" }}
                          className="hover-link-color"
                        />
                      </div>
                    </div> */}
                    <div>
                      <div
                        className="shop-node-messaging"
                        style={{ margin: "0.5rem" }}
                      >
                        <FontAwesomeIcon
                          icon={faCommentDots}
                          style={{ fontSize: "1rem" }}
                          className="hover-link-color"
                        />
                      </div>
                    </div>
                    <div>
                      <div
                        className="shop-node-action"
                        style={{ margin: "0.5rem" }}
                      >
                        <FontAwesomeIcon
                          icon={faBell}
                          style={{ fontSize: "1rem" }}
                          className="hover-link-color"
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ padding: "0 1rem 1rem 1rem", marginTop: 0 }}>
                    <ProgressBar
                      percent={d.data.percentComplete}
                      color={
                        d.data.percentComplete === 100
                          ? "var(--green)"
                          : d.data.percentComplete > 87
                            ? "var(--yellow)"
                            : "var(--red)"
                      }
                    />
                  </div>
                </div>
              )
            case "trainee":
              return renderToString(
                <div
                  className="org-chart-card shadow"
                  style={{ width: 200, height: 160, cursor: "default" }}
                >
                  <div
                    className="card-body"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexFlow: "row wrap",
                      alignItems: "center",
                    }}
                  >
                    <h6
                      style={{
                        textAlign: "center",
                        minWidth: "100%",
                        color: "var(--blue)",
                        cursor: "pointer",
                      }}
                      className="hover-link-color trainee-node-navigate"
                    >
                      <b>{d.data.name}</b>
                    </h6>
                    <p
                      style={{
                        cursor: "text",
                        color: "var(--gray-300)",
                        minWidth: "100%",
                        textAlign: "center",
                        margin: 0,
                      }}
                    >
                      Trainee
                    </p>
                    {/* <div>
                      <div
                        onClick={() => {
                          history.push(`/trainee/profile/${d.data.pk}`)
                        }}
                        className="trainee-node-navigate"
                      >
                        <FontAwesomeIcon
                          icon={faExternalLinkAlt}
                          style={{ fontSize: "1rem" }}
                          className="hover-link-color"
                        />
                      </div>
                    </div> */}
                  </div>
                  <div style={{ padding: "0 1rem 1rem 1rem", marginTop: 0 }}>
                    <ProgressBar
                      percent={d.data.percentComplete}
                      color={
                        d.data.percentComplete === 100
                          ? "var(--green)"
                          : d.data.percentComplete > 87
                            ? "var(--yellow)"
                            : "var(--red)"
                      }
                    />
                  </div>
                </div>
              )
            default:
              break
          }
        })
        .nodeUpdate(function (d) {
          // Navigate to trainee profile icon
          select(this)
            .select(".trainee-node-navigate")
            .on("click", (e) => {
              history(`/trainee/profile/${d.data.pk}`)
            })
          // Navigate to shop
          select(this)
            .select(".shop-node-navigate")
            .on("click", (e) => {
              dispatch(setTeam(d.data.code))
              history(`/home`)
            })
          select(this)
            .select(".shop-node-messaging")
            .on("click", (e) => {
              setMessagingModalOpen(true)
              setMessagingShop(d.data)
            })
          select(this)
            .select(".shop-node-action")
            .on("click", (e) => {
              setActionReminderModalOpen(true)
              setMessagingShop(d.data)
            })
        })
        .render()
    }
  }, [d3Container.current])

  if (orgChartDataStatus === StatusEnum.error) {
    return (
      <div style={{ width: "100%", textAlign: "center", padding: "1rem" }}>
        <h5>An unexpected error has occurred, please try again</h5>
      </div>
    )
  }

  return (
    <div>
      <div ref={d3Container} />
    </div>
  )
}

// Shop comparator functions
const compShopByPercentComplete = (a, b) => {
  if (a.percent_complete === b.percent_complete) {
    return a.title > b.title ? 1 : a.title < b.title ? -1 : 0
  }

  return a.percent_complete > b.percent_complete ? 1 : -1
}
const compShopByTitle = (a, b) => {
  if (a.title === b.title) {
    return a.percent_complete > b.percent_complete
      ? 1
      : a.percent_complete < b.percent_complete
        ? -1
        : 0
  }

  return a.title > b.title ? 1 : -1
}

// Main component
const BaseOverview = () => {
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()

  const changeTeam = (code) => {
    dispatch(setTeam(code))
  }

  const [shopSummaries, setShopSummaries] = useState([])
  const [loading, setLoading] = useState(false)
  const [overviewView, setOverviewView] = useState("shop-summaries")
  const [selectedShops, setSelectedShops] = useState([])
  const [verificationOpen, setVerificationOpen] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [shareableCode, setShareableCode] = useState()
  const [createdNewShareCode, setCreatedNewShareCode] = useState(false)
  const [addingShopsShareCode, setAddingShopsShareCode] = useState(false)
  const [addedShops, setAddedShops] = useState([])
  const [shopsWereAdded, setShopsWereAdded] = useState(false)
  const [enteredCode, setEnteredCode] = useState("")
  const [selectedTrainees, setSelectedTrainees] = useState([])
  const [orgChartData, setOrgChartData] = useState({})
  const [orgChartDataStatus, setOrgChartDataStatus] = useState(
    StatusEnum.loading
  )
  const [sortShopsBy, setSortShopsBy] = useState("title")

  const [messagingShop, setMessagingShop] = useState({})
  const [messagingModalStatus, setMessagingModalStatus] = useState(
    StatusEnum.idle
  )
  const [messagingModalError, setMessagingModalError] = useState("")

  const [messagingModalOpen, setMessagingModalOpen] = useState(false)
  const [customMessage, setCustomMessage] = useState("")
  const [actionReminderModalOpen, setActionReminderModalOpen] = useState(false)

  // States for search functionality
  const [q, setQ] = useState("")
  const searchParam = ["title"]

  function search(items) {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        if (item[newItem]) {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          )
        } else {
          return false
        }
      })
    })
  }

  function createShareableCode(e) {
    e.preventDefault()
    setVerificationOpen(true)
  }

  function addShopsShareCode(e) {
    e.preventDefault()
    setAddingShopsShareCode(true)
  }

  function makeShareableCodeRequest(e) {
    e.preventDefault()
    // setModalError("")
    setVerificationOpen(false)
    setModalLoading(true)
    const body = {
      shops: selectedShops.map((s) => s.supervisorCode),
    }
    axios
      .post("/create-shopssharecode", body)
      .then((res) => {
        setShareableCode(res.data.code)
        setCreatedNewShareCode(true)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setModalLoading(false)
      })
  }

  function addShopsShareCodeRequest(e) {
    e.preventDefault()
    // setModalError("")
    setAddingShopsShareCode(false)
    setModalLoading(true)
    const body = {
      code: enteredCode,
    }
    axios
      .post("/add-shopssharecode", body)
      .then((res) => {
        setShopsWereAdded(true)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setModalLoading(false)
        setEnteredCode("")
      })
  }

  useEffect(() => {
    if (user.isInstitutionAdmin) {
      setOverviewView("readiness-analysis")
    }
  }, [user])

  useEffect(() => {
    if (user.status === "idle" || user.status === "success") {
      setLoading(true)

      axios
        .get(`/shop/shops/summary/`)
        .then((res) => {
          setShopSummaries(res.data)
          setLoading(false)
        })
        .catch((err) => {
          console.log(err)
          dispatch(
            setErrors([
              "An unexpected error has occurred, please reload the page",
            ])
          )
        })
    }

    // Get data for the metric page

    // Get data for org chart
    axios
      .get(`/get-group-org-chart`)
      .then((res) => {
        setOrgChartData(res.data)
        setOrgChartDataStatus(StatusEnum.success)
      })
      .catch((err) => {
        setOrgChartDataStatus(StatusEnum.error)
      })
  }, [])

  const handleShopsComparatorChange = (isName) => {
    if (isName) {
      if (Array.isArray(shopSummaries)) {
        setSortShopsBy("title")
        setShopSummaries((prev) => [...prev].sort(compShopByTitle))
      }
    } else {
      if (Array.isArray(shopSummaries)) {
        setSortShopsBy("percentComplete")
        setShopSummaries((prev) => [...prev].sort(compShopByPercentComplete))
      }
    }
  }

  const sendCustomMessage = (e) => {
    e.preventDefault()
    if (!customMessage || customMessage.length === 0) {
      setMessagingModalError("Please write a message")
      return
    }

    if (customMessage.length > 450) {
      setMessagingModalError(
        "Please enter a message containing less than 450 characters"
      )
      return
    }

    if (!messagingShop || !messagingShop.code) {
      setMessagingModalError(
        "An unexpected error has happened, please try again"
      )
    }

    setMessagingModalError("")
    setMessagingModalStatus(StatusEnum.loading)

    const body = {
      code: messagingShop.code,
      type: "custom",
      message: customMessage,
    }

    axios
      .post("/notify-trainees", body)
      .then(() => {
        setMessagingModalStatus(StatusEnum.success)
        setMessagingModalOpen(false)
        setCustomMessage("")
        setMessagingModalError("")
      })
      .catch((err) => {
        setMessagingModalStatus(StatusEnum.error)

        if (err.response.data === "No trainees were passed") {
          setMessagingModalError(
            "There are no trainees in this shop with phone numbers"
          )
        } else {
          setMessagingModalError(
            "An unexpected error has happened, please try again"
          )
        }
      })
  }

  const sendActionReminder = (e) => {
    e.preventDefault()
    if (!messagingShop || !messagingShop.code) {
      setMessagingModalError(
        "An unexpected error has happened, please try again"
      )
    }

    setMessagingModalError("")
    setMessagingModalStatus(StatusEnum.loading)

    const body = {
      code: messagingShop.code,
      type: "action",
    }

    axios
      .post("/notify-trainees", body)
      .then(() => {
        setMessagingModalStatus(StatusEnum.success)
        setActionReminderModalOpen(false)
      })
      .catch((err) => {
        setMessagingModalStatus(StatusEnum.error)
        if (err.response.data === "No trainees were passed") {
          setMessagingModalError(
            "There are no trainees in this shop with phone numbers"
          )
        } else {
          setMessagingModalError(
            "An unexpected error has happened, please try again"
          )
        }
      })
  }

  if (loading) {
    return <LoadingDashboard />
  }

  return (
    <DefaultLayout
      currentPage="My Teams"
      disableTeams={true}
      allowNoTeamSelection
    >
      <div>
        <div className="card shadow">
          <div
            className="card-header"
            style={{
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div className="nav">
              {user.isInstitutionAdmin && (
                <h4
                  className={
                    overviewView === "readiness-analysis"
                      ? "tab-link header-text tab-link-active"
                      : "tab-link header-text"
                  }
                  onClick={() => setOverviewView("readiness-analysis")}
                >
                  Readiness Analysis
                </h4>
              )}
              <h4
                className={
                  overviewView === "shop-summaries"
                    ? "tab-link header-text tab-link-active"
                    : "tab-link header-text"
                }
                onClick={() => setOverviewView("shop-summaries")}
              >
                Shop Summaries
              </h4>
              {/*<h4*/}
              {/*  className={*/}
              {/*    overviewView === "shop-manager"*/}
              {/*      ? "tab-link header-text tab-link-active"*/}
              {/*      : "tab-link header-text"*/}
              {/*  }*/}
              {/*  onClick={() => setOverviewView("shop-manager")}*/}
              {/*>*/}
              {/*  Shop Manager*/}
              {/*</h4>*/}
              <h4
                className={
                  overviewView === "trainee-manager"
                    ? "tab-link header-text tab-link-active"
                    : "tab-link header-text"
                }
                onClick={() => setOverviewView("trainee-manager")}
              >
                Trainee Manager
              </h4>
              <h4
                className={
                  overviewView === "shops-notifications"
                    ? "tab-link header-text tab-link-active"
                    : "tab-link header-text"
                }
                onClick={() => setOverviewView("shops-notifications")}
              >
                Shops Notifications
              </h4>
              {orgChartData && user.isInstitutionAdmin && (
                <h4
                  className={
                    overviewView === "org-chart"
                      ? "tab-link header-text tab-link-active"
                      : "tab-link header-text"
                  }
                  onClick={() => setOverviewView("org-chart")}
                >
                  Organization Chart
                </h4>
              )}
            </div>
          </div>
          {overviewView === "readiness-analysis" && <ReadinessAnalysisTab />}
          {overviewView === "shop-summaries" && (
            <div className="col-xl-12 col-lg-7 mt-2">
              <div className="container-fluid fade-in">
                <div className="row">
                  <div
                    className="search-wrapper"
                    style={{
                      marginBottom: "1rem",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <label htmlFor="search-form">
                      <input
                        type="search"
                        name="search-form"
                        id="search-form"
                        className="search-input textInput form-control"
                        placeholder="Search shops"
                        value={q}
                        onChange={(e) => setQ(e.target.value)}
                      />
                    </label>
                    <div>
                      <button
                        className={
                          sortShopsBy === "percentComplete"
                            ? "default-button default-button-active"
                            : "default-button"
                        }
                        style={{ margin: 0, marginLeft: "1rem" }}
                        onClick={() => handleShopsComparatorChange(false)}
                      >
                        Sort by Percent Complete
                      </button>
                      <button
                        className={
                          sortShopsBy === "title"
                            ? "default-button default-button-active"
                            : "default-button"
                        }
                        style={{ margin: 0, marginLeft: "1rem" }}
                        onClick={() => handleShopsComparatorChange(true)}
                      >
                        Sort by Title
                      </button>
                    </div>
                  </div>
                  {search(shopSummaries).map((shopSummary) => {
                    return (
                      <ShopSummary
                        data={shopSummary}
                        changeTeam={changeTeam}
                        key={shopSummary.code}
                      />
                    )
                  })}
                </div>
              </div>
            </div>
          )}
          {/*{overviewView === "shop-manager" && (*/}
          {/*  <div className="col-xl-12 col-lg-7 mt-2">*/}
          {/*    <div className="container-fluid fade-in">*/}
          {/*      <div className="row">*/}
          {/*        <div*/}
          {/*          className="search-wrapper"*/}
          {/*          style={{ marginBottom: "1rem" }}*/}
          {/*        >*/}
          {/*          <label htmlFor="search-form">*/}
          {/*            <input*/}
          {/*              type="search"*/}
          {/*              name="search-form"*/}
          {/*              id="search-form"*/}
          {/*              className="search-input textInput form-control"*/}
          {/*              placeholder="Search shops"*/}
          {/*              value={q}*/}
          {/*              onChange={(e) => setQ(e.target.value)}*/}
          {/*            />*/}
          {/*          </label>*/}
          {/*          <button*/}
          {/*            className="default-button float-left"*/}
          {/*            style={{ marginLeft: "1rem" }}*/}
          {/*            onClick={() => setSelectedShops([])}*/}
          {/*          >*/}
          {/*            Clear Selection*/}
          {/*          </button>*/}
          {/*          <button*/}
          {/*            className="default-button float-left"*/}
          {/*            style={{ marginLeft: "1rem" }}*/}
          {/*            onClick={() =>*/}
          {/*              setSelectedShops(*/}
          {/*                shopSummaries.map((s) => {*/}
          {/*                  return {*/}
          {/*                    supervisorCode: s.supervisorCode,*/}
          {/*                    title: s.title,*/}
          {/*                  }*/}
          {/*                })*/}
          {/*              )*/}
          {/*            }*/}
          {/*          >*/}
          {/*            Select All*/}
          {/*          </button>*/}
          {/*          <button*/}
          {/*            className="default-button float-left"*/}
          {/*            style={{ marginLeft: "1rem" }}*/}
          {/*            onClick={createShareableCode}*/}
          {/*          >*/}
          {/*            Create Shareable Code*/}
          {/*          </button>*/}
          {/*          <button*/}
          {/*            className="default-button float-left"*/}
          {/*            style={{ marginLeft: "1rem" }}*/}
          {/*            onClick={addShopsShareCode}*/}
          {/*          >*/}
          {/*            Add Shops from Shareable Code*/}
          {/*          </button>*/}
          {/*        </div>*/}
          {/*        {search(shopSummaries).map((shopSummary) => {*/}
          {/*          return (*/}
          {/*            <SimpleShop*/}
          {/*              data={shopSummary}*/}
          {/*              key={shopSummary.code}*/}
          {/*              selectedShops={selectedShops}*/}
          {/*              setSelectedShops={setSelectedShops}*/}
          {/*            />*/}
          {/*          )*/}
          {/*        })}*/}
          {/*        <Modal*/}
          {/*          styles={modalStyles}*/}
          {/*          open={shopsWereAdded}*/}
          {/*          onClose={() => {*/}
          {/*            setShopsWereAdded(false)*/}
          {/*            setSelectedShops([])*/}
          {/*            window.location.reload()*/}
          {/*          }}*/}
          {/*          center*/}
          {/*        >*/}
          {/*          The following shops have been added.*/}
          {/*          <ul>*/}
          {/*            {addedShops.map((shop_title) => {*/}
          {/*              return <li>{shop_title}</li>*/}
          {/*            })}*/}
          {/*          </ul>*/}
          {/*        </Modal>*/}
          {/*        <Modal*/}
          {/*          styles={modalStyles}*/}
          {/*          open={addingShopsShareCode}*/}
          {/*          onClose={() => {*/}
          {/*            setAddingShopsShareCode(false)*/}
          {/*            setSelectedShops([])*/}
          {/*          }}*/}
          {/*          center*/}
          {/*        >*/}
          {/*          <h5 className="modal-title">Create Shareable Code</h5>*/}
          {/*          <hr />*/}
          {/*          <p className="modal-subtitle">*/}
          {/*            Please enter your shops share code:*/}
          {/*            <input*/}
          {/*              name="shareableCode"*/}
          {/*              id="shareableCode"*/}
          {/*              className="textInput form-control"*/}
          {/*              placeholder="Enter code"*/}
          {/*              value={enteredCode}*/}
          {/*              onChange={(e) => setEnteredCode(e.target.value)}*/}
          {/*            />*/}
          {/*          </p>*/}
          {/*          <button*/}
          {/*            className="default-button float-left"*/}
          {/*            style={{ marginLeft: "1rem" }}*/}
          {/*            onClick={addShopsShareCodeRequest}*/}
          {/*            disabled={modalLoading}*/}
          {/*          >*/}
          {/*            Submit*/}
          {/*          </button>*/}
          {/*        </Modal>*/}
          {/*        <Modal*/}
          {/*          styles={modalStyles}*/}
          {/*          open={createdNewShareCode}*/}
          {/*          onClose={() => {*/}
          {/*            setVerificationOpen(false)*/}
          {/*            setCreatedNewShareCode(false)*/}
          {/*            setSelectedShops([])*/}
          {/*          }}*/}
          {/*          center*/}
          {/*        >*/}
          {/*          Shareable code successfully created:{" "}*/}
          {/*          <CopyContent text={shareableCode} />*/}
          {/*        </Modal>*/}
          {/*        <Modal*/}
          {/*          styles={modalStyles}*/}
          {/*          open={verificationOpen}*/}
          {/*          onClose={() => {*/}
          {/*            setVerificationOpen(false)*/}
          {/*            setSelectedShops([])*/}
          {/*          }}*/}
          {/*          center*/}
          {/*        >*/}
          {/*          <h5 className="modal-title">Create Shareable Code</h5>*/}
          {/*          <hr />*/}
          {/*          <p className="modal-subtitle">*/}
          {/*            You are creating a shareable code for the following shops:*/}
          {/*            <ul>*/}
          {/*              {selectedShops.map((s) => (*/}
          {/*                <li>{s.title}</li>*/}
          {/*              ))}*/}
          {/*            </ul>*/}
          {/*          </p>*/}
          {/*          <button*/}
          {/*            className="default-button float-left"*/}
          {/*            style={{ marginLeft: "1rem" }}*/}
          {/*            onClick={makeShareableCodeRequest}*/}
          {/*            disabled={modalLoading}*/}
          {/*          >*/}
          {/*            Confirm*/}
          {/*          </button>*/}
          {/*        </Modal>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*)}*/}
          {overviewView === "trainee-manager" && <TraineeManager />}
          {overviewView === "shops-notifications" && (
            <ShopsNotifications user={user} />
          )}
          {overviewView === "org-chart" && (
            <OrgChartTab
              orgChartData={orgChartData}
              orgChartDataStatus={orgChartDataStatus}
              setMessagingModalOpen={setMessagingModalOpen}
              setActionReminderModalOpen={setActionReminderModalOpen}
              setMessagingShop={setMessagingShop}
            />
          )}
          <div />
        </div>
      </div>
      {/* Org Chart Messaging Modal */}
      <Modal
        styles={modalStyles}
        center
        open={messagingModalOpen}
        onClose={() => {
          setMessagingModalOpen(false)
          setCustomMessage("")
          setMessagingModalStatus(StatusEnum.idle)
          setMessagingModalError("")
        }}
      >
        <h5 className="modal-title">
          Custom Message to <b>{messagingShop.name}</b>
        </h5>
        <hr />
        <textarea
          rows={4}
          style={{ width: "100%", padding: "0.5rem" }}
          value={customMessage}
          onChange={(e) => setCustomMessage(e.target.value)}
          maxLength={450}
        />
        <p
          style={{
            float: "right",
            marginTop: "-5px",
            fontSize: "0.70rem",
            color: "gray",
            marginBottom: 0,
          }}
        >
          {customMessage.length}/450
        </p>
        <p className="modal-subtitle" style={{ marginTop: "10px" }}>
          WARNING: This will notify all verified trainees in the shop{" "}
          <b>{messagingShop.name}</b> with your custom message.
        </p>
        {messagingModalError && (
          <div className="h6 text-center text-danger">
            {messagingModalError}
          </div>
        )}
        <div className="modal-button-container">
          <button
            className="default-button default-button-red"
            onClick={() => {
              setMessagingModalOpen(false)
              setCustomMessage("")
              setMessagingModalStatus(StatusEnum.idle)
              setMessagingModalError("")
            }}
          >
            Cancel
          </button>
          <button
            className="default-button"
            disabled={messagingModalStatus === StatusEnum.loading}
            onClick={sendCustomMessage}
          >
            Send
          </button>
        </div>
      </Modal>
      {/* Org Chart action reminder modal */}
      <Modal
        styles={modalStyles}
        open={actionReminderModalOpen}
        onClose={() => {
          setActionReminderModalOpen(false)
          setMessagingModalError("")
        }}
        center
      >
        <h5 className="modal-title">
          Action Reminder Text to <b>{messagingShop.name}</b>
        </h5>
        <hr />
        <p className="modal-subtitle">
          WARNING: This will notify all trainees in the shop{" "}
          <b>{messagingShop.name}</b> of their incomplete tasks. They may then
          reply and automatically update the system database. Recorded in the
          Finished Task Log.
        </p>
        <hr />
        {messagingModalError && (
          <div className="h6 text-center text-danger">
            {messagingModalError}
          </div>
        )}
        <div className="modal-button-container">
          <button
            className="default-button default-button-red"
            onClick={() => {
              setActionReminderModalOpen(false)
              setMessagingModalError("")
            }}
          >
            Cancel
          </button>
          <button
            className="default-button"
            disabled={loading}
            onClick={sendActionReminder}
          >
            Send
          </button>
        </div>
      </Modal>
    </DefaultLayout>
  )
}

export default BaseOverview
