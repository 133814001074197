import React from "react"
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts"

const IMRChart = (props) => {
  return (
    <div>
      <div className="header-text mt-1" style={{ textAlign: "center" }}>
        IMR Completion
      </div>
      <br />
      <ResponsiveContainer height={300}>
        <BarChart
          data={props.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          layout="vertical"
        >
          <CartesianGrid strokeDasharray="3 3" />
          <YAxis dataKey="name" type="category" />
          <XAxis type="number" />
          <Tooltip />
          <Legend />
          <Bar
            dataKey="overdue"
            fill="#ff0000"
            activeBar={<Rectangle fill="#ff0000" stroke="blue" />}
          />
          <Bar
            dataKey="due"
            fill="#ffde2e"
            activeBar={<Rectangle fill="#ffde2e" stroke="purple" />}
          />
          <Bar
            dataKey="finished"
            fill="#29b53d"
            activeBar={<Rectangle fill="#29b53d" stroke="red" />}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

export default IMRChart
