import React, { useEffect, useState } from "react"

import DefaultLayout from "../../layouts/defaultLayout"
import axios from "axios"
import { ScaleLoader } from "react-spinners"
import Modal from "react-responsive-modal"
import { modalStyles } from "../../styles/constanStyles"
import StatusEnum from "../../util/StatusEnum"

const RequestTab = ({ allShops }) => {
  const [selectedShops, setSelectedShops] = useState("")

  const [modalOpen, setModalOpen] = useState(false)

  const [requestStatus, setRequestStatus] = useState(StatusEnum.idle)

  const onSendRequest = (e) => {
    e.preventDefault()
    setRequestStatus(StatusEnum.loading)
    setModalOpen(true)
    const body = { "shop_list[]": selectedShops }
    axios
      .post("/request-shop-access", body)
      .then((res) => {
        setSelectedShops([])
        setRequestStatus(StatusEnum.success)
      })
      .catch((err) => {
        console.log(err.response)
        setRequestStatus(StatusEnum.error)
      })
  }

  return (
    <div
      className="col-xl-12 col-lg-7"
      style={{
        marginBottom: "1rem",
        marginTop: "1rem",
        marginLeft: "1rem",
        minHeight: "15rem",
      }}
    >
      <div className="container-fluid fade-in">
        <h3>Select Shop(s) to Request Access</h3>
        <form onSubmit={(e) => onSendRequest(e)}>
          {allShops &&
            allShops.length > 0 &&
            allShops.map((shop) => {
              return (
                <div key={shop[1]}>
                  <label style={{ cursor: "pointer" }}>
                    <input
                      type="checkbox"
                      checked={selectedShops.includes(shop[1])}
                      style={{ marginRight: "0.5rem" }}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedShops((prev) => [...prev, shop[1]])
                        } else {
                          setSelectedShops((prev) =>
                            prev.filter((code) => code !== shop[1])
                          )
                        }
                      }}
                    />
                    {shop[0]}
                  </label>
                </div>
              )
            })}
          <button className="default-button">Send Request</button>
        </form>
      </div>
      <Modal
        styles={modalStyles}
        open={modalOpen}
        onClose={() => {
          if (requestStatus !== StatusEnum.loading) {
            setModalOpen(false)
          }
        }}
        showCloseIcon={!requestStatus === StatusEnum.loading}
        center
        focusTrapped={false}
      >
        {requestStatus === StatusEnum.loading && (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <ScaleLoader color="black" loading={true} size={75} />
          </div>
        )}

        {requestStatus === StatusEnum.error && (
          <>
            <p className="modal-title" style={{ color: "var(--red)" }}>
              There was an error sending the access request
            </p>
            <div className="modal-button-container">
              <button
                className="default-button default-button-red"
                onClick={() => setModalOpen(false)}
              >
                Ok
              </button>
            </div>
          </>
        )}
        {requestStatus === StatusEnum.success && (
          <>
            <p className="modal-title">Access request successfully sent</p>
            <div className="modal-button-container">
              <button
                className="default-button"
                onClick={() => setModalOpen(false)}
              >
                Ok
              </button>
            </div>
          </>
        )}
      </Modal>
    </div>
  )
}

const PendingTab = () => {
  const [pendingRequests, setPendingRequests] = useState([])
  const [loadingRequests, setLoadingRequests] = useState(true)

  useEffect(() => {
    getPendingRequests()
  }, [])

  const getPendingRequests = () => {
    setLoadingRequests(true)
    axios
      .get("/get-pending-shop-requests")
      .then((res) => {
        setPendingRequests(res.data)
        setLoadingRequests(false)
      })
      .catch((err) => {
        console.log(err.response)
        setLoadingRequests(false)
      })
  }

  return (
    <div
      className="col-xl-12 col-lg-7"
      style={{
        marginBottom: "1rem",
        marginTop: "1rem",
        marginLeft: "1rem",
        minHeight: "15rem",
      }}
    >
      <div className="container-fluid fade-in">
        <h3>Pending Shop Access Requests</h3>
        {loadingRequests ? (
          <div style={{ textAlign: "center" }}>
            <ScaleLoader />
          </div>
        ) : (
          <table
            className="table table-hover table-responsive text-center"
            style={{ backgroundColor: "#eaecf4" }}
          >
            <thead className="thead-light">
              <tr>
                <th scope="col">Shop Name</th>
              </tr>
            </thead>
            <tbody>
              {pendingRequests.map((req) => {
                return (
                  <tr key={req.code}>
                    <td>{req.name}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  )
}

const ShopAccess = () => {
  const [allShops, setAllShops] = useState([])
  const [loadingShops, setLoadingShops] = useState(true)
  const [tabView, setTabView] = useState("request")

  useEffect(() => {
    axios
      .get("/get-institution-shops")
      .then((res) => {
        setAllShops(res.data)
        setLoadingShops(false)
      })
      .catch((err) => {
        console.log(err.response)
        setLoadingShops(false)
      })
  }, [])

  return (
    <DefaultLayout currentPage="Shop Access" allowNoTeamSelection>
      <div />
      {loadingShops ? (
        <ScaleLoader />
      ) : (
        <div className="card shadow">
          <div
            className="card-header"
            style={{
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center",
              paddingBottom: 0,
            }}
          >
            <div className="nav">
              <h4
                className={
                  tabView === "request"
                    ? "tab-link header-text tab-link-active"
                    : "tab-link header-text"
                }
                onClick={() => setTabView("request")}
              >
                Request Access
              </h4>
              <h4
                className={
                  tabView === "pending"
                    ? "tab-link header-text tab-link-active"
                    : "tab-link header-text"
                }
                onClick={() => setTabView("pending")}
              >
                Pending Requests
              </h4>
            </div>
          </div>
          {tabView === "request" && <RequestTab allShops={allShops} />}
          {tabView === "pending" && <PendingTab />}
        </div>
      )}
    </DefaultLayout>
  )
}

export default ShopAccess
