import React from "react"

const EventDetailBody = ({ event }) => {
  return (
    <>
      <b>Date/Time: </b>
      {event.all_day ? (
        event.start_date === event.end_date ? (
          <span>{event.start_date}, All Day</span>
        ) : (
          <span>
            {event.start_date} to {event.end_date}, All Day
          </span>
        )
      ) : event.start_date === event.end_date ? (
        <span>
          {event.start_date}, {event.start_time.substring(0, 5)} to{" "}
          {event.end_time.substring(0, 5)}
        </span>
      ) : (
        <span>
          {event.start_date} @ {event.start_time.substring(0, 5)} to{" "}
          {event.end_date} @ {event.end_time.substring(0, 5)}
        </span>
      )}
      <br />
      <b>Description: </b>
      {event.description}
      <br />
      {event.cap === 0 ? (
        <>
          <b>Capacity: </b>
          unlimited
        </>
      ) : (
        <>
          <b>Capacity: </b>
          {event.cap}
        </>
      )}
      <br />
      {event.location && event.location.length > 0 && (
        <>
          <b>Location: </b>
          {event.location}
          <br />
        </>
      )}
      <b>Trainees: </b>
      {Array.isArray(event.trainees) && (
        <ul>
          {event.trainees.map((trainee, ti) => {
            if (ti === event.trainees.length - 1) {
              return <li key={trainee.id}>{trainee.name}</li>
            } else {
              return <li key={trainee.id}>{trainee.name}, </li>
            }
          })}
        </ul>
      )}
      {(!event.trainees || event.trainees.length === 0) && (
        <>
          <span>None</span>
          <br />
          <br />
        </>
      )}
    </>
  )
}

export default EventDetailBody
