import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import DefaultLayout from "../../layouts/defaultLayout"
import LoadingDashboard from "../../components/loadingDashboard"
import NotifyTraineesCard from "../../components/notifyTraineesCard"

import axios from "axios"

// Phone number input
import "react-phone-number-input/style.css"
import PhoneInput from "react-phone-number-input"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPhone,
  faCheck,
  faTimes,
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons"

// Redux
import { useSelector } from "react-redux"

// Helper Component
const CommunicationsTableEntry = ({ trainee, phoneEditState, getList }) => {
  const [phoneNumber, setPhoneNumber] = useState(trainee.phoneNumber)

  useEffect(() => {
    // Patch phone number changes if changes detected from original and submit button pushed
    if (phoneEditState === "submit") {
      // TODO: For future - phone number checking and error catching
      // Changes detected
      if (phoneNumber !== trainee.phoneNumber) {
        if (phoneNumber) {
          const body = {
            id: trainee.id,
            phoneNumber: phoneNumber,
            isVerified: false,
          }
          axios
            .patch("/edit-trainee", body)
            .then(() => {
              getList()
            })
            .catch((err) => {
              console.log(err)
            })
        }

        // Change to blank phone number if empty
        else {
          const body = {
            id: trainee.id,
            phoneNumber: "",
            isVerified: false,
          }

          axios
            .patch("/edit-trainee", body)
            .then((res) => {
              getList()
            })
            .catch((err) => {
              console.log(err)
            })
        }
      }
    }

    // Reset input values if cancel is pressed
    if (phoneEditState === "cancel") {
      setPhoneNumber(trainee.phoneNumber)
    }
  }, [phoneEditState])

  return (
    <tr>
      <td>{trainee.emp}</td>
      <td>
        {trainee.lastName}, {trainee.firstName}
      </td>
      <td>{trainee.shop}</td>
      {trainee.percentComplete < 85 && (
        <td style={{ color: "var(--red)" }}>
          <b>{trainee.percentComplete}%</b>
        </td>
      )}
      {trainee.percentComplete === 100 && (
        <td style={{ color: "var(--green)" }}>
          <b>{trainee.percentComplete}%</b>
        </td>
      )}
      {trainee.percentComplete >= 85 && trainee.percentComplete < 100 && (
        <td style={{ color: "var(--yellow)" }}>
          <b>{trainee.percentComplete}%</b>
        </td>
      )}
      {phoneEditState === "edit" ? (
        <td>
          <PhoneInput
            style={{}}
            value={phoneNumber}
            onChange={setPhoneNumber}
            defaultCountry="US"
          />
        </td>
      ) : (
        <td>{trainee.phoneNumber}</td>
      )}
      <td>
        {trainee.isVerified && (
          <FontAwesomeIcon
            icon={faCheckCircle}
            style={{ color: "var(--green)" }}
          />
        )}
        {trainee.phoneNumber && !trainee.isVerified && (
          <FontAwesomeIcon
            icon={faTimesCircle}
            style={{ color: "var(--red)" }}
          />
        )}
      </td>
    </tr>
  )
}

// Main Component
const Communications = () => {
  const [loadingList, setLoadingList] = useState(false)
  const [loadingLog, setLoadingLog] = useState(false)
  const [notificationsList, setNotificationsList] = useState([])
  const [notificationsLog, setNotificationsLog] = useState([])

  // idle, edit, cancel, or save
  const [phoneEditState, setPhoneEditState] = useState("idle")

  //search states
  const [q, setQ] = useState("")
  const searchParam = ["emp", "firstName", "lastName", "shop", "phoneNumber"]

  const user = useSelector((state) => state.user)

  useEffect(() => {
    if (user.status === "idle" || user.status === "success") {
      if (user.currentFocusedTeam && user.currentFocusedTeam.length > 0) {
        setLoadingList(true)
        setLoadingLog(true)
        // Get Log
        axios
          .get(`/get-notificationsLog/${user.currentFocusedTeam}`)
          .then((res) => {
            setNotificationsLog(res.data)
            setLoadingLog(false)
          })
          .catch((err) => {
            console.log(err)
          })

        // Get List
        axios
          .get(`/get-notificationsList/${user.currentFocusedTeam}`)
          .then((res) => {
            setNotificationsList(res.data.sort(compTrainee))
            setLoadingList(false)
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }
  }, [user.currentFocusedTeam, user.status])

  // Get list function to be called once phone numbers are saved
  const getList = () => {
    setLoadingList(true)
    axios
      .get(`/get-notificationsList/${user.currentFocusedTeam}`)
      .then((res) => {
        setNotificationsList(res.data.sort(compTrainee))
        setLoadingList(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const compTrainee = (a, b) => {
    if (a.firstName === b.firstName && a.lastName === b.lastName) {
      return a.phoneNumber > b.phoneNumber
        ? 1
        : a.phoneNumber < b.phoneNumber
          ? -1
          : 0
    }
    if (a.lastName === b.lastName) {
      return a.firstName > b.firstName ? 1 : a.firstName < b.firstName ? -1 : 0
    }

    return a.lastName > b.lastName ? 1 : -1
  }

  // Get notifications log function for when a new SMS is sent
  const getNotificationsLog = () => {
    axios
      .get(`/get-notificationsLog/${user.currentFocusedTeam}`)
      .then((res) => {
        setNotificationsLog(res.data)
        setLoadingLog(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  if (loadingList || loadingLog) {
    return <LoadingDashboard />
  }

  function search(items) {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        if (!item[newItem]) {
          return false
        }
        if (newItem === "trainee" && item[newItem]) {
          return (
            item[newItem].name
              .toString()
              .toLowerCase()
              .indexOf(q.toLowerCase()) > -1
          )
        }
        if (item[newItem]) {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          )
        }
        return false
      })
    })
  }

  return (
    <DefaultLayout currentPage="Trainee Notifications">
      <div>
        <div style={{ margin: ".25rem 0" }}>
          <div className="row">
            <NotifyTraineesCard
              notificationsList={notificationsList}
              getNotificationsLog={getNotificationsLog}
            />

            <div className="col-sm-6">
              <div
                className="card"
                style={{ padding: "1rem", height: "100%", fontSize: "1.1rem" }}
              >
                <h5>
                  Notifications Log{" "}
                  <Link
                    to="/communications/log"
                    style={{
                      textDecoration: "none",
                      fontSize: "1rem",
                      textDecoration: "underline",
                    }}
                  >
                    <b>Detailed view</b>
                  </Link>
                </h5>
                <p>Overview of past notifications.</p>
                <div
                  style={{
                    overflow: "auto",
                    maxHeight: "200px",
                    fontSize: "1rem",
                  }}
                >
                  <table
                    className="table"
                    style={{
                      backgroundColor: "#eaecf4",
                    }}
                  >
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Message Info</th>
                        <th scope="col">Time Stamp</th>
                      </tr>
                    </thead>
                    <tbody>
                      {notificationsLog &&
                        notificationsLog.length > 0 &&
                        notificationsLog
                          .slice(0)
                          .reverse()
                          .map((item, i) => {
                            return (
                              <tr key={i}>
                                <td>
                                  {item.messageTitle} {"--> "}
                                  {item.recipient.toUpperCase()}
                                </td>
                                <td>
                                  {new Date(item.timeStamp).toLocaleString(
                                    "en-US",
                                    {
                                      hour12: false,
                                    }
                                  )}
                                </td>
                              </tr>
                            )
                          })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {phoneEditState !== "edit" ? (
          <button
            className="default-button"
            onClick={() => setPhoneEditState("edit")}
          >
            <FontAwesomeIcon icon={faPhone} /> Edit Phone Numbers
          </button>
        ) : (
          <>
            <button
              className="default-button default-button-red"
              onClick={() => setPhoneEditState("cancel")}
              style={{ marginRight: "0.5rem" }}
            >
              <FontAwesomeIcon icon={faTimes} /> Cancel
            </button>
            <button
              className="default-button"
              onClick={() => setPhoneEditState("submit")}
            >
              <FontAwesomeIcon icon={faCheck} /> Save
            </button>
          </>
        )}

        <div className="search-wrapper" style={{ display: "inline-block" }}>
          <label htmlFor="search-form">
            <input
              style={{ marginBottom: "16px" }}
              type="search"
              name="search-form"
              id="search-form"
              className="search-input textInput form-control"
              placeholder="Search for..."
              value={q}
              onChange={(e) => setQ(e.target.value)}
            />
            <span className="sr-only">Search names here</span>
          </label>
        </div>

        {/* Notifications list of trianees */}
        <table
          className="table table-hover table-responsive text-center"
          style={{ backgroundColor: "#eaecf4" }}
        >
          <thead className="thead-light">
            <tr>
              <th scope="col">EMP #</th>
              <th scope="col">Full Name</th>
              <th scope="col">Shop</th>
              <th scope="col">Tasks Complete</th>
              <th scope="col">Phone Number</th>
              <th scope="col">Verified?</th>
            </tr>
          </thead>
          <tbody>
            {search(notificationsList).map((trainee, ti) => {
              return (
                <CommunicationsTableEntry
                  key={ti}
                  trainee={trainee}
                  phoneEditState={phoneEditState}
                  getList={getList}
                />
              )
            })}
          </tbody>
        </table>
      </div>
    </DefaultLayout>
  )
}

export default Communications
