import React from "react"

import { Line } from "react-chartjs-2"

import Chart from "chart.js/auto"
import { CategoryScale } from "chart.js"
Chart.register(CategoryScale)

// function sortByMonth(arr) {
//   var months = [
//     "January",
//     "February",
//     "March",
//     "April",
//     "May",
//     "June",
//     "July",
//     "August",
//     "September",
//     "October",
//     "November",
//     "December",
//   ]
//
//   return arr.sort(function (a, b) {
//     return months.indexOf(a.month) - months.indexOf(b.month)
//   })
// }
const getRandomColor = () => {
  return "#" + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0")
}

const UTAChart = (props) => {
  const months = {
    percComplete: "December",
    percComplete1: "November",
    percComplete2: "October",
    percComplete3: "September",
    percComplete4: "August",
  }

  const chartData = {
    labels: Object.values(months),
    datasets: props.data.map((v) => {
      return {
        label: v.name,
        data: [
          Math.min(v.percComplete4, 100),
          Math.min(v.percComplete3, 100),
          Math.min(v.percComplete2, 100),
          Math.min(v.percComplete1, 100),
          Math.min(v.percComplete, 100),
        ],
        fill: false,
        borderColor: getRandomColor(),
        tension: 0.1,
      }
    }),
  }

  return (
    <div style={{ width: "100%" }}>
      <div className="header-text mt-1" style={{ textAlign: "center" }}>
        HISTORICAL READINESS
      </div>
      <br />

      <Line
        data={chartData}
        options={{
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
      />
    </div>
  )
}

export default UTAChart
