import React, { useState, useRef } from "react"

const Accordion = (props) => {
  const { children, title, containerStyles } = props

  const [height, setHeight] = useState(0)
  const content = useRef(null)
  const container = useRef(null)

  const toggleAccordion = () => {
    setHeight(height === 0 ? content.current.scrollHeight : 0)
  }

  return (
    <div style={{ marginBottom: "0.5rem" }}>
      <div
        onClick={toggleAccordion}
        className={
          height === 0
            ? "accordion-container"
            : "accordion-container accordion-container-open"
        }
        style={containerStyles ? containerStyles : {}}
        ref={container}
      >
        {title}
      </div>
      <div
        style={{
          overflow: "hidden",
          transition: "max-height 0.4s ease-in-out",
          maxHeight: `${height}px`,
          maxWidth: container.current ? container.current.offsetWidth : "none",
        }}
        ref={content}
      >
        <div
          style={{
            border: "2px solid #e6e7e8",
            borderTop: "none",
            padding: "1rem",
            marginBottom: "1rem",
            borderRadius: "0 0 0.5rem 0.5rem",
          }}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

export default Accordion
