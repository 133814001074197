import React, { useState, useEffect } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons"

import axios from "axios"

import Modal from "react-responsive-modal"
import { modalStylesDanger, modalStyles } from "../styles/constanStyles"

const DocumentListEntry = (props) => {
  const { doc, getDocuments } = props
  const [expString, setExpString] = useState("")
  const [expColor, setExpColor] = useState("black")

  const [timestamp, setTimestamp] = useState("")

  const [deleteModal, setDeleteModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [modalError, setModalError] = useState("")
  const [modalLoading, setModalLoading] = useState(false)

  const [docTitle, setDocTitle] = useState(doc.title)
  const [docDesc, setDocDesc] = useState(doc.description)
  const [docExp, setDocExp] = useState(doc.expiration_date)

  useEffect(() => {
    // Set expirataion dates
    if (props.doc.expiration_date) {
      setExpString(props.doc.expiration_date)
      if (props.expired.includes(props.doc.id)) {
        setExpColor("var(--red)")
      }
      if (props.almost_expired.includes(props.doc.id)) {
        setExpColor("var(--yellow)")
      }
    } else {
      setExpColor("var(--gray-300)")
    }

    // Set uploaded at dates
    setTimestamp(
      new Date(props.doc.uploaded_at).toLocaleString("en-US", {
        hour12: false,
      })
    )
  }, [props])

  const downloadDocument = () => {
    axios
      .get(`/get-download-link/${doc.id}`)
      .then((res) => {
        window.open(res.data, "_blank")
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleDelete = () => {
    setModalLoading(true)

    axios
      .delete(`/delete-document/${doc.id}`)
      .then(() => {
        setDeleteModal(false)
        setModalError("")
        setModalLoading(false)
        getDocuments()
      })
      .catch((err) => {
        setModalLoading(false)
        setModalError("An unexpected error has occured, please try again")
      })
  }

  const handleEdit = () => {
    setModalLoading(true)

    const body = {
      title: docTitle,
      description: docDesc,
      expiration_date: docExp ? docExp : "",
    }

    axios
      .patch(`/update-document/${doc.id}`, body)
      .then(() => {
        setEditModal(false)
        setModalError("")
        setModalLoading(false)
        getDocuments()
      })
      .catch((err) => {
        setModalLoading(false)
        setModalError("An unexpected error has occured, please try again")
      })
  }

  const resetEditInput = () => {
    setDocTitle(doc.title)
    setDocDesc(doc.description)
    setDocExp(doc.expiration_date)
  }

  return (
    <>
      {/* Delete Modal */}
      <Modal
        styles={modalStylesDanger}
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        center
        focusTrapped={false}
      >
        <h5 className="modal-title">Delete document</h5>
        <hr />
        <p className="modal-subtitle">
          Are you sure you want to delete this document: <b>{doc.title}</b>?
        </p>
        {modalError.length > 0 && (
          <p className="h6 text-center text-danger">{modalError}</p>
        )}
        <div className="modal-button-container">
          <button
            className="default-button"
            onClick={() => setDeleteModal(false)}
          >
            Cancel
          </button>
          <button
            className="default-button default-button-red"
            onClick={handleDelete}
            disabled={modalLoading}
          >
            Delete
          </button>
        </div>
      </Modal>
      {/* Edit Modal */}
      <Modal
        styles={modalStyles}
        open={editModal}
        onClose={() => setEditModal(false)}
        center
        focusTrapped={false}
      >
        <h5 className="modal-title">Edit document</h5>
        <hr />
        <p className="modal-subtitle">
          <b>{doc.local_name}</b>
        </p>
        <span>Title:</span>
        <input
          className="modal-input"
          type="text"
          value={docTitle}
          onChange={(e) => setDocTitle(e.target.value)}
          required
        />
        <br />
        <br />
        <span>Description:</span>
        <input
          className="modal-input"
          type="text"
          value={docDesc}
          onChange={(e) => setDocDesc(e.target.value)}
          required
        />
        <br />
        <br />
        <span>Expiration Date:</span>
        <input
          className="modal-input"
          type="date"
          value={docExp}
          onChange={(e) => setDocExp(e.target.value)}
        />
        <hr />
        {modalError.length > 0 && (
          <p className="h6 text-center text-danger">{modalError}</p>
        )}
        <div className="modal-button-container">
          <button
            className="default-button default-button-gray"
            onClick={() => setEditModal(false)}
          >
            Cancel
          </button>
          <button
            className="default-button"
            onClick={handleEdit}
            disabled={modalLoading}
          >
            Confirm
          </button>
        </div>
      </Modal>
      <tr style={{ fontSize: "0.8rem" }}>
        <td
          className="td-small hover-underline"
          style={{ color: "var(--blue)", cursor: "pointer" }}
          onClick={downloadDocument}
        >
          {doc.title}
        </td>
        <td className="td-small">{doc.description}</td>
        <td className="td-small">{timestamp}</td>
        <td className="td-small" style={{ color: expColor }}>
          {expString ? expString : "None"}
        </td>
        <td className="td-small">
          {doc.size.includes("B") ? (
            // Keep old string sizes for backwards compatability
            doc.size
          ) : (
            // Round up to the nearest 0.01 kb
            <>{Math.ceil(doc.size / 10) / 100} KB</>
          )}
        </td>
        <td
          className="td-small"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setDeleteModal(true)
            setModalError("")
            setModalLoading(false)
          }}
        >
          <FontAwesomeIcon
            style={{ color: "var(--red)", fontSize: "1rem" }}
            icon={faTrash}
          />
        </td>
        <td
          className="td-small"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setEditModal(true)
            setModalError("")
            setModalLoading(false)
            resetEditInput()
          }}
        >
          <FontAwesomeIcon style={{ fontSize: "1rem" }} icon={faEdit} />
        </td>
      </tr>
    </>
  )
}

const DocumentsView = ({
  documents,
  getDocuments,
  expired,
  almost_expired,
}) => {
  return (
    <table
      className="table table-hover table-responsive text-center"
      style={{ backgroundColor: "#eaecf4" }}
    >
      <thead className="thead-light">
        <tr>
          <th scope="col">File Name</th>
          <th scope="col">Description</th>
          <th scope="col">Uploaded On</th>
          <th scope="col">Expiration Date</th>
          <th scope="col">Size</th>
          <th scope="col">
            <FontAwesomeIcon icon={faTrash} />
          </th>
          <th scope="col">
            <FontAwesomeIcon icon={faEdit} />
          </th>
        </tr>
      </thead>
      <tbody>
        {documents.map((d) => {
          return (
            <DocumentListEntry
              doc={d}
              key={d.id}
              getDocuments={getDocuments}
              expired={expired}
              almost_expired={almost_expired}
            />
          )
        })}
      </tbody>
    </table>
  )
}

export default DocumentsView
