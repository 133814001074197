import React, { useState, useEffect } from "react"

// Modal
import { Modal } from "react-responsive-modal"
import { modalStyles } from "../styles/constanStyles"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCog,
  faChevronRight,
  faWrench,
} from "@fortawesome/free-solid-svg-icons"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { setTeam } from "../redux/userSlice"
import { useNavigate } from "react-router"

const TeamSelector = () => {
  const user = useSelector((state) => state.user)

  // Search function state
  const [q, setQ] = useState("")
  const searchParam = ["title"]
  //

  const dispatch = useDispatch()
  const navigate = useNavigate()

  // Menu open state
  const [menuDropdownOpen, setMenuDropdownOpen] = useState(false)
  const [menuModalOpen, setMenuModalOpen] = useState(false)

  const [teams, setTeams] = useState([])

  const [
    currentFocusedTeamSupervisorCode,
    setCurrentFocusedTeamSupervisorCode,
  ] = useState("")

  useEffect(() => {
    if (user.status === "idle" || user.status === "success") {
      if (user.teams) {
        setTeams(user.teams)
      }
    }
  }, [user.status, user.teams])

  // Set supervisor code of current focused team
  useEffect(() => {
    if (!user.currentFocusedTeam || user.currentFocusedTeam.length < 1) {
      setCurrentFocusedTeamSupervisorCode("")
    } else {
      user.teams.map((team) => {
        if (team.code === user.currentFocusedTeam) {
          setCurrentFocusedTeamSupervisorCode(team.supervisorCode)
        }
      })
    }
  }, [user.currentFocusedTeam, user.teams])

  const changeTeam = (code) => {
    dispatch(setTeam(code))
  }

  if (user.status === "loading" || user.status === "failed") {
    return <div />
  }

  function search(items) {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        if (item[newItem]) {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          )
        } else {
          return false
        }
      })
    })
  }

  return (
    <div className="fade-in">
      <div className="sidebar-heading">CURRENT TEAM</div>

      {/* Toggle menu dropdown for desktop */}
      <div
        className="sidebar-dropdown desktop-only"
        onClick={() => setMenuDropdownOpen((prev) => !prev)}
        style={{ margin: "0 0 -1rem 0" }}
      >
        <FontAwesomeIcon icon={faCog} className="sidebar-icon" />
        {user.currentFocusedTeam && user.currentFocusedTeam.length > 0
          ? teams.map((team) => {
              if (team.code === user.currentFocusedTeam) {
                return team.title
              }
            })
          : "Choose Team"}

        <FontAwesomeIcon
          icon={faChevronRight}
          className="sidebar-icon sidebar-icon-right"
          style={!menuDropdownOpen ? {} : { transform: "rotate(90deg)" }}
        />
      </div>
      {/*  */}

      {/* Toggle menu modal for mobile */}
      <div
        className="sidebar-dropdown mobile-only"
        onClick={() => setMenuModalOpen(true)}
        style={{ margin: "0 0 -1rem 0" }}
      >
        <FontAwesomeIcon icon={faCog} className="sidebar-icon" />
        {user.currentFocusedTeam && user.currentFocusedTeam.length > 0
          ? user.currentFocusedTeam
          : "Choose Team"}
      </div>
      {/*  */}

      <div className="wrapper">
        <div
          className="teams-list"
          style={
            menuDropdownOpen
              ? { maxHeight: teams.length * 108 + 150 + teams.length * 108 }
              : { maxHeight: "0" }
          }
        >
          <div
            className="sidebar-heading"
            style={{ color: "var(--gray-300)" }}
          >
            TEAMS:
            <div className="search-wrapper">
              <label htmlFor="search-form">
                <input
                  type="search"
                  name="search-form"
                  id="search-form"
                  className="search-input textInput form-control"
                  placeholder="Search for..."
                  value={q}
                  onChange={(e) => setQ(e.target.value)}
                />
                <span className="sr-only">Search teams here</span>
              </label>
            </div>
          </div>
          {search(teams).map((team) => (
            <div className="teams-link" key={team.code}>
              <div
                onClick={() => {
                  changeTeam(team.code)
                  navigate("/home")
                }}
              >
                {team.title}
              </div>
            </div>
          ))}
        </div>
        {/* {user.currentFocusedTeam && (
          <div
            className="nav-link"
            to="/upload"
            style={{ margin: "-1rem 0 0 0", cursor: "text" }}
          >
            <FontAwesomeIcon icon={faWrench} className="sidebar-icon" />
            Team Code: {user.currentFocusedTeam}
          </div>
        )} */}

        {/*{user.currentFocusedTeam && (*/}
        {/*  <div*/}
        {/*    className="nav-link"*/}
        {/*    to="/upload"*/}
        {/*    style={{ margin: "-1rem 0 0 0", cursor: "text" }}*/}
        {/*  >*/}
        {/*    <FontAwesomeIcon icon={faWrench} className="sidebar-icon" />*/}
        {/*    Supervisors: {currentFocusedTeamSupervisorCode}*/}
        {/*  </div>*/}
        {/*)}*/}

        {/* Menu modal */}
        <Modal
          styles={modalStyles}
          open={menuModalOpen}
          onClose={() => setMenuModalOpen(false)}
          center
          focusTrapped={false}
        >
          <h5 className="modal-title">Change Team</h5>
          <hr />
          {teams.map((team) => {
            return (
              <div className="teams-link" key={team.code}>
                <div
                  onClick={() => {
                    setMenuModalOpen(false)
                    changeTeam(team.code)
                  }}
                >
                  {team.title}
                </div>
              </div>
            )
          })}
        </Modal>
      </div>
    </div>
  )
}

export default TeamSelector
