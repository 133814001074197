import React from "react"
import { ScaleLoader } from "react-spinners"

const SmallLoader = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        margin: "2rem 0",
      }}
      className="fade-in"
    >
      <ScaleLoader color="black" loading={true} size={75} />
    </div>
  )
}

export default SmallLoader
