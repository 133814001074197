import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import TraineeLayout from "../../layouts/traineeLayout"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ScaleLoader } from "react-spinners"
import {
  faUser,
  faKey,
  faPhone,
  faEdit,
  faCheck,
  faTimes,
} from "@fortawesome/free-solid-svg-icons"

import axios from "axios"
// Redux
import { useDispatch } from "react-redux"

import "react-phone-number-input/style.css"
import PhoneInput from "react-phone-number-input"

// Helper Components
const DataRow = ({ label, value }) => (
  <div className="row">
    <div className="col-lg-5">
      <h6>
        <strong>{label}</strong>
      </h6>
    </div>
    <div className="col-lg-5">{value ? value : value === 0 ? 0 : "None"}</div>
  </div>
)

const SettingsHeader = ({ setPersonalInfo, setAccountDisplay }) => {
  return (
    <>
      <br />
      <h1 className="" style={{ color: "var(--blue)" }}>
        <FontAwesomeIcon icon={faUser} /> My Profile
      </h1>
      <br />
      <div className="row">
        <div className="col-md-6">
          <button
            className="btn btn-outline-primary"
            onClick={() => setAccountDisplay("changePassword")}
          >
            <FontAwesomeIcon icon={faKey} className="fas fa-key" /> Change
            Password
          </button>
        </div>
        <div className="col-md-6">
          <button
            type="button"
            className="btn btn-outline-primary btn-trainee-profile"
            onClick={() => setPersonalInfo("editPhoneNumber")}
            style={{ marginRight: 5 }}
          >
            <FontAwesomeIcon icon={faPhone} className="fas fa-phone" /> Edit
            Phone Number
          </button>
          <button
            type="button"
            className="btn btn-outline-primary btn-trainee-profile"
            onClick={() => setPersonalInfo("editInformation")}
          >
            <FontAwesomeIcon icon={faEdit} className="fas fa-edit" /> Edit
            Information
          </button>
        </div>
      </div>
      <hr style={{ color: "rgba(100,100,100,0.8)", height: "2px" }} />
    </>
  )
}

const FormField = ({ title, value, type, setter }) => (
  <>
    <p>{title}</p>
    <div className="input-group mb-3">
      <input
        style={{ color: "rgba(100,100,100,0.8)" }}
        type={type}
        className="textinput textInput form-control"
        value={value}
        onChange={(v) => {
          setter(v.target.value)
        }}
      />
    </div>
  </>
)

const AccountInfo = ({ user, trainee, toShow, toShowUpdater }) => {
  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [newPassword2, setNewPassword2] = useState("")
  const [error, setError] = useState("")
  const [uploadComplete, setUploadComplete] = useState(false)
  const errorStyle = {
    backgroundColor: "rgb(251, 198, 16)",
    marginRight: 20,
    color: "black",
    fontSize: "16px",
    fontStyle: "bold",
    borderRadius: 3,
    textAlign: "center",
  }
  const successStyle = {
    backgroundColor: "lightgreen",
    marginRight: 20,
    color: "black",
    fontSize: "16px",
    fontStyle: "bold",
    borderRadius: 3,
    textAlign: "center",
  }

  const clearInput = () => {
    setOldPassword("")
    setNewPassword("")
    setNewPassword2("")
  }
  const handlePasswordSubmission = (e) => {
    e.preventDefault()
    if (newPassword !== newPassword2) {
      setError("New passwords must match")
      clearInput()
      return
    }
    toShowUpdater("loading")
    const data = {
      email: user.email,
      oldPassword: oldPassword,
      newPassword: newPassword,
    }
    axios
      .patch(`/change-password`, data)
      .then((res) => {
        setError("")
        setUploadComplete(true)
      })
      .catch((err) => {
        toShowUpdater("changePassword")
        setError(err.response.data)
      })
    clearInput()
  }
  return (
    <>
      {toShow === "default" && (
        <div className="col-md-6">
          <h3>Account Information</h3>
          <div className="col-lg-8">
            <strong>Name: </strong>
            {`${user.first_name} ${user.last_name}`}
          </div>
          <hr style={{ color: "rgba(100,100,100,0.8)", height: "2px" }} />
          <div className="col-lg-8">
            <strong>Username / Email: </strong>
            {user.email}
          </div>
          <hr style={{ color: "rgba(100,100,100,0.8)", height: "2px" }} />
          <div className="col-lg-8">
            <strong>Date Joined: </strong>
            {trainee.date_joined
              ? trainee.date_joined
              : trainee.created
                ? new Date(trainee.created).toDateString().substring(4)
                : ""}
          </div>
        </div>
      )}
      {toShow === "changePassword" && (
        <div className="col-md-6">
          <h3 className="text-danger">Changing Password</h3>

          <form onSubmit={handlePasswordSubmission}>
            <button
              type="button"
              className="btn btn-outline-danger mb-4  mr-4"
              onClick={() => {
                setError("")
                toShowUpdater("default")
              }}
              style={{ marginRight: 20 }}
            >
              <FontAwesomeIcon icon={faTimes} className="fas fa-times" /> Cancel
            </button>

            <br />
            {error && (
              <>
                <div style={errorStyle}>{error}</div>
                <br />
              </>
            )}

            <div>Password Criteria:</div>
            <ul>
              <li>
                Your password can’t be too similar to your other personal
                information.
              </li>
              <li>Your password must contain at least 8 characters.</li>
              <li>Your password can’t be a commonly used password.</li>
              <li>Your password can’t be entirely numeric.</li>
            </ul>
            <table className="table">
              <tbody>
                <tr>
                  <td>Old password:</td>
                  <td>
                    <input
                      type="password"
                      value={oldPassword}
                      onChange={(v) => setOldPassword(v.target.value)}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td>New password:</td>
                  <td>
                    <input
                      type="password"
                      value={newPassword}
                      onChange={(v) => setNewPassword(v.target.value)}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td>Confirm new password:</td>
                  <td>
                    <input
                      type="password"
                      value={newPassword2}
                      onChange={(v) => setNewPassword2(v.target.value)}
                      required
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <button type="submit" className="btn btn-outline-success mb-4">
              <FontAwesomeIcon icon={faCheck} className="fas fa-check" /> Save
              changes
            </button>
          </form>
        </div>
      )}
      {toShow === "loading" && (
        <div className="col-md-6">
          {uploadComplete || (
            <>
              <h5>Updating password...</h5>
              <br />
              <ScaleLoader color="gray" loading={true} size={50} />
            </>
          )}
          {uploadComplete && (
            <>
              <h5 style={successStyle}>Password successfully changed</h5>
              <br />
              <button
                className="btn btn-primary"
                type="button"
                onClick={(e) => {
                  e.preventDefault()
                  toShowUpdater("default")
                  setUploadComplete(false)
                }}
              >
                Return
              </button>
            </>
          )}
        </div>
      )}
    </>
  )
}

const PersonalDetails = ({
  user,
  trainee,
  toShow,
  toShowUpdater,
  traineeUpdater,
}) => {
  const [certLevel, setCertLevel] = useState("")
  const [semesters, setSemesters] = useState(0)
  const [rank, setRank] = useState("")
  const [year, setYear] = useState("")
  const [dateJoined, setDateJoined] = useState("")
  const [expiration, setExpiration] = useState("")
  const [height, setHeight] = useState("")
  const [shirtSize, setShirtSize] = useState("")
  const [pantSize, setPantSize] = useState("")
  const [pantDimension, setPantDimension] = useState("")
  const [phoneNumber, setPhoneNumber] = useState(12345678910)
  useEffect(() => {
    setCertLevel(trainee.certLevel)
    setSemesters(trainee.active_semesters)
    setRank(trainee.rank)
    setYear(trainee.year)
    setDateJoined(trainee.date_joined)
    setExpiration(trainee.expiration)
    setHeight(trainee.height)
    setShirtSize(trainee.shirt_size)
    setPantSize(trainee.pant_size)
    setPantDimension(trainee.pant_dimensions)
    setPhoneNumber(trainee.phoneNumber)
  }, [trainee])

  const updateTraineeInfo = (e) => {
    e.preventDefault()
    const updatedTrainee = {
      id: trainee.id,
      certLevel: certLevel,
      active_semesters: parseInt(semesters),
      rank: rank,
      year: parseInt(year),
      date_joined: dateJoined,
      expiration: expiration,
      height: height,
      shirt_size: shirtSize,
      pant_size: pantSize,
      pant_dimensions: pantDimension,
    }
    axios
      .patch(`/edit-trainee`, updatedTrainee)
      .then((res) => {
        traineeUpdater({ ...trainee, ...updatedTrainee })
      })
      .catch((err) => {
        console.log(err)
        alert(
          "We apologize, but an error occurred while updating your data. Please try again."
        )
        toShowUpdater("default")
      })
    toShowUpdater("default")
  }
  // TODO: Add phone number GUI that formats numbers based on nation
  const handlePhoneNumber = (e) => {
    e.preventDefault()
    const updatedTrainee = {
      id: trainee.id,
      phoneNumber: phoneNumber,
      isVerified: false,
    }
    axios
      .patch(`/edit-trainee`, updatedTrainee)
      .then((res) => {
        {
          /* Note: During unpackaing, right object should overrule left object */
        }
        traineeUpdater({ ...trainee, ...updatedTrainee })
      })
      .catch((err) => {
        console.log(err)
        alert(
          "We apologize, but an error occurred while updating your data. Please try again."
        )
      })
    toShowUpdater("default")
  }
  //TODO: Figure out why active_semesters isn't updating properly
  return (
    <>
      {toShow === "default" && (
        <div className="col-md-6" id="trainee-information">
          <h3>Personal Details</h3>
          <DataRow label="Phone Number" value={trainee.phoneNumber} />
          <DataRow label="Email" value={user.email.toUpperCase()} />
          <DataRow label="EMP #" value={trainee.emp} />
          <DataRow label="PAFSC" value={trainee.pafsc} />
          <DataRow label="Certification Level" value={trainee.certLevel} />
          <DataRow label="Active Semesters" value={trainee.active_semesters} />
          <DataRow label="Rank" value={trainee.rank} />
          <DataRow label="Year" value={trainee.year} />
          <DataRow label="Date Joined" value={trainee.date_joined} />
          <DataRow label="Expiration" value={trainee.expiration} />
          <DataRow label="Height" value={trainee.height} />
          <DataRow label="Shirt Size" value={trainee.shirt_size} />
          <DataRow label="Pant Size" value={trainee.pant_size} />
          <DataRow label="Pant Dimensions" value={trainee.pant_dimensions} />
        </div>
      )}
      {toShow === "editInformation" && (
        <div className="row edit-info-form-container col-md-6">
          <form onSubmit={updateTraineeInfo}>
            <button
              type="button"
              className="btn btn-outline-danger mb-4  mr-4"
              onClick={() => toShowUpdater("default")}
              style={{ marginRight: 20 }}
            >
              <FontAwesomeIcon icon={faTimes} className="fas fa-times" /> Cancel
            </button>
            <button type="submit" className="btn btn-outline-success mb-4">
              <FontAwesomeIcon icon={faCheck} className="fas fa-check" /> Save
            </button>
            <FormField
              title="Certification level"
              type="text"
              value={certLevel}
              setter={setCertLevel}
            />
            <FormField
              title="Active semesters*"
              type="number"
              value={semesters}
              setter={setSemesters}
            />
            <FormField title="Rank" type="text" value={rank} setter={setRank} />
            <FormField
              title="Year"
              type="number"
              value={year}
              setter={setYear}
            />
            <FormField
              title="Date joined"
              type="date"
              value={dateJoined}
              setter={setDateJoined}
            />
            <FormField
              title="Expiration"
              type="date"
              value={expiration}
              setter={setExpiration}
            />
            <FormField
              title="Height"
              type="text"
              value={height}
              setter={setHeight}
            />
            <FormField
              title="Shirt size"
              type="text"
              value={shirtSize}
              setter={setShirtSize}
            />
            <FormField
              title="Pant size"
              type="text"
              value={pantSize}
              setter={setPantSize}
            />
            <FormField
              title="Pant dimension"
              type="text"
              value={pantDimension}
              setter={setPantDimension}
            />
          </form>
        </div>
      )}
      {toShow === "editPhoneNumber" && (
        <div className="row edit-phone-form-container col-md-6">
          <div className="col-xl-6">
            <form id="lookup" onSubmit={handlePhoneNumber}>
              <p>Enter your phone number:</p>
              <PhoneInput
                style={{ color: "rgba(100,100,100,0.8)" }}
                value={phoneNumber}
                onChange={setPhoneNumber}
                defaultCountry="US"
              />
              <br />
              <br />
              <button
                type="button"
                className="btn btn-outline-danger mb-2     mr-2"
                onClick={() => toShowUpdater("default")}
                style={{ marginRight: 10 }}
              >
                <FontAwesomeIcon icon={faTimes} className="fas fa-times" />{" "}
                Cancel
              </button>
              <button type="submit" className="btn btn-outline-success mb-2">
                <FontAwesomeIcon icon={faCheck} className="fas fa-check" /> Save
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  )
}

// Main Component
const TraineeUserProfile = () => {
  const [user, setUser] = useState(useSelector((state) => state.user))
  const [trainee, setTrainee] = useState([])
  const [personalInfo, setPersonalInfo] = useState("default")
  const [accountDisplay, setAccountDisplay] = useState("default")
  useEffect(() => {
    axios
      .get(`/get-trainee`)
      .then((res) => {
        setTrainee(res.data)
      })
      .catch((e) =>
        console.log(
          `ERROR: Issueing with /get-trainee. Error message: ${e.message}`
        )
      )
  }, [])

  var infoStyle = {
    color: "rgba(100,100,100,0.8)",
  }
  return (
    <TraineeLayout>
      <div className="container bootstrap snippets bootdey">
        <SettingsHeader
          setPersonalInfo={setPersonalInfo}
          setAccountDisplay={setAccountDisplay}
        />
        <div className="row" style={infoStyle}>
          {/* Left Column */}
          <AccountInfo
            user={user}
            trainee={trainee}
            userUpdater={setUser}
            toShow={accountDisplay}
            toShowUpdater={setAccountDisplay}
          />
          <PersonalDetails
            user={user}
            trainee={trainee}
            toShow={personalInfo}
            toShowUpdater={setPersonalInfo}
            traineeUpdater={setTrainee}
          />
        </div>
      </div>
    </TraineeLayout>
  )
}

export default TraineeUserProfile
