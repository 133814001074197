import React, { useEffect, useState } from "react"
import DefaultLayout from "../../layouts/defaultLayout"
import LoadingDashboard from "../../components/loadingDashboard"
import SmallLoader from "../../components/smallLoader"
import ProgressBar from "../../components/progressBar"
import SingleChat from "../../components/singleChat"

import Modal from "react-responsive-modal"
import { modalStylesDanger, modalStyles } from "../../styles/constanStyles"

import { useSelector } from "react-redux"

import PhoneInput from "react-phone-number-input"

import axios from "axios"

import { useNavigate, useParams } from "react-router"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBell,
  faCheck,
  faCheckCircle,
  faEdit,
  faTasks,
  faTimes,
  faTimesCircle,
  faUserFriends,
  faUserSlash,
  faPlus,
  faTrash,
  faCommentDots,
} from "@fortawesome/free-solid-svg-icons"

const verboseStatusFromCode = {
  d: "Due",
  cd: "Coming Due",
  od: "Overdue",
  f: "Satisfied",
  ff: "Satisfied",
  nk: "Not Known",
  yes: "Satisfied",
  no: "Due",
}

const ComplianceTab = ({
  dueTasks,
  overdueTasks,
  comingDueTasks,
  completedTasks,
  setDueTasks,
  setOverdueTasks,
  setComingDueTasks,
  setCompletedTasks,
}) => {
  // Modals
  const [completeTaskModal, setCompleteTaskModal] = useState(false)
  const [undoTaskModal, setUndoTaskModal] = useState(false)
  const [modalError, setModalError] = useState("")
  const [modalLoading, setModalLoading] = useState(false)
  const [taskToBeCompleted, setTaskToBeCompleted] = useState({
    title: "",
    id: 0,
    status: "",
    lastStatus: "",
  })

  const handleComplete = () => {
    setModalLoading(true)
    setModalError("")

    const body = {
      id: taskToBeCompleted.id,
      status: "f",
    }
    axios
      .patch("/update-task", body)
      .then(() => {
        // Change local state
        // Remove from current column
        setOverdueTasks((prev) =>
          prev.filter((task) => task.id !== taskToBeCompleted.id)
        )
        setDueTasks((prev) =>
          prev.filter((task) => task.id !== taskToBeCompleted.id)
        )
        setComingDueTasks((prev) =>
          prev.filter((task) => task.id !== taskToBeCompleted.id)
        )
        // Add to completed column
        setCompletedTasks((prev) => [
          ...prev,
          {
            task: taskToBeCompleted.id,
            id: taskToBeCompleted.id,
            lastStatus: taskToBeCompleted.status,
            finishedAction: taskToBeCompleted.title,
            status: "f",
          },
        ])

        setModalLoading(false)
        setCompleteTaskModal(false)
      })
      .catch((err) => {
        console.log(err)
        setModalError("An error has occured, please try again")
        setModalLoading(false)
      })
  }

  const handleUndo = () => {
    setModalLoading(true)
    setModalError("")

    const body = {
      id: taskToBeCompleted.task,
      undo: true,
      status:
        taskToBeCompleted.lastStatus === "cd" ||
        taskToBeCompleted.lastStatus === "ff"
          ? "cd"
          : taskToBeCompleted.lastStatus === "od"
            ? "od"
            : "d",
    }
    axios
      .patch("/update-task", body)
      .then(() => {
        // Change local state
        // Add to correct column
        const formattedTaskToBeCompleted = {
          id: taskToBeCompleted.id,
          title: taskToBeCompleted.finishedAction,
          status:
            taskToBeCompleted.lastStatus === "cd" ||
            taskToBeCompleted.lastStatus === "ff"
              ? "cd"
              : taskToBeCompleted.lastStatus === "od"
                ? "od"
                : "d",
        }

        if (
          taskToBeCompleted.lastStatus === "cd" ||
          taskToBeCompleted.lastStatus === "ff"
        ) {
          setComingDueTasks((prev) => [...prev, formattedTaskToBeCompleted])
        } else if (taskToBeCompleted.lastStatus === "od") {
          setOverdueTasks((prev) => [...prev, formattedTaskToBeCompleted])
        } else {
          setDueTasks((prev) => [...prev, formattedTaskToBeCompleted])
        }

        // Remove from completed column
        setCompletedTasks((prev) =>
          prev.filter((t) => t.id !== taskToBeCompleted.id)
        )

        setModalLoading(false)
        setUndoTaskModal(false)
      })
      .catch((err) => {
        console.log(err)
        setModalError("An error has occured, please try again")
        setModalLoading(false)
      })
  }

  return (
    <>
      <div className="container-fluid fade-in" style={{ padding: "1rem 0" }}>
        <div className="row">
          <div className="col-sm-3">
            <ul className="list-group overdue-list">
              <li className="list-group-item list-group-item-danger text-center">
                <FontAwesomeIcon icon={faTasks} /> Overdue Tasks
              </li>
              {overdueTasks.map((task) => {
                return (
                  <li
                    className="list-group-item text-center"
                    style={{ padding: "1rem 0" }}
                    key={task.id}
                  >
                    {task.title}
                    <br />
                    <button
                      className="default-button default-button-green"
                      onClick={() => {
                        setModalLoading(false)
                        setModalError("")
                        setTaskToBeCompleted(task)
                        setCompleteTaskModal(true)
                      }}
                    >
                      Mark as Complete
                    </button>
                  </li>
                )
              })}
              {overdueTasks.length === 0 && (
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginTop: "1rem",
                  }}
                >
                  This trainee doesn't have any overdue tasks.
                </div>
              )}
            </ul>
          </div>
          <div className="col-sm-3">
            <ul className="list-group due-list">
              <li className="list-group-item list-group-item-warning text-center">
                <FontAwesomeIcon icon={faTasks} /> Due Tasks
              </li>
              {dueTasks.map((task) => {
                return (
                  <li
                    className="list-group-item text-center"
                    style={{ padding: "1rem 0" }}
                    key={task.id}
                  >
                    {task.title}
                    <br />
                    <button
                      className="default-button default-button-green"
                      onClick={() => {
                        setModalLoading(false)
                        setModalError("")
                        setTaskToBeCompleted(task)
                        setCompleteTaskModal(true)
                      }}
                    >
                      Mark as Complete
                    </button>
                  </li>
                )
              })}
              {dueTasks.length === 0 && (
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginTop: "1rem",
                  }}
                >
                  This trainee doesn't have any due tasks.
                </div>
              )}
            </ul>
          </div>
          <div className="col-sm-3">
            <ul className="list-group coming-due-list">
              <li
                className="list-group-item text-center "
                style={{ backgroundColor: "#CAE9F5" }}
              >
                <FontAwesomeIcon icon={faTasks} /> Coming Due Tasks
              </li>
              {comingDueTasks.map((task) => {
                return (
                  <li
                    className="list-group-item text-center"
                    style={{ padding: "1rem 0" }}
                    key={task.id}
                  >
                    {task.title}
                    <br />
                    <button
                      className="default-button default-button-green"
                      onClick={() => {
                        setModalLoading(false)
                        setModalError("")
                        setTaskToBeCompleted(task)
                        setCompleteTaskModal(true)
                      }}
                    >
                      Mark as Complete
                    </button>
                  </li>
                )
              })}
              {comingDueTasks.length === 0 && (
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginTop: "1rem",
                  }}
                >
                  This trainee doesn't have any coming due tasks.
                </div>
              )}
            </ul>
          </div>
          <div className="col-sm-3">
            <ul className="list-group coming-due-list">
              <li
                className="list-group-item text-center "
                style={{ backgroundColor: "var(--green)" }}
              >
                <FontAwesomeIcon icon={faTasks} /> Tasks Completed This UTA
              </li>
              {completedTasks.map((task) => {
                if (task.task) {
                  return (
                    <li
                      className="list-group-item text-center"
                      style={{ padding: "1rem 0" }}
                      key={task.task}
                    >
                      {task.finishedAction}
                      <br />
                      <span style={{ color: "gray", fontSize: "0.75rem" }}>
                        Last status:{" "}
                        {task.lastStatus === "ff" || task.lastStatus === "cd"
                          ? "Coming Due"
                          : task.lastStatus === "od"
                            ? "Overdue"
                            : "Due"}
                      </span>
                      <br />
                      <button
                        className="default-button default-button-red"
                        onClick={() => {
                          setModalLoading(false)
                          setModalError("")
                          setTaskToBeCompleted(task)
                          setUndoTaskModal(true)
                        }}
                      >
                        Undo
                      </button>
                    </li>
                  )
                } else {
                  return <></>
                }
              })}
              {completedTasks.length === 0 && (
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginTop: "1rem",
                  }}
                >
                  This trainee doesn't have any tasks completed this UTA.
                </div>
              )}
            </ul>
          </div>
        </div>
      </div>
      {/* Complete Task Modal */}
      <Modal
        styles={modalStyles}
        open={completeTaskModal}
        onClose={() => setCompleteTaskModal(false)}
        center
        focusTrapped={false}
      >
        <h5 className="modal-title">Complete Task</h5>
        <hr />
        <p className="modal-subtitle">
          Are you sure you want to complete this task:{" "}
          <b>{taskToBeCompleted.title}</b>? This action can be undone in the
          finished task log.
        </p>
        {modalError && (
          <div className="h6 text-center text-danger">{modalError}</div>
        )}
        <button
          className="default-button float-right"
          style={{ marginLeft: "0.5rem" }}
          disabled={modalLoading}
          onClick={handleComplete}
        >
          Complete Task
        </button>
        <button
          className="default-button default-button-gray float-right"
          onClick={() => setCompleteTaskModal(false)}
          disabled={modalLoading}
        >
          Cancel
        </button>
      </Modal>
      {/* Undo Task Modal */}
      <Modal
        styles={modalStylesDanger}
        open={undoTaskModal}
        onClose={() => setUndoTaskModal(false)}
        center
        focusTrapped={false}
      >
        <h5 className="modal-title">Undo Task</h5>
        <hr />
        <p className="modal-subtitle">
          Are you sure you want to undo this task:{" "}
          <b>{taskToBeCompleted.finishedAction}</b>?
        </p>
        {modalError && (
          <div className="h6 text-center text-danger">{modalError}</div>
        )}
        <button
          className="default-button float-right default-button-red"
          style={{ marginLeft: "0.5rem" }}
          disabled={modalLoading}
          onClick={handleUndo}
        >
          Undo Task
        </button>
        <button
          className="default-button float-right"
          onClick={() => setUndoTaskModal(false)}
          disabled={modalLoading}
        >
          Cancel
        </button>
      </Modal>
    </>
  )
}

const DocumentsTab = ({ documents, documentLoading, getTraineeDocuments }) => {
  return (
    <div className="container-fluid fade-in" style={{ padding: "1rem 0" }}>
      {documentLoading ? (
        <SmallLoader />
      ) : !documents || documents.length === 0 ? (
        <div>This trainee does not have any documents</div>
      ) : (
        // <DocumentsView
        //   documents={documents}
        //   getTraineeDocuments={getTraineeDocuments}
        // />
        <></>
      )}
    </div>
  )
}

const DataRow = ({ label, value }) => (
  <div className="row">
    <div className="col-lg-5">
      <h6>
        <strong>{label}</strong>
      </h6>
    </div>
    <div className="col-lg-5">{value ? value : value === 0 ? 0 : "None"}</div>
  </div>
)

const FormField = ({ title, value, type, setter }) => (
  <>
    <p>{title}</p>
    <div className="input-group mb-3">
      <input
        style={{ color: "rgba(100,100,100,0.8)" }}
        type={type}
        className="textinput textInput form-control"
        value={value}
        onChange={(v) => {
          setter(v.target.value)
        }}
      />
    </div>
  </>
)

const InformationTab = ({ trainee, getInfo }) => {
  const [editView, setEditView] = useState(false)

  const [loading, setLoading] = useState(false)

  // Form states
  const [phoneNumber, setPhoneNumber] = useState(trainee.phoneNumber)
  const [certLevel, setCertLevel] = useState(trainee.certLevel)
  const [semesters, setSemesters] = useState(trainee.active_semesters)
  const [rank, setRank] = useState(trainee.rank)
  const [year, setYear] = useState(trainee.year)
  const [dateJoined, setDateJoined] = useState(trainee.date_joined)
  const [expiration, setExpiration] = useState(trainee.expiration)
  const [height, setHeight] = useState(trainee.height)
  const [shirtSize, setShirtSize] = useState(trainee.shirt_size)
  const [pantSize, setPantSize] = useState(trainee.pant_size)
  const [pantDimension, setPantDimension] = useState(trainee.pant_dimensions)

  const updateTraineeInfo = (e) => {
    e.preventDefault()
    setLoading(true)

    const body = {
      phoneNumber: phoneNumber ? phoneNumber : "",
      id: trainee.id,
      certLevel: certLevel,
      active_semesters: parseInt(semesters),
      rank: rank,
      year: parseInt(year),
      date_joined: dateJoined,
      expiration: expiration,
      height: height,
      shirt_size: shirtSize,
      pant_size: pantSize,
      pant_dimensions: pantDimension,
    }

    axios
      .patch("/edit-trainee", body)
      .then((res) => {
        getInfo()

        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  return (
    <div className="container-fluid fade-in" style={{ padding: "1rem 0" }}>
      {!editView ? (
        <div className="col-md-10" id="trainee-information">
          <button className="default-button" onClick={() => setEditView(true)}>
            <FontAwesomeIcon icon={faEdit} /> Edit Information
          </button>
          <h3>Personal Details</h3>
          <DataRow label="Phone Number" value={trainee.phoneNumber} />
          <DataRow label="Email" value={trainee.email} />
          <DataRow label="EMP #" value={trainee.emp} />
          <DataRow label="PAFSC" value={trainee.pafsc} />
          <DataRow label="Certification Level" value={trainee.certLevel} />
          <DataRow label="Active Semesters" value={trainee.active_semesters} />
          <DataRow label="Rank" value={trainee.rank} />
          <DataRow label="Year" value={trainee.year} />
          <DataRow
            label="Date Joined"
            value={
              trainee.date_joined
                ? trainee.date_joined
                : trainee.created
                  ? new Date(trainee.created).toDateString().substring(4)
                  : ""
            }
          />
          <DataRow label="Expiration" value={trainee.expiration} />
          <DataRow label="Height" value={trainee.height} />
          <DataRow label="Shirt Size" value={trainee.shirt_size} />
          <DataRow label="Pant Size" value={trainee.pant_size} />
          <DataRow label="Pant Dimensions" value={trainee.pant_dimensions} />
        </div>
      ) : (
        <div className="row edit-info-form-container col-md-6">
          <form onSubmit={updateTraineeInfo}>
            <button
              type="button"
              className="btn btn-outline-danger mb-4  mr-4"
              onClick={() => setEditView(false)}
              style={{ marginRight: 20 }}
            >
              <FontAwesomeIcon icon={faTimes} className="fas fa-times" /> Cancel
            </button>
            <button type="submit" className="btn btn-outline-success mb-4">
              <FontAwesomeIcon
                icon={faCheck}
                className="fas fa-check"
                disabled={loading}
              />{" "}
              Save
            </button>
            <div>
              <p>Phone Number</p>
              <div className="input-group mb-3">
                <PhoneInput
                  style={{}}
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                  defaultCountry="US"
                />
              </div>
            </div>
            <FormField
              title="Certification level"
              type="text"
              value={certLevel}
              setter={setCertLevel}
            />
            <FormField
              title="Active semesters*"
              type="number"
              value={semesters}
              setter={setSemesters}
            />
            <FormField title="Rank" type="text" value={rank} setter={setRank} />
            <FormField
              title="Year"
              type="number"
              value={year}
              setter={setYear}
            />
            <FormField
              title="Date joined"
              type="date"
              value={dateJoined}
              setter={setDateJoined}
            />
            <FormField
              title="Expiration"
              type="date"
              value={expiration}
              setter={setExpiration}
            />
            <FormField
              title="Height"
              type="text"
              value={height}
              setter={setHeight}
            />
            <FormField
              title="Shirt size"
              type="text"
              value={shirtSize}
              setter={setShirtSize}
            />
            <FormField
              title="Pant size"
              type="text"
              value={pantSize}
              setter={setPantSize}
            />
            <FormField
              title="Pant dimension"
              type="text"
              value={pantDimension}
              setter={setPantDimension}
            />
          </form>
        </div>
      )}
    </div>
  )
}

const MessagingTab = ({ messaging, trainee }) => {
  return (
    <div
      style={{
        alignItems: "center",
        backgroundColor: "rgb(217,242,196)",
        width: "50%",
        maxHeight: 800,
        border: "2px",
        borderStyle: "solid",
        borderRadius: "5%",
        borderWidth: "1px",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
      }}
    >
      <SingleChat messaging={messaging} trainee={trainee} />
    </div>
  )
}

const AvailabilityTab = ({ availability, availabilityLoading }) => {
  if (availabilityLoading) {
    return <SmallLoader />
  }

  return (
    <div className="container-fluid fade-in" style={{ padding: "1rem 0" }}>
      <div></div>
    </div>
  )
}

const compG081 = (a, b) => {
  if (a.status === b.status) {
    return a.task_details.title > b.task_details.title ? 1 : -1
  } else if (a.status === "od" || (a.status === "d" && b.status !== "od")) {
    return -1
  } else if (b.status === "od" || (b.status === "d" && a.status !== "od")) {
    return 1
  }
  return a.task_details.title > b.task_details.title ? 1 : -1
}

const G081Tab = ({ tasks }) => {
  const [sortedTasks, setSortedTasks] = useState([])
  useEffect(() => {
    setSortedTasks(tasks.sort(compG081))
  }, [tasks])
  return (
    <div className="container-fluid fade-in" style={{ padding: "1rem 0" }}>
      <table
        className="table table-hover table-responsive text-center"
        style={{ backgroundColor: "#eaecf4" }}
      >
        <thead className="thead-light">
          <tr>
            <th scope="col">PFX</th>
            <th scope="col">Course Code</th>
            <th scope="col">Title</th>
            <th scope="col">Freq</th>
            <th scope="col">APR Status</th>
            <th scope="col">C/W</th>
            <th scope="col">Due</th>
            <th scope="col">Sched</th>
          </tr>
        </thead>
        <tbody>
          {sortedTasks.map((task) => {
            return (
              <tr key={task.id}>
                <td>{task.task_details.pfx}</td>
                <td>{task.task_details.course}</td>
                <td>{task.task_details.title}</td>
                <td>{task.task_details.freq}</td>
                <td>
                  <h5>
                    {task.status === "od" ? (
                      <span
                        className="badge badge-danger"
                        style={{ backgroundColor: "#e74a3b" }}
                      >
                        Unqualified
                      </span>
                    ) : task.status === "d" ? (
                      <span
                        className="badge badge-primary"
                        style={{ backgroundColor: "var(--blue)" }}
                      >
                        Awaiting Action
                      </span>
                    ) : (
                      <span
                        className="badge badge-success"
                        style={{ backgroundColor: "#1cc88a" }}
                      >
                        Qualified
                      </span>
                    )}
                  </h5>
                </td>
                <td>{task.task_details.cw}</td>
                <td>{task.task_details.due}</td>
                <td>{task.task_details.sched}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

const DentalTab = ({ task }) => {
  return (
    <div className="container-fluid fade-in" style={{ padding: "1rem 0" }}>
      <table
        className="table table-hover table-responsive text-center"
        style={{ backgroundColor: "#eaecf4" }}
      >
        <thead className="thead-light">
          <tr>
            <th scope="col">Dental</th>
            <th scope="col">Date Next</th>
            <th scope="col">Service Member</th>
            <th scope="col">Next Exam</th>
            <th scope="col">Next Scheduled</th>
            <th scope="col">Comments</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <h5>
                <span
                  className="badge"
                  style={{ backgroundColor: "var(--green)" }}
                >
                  {task.task_details.dental}
                </span>
              </h5>
            </td>
            <td>
              <h5>
                <span
                  className="badge"
                  style={{
                    backgroundColor:
                      task.status === "od" ? "var(--red)" : "gray",
                  }}
                >
                  {task.task_details.date_next}
                </span>
              </h5>
            </td>
            <td>{task.task_details.name}</td>
            <td>{task.task_details.next_exam}</td>
            <td>
              {task.task_details.next_scheduled === "nan"
                ? ""
                : task.task_details.next_scheduled}
            </td>
            <td>
              {task.task_details.comments === "nan"
                ? ""
                : task.task_details.comments}
            </td>
          </tr>
        </tbody>
      </table>
      {task.status === "od" ? (
        <h5 style={{ color: "var(--red)", fontWeight: "700" }}>
          <FontAwesomeIcon icon={faTimesCircle} /> Dental is unqualified
        </h5>
      ) : (
        <h5 style={{ color: "var(--green)", fontWeight: "700" }}>
          <FontAwesomeIcon icon={faCheckCircle} /> Dental is qualified
        </h5>
      )}
    </div>
  )
}

const ImrTab = ({ tasks }) => {
  const GreenDisplay = ({ children }) => {
    return (
      <div
        style={{
          backgroundColor: "var(--green)",
          width: "2rem",
          borderRadius: "3px",
          fontWeight: "bold",
          color: "white",
          margin: "auto",
        }}
      >
        {children}
      </div>
    )
  }

  const YellowDisplay = ({ children }) => {
    return (
      <div
        style={{
          backgroundColor: "var(--yellow)",
          width: "2rem",
          borderRadius: "3px",
          fontWeight: "bold",
          color: "white",
          margin: "auto",
        }}
      >
        {children}
      </div>
    )
  }

  const RedDisplay = ({ children }) => {
    return (
      <div
        style={{
          backgroundColor: "var(--red)",
          width: "2rem",
          borderRadius: "3px",
          fontWeight: "bold",
          color: "white",
          margin: "auto",
        }}
      >
        {children}
      </div>
    )
  }
  return (
    <div className="container-fluid fade-in" style={{ padding: "1rem 0" }}>
      <table
        className="table table-hover table-responsive text-center"
        style={{ backgroundColor: "#eaecf4" }}
      >
        <thead className="thead-light">
          <tr>
            <th scope="col">Imm</th>
            <th scope="col">Den</th>
            <th scope="col">Lab</th>
            <th scope="col">DLC</th>
            <th scope="col">PHA</th>
            <th scope="col">Eqp</th>
            <th scope="col">IMR</th>
            <th scope="col">Go Red Date</th>
            <th scope="col">Action List</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {tasks[0].task_details.imm === "G" && (
                <GreenDisplay>G</GreenDisplay>
              )}
              {tasks[0].task_details.imm === "Y" && (
                <YellowDisplay>Y</YellowDisplay>
              )}
              {tasks[0].task_details.imm === "R" && <RedDisplay>R</RedDisplay>}
            </td>
            <td>
              {tasks[0].task_details.den === 1 && (
                <GreenDisplay>1</GreenDisplay>
              )}
              {tasks[0].task_details.den === 2 && (
                <YellowDisplay>2</YellowDisplay>
              )}
              {tasks[0].task_details.den === 3 && <RedDisplay>3</RedDisplay>}
              {tasks[0].task_details.den === 4 && <RedDisplay>4</RedDisplay>}
            </td>
            <td>
              {tasks[0].task_details.lab === "G" && (
                <GreenDisplay>G</GreenDisplay>
              )}
              {tasks[0].task_details.lab === "Y" && (
                <YellowDisplay>Y</YellowDisplay>
              )}
              {tasks[0].task_details.lab === "R" && <RedDisplay>R</RedDisplay>}
            </td>
            <td>
              {!tasks[0].task_details.dlc ||
              tasks[0].task_details.dlc === "nan" ? (
                <></>
              ) : (
                <RedDisplay>{tasks[0].task_details.dlc}</RedDisplay>
              )}
            </td>
            <td>
              {tasks[0].task_details.pha === "G" && (
                <GreenDisplay>G</GreenDisplay>
              )}
              {tasks[0].task_details.pha === "Y" && (
                <YellowDisplay>Y</YellowDisplay>
              )}
              {tasks[0].task_details.pha === "R" && <RedDisplay>R</RedDisplay>}
            </td>
            <td>
              {tasks[0].task_details.eqp === "G" && (
                <GreenDisplay>G</GreenDisplay>
              )}
              {tasks[0].task_details.eqp === "Y" && (
                <YellowDisplay>Y</YellowDisplay>
              )}
              {tasks[0].task_details.eqp === "R" && <RedDisplay>R</RedDisplay>}
            </td>
            <td>
              {tasks[0].task_details.imr === "G" && (
                <GreenDisplay>G</GreenDisplay>
              )}
              {tasks[0].task_details.imr === "Y" && (
                <YellowDisplay>Y</YellowDisplay>
              )}
              {tasks[0].task_details.imr === "R" && <RedDisplay>R</RedDisplay>}
            </td>
            <td>
              {!tasks[0].task_details.go_red_date ||
              tasks[0].task_details.go_red_date === "nan"
                ? ""
                : tasks[0].task_details.go_red_date.substring(0, 10)}
            </td>
            <td>{tasks[0].task_details.action_list}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

const PhaTab = ({ task }) => {
  return (
    <div className="container-fluid fade-in" style={{ padding: "1rem 0" }}>
      <table
        className="table table-hover table-responsive text-center"
        style={{ backgroundColor: "#eaecf4" }}
      >
        <thead className="thead-light">
          <tr>
            <th>Appt</th>
            <th>Unit</th>
            <th>Name</th>
            <th>Rank</th>
            <th>Appt Type</th>
            <th>FileSort</th>
            <th>HA Date</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{task.task_details.appt}</td>
            <td>{task.task_details.unit}</td>
            <td>{task.task_details.name}</td>
            <td>{task.task_details.rank}</td>
            <td>{task.task_details.appt_type}</td>
            <td>{task.task_details.file_sort}</td>
            <td>{task.task_details.ha_date}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

const PhaqTab = ({ task }) => {
  return (
    <div className="container-fluid fade-in" style={{ padding: "1rem 0" }}>
      <table
        className="table table-hover table-responsive text-center"
        style={{ backgroundColor: "#eaecf4" }}
      >
        <thead className="thead-light">
          <tr>
            <th>Last Review</th>
            <th>HA Date</th>
            <th>C/W Status</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{task.task_details.last_review}</td>
            <td>{task.task_details.ha_date}</td>
            <td>{task.task_details.cw_status}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

const GtcTab = ({ tasks }) => {
  return (
    <div className="container-fluid fade-in" style={{ padding: "1rem 0" }}>
      <table
        className="table table-hover table-responsive text-center"
        style={{ backgroundColor: "#eaecf4" }}
      >
        <thead className="thead-light">
          <tr>
            <th>Current Balance</th>
            <th>Last Payment Date</th>
            <th>Past Due Amount</th>
            <th>Payment Due Amount</th>
            <th>Payment Due Date</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map((task) => {
            return (
              <tr key={task.id}>
                <td>{task.task_details.current_balance}</td>
                <td>{task.task_details.last_payment_date}</td>
                <td>{task.task_details.past_due_amount}</td>
                <td>{task.task_details.payment_due_amount}</td>
                <td>{task.task_details.payment_due_date}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

const DeployableTab = ({ tasks }) => {
  return (
    <div className="container-fluid fade-in" style={{ padding: "1rem 0" }}>
      <table
        className="table table-hover table-responsive text-center"
        style={{ backgroundColor: "#eaecf4" }}
      >
        <thead className="thead-light">
          <tr>
            <th>Training Needs</th>
            <th>Training Status</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map((task) => {
            return (
              <tr key={task.id}>
                <td>{task.title}</td>
                <th
                  style={
                    task.task_details.status === "OVERDUE"
                      ? { color: "var(--red)" }
                      : {}
                  }
                >
                  {task.task_details.status}
                </th>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

const GroupTab = ({ tasks }) => {
  const [completeModalOpen, setCompleteModalOpen] = useState(false)
  const [completeTask, setCompleteTask] = useState(tasks[0])
  const [modalError, setModalError] = useState("")
  const [modalLoading, setModalLoading] = useState(false)

  const [displayTasks, setDisplayTasks] = useState(tasks)

  const handleComplete = () => {
    setModalLoading(true)
    setModalError("")

    const body = {
      id: completeTask.id,
      status: "f",
    }

    axios
      .patch("/update-task", body)
      .then(() => {
        // Replace unfinished task with finished in tasks array
        setDisplayTasks((prev) =>
          prev.filter((task) => task.id !== completeTask.id)
        )
        setDisplayTasks((prev) => [...prev, { ...completeTask, status: "f" }])

        setCompleteModalOpen(false)
        setModalLoading(false)
      })
      .catch((err) => {
        console.log(err.response)
        setModalError("An error occured, please try again")
        setModalLoading(false)
      })
  }

  return (
    <div className="container-fluid fade-in" style={{ padding: "1rem 0" }}>
      <table
        className="table table-hover table-responsive text-center"
        style={{ backgroundColor: "#eaecf4" }}
      >
        <thead className="thead-light">
          <tr>
            <th>Task Title</th>
            <th>Task Status</th>
            <th>Complete Task</th>
          </tr>
        </thead>
        <tbody>
          {displayTasks.map((task) => {
            return (
              <tr key={task.id}>
                <td>{task.title}</td>
                {task.status === "f" ? (
                  <th style={{ color: "var(--green)" }}>Finished</th>
                ) : (
                  <th style={{ color: "var(--red)" }}>Not Finished</th>
                )}
                <td>
                  {task.status !== "f" && (
                    <button
                      className="default-button"
                      style={{
                        margin: 0,
                        fontSize: "0.8rem",
                        padding: "0.25rem",
                      }}
                      onClick={() => {
                        setCompleteTask(task)
                        setCompleteModalOpen(true)
                        setModalError("")
                      }}
                    >
                      Complete
                    </button>
                  )}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      {/* Complete Task Modal */}
      <Modal
        styles={modalStyles}
        open={completeModalOpen}
        onClose={() => setCompleteModalOpen(false)}
        center
        focusTrapped={false}
      >
        <h5 className="modal-title">
          Are you sure you want to complete the following task:{" "}
          <b>{completeTask.title}?</b>
        </h5>
        <hr />
        <p className="modal-subtitle">
          You will be able to undo this action in the complete task log.
        </p>
        {modalError && (
          <div className="h6 text-center text-danger">{modalError}</div>
        )}
        <button
          className="default-button float-right"
          style={{ marginLeft: "0.5rem" }}
          onClick={handleComplete}
          disabled={modalLoading}
        >
          Complete Task
        </button>
        <button
          className="default-button default-button-gray float-right"
          onClick={() => setCompleteModalOpen(false)}
          disabled={modalLoading}
        >
          Cancel
        </button>
      </Modal>
    </div>
  )
}

const GtcTrainTab = ({ tasks }) => {
  function addYears(date, years) {
    if (date) {
      let result = new Date(date)
      result.setYear(result.getFullYear() + years)
      return result.toDateString()
    }
    return ""
  }

  function formatDate(date) {
    if (date) {
      const newDate = new Date(date)
      return newDate.toDateString()
    }
    return "N/A"
  }

  function remainingDays(date) {
    if (date) {
      let today = new Date()
      let dueDate = new Date(date)
      let dayMs = 1000 * 60 * 60 * 24
      let remainingDays = Math.ceil(
        (dueDate.getTime() - today.getTime()) / dayMs
      )
      if (remainingDays >= 0) {
        return `${remainingDays} Days`
      }
      if (remainingDays < 0) {
        let daysOverdue = remainingDays * -1
        let daysOverdueMsg = (
          <p style={{ color: "red" }}>{daysOverdue} Days Overdue</p>
        )
        return daysOverdueMsg
      }
    }
    return "N/A"
  }

  return (
    <div className="container-fluid fade-in" style={{ padding: "1rem 0" }}>
      <table
        className="table table-hover table-responsive text-center"
        style={{ backgroundColor: "#eaecf4" }}
      >
        <thead className="thead-light">
          <tr>
            <th scope="col">Title</th>
            <th scope="col">Last Test Date</th>
            <th scope="col">Days Until Due Date</th>
            <th scope="col">Due Date</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map((task) => {
            const due_date_display =
              addYears(task.task_details.date, 3) || "N/A"
            return (
              <tr key={task.task_details.pk}>
                <td>{task.task_details.title}</td>
                <td>{formatDate(task.task_details.date)}</td>
                <td>{remainingDays(addYears(task.task_details.date, 3))}</td>
                <td>{due_date_display}</td>
                <td>{verboseStatusFromCode[task.status]}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

const ReenlistmentTab = ({ tasks }) => {
  function formatDate(date) {
    const newDate = new Date(date)
    return newDate.toDateString()
  }

  function remainingDays(date) {
    if (date) {
      let today = new Date()
      let dueDate = new Date(date)
      let dayMs = 1000 * 60 * 60 * 24
      let remainingDays = Math.ceil(
        (dueDate.getTime() - today.getTime()) / dayMs
      )
      if (remainingDays > 0) {
        return `${remainingDays} Days`
      }
      if (remainingDays < 0) {
        let daysOverdue = remainingDays * -1
        let daysOverdueMsg = (
          <p style={{ color: "red" }}>{daysOverdue} Days Overdue</p>
        )
        return daysOverdueMsg
      }
      return ""
    }
  }

  function statusOld(date) {
    if (date === "") {
      return "Compliant"
    }
    const daysLeft = parseInt(remainingDays(date))
    if (daysLeft > 90) {
      return "Compliant"
    } else if (daysLeft > 60) {
      return "Due within 90 days"
    } else if (daysLeft > 30) {
      return "Due within 60 days"
    } else if (daysLeft > 0) {
      return "Due within 30 days"
    }
    return "Overdue"
  }

  return (
    <div className="container-fluid fade-in" style={{ padding: "1rem 0" }}>
      <table
        className="table table-hover table-responsive text-center"
        style={{ backgroundColor: "#eaecf4" }}
      >
        <thead className="thead-light">
          <tr>
            <th scope="col">Task</th>
            <th scope="col">Time to ETS</th>
            <th scope="col">ETS</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map((task) => {
            return (
              <tr key={task.task_details.pk}>
                <td>{task.task_details.task}</td>
                <td>
                  {task.task_details.ets
                    ? remainingDays(task.task_details.ets)
                    : "N/A"}
                </td>
                <td>
                  {task.task_details.ets
                    ? formatDate(task.task_details.ets)
                    : "N/A"}
                </td>
                <td>{verboseStatusFromCode[task.status]}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

const UnpaidTab = ({ tasks }) => {
  return (
    <div className="container-fluid fade-in" style={{ padding: "1rem 0" }}>
      <table
        className="table table-hover table-responsive text-center"
        style={{ backgroundColor: "#eaecf4" }}
      >
        <thead className="thead-light">
          <tr>
            <th scope="col">Unpaid Order Number</th>
            <th scope="col">Notes</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map((task) => {
            return (
              <tr key={task.task_details.pk}>
                <td>{task.task_details.order_number}</td>
                <td>{task.task_details.notes}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

const TravelVoucherTab = ({ tasks }) => {
  function formatDate(date) {
    const newDate = new Date(date)
    return newDate.toDateString()
  }

  function sinceDays(date) {
    let today = new Date()
    let dueDate = new Date(date)
    let dayMs = 1000 * 60 * 60 * 24
    let sinceDays = `${Math.ceil(
      (today.getTime() - dueDate.getTime()) / dayMs
    )}`
    return sinceDays
  }

  return (
    <div className="container-fluid fade-in" style={{ padding: "1rem 0" }}>
      <table
        className="table table-hover table-responsive text-center"
        style={{ backgroundColor: "#eaecf4" }}
      >
        <thead className="thead-light">
          <tr>
            <th scope="col">Travel Order</th>
            <th scope="col">Days Old</th>
            <th scope="col">Last Day Travel QBE</th>
            <th scope="col">Status</th>
            <th scope="col">Status Details</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map((task) => {
            return (
              <tr key={task.task_details.pk}>
                <td>{task.task_details.travel_order}</td>
                <td>{sinceDays(task.task_details.last_day_travel_qbe)}</td>
                <td>{formatDate(task.task_details.last_day_travel_qbe)}</td>
                <td>{verboseStatusFromCode[task.status]}</td>
                <td>{task.task_details.status_details}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

const CprTab = ({ tasks }) => {
  function formatDate(date) {
    if (!date) {
      return ""
    }
    const newDate = new Date(date)
    return newDate.toDateString()
  }

  function remainingDays(date) {
    let today = new Date()
    let dueDate = new Date(date)
    let dayMs = 1000 * 60 * 60 * 24
    let remainingDays = Math.ceil((dueDate.getTime() - today.getTime()) / dayMs)
    if (remainingDays > 0) {
      return `${remainingDays} Days`
    }
    if (remainingDays < 0) {
      let daysOverdue = remainingDays * -1
      let daysOverdueMsg = (
        <p style={{ color: "red" }}>{daysOverdue} Days Overdue</p>
      )
      return daysOverdueMsg
    }
    return ""
  }

  return (
    <div className="container-fluid fade-in" style={{ padding: "1rem 0" }}>
      <table
        className="table table-hover table-responsive text-center"
        style={{ backgroundColor: "#eaecf4" }}
      >
        <thead className="thead-light">
          <tr>
            <th scope="col">Course Narrative</th>
            <th scope="col">Dur</th>
            <th scope="col">Days Until Due Date</th>
            <th scope="col">Due Date</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map((task) => {
            return (
              <tr key={task.task_details.pk}>
                <td>{task.task_details.course_narrative}</td>
                <td>{task.task_details.dur}</td>
                <td>
                  {task.task_details.due_dt
                    ? remainingDays(task.task_details.due_dt)
                    : "N/A"}
                </td>
                <td>
                  {task.task_details.due_dt
                    ? formatDate(task.task_details.due_dt)
                    : "N/A"}
                </td>
                <td>{verboseStatusFromCode[task.status]}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

const JstoTab = ({ tasks }) => {
  function formatDate(date) {
    if (!date) {
      return ""
    }
    const newDate = new Date(date)
    return newDate.toDateString()
  }

  function remainingDays(date) {
    let today = new Date()
    let dueDate = new Date(date)
    let dayMs = 1000 * 60 * 60 * 24
    let remainingDays = Math.ceil((dueDate.getTime() - today.getTime()) / dayMs)
    if (remainingDays > 0) {
      return `${remainingDays} Days`
    }
    if (remainingDays < 0) {
      let daysOverdue = remainingDays * -1
      let daysOverdueMsg = (
        <p style={{ color: "red" }}>{daysOverdue} Days Overdue</p>
      )
      return daysOverdueMsg
    }
    return ""
  }

  return (
    <div className="container-fluid fade-in" style={{ padding: "1rem 0" }}>
      <table
        className="table table-hover table-responsive text-center"
        style={{ backgroundColor: "#eaecf4" }}
      >
        <thead className="thead-light">
          <tr>
            <th scope="col">Course Narrative</th>
            <th scope="col">Dur</th>
            <th scope="col">Days Until Due Date</th>
            <th scope="col">Due Date</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map((task) => {
            return (
              <tr key={task.task_details.pk}>
                <td>{task.task_details.course_narrative}</td>
                <td>{task.task_details.dur}</td>
                <td>
                  {task.task_details.due_dt
                    ? remainingDays(task.task_details.due_dt)
                    : "N/A"}
                </td>
                <td>
                  {task.task_details.due_dt
                    ? formatDate(task.task_details.due_dt)
                    : "N/A"}
                </td>
                <td>{verboseStatusFromCode[task.status]}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

const VREDTab = ({ tasks }) => {
  return (
    <div className="container-fluid fade-in" style={{ padding: "1rem 0" }}>
      <table
        className="table table-hover table-responsive text-center"
        style={{ backgroundColor: "#eaecf4" }}
      >
        <thead className="thead-light">
          <tr>
            <th scope="col">DAS Assigned MPF</th>
            <th scope="col">Last VRED Update</th>
            <th scope="col">Remarks</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map((task) => {
            return (
              <tr key={task.task_details.pk}>
                <td>{task.task_details.das_assigned_mpf}</td>
                <td>{task.task_details.last_vred_update}</td>
                <td>{task.task_details.remarks}</td>
                <td>{verboseStatusFromCode[task.status]}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

const FitnessTab = ({ tasks }) => {
  return (
    <div className="container-fluid fade-in" style={{ padding: "1rem 0" }}>
      <table
        className="table table-hover table-responsive text-center"
        style={{ backgroundColor: "#eaecf4" }}
      >
        <thead className="thead-light">
          <tr>
            <th scope="col">Due Date</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map((task) => {
            return (
              <tr key={task.task_details.pk}>
                <td>{task.task_details.test_date}</td>
                <td>{verboseStatusFromCode[task.status]}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

const GovTab = ({ tasks }) => {
  function formatDate(date) {
    if (date) {
      const newDate = new Date(date)
      return newDate.toDateString()
    }
  }

  function remainingDays(date) {
    if (date) {
      let today = new Date()
      let dueDate = new Date(date)
      let dayMs = 1000 * 60 * 60 * 24
      let remainingDays = Math.ceil(
        (dueDate.getTime() - today.getTime()) / dayMs
      )
      if (remainingDays > 0) {
        return `${remainingDays} Days`
      }
      if (remainingDays < 0) {
        let daysOverdue = remainingDays * -1
        let daysOverdueMsg = (
          <p style={{ color: "red" }}>{daysOverdue} Days Overdue</p>
        )
        return daysOverdueMsg
      }
      return ""
    }
  }

  return (
    <div className="container-fluid fade-in" style={{ padding: "1rem 0" }}>
      <table
        className="table table-hover table-responsive text-center"
        style={{ backgroundColor: "#eaecf4" }}
      >
        <thead className="thead-light">
          <tr>
            <th scope="col">Days Until Due</th>
            <th scope="col">Date Expires</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map((task) => {
            return (
              <tr key={task.task_details.pk}>
                <td>
                  {!task.task_details.date_expires
                    ? "N/A"
                    : remainingDays(task.task_details.date_expires)}
                </td>
                <td>
                  {!task.task_details.date_expires
                    ? "N/A"
                    : formatDate(task.task_details.date_expires)}
                </td>
                <td>
                  {!task.status
                    ? "Overdue"
                    : verboseStatusFromCode[task.status]}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

const AirfieldTab = ({ tasks }) => {
  function formatDate(date) {
    if (date) {
      const newDate = new Date(date)
      return newDate.toDateString()
    }
  }

  function remainingDays(date) {
    if (date) {
      let today = new Date()
      let dueDate = new Date(date)
      let dayMs = 1000 * 60 * 60 * 24
      let remainingDays = Math.ceil(
        (dueDate.getTime() - today.getTime()) / dayMs
      )
      if (remainingDays > 0) {
        return `${remainingDays} Days`
      }
      if (remainingDays < 0) {
        let daysOverdue = remainingDays * -1
        let daysOverdueMsg = (
          <p style={{ color: "red" }}>{daysOverdue} Days Overdue</p>
        )
        return daysOverdueMsg
      }
      return ""
    }
  }

  return (
    <div className="container-fluid fade-in" style={{ padding: "1rem 0" }}>
      <table
        className="table table-hover table-responsive text-center"
        style={{ backgroundColor: "#eaecf4" }}
      >
        <thead className="thead-light">
          <tr>
            <th scope="col">Days Until Due</th>
            <th scope="col">Date Expires</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map((task) => {
            return (
              <tr key={task.task_details.pk}>
                <td>
                  {!task.task_details.date_expires
                    ? "N/A"
                    : remainingDays(task.task_details.date_expires)}
                </td>
                <td>
                  {!task.task_details.date_expires
                    ? "N/A"
                    : formatDate(task.task_details.date_expires)}
                </td>
                <td>
                  {!task.status
                    ? "Overdue"
                    : verboseStatusFromCode[task.status]}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

const TFATTab = ({ tasks }) => {
  function formatDate(date) {
    const newDate = new Date(date)
    return newDate.toDateString()
  }

  function remainingDays(date) {
    let today = new Date()
    let dueDate = new Date(date)
    let dayMs = 1000 * 60 * 60 * 24
    let remainingDays = Math.ceil((dueDate.getTime() - today.getTime()) / dayMs)
    if (remainingDays > 0) {
      return `${remainingDays} Days`
    }
    if (remainingDays < 0) {
      let daysOverdue = remainingDays * -1
      let daysOverdueMsg = (
        <p style={{ color: "red" }}>{daysOverdue} Days Overdue</p>
      )
      return daysOverdueMsg
    }
    return ""
  }

  return (
    <div className="container-fluid fade-in" style={{ padding: "1rem 0" }}>
      <table
        className="table table-hover table-responsive text-center"
        style={{ backgroundColor: "#eaecf4" }}
      >
        <thead className="thead-light">
          <tr>
            <th scope="col">Title</th>
            <th scope="col">Days Until Due Date</th>
            <th scope="col">Due Date</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map((task) => {
            return (
              <tr key={task.task_details.pk}>
                <td>{task.task_details.title}</td>
                <td>
                  {task.task_details.due_date
                    ? remainingDays(task.task_details.due_date)
                    : "N/A"}
                </td>
                <td>
                  {task.task_details.due_date
                    ? formatDate(task.task_details.due_date)
                    : "N/A"}
                </td>
                <td>{verboseStatusFromCode[task.status]}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

const NotesTabRow = ({
  notes,
  note,
  setNotes,
  formatDate,
  removeNote,
  setDeleteModalOpen,
  handleDeleteNote,
  setDeleteNote,
  setDeleteError,
  setEditModalOpen,
  setNoteToEdit,
}) => {
  return (
    <tr key={note.id}>
      <td>{formatDate(note.created)}</td>
      <td style={{ "white-space": "pre-line" }}>{note.text}</td>
      <td>
        <button
          onClick={() => {
            setDeleteModalOpen(true)
            setDeleteNote(note.id)
            setDeleteError("")
          }}
          className="btn"
        >
          <FontAwesomeIcon
            icon={faTrash}
            style={{
              color: "var(--red)",
              marginLeft: "0.5rem",
              cursor: "pointer",
            }}
          />
          {""}
        </button>
      </td>
    </tr>
  )
}

const NotesTab = ({ notes, setNotes, trainee }) => {
  const [note, setNote] = useState("")

  //States for deletion of note
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [deleteNote, setDeleteNote] = useState()
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [deleteError, setDeleteError] = useState("")

  function formatDate(date) {
    if (date) {
      const newDate = new Date(date)
      return newDate.toDateString()
    }
    return "N/A"
  }

  function submitHandler(event) {
    event.preventDefault()

    const body = {
      text: note,
      trainee: trainee.id,
    }

    axios
      .post(`/notes/`, body)
      .then((res) => {
        setNotes((prevState) => [res.data, ...prevState])
      })
      .catch((err) => {
        console.log(err)
      })
    setNote("")
  }

  function changeHandler(event) {
    event.preventDefault()
    setNote(event.target.value)
  }

  const handleDeleteNote = (e) => {
    e.preventDefault()

    setDeleteError("")
    setDeleteLoading(true)

    axios
      .delete(`/notes/${deleteNote}`)
      .then(() => {
        setDeleteModalOpen(false)
        setDeleteLoading(false)
        setDeleteError("")
        // Filter out deleted note
        setNotes((prev) => prev.filter((note) => note.id !== deleteNote))
      })
      .catch((err) => {
        setDeleteError("An unexpected error has occured, please try again!")
        setDeleteLoading(false)
        console.log(err.response)
      })
  }

  return (
    <>
      <div className="container-fluid fade-in" style={{ padding: "1rem 0" }}>
        <div className="px-5 mx-5">
          <table
            className="table table-hover table-responsive"
            style={{ width: "100%", backgroundColor: "#eaecf4" }}
          >
            <col style={{ width: "10%" }}></col>
            <col style={{ width: "85%" }}></col>
            <col style={{ width: "5%" }}></col>
            <thead className="thead-light">
              <tr>
                <th>Date Created</th>
                <th>Note</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{formatDate(new Date())}</td>
                <td>
                  <div className="form-group">
                    <textarea
                      id="notes-title"
                      type="text"
                      name="note"
                      value={note}
                      className="form-control"
                      placeholder="Write your note here..."
                      onChange={changeHandler}
                    />
                  </div>
                </td>
                <td>
                  <button className="default-button" onClick={submitHandler}>
                    <FontAwesomeIcon icon={faPlus} />
                    {""}
                  </button>
                </td>
              </tr>
              {notes.map((note) => {
                return (
                  <NotesTabRow
                    key={note.id}
                    note={note}
                    notes={notes}
                    setNotes={setNotes}
                    formatDate={formatDate}
                    setDeleteModalOpen={setDeleteModalOpen}
                    handleDeleteNote={handleDeleteNote}
                    setDeleteNote={setDeleteNote}
                    setDeleteError={setDeleteError}
                  />
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        styles={modalStylesDanger}
        open={deleteModalOpen}
        center
        focusTrapped={false}
        onClose={() => setDeleteModalOpen(false)}
      >
        <h5 className="modal-title">Delete Note Record</h5>
        <hr />
        <p className="modal-subtitle">
          Are you sure you want to delete this note record? This action cannot
          be undone.
        </p>
        <br />
        {deleteError && (
          <div
            className="h6 text-center text-danger"
            style={{ flexBasis: "100%" }}
          >
            {deleteError}
          </div>
        )}
        <button
          className="default-button default-button-red float-right"
          style={{ marginLeft: "0.5rem" }}
          onClick={handleDeleteNote}
          disabled={deleteLoading}
        >
          Delete
        </button>
        <button
          className="default-button float-right"
          type="reset"
          onClick={(e) => setDeleteModalOpen(false)}
          disabled={deleteLoading}
        >
          Cancel
        </button>
      </Modal>
    </>
  )
}

const ImdsTab = ({ tasks }) => {
  function formatDate(date) {
    if (!date) {
      return ""
    }
    const newDate = new Date(date)
    return newDate.toDateString()
  }

  function remainingDays(date) {
    let today = new Date()
    let dueDate = new Date(date)
    let dayMs = 1000 * 60 * 60 * 24
    let remainingDays = Math.ceil((dueDate.getTime() - today.getTime()) / dayMs)
    if (remainingDays > 0) {
      return `${remainingDays} Days`
    }
    if (remainingDays < 0) {
      let daysOverdue = remainingDays * -1
      let daysOverdueMsg = (
        <p style={{ color: "red" }}>{daysOverdue} Days Overdue</p>
      )
      return daysOverdueMsg
    }
    return ""
  }

  return (
    <div className="container-fluid fade-in" style={{ padding: "1rem 0" }}>
      <table
        className="table table-hover table-responsive text-center"
        style={{ backgroundColor: "#eaecf4" }}
      >
        <thead className="thead-light">
          <tr>
            <th scope="col">Course Narrative</th>
            <th scope="col">Dur</th>
            <th scope="col">Status Details</th>
            <th scope="col">Days Until Due Date</th>
            <th scope="col">Status Date</th>
            <th scope="col">Due Date</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map((task) => {
            return (
              <tr key={task.task_details.pk}>
                <td>{task.task_details.course_narrative}</td>
                <td>{task.task_details.dur}</td>
                <td>{task.task_details.status}</td>
                <td>{remainingDays(formatDate(task.task_details.due_dt))}</td>
                <td>{formatDate(task.task_details.stat_dt)}</td>
                <td>{formatDate(task.task_details.due_dt)}</td>
                <td>{verboseStatusFromCode[task.status]}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

const Tfat943Tab = ({ tasks }) => {
  function formatDate(date) {
    if (!date) {
      return ""
    }
    const newDate = new Date(date)
    return newDate.toDateString()
  }
  return (
    <div className="container-fluid fade-in" style={{ padding: "1rem 0" }}>
      <table
        className="table table-hover table-responsive text-center"
        style={{ backgroundColor: "#eaecf4" }}
      >
        <thead className="thead-light">
          <tr>
            <th scope="col">Course Name</th>
            <th scope="col">Date Completed</th>
            <th scope="col">Enrollment Date</th>
            <th scope="col">Grade</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map((task) => {
            return (
              <tr key={task.task_details.pk}>
                <td>{task.title}</td>
                <td>{formatDate(task.task_details.date_completed)}</td>
                <td>{formatDate(task.task_details.enrollment_date)}</td>
                <td>{task.task_details.grade}</td>
                <td>{verboseStatusFromCode[task.status]}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

const Tvl943Tab = ({ tasks }) => {
  const getTaskDisplayString = (tasksAssigned, tasksCompleted) => {
    let assignedString = tasksAssigned ? tasksAssigned : "0"
    let completedString = tasksCompleted ? tasksCompleted : "0"

    return completedString + "/" + assignedString + " Tasks Completed"
  }

  return (
    <div className="container-fluid fade-in" style={{ padding: "1rem 0" }}>
      <table
        className="table table-hover table-responsive text-center"
        style={{ backgroundColor: "#eaecf4" }}
      >
        <thead className="thead-light">
          <tr>
            <th scope="col">CAFSC</th>
            <th scope="col">DAFSC</th>
            <th scope="col">PAFSC</th>
            <th scope="col">AFSC</th>
            <th scope="col">Training Status Code</th>
            <th scope="col">Level 5</th>
            <th scope="col">Level 7</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map((task) => {
            return (
              <tr key={task.task_details.pk}>
                <td>{task.task_details.cafsc}</td>
                <td>{task.task_details.dafsc}</td>
                <td>{task.task_details.pafsc}</td>
                <td>{task.task_details.afb2afsc}</td>
                <td>{task.task_details.training_status_code}</td>
                <td>
                  {getTaskDisplayString(
                    task.task_details.level_5_tasks_assigned,
                    task.task_details.level_5_tasks_completed
                  )}
                </td>
                <td>
                  {getTaskDisplayString(
                    task.task_details.level_7_tasks_assigned,
                    task.task_details.level_7_tasks_completed
                  )}
                </td>
                <td>{verboseStatusFromCode[task.status]}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

const FouoGtc943Tab = ({ tasks }) => {
  function formatDate(date) {
    if (!date) {
      return ""
    }
    const newDate = new Date(date)
    return newDate.toDateString()
  }
  return (
    <div className="container-fluid fade-in" style={{ padding: "1rem 0" }}>
      <table
        className="table table-hover table-responsive text-center"
        style={{ backgroundColor: "#eaecf4" }}
      >
        <thead className="thead-light">
          <tr>
            <th scope="col">TNG Cert</th>
            <th scope="col">Sou</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map((task) => {
            return (
              <tr key={task.task_details.pk}>
                <td>{formatDate(task.task_details.tng_cert)}</td>
                <td>{formatDate(task.task_details.sou)}</td>
                <td>{verboseStatusFromCode[task.status]}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

const GenericTaskTab = ({ tasks }) => {
  return (
    <div className="container-fluid fade-in" style={{ padding: "1rem 0" }}>
      <table
        className="table table-hover table-responsive text-center"
        style={{ backgroundColor: "#eaecf4" }}
      >
        <thead className="thead-light">
          <tr>
            <th scope="col">Task Title</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map((task) => {
            return (
              <tr key={task.id}>
                <td>{task.title}</td>
                <td>{verboseStatusFromCode[task.status]}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

const TabTitle = ({ setCurrentTab, tabName, currentTab, tabTitleOverride }) => {
  return (
    <h4
      className={
        currentTab === tabName
          ? "tab-link header-text tab-link-active"
          : "tab-link header-text"
      }
      onClick={() => setCurrentTab(tabName)}
    >
      {tabTitleOverride ? tabTitleOverride : tabName}
    </h4>
  )
}

const Tabs = ({
  trainee,
  documents,
  documentLoading,
  getTraineeDocuments,
  availability,
  availabilityLoading,
  getInfo,
  messaging,
  notes,
  setNotes,
  completedTasks,
  setCompletedTasks,
}) => {
  const [currentTab, setCurrentTab] = useState("Compliance")

  // States for all task types
  const [dueTasks, setDueTasks] = useState([])
  const [overdueTasks, setOverdueTasks] = useState([])
  const [comingDueTasks, setComingDueTasks] = useState([])

  // State for each task type
  const [g081Tasks, setG081Tasks] = useState([])
  const [imrTasks, setImrTasks] = useState([])
  const [phaTask, setPhaTask] = useState(null)
  const [phaqTask, setPhaqTask] = useState(null)
  const [gtcTasks, setGtcTasks] = useState([])
  const [dentalTask, setDentalTask] = useState(null)
  const [deployableTasks, setDeployableTasks] = useState([])
  const [groupTasks, setGroupTasks] = useState([])
  const [gtcTrainTasks, setGtcTrainTasks] = useState([])
  const [reenlistmentTasks, setReenlistmentTasks] = useState([])
  const [unpaidTasks, setUnpaidTasks] = useState([])
  const [travelTasks, setTravelTasks] = useState([])
  const [fitnessTasks, setFitnessTasks] = useState([])
  const [tfatTasks, setTfatTasks] = useState([])
  const [cprTasks, setCprTasks] = useState([])
  const [imdsTasks, setImdsTasks] = useState([])
  const [jstoTasks, setJstoTasks] = useState([])
  const [govTasks, setGovTasks] = useState([])
  const [airfieldTasks, setAirfieldTasks] = useState([])
  const [vredTasks, setVredTasks] = useState([])
  const [tfat943Tasks, setTfat943Tasks] = useState([])
  const [tvl943Tasks, setTvl943Tasks] = useState([])
  const [fouoGtc943Tasks, setFouoGtc943Tasks] = useState([])

  const [otherTasks, setOtherTasks] = useState([])
  const [otherTaskTypes, setOtherTaskTypes] = useState([])

  // Filter into tasks types
  useEffect(() => {
    setDueTasks([])
    setOverdueTasks([])
    setComingDueTasks([])

    if (trainee.tasks) {
      trainee.tasks.forEach((task) => {
        const status = task.status
        const taskType = task.action_type
        // Compliance statuses
        if (status === "d") {
          setDueTasks((prev) => [...prev, task])
        } else if (status === "od") {
          setOverdueTasks((prev) => [...prev, task])
        } else if (status === "cd") {
          setComingDueTasks((prev) => [...prev, task])
        }

        switch (taskType) {
          case "G081":
            setG081Tasks((prev) => [...prev, task])
            break
          case "IMR":
            if (task.task_details) {
              setImrTasks((prev) => [...prev, task])
            }
            break
          case "PHA":
            setPhaTask(task)
            break
          case "WebHA":
            setPhaqTask(task)
            break
          case "GTC":
            setGtcTasks((prev) => [...prev, task])
            break
          case "Dental":
            setDentalTask(task)
            break
          case "DeployableReq":
            setDeployableTasks((prev) => [...prev, task])
            break
          case "group":
            setGroupTasks((prev) => [...prev, task])
            break
          case "GTCTraining":
            setGtcTrainTasks((prev) => [...prev, task])
            break
          case "Reenlistment":
            setReenlistmentTasks((prev) => [...prev, task])
            break
          case "UnpaidOrder":
            setUnpaidTasks((prev) => [...prev, task])
            break
          case "DMTravelVoucher":
            setTravelTasks((prev) => [...prev, task])
            break
          case "Fitness":
            setFitnessTasks((prev) => [...prev, task])
            break
          case "TFAT":
            setTfatTasks((prev) => [...prev, task])
            break
          case "CPR":
            setCprTasks((prev) => [...prev, task])
            break
          case "JSTO":
            setJstoTasks((prev) => [...prev, task])
            break
          case "GOV":
            setGovTasks((prev) => [...prev, task])
            break
          case "AirfieldDriver":
            setAirfieldTasks((prev) => [...prev, task])
            break
          case "IMDS":
            setImdsTasks((prev) => [...prev, task])
            break
          case "VRED":
            setVredTasks((prev) => [...prev, task])
            break
          case "TFAT_943":
            setTfat943Tasks((prev) => [...prev, task])
            break
          case "TVL_943":
            setTvl943Tasks((prev) => [...prev, task])
            break
          case "FOUO_GTC_943":
            setFouoGtc943Tasks((prev) => [...prev, task])
            break
          default:
            setOtherTasks((prev) => [...prev, task])
            setOtherTaskTypes((prev) => [...new Set(prev)])
            break
        }
      })
    }
  }, [trainee.tasks])

  return (
    <div style={{ padding: "1rem" }}>
      <div className="nav">
        <TabTitle
          setCurrentTab={setCurrentTab}
          currentTab={currentTab}
          tabName="Compliance"
        />
        {/* <TabTitle
          setCurrentTab={setCurrentTab}
          currentTab={currentTab}
          tabName="Documents"
        /> */}
        <TabTitle
          setCurrentTab={setCurrentTab}
          currentTab={currentTab}
          tabName="Information"
        />
        {
          <TabTitle
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
            tabName="Messaging"
          />
        }
        {
          <TabTitle
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
            tabName="Notes"
          />
        }
        {/* <TabTitle
          setCurrentTab={setCurrentTab}
          currentTab={currentTab}
          tabName="Availability"
        /> */}
        {/* Only display headers if trainee has tasks under this type */}
        {g081Tasks.length > 0 && (
          <TabTitle
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
            tabName="G081"
          />
        )}
        {imrTasks.length > 0 && (
          <TabTitle
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
            tabName="IMR"
          />
        )}
        {phaTask && (
          <TabTitle
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
            tabName="PHA"
          />
        )}
        {phaqTask && (
          <TabTitle
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
            tabName="PHAQ"
          />
        )}
        {gtcTasks.length > 0 && (
          <TabTitle
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
            tabName="GTC"
          />
        )}
        {dentalTask && (
          <TabTitle
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
            tabName="Dental"
          />
        )}
        {deployableTasks.length > 0 && (
          <TabTitle
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
            tabName="Deployable Requirement"
          />
        )}
        {groupTasks.length > 0 && (
          <TabTitle
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
            tabName="Group Tasks"
          />
        )}
        {gtcTrainTasks.length > 0 && (
          <TabTitle
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
            tabName="GTC Training"
          />
        )}
        {reenlistmentTasks.length > 0 && (
          <TabTitle
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
            tabName="Enlistment"
          />
        )}
        {unpaidTasks.length > 0 && (
          <TabTitle
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
            tabName="Unpaid Order"
          />
        )}
        {travelTasks.length > 0 && (
          <TabTitle
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
            tabName="Travel Voucher"
          />
        )}
        {tfatTasks.length > 0 && (
          <TabTitle
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
            tabName="TFAT"
          />
        )}
        {cprTasks.length > 0 && (
          <TabTitle
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
            tabName="CPR"
          />
        )}
        {imdsTasks.length > 0 && (
          <TabTitle
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
            tabName="IMDS"
          />
        )}
        {jstoTasks.length > 0 && (
          <TabTitle
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
            tabName="JSTO"
          />
        )}
        {fitnessTasks.length > 0 && (
          <TabTitle
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
            tabName="Fitness"
          />
        )}
        {govTasks.length > 0 && (
          <TabTitle
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
            tabName="Gov"
          />
        )}
        {airfieldTasks.length > 0 && (
          <TabTitle
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
            tabName="Airfield Driver"
          />
        )}
        {vredTasks.length > 0 && (
          <TabTitle
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
            tabName="VRED"
          />
        )}
        {tfat943Tasks.length > 0 && (
          <TabTitle
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
            tabName="TFAT_943"
            tabTitleOverride="TFAT"
          />
        )}
        {tvl943Tasks.length > 0 && (
          <TabTitle
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
            tabName="TVL_943"
            tabTitleOverride="myTraining"
          />
        )}
        {fouoGtc943Tasks.length > 0 && (
          <TabTitle
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
            tabName="FOUO_GTC_943"
            tabTitleOverride="FOUO GTC"
          />
        )}
        {otherTaskTypes.map((taskType) => {
          return (
            <TabTitle
              setCurrentTab={setCurrentTab}
              currentTab={currentTab}
              tabName={taskType}
            />
          )
        })}
      </div>
      {currentTab === "Compliance" && (
        <ComplianceTab
          dueTasks={dueTasks}
          setDueTasks={setDueTasks}
          overdueTasks={overdueTasks}
          setOverdueTasks={setOverdueTasks}
          comingDueTasks={comingDueTasks}
          setComingDueTasks={setComingDueTasks}
          trainee={trainee}
          completedTasks={completedTasks}
          setCompletedTasks={setCompletedTasks}
        />
      )}
      {/* {currentTab === "Documents" && (
        <DocumentsTab
          documents={documents}
          documentLoading={documentLoading}
          getTraineeDocuments={getTraineeDocuments}
        />
      )} */}
      {currentTab === "Information" && (
        <InformationTab trainee={trainee} getInfo={getInfo} />
      )}
      {currentTab === "Messaging" && (
        <MessagingTab messaging={messaging} trainee={trainee} />
      )}
      {currentTab === "Notes" && (
        <NotesTab notes={notes} setNotes={setNotes} trainee={trainee} />
      )}
      {currentTab === "Availability" && (
        <AvailabilityTab
          availabilityLoading={availabilityLoading}
          availability={availability}
        />
      )}
      {currentTab === "G081" && <G081Tab tasks={g081Tasks} />}
      {currentTab === "Dental" && <DentalTab task={dentalTask} />}
      {currentTab === "IMR" && <ImrTab tasks={imrTasks} />}
      {currentTab === "PHA" && <PhaTab task={phaTask} />}
      {currentTab === "PHAQ" && <PhaqTab task={phaqTask} />}
      {currentTab === "GTC" && <GtcTab tasks={gtcTasks} />}
      {currentTab === "Deployable Requirement" && (
        <DeployableTab tasks={deployableTasks} />
      )}
      {currentTab === "Group Tasks" && <GroupTab tasks={groupTasks} />}
      {currentTab === "GTC Training" && <GtcTrainTab tasks={gtcTrainTasks} />}
      {currentTab === "Enlistment" && (
        <ReenlistmentTab tasks={reenlistmentTasks} />
      )}
      {currentTab === "Unpaid Order" && <UnpaidTab tasks={unpaidTasks} />}
      {currentTab === "Travel Voucher" && (
        <TravelVoucherTab tasks={travelTasks} />
      )}
      {currentTab === "Fitness" && <FitnessTab tasks={fitnessTasks} />}
      {currentTab === "TFAT" && <TFATTab tasks={tfatTasks} />}
      {currentTab === "CPR" && <CprTab tasks={cprTasks} />}
      {currentTab === "IMDS" && <ImdsTab tasks={imdsTasks} />}
      {currentTab === "JSTO" && <JstoTab tasks={jstoTasks} />}
      {currentTab === "Gov" && <GovTab tasks={govTasks} />}
      {currentTab === "VRED" && <VREDTab tasks={vredTasks} />}
      {currentTab === "Airfield Driver" && (
        <AirfieldTab tasks={airfieldTasks} />
      )}
      {currentTab === "TFAT_943" && <Tfat943Tab tasks={tfat943Tasks} />}
      {currentTab === "TVL_943" && <Tvl943Tab tasks={tvl943Tasks} />}
      {currentTab === "FOUO_GTC_943" && (
        <FouoGtc943Tab tasks={fouoGtc943Tasks} />
      )}

      {otherTaskTypes.map((taskType) => {
        if (currentTab === taskType) {
          return <GenericTaskTab tasks={otherTasks} />
        }
        return <></>
      })}
    </div>
  )
}

// Main Component
const Profile = () => {
  const user = useSelector((state) => state.user)

  const { userID } = useParams()
  const { teams } = useSelector((state) => state.user)

  const [loading, setLoading] = useState(true)
  const [trainee, setTrainee] = useState({})
  const [error, setError] = useState("")

  const navigate = useNavigate()

  const [documents, setDocuments] = useState([])
  const [documentLoading, setDocumentLoading] = useState(true)
  const [availabilityLoading, setAvailabilityLoading] = useState(true)
  const [availability, setAvailability] = useState([])
  const [notes, setNotes] = useState([])
  const [messaging, setMessaging] = useState([])

  // Modals
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const [mergeModalOpen, setMergeModalOpen] = useState(false)
  const [mergeTrainee, setMergeTrainee] = useState("")
  const [allTrainees, setAllTrainees] = useState([])

  const [moveModalOpen, setMoveModalOpen] = useState(false)
  const [moveShop, setMoveShop] = useState("")
  const [allTeams, setAllTeams] = useState([])
  const [moveSuccessModalOpen, setMoveSuccessModalOpen] = useState(false)

  const [smsModalOpen, setSmsModalOpen] = useState(false)
  const [customModalOpen, setCustomModalOpen] = useState(false)
  const [customMessage, setCustomMessage] = useState("")

  const [modalError, setModalError] = useState("")
  const [modalLoading, setModalLoading] = useState(false)

  // State for all tasks completed this UTA
  const [completedTasks, setCompletedTasks] = useState([])
  const [loadingCompeltedTasks, setLoadingCompletedTasks] = useState(true)

  const [fetchError, setFetchError] = useState(false)

  // Sort all teams into alphabetical order for move trainee dropdown
  useEffect(() => {
    if (Array.isArray(teams)) {
      setAllTeams([...teams].sort())
    }
  }, [teams])

  useEffect(() => {
    if (userID) {
      // Fetch trainee info on load
      axios
        .get(`/get-trainee/${userID}`)
        .then((res) => {
          setTrainee(res.data)
          setLoading(false)
        })
        .catch((err) => {
          if (err.response.status === 404) {
            setError("The profile you are looking for does not exist")
          } else if (err.response.status === 403) {
            setError("You are not authorized to view this profile")
          } else {
            setError("An unexpected error has occured, please try again")
          }
          setLoading(false)
          setFetchError(true)
        })

      // Fetch trainee documents on load
      axios
        .get(`/get-trainee-documents/${userID}`)
        .then((res) => {
          if (res.data) {
            setDocuments(res.data)
            setDocumentLoading(false)
          }
        })
        .catch((err) => {
          setError("An unexpected error has occured, please try again")
        })

      // Fetch availability on load (Availability currently deprecated)
      // axios
      //   .get(`/get-availability/${userID}`)
      //   .then((res) => {
      //     setAvailability(res.data.availability)
      //     setAvailabilityLoading(false)
      //   })
      //   .catch((err) => {
      //     setError("An unexpected error has occured, please try again")
      //   })
      axios
        .get(`/get-trainee-messages/${userID}`)
        .then((res) => {
          setMessaging(res.data)
        })
        .catch((err) => {
          console.log(err)
          setError("An unexpected error has occured, please try again")
        })

      // Fetch notes on load
      axios
        .get(`/notes/?trainee=${userID}`)
        .then((res) => {
          setNotes(res.data)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [userID])

  // Fetch completed tasks once user info is fetched
  useEffect(() => {
    if (trainee && trainee.shop) {
      setLoadingCompletedTasks(true)
      setCompletedTasks([])
      axios
        .get(`/get-task-log/${trainee.shop}`)
        .then((res) => {
          res.data.forEach((t) => {
            if (t.trainee.id === trainee.id) {
              setCompletedTasks((prev) => [...prev, t])
            }
          })
          setLoadingCompletedTasks(false)
        })
        .catch((err) => {
          console.log(err)
          setError("An unexpected error has occured, please try again")
        })
    }
  }, [trainee])

  const getInfo = () => {
    setLoading(true)

    axios
      .get(`/get-trainee/${userID}`)
      .then((res) => {
        setTrainee(res.data)
        setLoading(false)
      })
      .catch((err) => {
        if (err.response.status === 404) {
          setError("The profile you are looking for does not exist")
        } else if (err.response.status === 403) {
          setError("You are not authorized to view this profile")
        } else {
          setError("An unexpected error has occured, please try again")
        }
        setLoading(false)
        setFetchError(true)
      })
  }

  // Make list for merge trainees
  useEffect(() => {
    if (trainee && trainee.id) {
      setAllTrainees([])
      axios.get("/get-trainees").then((res) => {
        res.data.trainees.forEach((t) => {
          if (t.id !== trainee.id) {
            setAllTrainees((prev) => [...prev, t])
          }
        })
      })
    }
  }, [trainee])

  const getTraineeDocuments = () => {
    setDocumentLoading(true)
    axios
      .get(`/get-trainee-documents/${userID}`)
      .then((res) => {
        if (res.data) {
          setDocuments(res.data)
          setDocumentLoading(false)
        }
      })
      .catch((err) => {
        setError("An unexpected error has occured, please try again")
      })
  }

  const handleDelete = (e) => {
    e.preventDefault()
    setModalLoading(true)

    axios
      .delete(`/delete-trainee/${trainee.id}`)
      .then(() => {
        navigate("/home")
      })
      .catch((err) => {
        console.log(err.response)
        setModalLoading(false)
        setModalError("An unexpected error has occured, please try again")
      })
  }

  const handleMerge = (e) => {
    e.preventDefault()

    if (!mergeTrainee || mergeTrainee === "" || !parseInt(mergeTrainee)) {
      setModalError("Please select a trainee to merge")
      return
    }

    setModalLoading(true)
    setModalError("")

    const body = {
      from: trainee.id,
      to: parseInt(mergeTrainee),
    }

    axios
      .post("/merge-trainees", body)
      .then(() => {
        navigate("/home")
      })
      .catch((err) => {
        if (err.response.message) {
          setModalError(err.response.message)
        } else {
          setModalError("An unexpected error has happened, please try again")
        }
        setLoading(false)
      })
  }

  const handleSMS = (e) => {
    e.preventDefault()

    setModalLoading(false)
    setModalError("")

    const body = {
      type: "action",
      trainees: [trainee.id],
    }

    axios
      .post("/notify-trainees", body)
      .then(() => {
        setSmsModalOpen(false)
        setModalError("")
        setModalLoading(false)
      })
      .catch((err) => {
        setModalError("An unexpected error has occured, please try again")
        setModalLoading(false)
      })
  }
  const handleSendCustomMessage = (e) => {
    e.preventDefault()

    if (customMessage.length > 450) {
      setModalError("Please enter a message less than 450 characters")
      return
    }

    if (customMessage.length <= 0) {
      setModalError("Please enter a message")
      return
    }

    setModalLoading(false)
    setModalError("")

    const body = {
      type: "custom",
      trainees: [trainee.id],
      message: customMessage,
    }

    axios
      .post("/notify-trainees", body)
      .then(() => {
        setCustomModalOpen(false)
        setModalError("")
        setModalLoading(false)
      })
      .catch((err) => {
        setModalError("An unexpected error has occured, please try again")
        setModalLoading(false)
      })
  }

  const handleMoveTrainee = (e) => {
    e.preventDefault()

    if (!moveShop || moveShop.length === 0) {
      setModalError("Please select a shop")
    } else {
      setModalLoading(true)
      setModalError("")

      const body = {
        to: moveShop,
        id: trainee.id,
      }

      axios
        .post("/move-trainee", body)
        .then(() => {
          setModalLoading(false)
          setMoveModalOpen(false)
          setMoveShop("")
          setMoveSuccessModalOpen(true)
        })
        .catch((err) => {
          setModalError("An unexpected error has happened, please try again")
          setModalLoading(false)
          console.log(err.response.data)
        })
    }
  }

  if (!fetchError && (loading || documentLoading || loadingCompeltedTasks)) {
    return <LoadingDashboard />
  }

  if (error && error.length > 0) {
    return (
      <DefaultLayout disableTeams>
        <h2>{error}</h2>
      </DefaultLayout>
    )
  }

  const progressBarHeader = (trainee) => {
    return (
      <div className="col-xl-3 col-md-6 mb-4">
        <div className="card h-100">
          <div className="card-body">
            <ProgressBar
              percent={trainee.percentComplete}
              color={
                trainee.percentComplete === 100
                  ? "var(--green)"
                  : trainee.percentComplete < 87
                    ? "var(--red)"
                    : "var(--yellow)"
              }
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <DefaultLayout
      currentPage={`${trainee.lastName}, ${trainee.firstName}`}
      progressBar={
        <ProgressBar
          percent={trainee.percentComplete}
          color={
            trainee.percentComplete === 100
              ? "var(--green)"
              : trainee.percentComplete < 87
                ? "var(--red)"
                : "var(--yellow)"
          }
        />
      }
      disableTeams
    >
      {/* Delete Modal */}
      <Modal
        styles={modalStyles}
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        center
        focusTrapped={false}
      >
        <h5 className="modal-title">
          Are you sure you want to delete{" "}
          <b>
            {trainee.lastName.toUpperCase()}, {trainee.firstName.toUpperCase()}{" "}
            ?
          </b>
        </h5>
        <hr />
        <p className="modal-subtitle">
          Removing this trainee will also delete all of their associated data.
          This action cannot be undone.
        </p>
        {modalError && (
          <div className="h6 text-center text-danger">{modalError}</div>
        )}
        <button
          className="default-button default-button-red float-right"
          style={{ marginLeft: "0.5rem" }}
          onClick={handleDelete}
          disabled={modalLoading}
        >
          Delete Trainee
        </button>
        <button
          className="default-button float-right"
          onClick={() => setDeleteModalOpen(false)}
          disabled={modalLoading}
        >
          Cancel
        </button>
      </Modal>
      {/* Merge Modal */}
      <Modal
        styles={modalStyles}
        open={mergeModalOpen}
        onClose={() => setMergeModalOpen(false)}
        center
        focusTrapped={false}
      >
        <h5 className="modal-title">Merge Trainees</h5>
        <hr />
        <p className="modal-subtitle">
          Merge{" "}
          <b>
            {trainee.firstName.toUpperCase()}, {trainee.lastName.toUpperCase()}
          </b>{" "}
          into...
        </p>
        <form onSubmit={handleMerge}>
          <select
            required
            className="custom-select"
            value={mergeTrainee}
            onChange={(e) => setMergeTrainee(e.target.value)}
          >
            <option value="">Choose...</option>
            {allTrainees.map((t) => {
              return (
                <option value={t.id} key={t.id}>
                  {t.name.toUpperCase()} from {t.shop}
                </option>
              )
            })}
          </select>
          <br />
          {modalError && (
            <div className="h6 text-center text-danger">{modalError}</div>
          )}
          <button
            className="default-button float-right"
            style={{ marginLeft: "0.5rem" }}
            disabled={modalLoading}
          >
            Merge Trainee
          </button>
          <button
            className="default-button default-button-gray float-right"
            onClick={() => setMergeModalOpen(false)}
            disabled={modalLoading}
            type="reset"
          >
            Cancel
          </button>
        </form>
      </Modal>
      {/* SMS Modal */}
      <Modal
        styles={modalStyles}
        open={smsModalOpen}
        onClose={() => setSmsModalOpen(false)}
        center
        focusTrapped={false}
      >
        <h5 className="modal-title">
          Send action reminder to{" "}
          <b>
            {trainee.lastName.toUpperCase()}, {trainee.firstName.toUpperCase()}
          </b>{" "}
          via text SMS?
        </h5>
        <hr />
        {trainee.phoneNumber && trainee.phoneNumber.length > 0 ? (
          <>
            <p className="modal-subtitle">
              EzTrain will send a SMS text message to{" "}
              {trainee.lastName.toUpperCase()},{" "}
              {trainee.firstName.toUpperCase()} of all incomplete tasks
            </p>
            {modalError && (
              <div className="h6 text-center text-danger">{modalError}</div>
            )}
            <button
              className="default-button float-right"
              style={{ marginLeft: "0.5rem" }}
              onClick={handleSMS}
              disabled={modalLoading}
            >
              Send
            </button>
            <button
              className="default-button default-button-gray float-right"
              onClick={() => setSmsModalOpen(false)}
              disabled={modalLoading}
            >
              Cancel
            </button>
          </>
        ) : (
          <p className="modal-subtitle">
            Please add a phone number to {trainee.lastName.toUpperCase()},{" "}
            {trainee.firstName.toUpperCase()}
            before sending an SMS action reminder
          </p>
        )}
      </Modal>
      {/* Custom Message Modal */}
      <Modal
        styles={modalStyles}
        open={customModalOpen}
        onClose={() => setCustomModalOpen(false)}
        center
        focusTrapped={true}
      >
        <h5 className="modal-title">
          Send a custom message to{" "}
          <b>
            {trainee.lastName.toUpperCase()}, {trainee.firstName.toUpperCase()}
          </b>{" "}
        </h5>
        <hr />
        {trainee.phoneNumber && trainee.phoneNumber.length > 0 ? (
          <>
            <div style={{ marginBottom: "1rem" }}>
              <textarea
                rows={4}
                style={{ width: "100%", padding: "0.5rem" }}
                value={customMessage}
                onChange={(e) => setCustomMessage(e.target.value)}
                maxLength={450}
              />
              <p
                style={{
                  float: "right",
                  marginTop: "-5px",
                  fontSize: "0.70rem",
                  color: "gray",
                  marginBottom: 0,
                }}
              >
                {customMessage.length}/450
              </p>
            </div>
            {modalError && (
              <div className="h6 text-center text-danger">{modalError}</div>
            )}
            <button
              className="default-button float-right"
              style={{ marginLeft: "0.5rem" }}
              onClick={handleSendCustomMessage}
              disabled={modalLoading}
            >
              Send
            </button>
            <button
              className="default-button default-button-gray float-right"
              onClick={() => setCustomModalOpen(false)}
              disabled={modalLoading}
            >
              Cancel
            </button>
          </>
        ) : (
          <p className="modal-subtitle">
            Please add a phone number to {trainee.lastName.toUpperCase()},{" "}
            {trainee.firstName.toUpperCase()}
            before sending a custom message
          </p>
        )}
      </Modal>
      {/* Move Trainee Modal */}
      <Modal
        styles={modalStyles}
        open={moveModalOpen}
        onClose={() => setMoveModalOpen(false)}
        center
        focusTrapped={false}
      >
        <h5 className="modal-title">Move Trainee</h5>
        <hr />
        <p className="modal-subtitle">
          Move{" "}
          <b>
            {trainee.lastName}, {trainee.firstName}
          </b>{" "}
          into
        </p>
        <form onSubmit={handleMoveTrainee}>
          <select
            value={moveShop}
            required
            onChange={(e) => setMoveShop(e.target.value)}
            className="custom-select"
          >
            <option value="">Choose...</option>
            {allTeams.map((t) => {
              if (t.code !== trainee.shop) {
                return (
                  <option value={t.supervisorCode} key={t.supervisorCode}>
                    {t.title}
                  </option>
                )
              }
              return <></>
            })}
          </select>
          <br />
          {modalError && (
            <div className="h6 text-center text-danger">{modalError}</div>
          )}
          <button
            className="default-button float-right"
            style={{ marginLeft: "0.5rem" }}
            disabled={modalLoading}
          >
            Move Trainee
          </button>
          <button
            className="default-button default-button-gray float-right"
            onClick={() => setMoveModalOpen(false)}
            disabled={modalLoading}
            type="reset"
          >
            Cancel
          </button>
        </form>
      </Modal>
      {/* Move Trainee Success Modal */}
      <Modal
        styles={modalStyles}
        open={moveSuccessModalOpen}
        onClose={() => setMoveSuccessModalOpen(false)}
        center
        focusTrapped={false}
      >
        <h5 className="modal-title">Move Trainee Success</h5>
        <hr />
        <p className="modal-subtitle">
          You have successfully moved{" "}
          <b>
            {trainee.lastName}, {trainee.firstName}
          </b>
        </p>
      </Modal>
      <div>
        <div className="card">
          <div
            className="card-header"
            style={{
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div>
              <button
                className={
                  user.uta && user.uta.active
                    ? "default-button"
                    : "disabled-button"
                }
                disabled={!user.uta || !user.uta.active}
                onClick={() => {
                  setModalLoading(false)
                  setModalError("")
                  setSmsModalOpen(true)
                }}
              >
                <FontAwesomeIcon icon={faBell} /> Send Action Reminder
              </button>
              <button
                className={
                  user.uta && user.uta.active
                    ? "default-button"
                    : "disabled-button"
                }
                disabled={!user.uta || !user.uta.active}
                onClick={() => {
                  setModalLoading(false)
                  setModalError("")
                  setCustomModalOpen(true)
                  setCustomMessage("")
                }}
              >
                <FontAwesomeIcon icon={faCommentDots} /> Send Custom Message
              </button>
            </div>
            <div>
              <button
                className="default-button"
                onClick={() => {
                  setModalLoading(false)
                  setModalError("")
                  setMoveShop("")
                  setMoveModalOpen(true)
                }}
              >
                <FontAwesomeIcon icon={faUserFriends} /> Move Trainee
              </button>
              <button
                className="default-button"
                onClick={() => {
                  setModalLoading(false)
                  setModalError("")
                  setMergeTrainee("")
                  setMergeModalOpen(true)
                }}
              >
                <FontAwesomeIcon icon={faUserFriends} /> Merge Trainee
              </button>
              <button
                className="default-button default-button-red"
                onClick={() => {
                  setDeleteModalOpen(true)
                  setModalError("")
                  setModalLoading(false)
                }}
              >
                <FontAwesomeIcon icon={faUserSlash} /> Delete Trainee
              </button>
            </div>
          </div>
          <Tabs
            trainee={trainee}
            getInfo={getInfo}
            availability={availability}
            availabilityLoading={availabilityLoading}
            documents={documents}
            documentLoading={documentLoading}
            getTraineeDocuments={getTraineeDocuments}
            notes={notes}
            setNotes={setNotes}
            messaging={messaging}
            completedTasks={completedTasks}
            setCompletedTasks={setCompletedTasks}
          />
        </div>
      </div>
    </DefaultLayout>
  )
}

export default Profile
