import "../styles/layout.css"
import { Link, NavLink } from "react-router-dom"

// Images
import Logo from "../images/logo.png"
import LogoSmall from "../images/logo-small.png"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFileExcel,
  faTachometerAlt,
  faArchive,
  faCalendarAlt,
  faUsers,
  faAddressBook,
  faFileAlt,
  faChartLine,
  faLock,
} from "@fortawesome/free-solid-svg-icons"

import { useSelector } from "react-redux"

// Components
import TeamSelector from "./teamSelector"

const Sidebar = ({ disableTeams }) => {
  const user = useSelector((state) => state.user)

  return (
    <div className="sidebar disable-scrollbars">
      <div className="mt-3 mb-3" style={{ padding: "0.4rem" }}>
        <Link to="/home" style={{ outline: "none" }}>
          <img src={Logo} className="logo" alt="EzTrain Logo" />
        </Link>
      </div>
      <Link to="/home" style={{ outline: "none" }}>
        <img src={LogoSmall} className="logo logo-small" alt="EzTrain Logo" />
      </Link>

      <hr className="sidebar-divider" />

      <NavLink className="nav-link" to="/upload">
        <FontAwesomeIcon icon={faFileExcel} className="sidebar-icon" />
        Upload
      </NavLink>

      <hr className="sidebar-divider" />

      {user.isInstitutionAdmin && (
        <NavLink className="nav-link" to="/admin">
          <FontAwesomeIcon icon={faLock} className="sidebar-icon" />
          Admin Settings
        </NavLink>
      )}

      {!user.isInstitutionAdmin && (
        <NavLink className="nav-link" to="/shop-access">
          <FontAwesomeIcon icon={faLock} className="sidebar-icon" />
          Shop Access Settings
        </NavLink>
      )}

      <NavLink className="nav-link" to="/base-overview">
        <FontAwesomeIcon icon={faChartLine} className="sidebar-icon" />
        My Teams
      </NavLink>

      <NavLink className="nav-link" to="/home">
        <FontAwesomeIcon icon={faTachometerAlt} className="sidebar-icon" />
        Team Overview
      </NavLink>

      <NavLink className="nav-link" to="/communications">
        <FontAwesomeIcon icon={faAddressBook} className="sidebar-icon" />
        Trainee Notifications
      </NavLink>

      <NavLink className="nav-link" to="/tasks">
        <FontAwesomeIcon icon={faArchive} className="sidebar-icon" />
        Completed Task Log
      </NavLink>

      <NavLink className="nav-link" to="/events">
        <FontAwesomeIcon icon={faCalendarAlt} className="sidebar-icon" />
        Events
      </NavLink>

      {/*<NavLink className="nav-link" to="/documents">*/}
      {/*  <FontAwesomeIcon icon={faFileAlt} className="sidebar-icon" />*/}
      {/*  Documents*/}
      {/*</NavLink>*/}

      {/* <NavLink
        
        className="nav-link"
        to="/compliance"
      >
        <FontAwesomeIcon icon={faClipboardCheck} className="sidebar-icon" />
        Compliance
      </NavLink> */}

      <NavLink className="nav-link" to="/attendance">
        <FontAwesomeIcon icon={faUsers} className="sidebar-icon" />
        Attendance
      </NavLink>

      {/* <NavLink
        
        className="nav-link"
        to="/compliance"
      >
        <FontAwesomeIcon icon={faClipboardCheck} className="sidebar-icon" />
        Compliance
      </NavLink> */}

      {!disableTeams && (
        <>
          <hr className="sidebar-divider" />

          <TeamSelector />
        </>
      )}

      <hr className="sidebar-divider" />
    </div>
  )
}

export default Sidebar
