import React from "react"
import { Navigate } from "react-router-dom"
import { useSelector } from "react-redux"

// Route only for unauthenticated users
const UnauthenticatedRoute = ({ children }) => {
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated)

  if (isAuthenticated) {
    return <Navigate to="/home" />
  }

  return <>{children}</>
}

export default UnauthenticatedRoute
