import React, { useState, useEffect } from "react"
import axios from "axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTasks } from "@fortawesome/free-solid-svg-icons"

import TraineeLayout from "../../layouts/traineeLayout"

// Helper Component

const CompleteButton = ({ task_id, traineeTasks, setTraineeTasks }) => {
  const [phase, setPhase] = useState("Complete")

  const updateStatus = (e) => {
    e.preventDefault()
    if (phase === "Complete") {
      setPhase("Confirm?")
    } else {
      setPhase("Completed")
      const data = { id: task_id, status: "f" }
      axios
        .patch("/update-task", data)
        .then((res) => {
          const updatedTasks = traineeTasks.filter(
            (task) => task.id !== task_id
          )
          setTraineeTasks(updatedTasks)
          console.log("updated task")
        })
        .catch((err) =>
          console.log(`There was an error updating ${err.response.data}`)
        )
    }
    phase === "Complete" ? setPhase("Confirm?") : setPhase("Completed")
  }
  return (
    <>
      {phase === "Complete" && (
        <button
          className="btn btn-sm btn-outline-success"
          onClick={updateStatus}
        >
          {phase}
        </button>
      )}
      {phase === "Confirm?" && (
        <button className="btn btn-sm btn-danger" onClick={updateStatus}>
          {phase}
        </button>
      )}
      {phase === "Completed" && (
        <button
          className="btn btn-sm btn-outline-secondary"
          onClick={updateStatus}
        >
          {phase}
        </button>
      )}
    </>
  )
}

const TaskCard = ({ type, organizedTasks, traineeTasks, setTraineeTasks }) => {
  const tasks = organizedTasks[type]
  const containerStyle = {
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
    border: "1px solid #666",
    borderRadius: 4,
    marginTop: "2rem",
    width: "60rem",
    minWidth: "20rem",
  }

  const headerStyle = {
    borderRadius: "4px 4px 0px 0px",
    backgroundColor:
      type === "due" ? "rgb(254, 245, 176)" : "rgb(249, 175, 175)",
    width: "100%",
    minWidth: "20rem",
    padding: 9,
  }

  const taskBodyStyle = {
    backgroundColor: "white",
    width: "100%",
    minWidth: "20rem",
    minHeight: 50,
    padding: 10,
  }

  return (
    <div style={containerStyle}>
      <div style={headerStyle}>
        <FontAwesomeIcon icon={faTasks} />{" "}
        <strong>{type === "due" ? "Due" : "Overdue/Unqualified"} Tasks</strong>
      </div>
      {tasks.map((task) => (
        <div style={taskBodyStyle}>
          <p>{task.title}</p>
          <CompleteButton
            key={task.id}
            task_id={task.id}
            traineeTasks={traineeTasks}
            setTraineeTasks={setTraineeTasks}
          />
        </div>
      ))}
    </div>
  )
}

// Main Component
const TraineeViewTasks = () => {
  const [tasksShown, setTasksShown] = useState("overdue")
  const [traineeTasks, setTraineeTasks] = useState([])

  const [error, setError] = useState("")
  const errorStyle = {
    backgroundColor: "rgb(251, 198, 16)",
    marginRight: 20,
    color: "black",
    fontSize: "16px",
    fontStyle: "bold",
    borderRadius: 3,
    textAlign: "center",
  }

  useEffect(() => {
    axios
      .get("/get-tasks")
      .then((res) => {
        setTraineeTasks(res.data)
      })
      .catch((err) => {
        console.log(err.response.data)
        setError(err.response.data)
      })
  }, [])

  const organizedTasks = {
    overdue: traineeTasks.filter((task) => task.status === "od"),
    due: traineeTasks.filter((task) => task.status !== "od"),
  }

  const buttonStyle = {
    backgroundColor: "rgba(232, 243, 250,0.5)",
    borderRadius: "25px 25px 25px 25px",
    color: "#333",
  }

  return (
    <TraineeLayout>
      <div className="container-fluid">
        <div
          className="h3 text-center"
          style={{ marginTop: "2rem", color: "darkblue" }}
        >
          <strong>My Tasks</strong>
        </div>
        <br />
        <ul
          className="nav mb-3 justify-content-center"
          id="pills-tab"
          role="tablist"
        >
          <button
            style={buttonStyle}
            className="nav-item btn default-button"
            onClick={(e) => {
              e.preventDefault()
              setTasksShown("overdue")
            }}
          >
            Overdue/Unqualified
          </button>
          <button
            style={buttonStyle}
            className="nav-item btn default-button"
            onClick={(e) => {
              e.preventDefault()
              setTasksShown("due")
            }}
          >
            Due
          </button>
        </ul>
        {error && (
          <>
            <div style={errorStyle}>{error}</div>
            <br />
          </>
        )}
        <div style={{ marginBottom: "3rem" }}>
          <TaskCard
            type={tasksShown}
            organizedTasks={organizedTasks}
            traineeTasks={traineeTasks}
            setTraineeTasks={setTraineeTasks}
          />
        </div>
      </div>
    </TraineeLayout>
  )
}

export default TraineeViewTasks
