import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"

import DefaultLayout from "../../layouts/defaultLayout"
import LoadingDashboard from "../../components/loadingDashboard"

import Modal from "react-responsive-modal"
import { modalStyles } from "../../styles/constanStyles"

import axios from "axios"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { setErrors } from "../../redux/userSlice"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileCsv } from "@fortawesome/free-solid-svg-icons"

const Log = () => {
  const [loading, setLoading] = useState(false)
  const [generateLoading, setGenerateLoading] = useState(false)
  const [generateError, setGenerateError] = useState("")

  // Shops user has access to
  const [teams, setTeams] = useState([])

  // Modal States
  const [shopOpen, setShopOpen] = useState(false)

  // Checkbox states
  const [shopList, setShopList] = useState([])

  const [taskLog, setTaskLog] = useState([])
  const [messagesLog, setMessagesLog] = useState([])
  const [loadingMessages, setLoadingMessages] = useState(false)

  const [undoModal, setUndoModal] = useState(false)
  const [undoTask, setUndoTask] = useState({})
  const [modalLoading, setModalLoading] = useState(false)
  const [modalError, setModalError] = useState("")

  const [q, setQ] = useState("")
  const searchParam = ["emp", "trainee", "shop", "finishedAction"]

  const user = useSelector((state) => state.user)

  const dispatch = useDispatch()

  useEffect(() => {
    if (user.status === "idle" || user.status === "success") {
      if (user.currentFocusedTeam && user.currentFocusedTeam.length > 0) {
        setLoading(true)
        setLoadingMessages(true)
        if (user.teams) {
          setTeams(user.teams)
        }
        axios
          .get(`/get-task-log/${user.currentFocusedTeam}`)
          .then((res) => {
            setTaskLog(res.data)
            setLoading(false)
          })
          .catch((err) => {
            console.log(err)
            setLoading(false)
            dispatch(
              setErrors(
                "An unexpected error has occured, please reload and try again"
              )
            )
          })

        // Get Received Messages Log
        axios
          .get(`/get-received-messages/${user.currentFocusedTeam}`)
          .then((res) => {
            setMessagesLog(res.data)
            setLoadingMessages(false)
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }
  }, [user.currentFocusedTeam, user.status])

  const getTaskLog = () => {
    setLoading(true)
    axios
      .get(`/get-task-log/${user.currentFocusedTeam}`)
      .then((res) => {
        setTaskLog(res.data)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
        dispatch(
          setErrors(
            "An unexpected error has occured, please reload and try again"
          )
        )
      })
  }

  const handleGenerate = () => {
    const formData = new FormData()
    shopList.forEach((item) => {
      formData.append("shop_list[]", item)
    })
    setGenerateLoading(true)
    setGenerateError("")
    axios
      .post("/generate-report", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        window.open(res.data.link, "_blank")
        setGenerateLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setGenerateError(
          "An error occured while generating the report, please reload and try again"
        )
        setGenerateLoading(false)
      })
  }

  const handleUndo = () => {
    setModalLoading(true)
    setModalError("")

    const body = {
      id: undoTask.task,
      undo: true,
      status:
        undoTask.lastStatus === "f"
          ? "d"
          : undoTask.lastStatus === "not known"
            ? "nk"
            : undoTask.lastStatus,
    }

    axios
      .patch("/update-task", body)
      .then(() => {
        setUndoModal(false)
        setModalError("")
        setModalLoading(false)
        getTaskLog()
      })
      .catch((err) => {
        console.log(err)
        setModalLoading(false)
        setModalError("An unexpected error happened, please try again")
      })
  }

  if (loading || loadingMessages) {
    return <LoadingDashboard />
  }

  function search(items) {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        if (!item[newItem]) {
          return false
        }
        if (newItem === "trainee" && item[newItem]) {
          return (
            item[newItem].name
              .toString()
              .toLowerCase()
              .indexOf(q.toLowerCase()) > -1
          )
        }
        if (newItem === "shop" && item[newItem]) {
          return (
            item[newItem].title
              .toString()
              .toLowerCase()
              .indexOf(q.toLowerCase()) > -1
          )
        }
        if (item[newItem]) {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          )
        }
        return false
      })
    })
  }

  return (
    <DefaultLayout currentPage="Completed Task Log">
      {/* Undo task modal */}
      <Modal
        styles={modalStyles}
        open={undoModal}
        onClose={() => setUndoModal(false)}
        center
        focusTrapped={false}
      >
        <h5 className="modal-title">Undo task</h5>
        <hr />
        <p className="modal-subtitle">
          Are you sure you want to undo this task:{" "}
          <b>{undoTask.finishedAction && undoTask.finishedAction}</b> for{" "}
          <b>{undoTask.trainee && undoTask.trainee.name}</b>?
        </p>
        {modalError.length > 0 && (
          <p className="h6 text-center text-danger">{modalError}</p>
        )}
        <div className="modal-button-container">
          <button
            className="default-button"
            onClick={() => setUndoModal(false)}
          >
            Cancel
          </button>
          <button
            className="default-button default-button-red"
            onClick={handleUndo}
            disabled={modalLoading}
          >
            Undo
          </button>
        </div>
      </Modal>
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-4">
              <div
                className="card"
                style={{ padding: "1rem", height: "100%", fontSize: "1.1rem" }}
              >
                <h5>Generate Report</h5>
                <button
                  className="default-button"
                  onClick={() => setShopOpen(true)}
                  disabled={generateLoading}
                >
                  <FontAwesomeIcon icon={faFileCsv} /> Generate
                </button>
                {generateError && generateError.length > 0 && (
                  <div className="h6 text-center text-danger">
                    {generateError}
                  </div>
                )}
                <p>
                  Click to see an on-screen prompt to generate a .csv file
                  containing tasks completed this UTA.
                </p>
              </div>
            </div>
            <div className="col-sm-8">
              <div
                className="card"
                style={{ padding: "1rem", height: "100%", fontSize: "1.1rem" }}
              >
                <h5>
                  Received Messages Log{" "}
                  <Link
                    to="/tasks/log"
                    style={{
                      textDecoration: "none",
                      fontSize: "1rem",
                      textDecoration: "underline",
                    }}
                  >
                    <b>Detailed view</b>
                  </Link>
                </h5>
                <p>Overview of received responses from trainees. </p>
                <div style={{ overflow: "auto", maxHeight: "200px" }}>
                  <table
                    className="table"
                    style={{
                      backgroundColor: "#eaecf4",
                      fontSize: "1rem",
                    }}
                  >
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Message Topic</th>
                        <th scope="col">Trainee</th>
                        <th scope="col">Time Stamp</th>
                      </tr>
                    </thead>
                    <tbody>
                      {messagesLog &&
                        messagesLog.length > 0 &&
                        messagesLog
                          .slice(0)
                          .reverse()
                          .map((item, i) => {
                            return (
                              <tr key={i}>
                                <td>{item.messageTitle}</td>
                                <td>{item.trainee.name}</td>
                                <td>
                                  {new Date(item.timeStamp).toLocaleString(
                                    "en-US",
                                    {
                                      hour12: false,
                                    }
                                  )}
                                </td>
                              </tr>
                            )
                          })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="wrapper">
            <div className="search-wrapper" style={{ marginBottom: "1rem" }}>
              <label htmlFor="search-form">
                <input
                  type="search"
                  name="search-form"
                  id="search-form"
                  className="search-input textInput form-control"
                  placeholder="Search for..."
                  value={q}
                  onChange={(e) => setQ(e.target.value)}
                />
                <span className="sr-only">Search names here</span>
              </label>
            </div>
            <table
              className="table table-hover text-center"
              style={{ backgroundColor: "#eaecf4" }}
            >
              <thead className="thead-light">
                <tr>
                  <th scope="col">EMP #</th>
                  <th scope="col">Full Name</th>
                  <th scope="col">Shop</th>
                  <th scope="col">PFX</th>
                  <th scope="col">Course Code</th>
                  <th scope="col">Action Title</th>
                  <th scope="col">Action</th>
                  <th scope="col">Completed By</th>
                  <th scope="col">Time Stamp</th>
                  <th scope="col">Undo</th>
                </tr>
              </thead>
              <tbody>
                {search(taskLog).map((task, ti) => {
                  return (
                    <tr key={ti}>
                      <td>{task.emp}</td>
                      <td>{task.trainee.name}</td>
                      <td>{task.shop.title}</td>
                      <td>{task.pfx}</td>
                      <td>{task.course}</td>
                      <td>{task.finishedAction}</td>
                      <td>
                        {task.cur_status
                          ? task.cur_status.charAt(0).toUpperCase() +
                            task.cur_status.substring(1)
                          : ""}
                      </td>
                      <td>{task.author ? task.author : "Trainee"}</td>
                      <td>
                        {new Date(task.timeStamp).toLocaleString("en-US", {
                          hour12: false,
                        })}
                      </td>
                      <td>
                        <button
                          onClick={() => {
                            setUndoTask(task)
                            setUndoModal(true)
                            setModalError("")
                            setModalLoading(false)
                          }}
                          className="default-button"
                          style={{
                            margin: 0,
                            fontSize: "0.8rem",
                            padding: "0.25rem",
                          }}
                        >
                          Undo
                        </button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* Generate Report Modal */}
      <Modal
        styles={modalStyles}
        open={shopOpen}
        onClose={() => setShopOpen(false)}
        center
      >
        <h5 className="modal-title">Select Shops to Generate a Report For</h5>
        <hr />
        <p className="modal-subtitle">
          This will generate a report (.csv file) containing all tasks completed
          this UTA for the following selected shops:
        </p>
        {teams.map((shop, index) => {
          return (
            <div key={index}>
              <label style={{ cursor: "pointer" }} key={shop.code}>
                <input
                  type="checkbox"
                  checked={shopList.includes(shop.code)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setShopList((list) => [...list, shop.code])
                    } else {
                      setShopList((list) =>
                        list.filter((item) => item !== shop.code)
                      )
                    }
                  }}
                />
                <b> {shop.title.toUpperCase()}</b>
              </label>
            </div>
          )
        })}
        <div className="modal-button-container">
          <button
            className="default-button default-button-red"
            onClick={() => setShopOpen(false)}
          >
            Cancel
          </button>
          <button
            className="default-button"
            disabled={loading}
            onClick={handleGenerate}
          >
            Download
          </button>
        </div>
      </Modal>
    </DefaultLayout>
  )
}

export default Log
