import React, { useState, useEffect } from "react"
import axios from "axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faTimes, faCheck } from "@fortawesome/free-solid-svg-icons"

import TraineeLayout from "../../layouts/traineeLayout"

const TraineeAvailability = () => {
  const [editing, setEditing] = useState(false)

  // Keep track of mouse up and down
  const [mouseDown, setMouseDown] = useState(false)
  document.body.onmousedown = () => {
    setMouseDown(true)
  }
  document.body.onmouseup = () => {
    setMouseDown(false)
  }

  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
  const dayIndexToVerboseDay = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ]
  const times = ["12:00AM", "12:30AM"]
  for (var i = 1; i < 24; i++) {
    var j = i % 12
    var ending = i > 11 ? "PM" : "AM"
    var onHour = j < 10 ? "0" + j + ":00" + ending : "" + j + ":00" + ending
    var halfHour = j < 10 ? "0" + j + ":30" + ending : "" + j + ":30" + ending
    times.push(onHour)
    times.push(halfHour)
  }

  // Keep track of the active cells of the table
  var tableOutline = times.map((row) => days.map((col) => false))

  const availabilityToActiveCell = (availability) => {
    const activeCell = tableOutline.map((row) => row.map((col) => col))
    for (const timeBlock of availability) {
      const { day, start_time, end_time } = timeBlock

      const day_index = dayIndexToVerboseDay.indexOf(day)
      let hour = parseInt(start_time.slice(0, 2))
      const suffix = hour >= 12 ? "PM" : "AM"
      hour = hour > 12 ? hour - 12 : hour // Account for 24hr clock
      hour = hour === 0 ? 12 : hour // Account for midnight
      const prefix = hour < 10 ? "0" : ""
      const readable_time = prefix + `${hour}${start_time.slice(2, 5)}` + suffix
      const time_slot_row_index = times.indexOf(readable_time)
      activeCell[time_slot_row_index][day_index] = true
    }
    return activeCell
  }

  const [activeCell, setActiveCell] = useState(
    tableOutline.map((row) => row.map((col) => col))
  )

  const getAvailability = () => {
    console.log("/get-availability")
    axios
      .get(`/get-availability`)
      .then((res) => {
        setActiveCell(availabilityToActiveCell(res.data.availability))
      })
      .catch((err) => {
        alert("There was an error while getting availability")
      })
  }

  useEffect(() => {
    getAvailability()
  }, [])

  const [pendingCell, setPendingCell] = useState(
    tableOutline.map((row) => row.map((col) => col))
  )

  const makeMilitaryTime = (readableTime) => {
    /* 04:00PM ===>  16:00:00 */
    const suffix = readableTime.slice(5, 7)
    let hour = parseInt(readableTime.slice(0, 2)) + (suffix === "PM") * 12
    if (suffix === "AM" && hour === 12) {
      hour = 0
    }
    const prefix = hour < 10 ? "0" : ""
    return prefix + `${hour.toString()}:${readableTime.slice(3, 5)}:00`
  }

  const makeReadableTime = (militaryTime) => {
    /* 16:00:00 ===>  04:00PM */
    let hour = parseInt(militaryTime.slice(0, 2))
    const suffix = hour >= 12 ? "PM" : "AM"
    if (hour === 12 && suffix === "AM") {
      hour = 12
    }
    const standard_hour = hour <= 12 ? hour : hour - 12
    const hourString =
      standard_hour < 10
        ? "0" + standard_hour.toString()
        : standard_hour.toString()
    return hourString + militaryTime.slice(2, 5) + suffix
  }

  const activeCellToAvailability = (activeCell) => {
    const availability = []
    for (
      let time_slot_row_index = 0;
      time_slot_row_index < activeCell.length;
      time_slot_row_index++
    ) {
      const days_at_timeslot = activeCell[time_slot_row_index]
      for (
        let day_index = 0;
        day_index < days_at_timeslot.length;
        day_index++
      ) {
        const is_available_now = days_at_timeslot[day_index]
        if (is_available_now) {
          let cur_start_time = times[time_slot_row_index]
          let cur_end_time =
            time_slot_row_index !== times.length - 1
              ? times[time_slot_row_index + 1]
              : "11:59PM"
          availability.push({
            day: dayIndexToVerboseDay[day_index],
            start_time: makeMilitaryTime(cur_start_time),
            end_time: makeMilitaryTime(cur_end_time),
          })
        }
      }
    }
    return availability
  }

  const editAvailability = (e) => {
    e.preventDefault()
    // trying to copy activeCell
    var copiedActiveCell = activeCell.map((row) => row.map((col) => col))
    setPendingCell(copiedActiveCell)
    setEditing(true)
  }

  const saveToBackend = (pendingCell) => {
    const body = {
      availability: activeCellToAvailability(pendingCell),
    }
    console.log("/update-availability")
    axios
      .post(`/update-availability`, body)
      .then(() => {})
      .catch((err) =>
        alert(
          "Sorry, but we're having an issue saving your availability. Please try again later."
        )
      )
  }

  const saveAvailability = (e) => {
    e.preventDefault()
    setActiveCell(pendingCell.map((row) => row.map((col) => col)))
    saveToBackend(pendingCell)
    setEditing(false)
  }

  const cancelEditing = (e) => {
    e.preventDefault()
    setPendingCell(tableOutline.map((row) => row.map((col) => col)))
    setEditing(false)
  }

  const handleMouseOver = (e, row, col) => {
    e.preventDefault()
    if (mouseDown) {
      changeColor(e, row, col)
    }
  }

  const changeColor = (e, row, col) => {
    e.preventDefault()
    var newArray = pendingCell.map((r) => r.map((c) => c))
    newArray[row][col] = !newArray[row][col]
    setPendingCell(newArray)
  }

  return (
    <TraineeLayout>
      <div className="container-fluid">
        <div
          className="h3 text-center"
          style={{ marginTop: "2rem", color: "darkblue" }}
        >
          <strong>My Availability</strong>
          <div className="text-center">
            {editing && (
              <>
                <button
                  id="cancel-button"
                  type="button"
                  className="btn btn-outline-secondary mt-3 my-1 mr-4 cancel"
                  style={{ marginRight: 20 }}
                  onClick={cancelEditing}
                >
                  <FontAwesomeIcon icon={faTimes} /> Cancel
                </button>
                <button
                  id="save-button"
                  type="button"
                  className="btn btn-outline-primary mt-3 my-1 save-avail"
                  onClick={saveAvailability}
                >
                  <FontAwesomeIcon icon={faCheck} /> Save
                </button>
                <div
                  className="edit-avail-desc mt-3"
                  style={{ fontSize: "1rem", color: "rgba(100,100,100,0.9)" }}
                >
                  Click and drag to select the blocks of time that you are
                  available.
                </div>
              </>
            )}
            {!editing && (
              <button
                id="edit-button"
                type="button"
                className="btn btn-outline-primary mt-3 my-1 edit-avail"
                onClick={editAvailability}
              >
                <FontAwesomeIcon icon={faEdit} /> Edit Availability
              </button>
            )}
          </div>
        </div>
        <div className="my-4">
          <div
            className="table-responsive mx-auto"
            style={{
              maxWidth: "800px",
              maxHeight: "600px",
              borderBottom: "1px solid #e3e6f0",
            }}
          >
            {/* Table for viewing availability */}
            {!editing && (
              <table
                className="table table-bordered table-sm mb-0 table-view"
                id="editing-table"
              >
                <thead>
                  <tr>
                    <th
                      key={`upperCorner`}
                      className="table-secondary"
                      style={{
                        width: "19.5%",
                        position: "sticky",
                        top: "-1px",
                      }}
                    />
                    {days.map((day) => (
                      <th
                        key={`day${day}`}
                        scope="col"
                        className="text-center table-secondary"
                        style={{
                          width: "11.5%",
                          position: "sticky",
                          top: "-1px",
                        }}
                      >
                        {day}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody style={{ maxHeight: "300px" }}>
                  {times.map((time, row) => (
                    <tr>
                      <th
                        key={`view${time}`}
                        scope="row"
                        col="view-time"
                        row={`view-r${row}`}
                        style={{
                          textAlign: "right",
                          color: "rgb(100,100,100)",
                          backgroundColor: "white",
                        }}
                      >
                        {time}
                      </th>
                      {days.map((day, col) => (
                        <td
                          key={`v_r${row}_c${col}`}
                          id={`v_r${row}_c${col}`}
                          className={`cur_availability ${
                            activeCell[row][col] ? "bg-success" : "bg-light"
                          }`}
                          row={`view-r${row}`}
                          col={`view-c${col}`}
                        />
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {/* Table for editing availability */}
            {editing && (
              <>
                <button
                  type="button"
                  className="btn btn-outline-danger mt-3 my-1 mr-4"
                  onClick={(e) => {
                    setPendingCell(tableOutline)
                  }}
                >
                  Reset
                </button>
                <table className="table table-bordered table-sm mb-0 table-edit">
                  <thead>
                    <tr>
                      <th
                        className="table-secondary"
                        style={{
                          width: "19.5%",
                          position: "sticky",
                          top: "-1px",
                        }}
                      />
                      {days.map((day) => (
                        <th
                          key={`th${day}`}
                          scope="col"
                          className="text-center table-secondary"
                          style={{
                            width: "11.5%",
                            position: "sticky",
                            top: "-1px",
                          }}
                        >
                          {day}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody style={{ maxHeight: "300px" }}>
                    {times.map((time, row) => (
                      <tr>
                        <th
                          key={`edit${time}`}
                          scope="row"
                          col="view-time"
                          row={`view-r${row}`}
                          style={{
                            textAlign: "right",
                            color: "rgb(100,100,100)",
                            backgroundColor: "white",
                          }}
                        >
                          {time}
                        </th>
                        {days.map((day, col) => (
                          <td
                            key={`e_r${row}_c${col}`}
                            id={`e_r${row}_c${col}`}
                            className={`time-block cur_availability ${
                              pendingCell[row][col] ? "bg-success" : "bg-light"
                            }`}
                            row={`view-r${row}`}
                            col={`view-c${col}`}
                            onMouseDown={(e) => changeColor(e, row, col)}
                            onMouseOver={(e) => handleMouseOver(e, row, col)}
                          />
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>
      </div>
    </TraineeLayout>
  )
}

export default TraineeAvailability
